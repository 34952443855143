export enum EntityState {
  DELETED = 'delete',
  INVALID = 'invalid',
  NEW = 'add',
  CHANGED = 'update',
  SAVED = 'saved',
  ERRORS = 'errors', // deprecated
}

export enum EntityEvent {
  STORED = 'stored',
  UPDATED = 'updated',
  DESTROYED = 'destroyed',
}
