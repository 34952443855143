<template>
  <form class="repository-import-form" @submit.prevent="onSubmitForm">
    <UIFileField
      v-model="json"
      v-model:error="errorMessage"
      v-model:file-name="fileName"
      v-model:loading="loading"
      data-refid="repositoryFileField"
      :disabled="loading"
      name="file"
      placeholder="Chose json file"
      accept=".json"
    />
    <div class="repository-import-form__buttons">
      <UIButtonClose data-refid="repositoryCloseButton" @click="handleClose" />
      <UIButtonSave
        v-bind="{ disabled, loading }"
        data-refid="repositoryImportButton"
        label="Import"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { TASK_STATUS } from '@/store/tasks/utils/enums'

import { ROUTE_NAME } from '@/const'
import { NOTIFICATION_DELAY } from '@/const/common'

import { handleCatchedError } from '@/helpers/common'

import { NotificationTypes, useNotifications } from '@/plugins/notification'

import router from '@/router'

import { useFilesStore } from '@/store/files'
import { useRepositoriesStore } from '@/store/repositories'
import { useTasksStore } from '@/store/tasks'
import { useTransactionsStore } from '@/store/transactions'

import { UIButtonClose, UIButtonSave, UIFileField } from '@ui'

type Emits = {
  close: []
}

const emit = defineEmits<Emits>()

const filesStore = useFilesStore()
const repositoriesStore = useRepositoriesStore()
const transactionsStore = useTransactionsStore()
const tasksStore = useTasksStore()

const json = ref<FormData>()
const fileName = ref<string>()
const loading = ref(false)
const errorMessage = ref<string>()

const handleClose = () => {
  emit('close')
}

const disabled = computed(() => !json.value || loading.value)

const onSubmitForm = async () => {
  const { progress, update } = useNotifications()

  let notifyInstance
  errorMessage.value = ''
  try {
    if (!json.value) {
      errorMessage.value = 'Nothing to import'
      return
    }
    loading.value = true
    handleClose()

    notifyInstance = await progress({
      message: 'Uploading repository file to server',
      description: 'This may take several minutes',
    })

    const filename = (json?.value?.get('filename') as string) || 'error'
    const repoName = filename.split('.').shift()
    if (!repoName) throw Error('Unknown name of imported repository')
    const res = await filesStore.fetchUploadURL({
      type: 'repository',
      repoName,
    })
    const taskId = res.task_id
    if (!taskId) {
      await update(notifyInstance, {
        type: 'error',
        message: 'Wrong task id during repository import',
        description:
          'Try to import the repository again. If the error repeats, contact support',
      })
      return
    }

    const payload = new FormData()
    if (res.fields) {
      Object.entries(res.fields).forEach(([key, value]) => {
        payload.append(key, value)
      })
    }
    payload.append('file', json?.value?.get('file') as File)
    const uploadRes = await filesStore.uploadFile({
      url: res.url,
      payload,
    })

    if (!uploadRes) {
      await update(notifyInstance, {
        type: 'error',
        message: 'Error uploading repository file to server',
        description:
          'Try to import the repository again. If the error repeats, contact support',
      })
      return
    }

    let type: NotificationTypes
    let message: string
    let description: string | undefined
    try {
      await tasksStore.observe(taskId)
      repositoriesStore.fetchRepositories()
      type = 'success'
      message = 'Repository successfully imported'
      description = undefined
    } catch (e) {
      type = 'error'
      message = 'Error'
      description = `${e}`
    }
    await update(
      notifyInstance,
      {
        type,
        message,
        description,
      },
      NOTIFICATION_DELAY,
    )

    loading.value = false
  } catch (e) {
    handleCatchedError(e as string)
    loading.value = false
    if (!notifyInstance) return
    await update(notifyInstance, {
      type: 'error',
      message: 'Failed to upload file',
      description:
        'Try to import the repository again. If the error repeats, contact support',
    })
  }
}

const routerCanBePushed = ref(false)

const transactionsLoading = computed(() => transactionsStore.loading)
watch(transactionsLoading, (value, old) => {
  if (old && !value && routerCanBePushed.value) {
    router.push({ name: ROUTE_NAME.TRANSACTIONS })
    handleClose()
    routerCanBePushed.value = true
  }
})
</script>

<script lang="ts">
export default {
  name: 'RepositoriesFormImport',
}
</script>

<style scoped lang="postcss">
.repository-import-form {
  @apply flex flex-col gap-4;

  &__buttons {
    @apply flex justify-end gap-2;
  }
}
</style>
