<template>
  <UIButton
    v-bind="{ icon, label, numberLabel, variant, ...$attrs }"
    data-refid="filter-button"
    class="ui-filter__toggle-button"
  />
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'

import { I18nTranslate } from '@types'

import { UIButton } from '@ui'
import { FunnelIcon as icon } from '@heroicons/vue/24/outline'

type Props = {
  count?: number
}

const { count } = defineProps<Props>()

const t = inject<I18nTranslate>('t', (data: string) => data)

const label = computed(() => (count ? undefined : t('Filter')))
const numberLabel = computed(() => count)
const variant = computed(() => (count ? 'light-secondary' : 'light-gray'))
</script>

<script lang="ts">
export default {
  name: 'FilterToggleButton',
}
</script>

<style scoped lang="postcss">
.toggle-button {
  @apply h-full;
}
.filter__panel {
  @apply flex justify-between;
  @apply mb-2;

  h3 {
    @apply text-sm;
    @apply text-gray-500;
  }

  button {
    @apply !px-2;
  }
}
.filter__list {
  @apply space-y-2 mb-3;
}
.filter__add {
  @apply flex items-center justify-between;
}
</style>
