import { defineStore } from 'pinia'
import { ref } from 'vue'

export enum EventType {
  CLOSE,
  UPDATE,
}

// linux like return: 0 - success, another value - failure
export type ListenerFunction = (
  type: EventType,
  data?: { newIndex?: number; force?: boolean },
) => Promise<number>

type StackItem = {
  id: string
  listener: ListenerFunction
}
export const useFloatingPanelStore = defineStore('floatingPanel', () => {
  const stack = ref<StackItem[]>([])
  const closeLatest = async (): Promise<number> => {
    if (stack.value.length <= 0) return 1
    if (
      (await stack.value[stack.value.length - 1].listener(EventType.CLOSE)) ===
      0
    )
      stack.value.pop()
    return 0
  }

  const push = (id: string, listener: ListenerFunction) => {
    stack.value.push({ id, listener })
    listener(EventType.UPDATE, {
      newIndex: stack.value.length,
    }).then()
  }

  const remove = async (id: string): Promise<number> => {
    const aid = stack.value.findIndex(item => item.id === id)
    if (~aid) {
      const result = await stack.value[aid].listener(EventType.CLOSE)
      if (result !== 0) return result
      stack.value.splice(aid, 1)
      for (let i = aid; i <= stack.value.length - 1; i++) {
        stack.value[i].listener(EventType.UPDATE, { newIndex: i }).then()
      }
      return 0
    }
    return 1
  }

  const clearStack = () => {
    for (let i = stack.value.length - 1; i >= 0; i--) {
      stack.value[i].listener(EventType.CLOSE, { force: true }).then()
    }
  }

  return { closeLatest, push, remove, clearStack }
})
