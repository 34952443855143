<template>
  <div class="relative flex items-start">
    <div class="flex items-center h-5">
      <input
        :id="id"
        v-model="modelValue"
        type="checkbox"
        class="checkbox"
        v-bind="{ value, disabled }"
      />
    </div>
    <label :for="id" class="checkbox__label" :class="labelClasses">
      <slot>{{ label }}</slot>
    </label>
  </div>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance } from 'vue'

type Props = {
  label?: string
  disabled?: boolean
  light?: boolean
  value?: any
}

const props = defineProps<Props>()

const modelValue = defineModel<boolean>({ required: true })

const id = computed(() => getCurrentInstance()?.uid.toString())

const labelClasses = computed(() => ({
  'checkbox__label--light': props.light,
}))
</script>

<script lang="ts">
export default {
  name: 'UICheckbox',
}
</script>
