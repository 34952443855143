import { EntityFields } from '@/entities/utils/types'
import { Document } from './types'

export { DOCUMENT_ID_PREFIX } from '@/const/common'

export const DOCUMENT_FIELD: EntityFields<Document> = {
  ID: 'id',
  NAME: 'name',
  PATH: 'path',
  ASSET_ID: 'asset_id',
  REPOSITORY_ID: 'repository_id',
  IS_EXTERNAL: 'is_external',
  TAGS: 'tags',
  CREATED_AT: 'created_at',
  CREATED_BY: 'created_by',
  UPDATED_AT: 'updated_at',
  UPDATED_BY: 'updated_by',
}

export const DOCUMENT_DEFAULT_DATA = {
  id: '',
  name: '',
  path: '',
  asset_id: null,
  repository_id: '',
}

export const DOCUMENT_FIELD_LABEL = {
  [DOCUMENT_FIELD.NAME]: 'Document',
  [DOCUMENT_FIELD.PATH]: 'Path',
  [DOCUMENT_FIELD.ASSET_ID]: 'Asset',
  [DOCUMENT_FIELD.TAGS]: 'Tags',
  [DOCUMENT_FIELD.CREATED_AT]: 'Created at',
  [DOCUMENT_FIELD.UPDATED_AT]: 'Updated at',
}
