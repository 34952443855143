import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { Settings } from 'luxon'

import { Capacitor } from '@capacitor/core'

import FloatingVue, { vTooltip } from 'floating-vue'
import 'floating-vue/dist/style.css'

import '@/assets/styles/index.css'

import App from '@/App.vue'

import router from '@/router'

import circularTab from '@/directives/circularTab'

import alerts from '@/plugins/alerts'
import intercom from '@/plugins/intercom'
import notifications from '@/plugins/notification'
import { i18nInit } from '@/plugins/i18n'

import auth from '@/services/auth'
import faro, { initFaro } from '@/services/faro'
import useWSService from '@/services/ws'

import CapacitorInit from './capacitor-init'

Settings.defaultLocale = 'en'

const {
  VITE_APP_APP_CLIENT_ID,
  VITE_APP_AWS_REGION,
  VITE_APP_FARO_URL,
  VITE_APP_FARO_SECRET,
  VITE_APP_INTERCOM_WORKSPACE_ID,
  VITE_APP_MODE,
  VITE_APP_OAUTH_DOMAIN,
  VITE_APP_USER_POOL_ID,
  VITE_APP_WS_URL,
  VITE_APP_WS_DISABLED,
} = import.meta.env

auth(
  VITE_APP_USER_POOL_ID,
  VITE_APP_APP_CLIENT_ID,
  VITE_APP_AWS_REGION,
  VITE_APP_OAUTH_DOMAIN,
)

i18nInit()

const app = createApp(App)

// TODO: move app versioning into discrete plugin
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const versionArr = (__APP_VERSION__ as string)
  .match(/\d+.\d+.\d+/gm)?.[0]
  .match(/\d+/gm)

app.use(router)
app.use(notifications)
app.use(alerts)

if (VITE_APP_FARO_SECRET) {
  initFaro(VITE_APP_FARO_URL, VITE_APP_FARO_SECRET, VITE_APP_MODE)
}

const isWSDisabled = VITE_APP_WS_DISABLED === 'true'
app.provide('wsDisabled', isWSDisabled)
let ws
if (!isWSDisabled) {
  ws = useWSService(VITE_APP_WS_URL)
}
app.provide('ws', ws)

app.use(intercom, {
  app_id: VITE_APP_INTERCOM_WORKSPACE_ID,
  app_stand: 'stage',
  app_version:
    versionArr?.length === 3
      ? +versionArr[1] * 1000000 + +versionArr[1] * 1000 + +versionArr[2]
      : Number.MAX_SAFE_INTEGER,
  app_mode: VITE_APP_MODE,
})

FloatingVue.options.themes.tooltip.placement = 'bottom'
app.directive('tooltip', vTooltip)

app.directive('circular-tab', circularTab)

if (Capacitor.isNativePlatform()) {
  CapacitorInit().then()
}

app.use(createPinia()).mount('#app')

window.addEventListener('unhandledrejection', (e: PromiseRejectionEvent) => {
  if (!faro) return
  faro.log.default([JSON.stringify(e)])
})
