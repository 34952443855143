<template>
  <div class="header__bell" @click="handleClick">
    <span class="sr-only">View notifications</span>
    <AppHeaderIcon :icon="BellIcon" />
    <span class="header__bell__point"></span>
  </div>
</template>

<script setup lang="ts">
import { BellIcon } from '@heroicons/vue/24/outline'

import { AppHeaderIcon } from '@app'

type Emits = {
  click: []
}

const emit = defineEmits<Emits>()

const handleClick = () => {
  emit('click')
}
</script>

<script lang="ts">
export default {
  name: 'HeaderNotificationsIcon',
}
</script>

<style>
.header__bell {
  @apply mr-4;
  @apply relative;
}
.header__bell__point {
  @apply w-1.5 h-1.5;
  @apply absolute top-0 right-0;
  @apply rounded-full;
  @apply bg-red-500;
}
</style>
