import { Component } from 'vue'
import { ModalEvent, ModalState } from './enums'
import { addEventListener, callEvent, removeEventListener } from './helpers'

export type ModalEventCallback = (...props: any) => any
export type ModalEvents = {
  [key in ModalEvent]?: ModalEventCallback[]
}
export type ModalProps = Record<string, any>

export class ModalEntity {
  private component: Component

  constructor(component: Component) {
    this.component = component
  }

  getModal() {
    return this.component
  }
}

export class ModalClass<T> {
  state: ModalState = ModalState.CLOSED
  zIndex = 0
  props: ModalProps | undefined
  entity: T

  protected events: ModalEvents = {}

  constructor(entity: T) {
    this.entity = entity
  }

  protected callEvent(name: ModalEvent) {
    callEvent(this.events, name)
  }

  addEventListener(name: ModalEvent, callback: ModalEventCallback) {
    addEventListener(this.events, name, callback)
  }

  removeEventListener(name: ModalEvent, callback: ModalEventCallback) {
    removeEventListener(this.events, name, callback)
  }

  open(zIndex: number, props?: ModalProps) {
    this.zIndex = zIndex
    this.props = props
    this.state = ModalState.OPENED
    this.callEvent(ModalEvent.OPEN)
  }

  close() {
    this.state = ModalState.CLOSED
    this.callEvent(ModalEvent.CLOSE)
  }

  conditionalClose() {
    this.close()
  }

  hide() {
    this.state = ModalState.HIDDEN
    this.callEvent(ModalEvent.HIDE)
  }

  show(zIndex: number) {
    this.zIndex = zIndex
    this.state = ModalState.OPENED
    this.callEvent(ModalEvent.SHOW)
  }
}
