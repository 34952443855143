<template>
  <AppGlobalSearch
    v-model:isOpen="isOpen"
    v-model:search="search"
    v-model:currentTag="currentTag"
    v-bind="{ itemsFound, tags }"
    @result:click="handleClickResult"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { SearchSummary } from '@types'
import { useRouter } from 'vue-router'

import { ROUTE_NAME } from '@/const'

import { TransactionClass } from '@/entities/transactions'

import { useModalsStore } from '@/store/modals'
import { useSearchStore } from '@/store/search'
import { useTransactionsStore } from '@/store/transactions'
import { useTransactionsBunchStore } from '@/store/transactions/bunch'

import { AppGlobalSearch } from '@app'

const router = useRouter()

const modalsStore = useModalsStore()
const searchStore = useSearchStore()
const transactionsStore = useTransactionsStore()
const transactionsBunchStore = useTransactionsBunchStore()

const isOpen = computed({
  get() {
    return searchStore.open
  },
  set(value) {
    searchStore.open = value
  },
})

const search = computed({
  get() {
    return searchStore.string
  },
  set(value) {
    searchStore.string = value
  },
})

const currentTag = computed({
  get() {
    return searchStore.currentTag
  },
  set(value) {
    searchStore.currentTag = value
  },
})

const tags = computed(() => searchStore.tags)

const itemsFound = computed(() =>
  searchStore.string.trim() !== '' ? searchStore.getSearchSummary : [],
)

const handleClickResult = async (item: SearchSummary) => {
  if (
    item.route.name === ROUTE_NAME.TRANSACTIONS_ITEM &&
    item.route?.params?.id &&
    !transactionsBunchStore.getList.has(item.route?.params?.id)
  ) {
    const transaction = await transactionsStore.fetchSingle(
      item.route.params.id.toString(),
    )
    const instance = new TransactionClass(transaction)
    const modalInstance = modalsStore.init(instance.id, instance)
    modalInstance?.open(modalsStore.getZIndex())
    return
  }
  router.replace(item.route)
}
</script>

<script lang="ts">
export default {
  name: 'HeaderSearch',
}
</script>

<style lang="postcss"></style>
