<template>
  <UILoading
    v-if="isLoading"
    message="Loading prices..."
    class="asset-summary-prices__loading"
  />
  <template v-else-if="prices?.length">
    <AssetTabPricesChart
      v-if="items.length"
      v-bind="{ instance, items, priceSource }"
    >
      <UIInputDropdown
        v-model="priceSource"
        id-key="key"
        size="xxsmall"
        :data="ASSET_PRICES_LIST"
      />
    </AssetTabPricesChart>
    <UILayoutNoData
      v-else
      hide-question-icon
      inline
      class="asset-summary-prices__no-data"
    />
  </template>
  <UILayoutNoData
    v-else
    hide-question-icon
    inline
    class="asset-summary-prices__no-data"
  />
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount } from 'vue'
import { orderBy } from 'lodash'

import { AssetClass } from '@/entities/assets'
import { AssetPriceSource } from './utils/enums'

import { ASSET_PRICES_LIST } from './utils/const'

import { filterPricesBySource, handlePricesOnMount } from './utils/helpers'

import { useAssetsStore } from '@/store/assets'

import { UIInputDropdown, UILayoutNoData, UILoading } from '@ui'
import AssetTabPricesChart from './AssetTabPricesChart.vue'

type Props = {
  instance: AssetClass
}

const props = defineProps<Props>()

const assetsStore = useAssetsStore()

const loading = ref(true)

const priceSource = ref(AssetPriceSource.ALL)

const isLoading = computed(
  () =>
    loading.value ||
    assetsStore.loadingPrices ||
    assetsStore.loadingSecurityMasterPrices ||
    assetsStore.loadingPricesAction,
)

const prices = computed(() => props.instance.prices)

const items = computed(() => {
  const filterFn = filterPricesBySource(priceSource.value)
  return orderBy(prices.value?.filter(filterFn), 'date', 'asc')
})

onBeforeMount(async () => {
  loading.value = true
  const result = await handlePricesOnMount(props.instance, assetsStore)
  // eslint-disable-next-line vue/no-mutating-props
  props.instance.prices = result
  loading.value = false
})
</script>

<script lang="ts">
export default {
  name: 'AssetSummaryPrices',
}
</script>

<style lang="postcss">
.asset-summary-prices {
  &__loading,
  &__no-data {
    @apply flex-auto;
  }
}
</style>
