<template>
  <Dropdown
    v-bind="{ container, disabled, placement, popperClass }"
    strategy="fixed"
    theme="dropdown"
    @show="handleShow"
    @hide="handleHide"
  >
    <slot v-bind="{ displayValue, isShown }">
      <div class="ui-dropdown__toggler" :class="togglerClasses">
        <span class="ui-dropdown__toggler-text">{{ displayValue }}</span>
        <component
          :is="chevronComponent"
          class="ui-dropdown__toggler-icon"
          aria-hidden="true"
        />
      </div>
    </slot>
    <template #popper="{ hide }">
      <slot name="before" v-bind="{ hide }" />
      <slot name="popper" v-bind="{ hide }">
        <div class="ui-dropdown__items">
          <Item
            v-for="(item, index) in items"
            :key="getId(item)"
            v-bind="{
              idKey,
              index,
              item,
              reverse,
              static,
              valueKey,
              ...item.attrs,
            }"
            :active="!!modelValue && getId(item) === modelValue"
            :value="getValue(item)"
            @click="handleClick($event, item, hide)"
          >
            <slot name="item" v-bind="{ item }" :value="getValue(item)" />
          </Item>
        </div>
      </slot>
      <slot name="after" v-bind="{ hide }" />
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Dropdown, Placement } from 'floating-vue'

import { DropdownItem } from './utils/types'

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/outline'
import Item from './components/DropdownItem.vue'

type T = any

type Props = {
  items?: DropdownItem<T>[]

  idKey?: keyof T
  valueKey?: keyof T

  container?: string
  placement?: Placement
  popperClass?: string

  disabled?: boolean
  static?: boolean
  reverse?: boolean
}

type Emits = {
  'click:item': [data: DropdownItem<T>, event: MouseEvent]
}

const { placement = 'bottom', ...props } = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<string>()
const isShown = defineModel<boolean>('show', { default: false })

const displayValue = computed(() => {
  const item = props.items?.find(item => modelValue.value === getId(item))
  return item && getValue(item)
})

const chevronComponent = computed(() =>
  isShown.value ? ChevronUpIcon : ChevronDownIcon,
)

const togglerClasses = computed(() => ({
  'ui-dropdown__toggler--disabled': props.disabled,
}))

const getId = (item: DropdownItem<T>) =>
  props.idKey ? item[props.idKey] : `${item}`
const getValue = (item: DropdownItem<T>) =>
  props.valueKey ? item[props.valueKey] : `${item}`

const handleClick = (
  event: MouseEvent,
  item: DropdownItem<T>,
  hide: () => void,
) => {
  if (props.static) return
  if (item.action) {
    item.action(hide)
    return
  }
  hide()
  modelValue.value = getId(item)
  emit('click:item', item, event)
}

const handleShow = () => {
  isShown.value = true
}

const handleHide = () => {
  isShown.value = false
}

defineOptions({
  name: 'UIDropdown',
})
</script>

<style lang="postcss">
.v-popper--theme-dropdown {
  .v-popper {
    &__wrapper {
      @apply dark:border dark:rounded-md;
      @apply dark:border-gray-600;
    }
    &__inner {
      @apply py-1;
      @apply rounded-md;
      @apply shadow-lg;
      @apply bg-white dark:bg-gray-800;
      @apply border-none;
      @apply ring-1 ring-black ring-opacity-5 focus:outline-none;
      @apply text-inherit;
      @apply overflow-y-auto;

      .ui-dropdown__items {
        @apply max-h-60;
        @apply overflow-y-auto;
      }
    }
    &__arrow-container {
      @apply hidden;
    }
  }
}
.ui-dropdown {
  &__toggler {
    @apply inline-flex items-center;
    @apply gap-x-0.5;
    @apply cursor-pointer;

    &--disabled {
      @apply opacity-50;
    }

    &-icon {
      @apply h-3 w-3;
      @apply shrink-0;
      @apply text-gray-400 dark:text-gray-500;
    }
  }
}
</style>
