<template>
  <div class="draggable-item">
    <Bars3Icon class="handle draggable-item__icon" />
    <div class="draggable-item__text">
      {{ item }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { Bars3Icon } from '@heroicons/vue/24/outline'

type Props = {
  item: string
}

defineProps<Props>()
</script>

<script lang="ts">
export default {
  name: 'UIDraggableItem',
}
</script>

<style scoped>
.draggable-item {
  @apply flex items-center space-x-2;
}

.draggable-item__icon {
  @apply w-4 h-4 cursor-move;
}

.draggable-item__text {
  @apply text-gray-500 dark:text-gray-400 text-sm;
}
</style>
