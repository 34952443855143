import { computed, ref, watch, WatchStopHandle } from 'vue'
import { useIdle, useTimestamp } from '@vueuse/core'
import { template } from 'lodash'

import { NOTIFY_DESCRIPTION, NOTIFY_MESSAGE } from './utils/const'

import {
  NotificationAction,
  NotificationInstance,
  useNotifications,
} from '../notification'

import { useUserStore } from '@/store/user'

const TIME_TO_SIGN_OUT = 5
export const DEFAULT_INACTIVITY_DELAY = 20

export const useInactivityHandler = () => {
  const userStore = useUserStore()

  const timestamp = useTimestamp({ interval: 1000 })

  const { clearAll, remove, timeout, warn, update } = useNotifications()

  const lastActivity = ref(0)

  let stopLastActiveWatch: WatchStopHandle
  let stopIsTimeUpWatch: WatchStopHandle
  let stopGetNotifyDescriptionWatch: WatchStopHandle

  let hideNotify: () => void

  let notifyInstance: NotificationInstance | undefined

  const start = (waitingTime: number = DEFAULT_INACTIVITY_DELAY) => {
    lastActivity.value = timestamp.value

    destroyWatchers()

    const { lastActive } = useIdle(undefined, {
      events: ['mousedown', 'keydown'],
    })

    const timeLeft = computed(() => {
      if (!userStore.isAuthenticated) return
      const passed = Math.floor((timestamp.value - lastActivity.value) / 1000)
      const totalTime = (waitingTime + TIME_TO_SIGN_OUT) * 60
      return totalTime - passed
    })

    const isNotifyVisible = computed(() => {
      if (timeLeft.value === undefined) return
      return timeLeft.value <= TIME_TO_SIGN_OUT * 60
    })

    const isTimeUp = computed(() => {
      if (timeLeft.value === undefined) return
      return timeLeft.value <= 0
    })

    const getNotifyDescription = computed(() => {
      if (!isNotifyVisible.value) return
      const compiled = template(NOTIFY_DESCRIPTION)
      const minutes = Math.floor(Number(timeLeft.value) / 60)
      const seconds = Number(timeLeft.value) % 60
      return compiled({
        waitingTime,
        timeToSignOut: TIME_TO_SIGN_OUT,
        minutes,
        seconds,
      })
    })

    stopLastActiveWatch = watch(lastActive, () => {
      if (isNotifyVisible.value) return
      lastActivity.value = timestamp.value
    })

    stopIsTimeUpWatch = watch(
      isTimeUp,
      value => {
        if (!value) return
        automaticallySignOut()
      },
      { immediate: true },
    )

    const showNotify = async () => {
      notifyInstance = await timeout({
        message: NOTIFY_MESSAGE,
        description: getNotifyDescription.value,
        actions,
      })
    }

    hideNotify = () => {
      notifyInstance && remove(notifyInstance)
      notifyInstance = undefined
    }

    stopGetNotifyDescriptionWatch = watch(
      getNotifyDescription,
      async description => {
        if (!description) return
        if (!notifyInstance) {
          showNotify()
        } else {
          update(notifyInstance, { message: NOTIFY_MESSAGE, description })
        }
      },
      { immediate: true },
    )
  }

  const actions: NotificationAction[] = [
    {
      label: 'Sign out',
      buttonVariant: 'primary',
      onClick: () => {
        forceSignOut()
      },
    },
    {
      label: 'Stay signed in',
      buttonVariant: 'light',
      onClick: () => {
        lastActivity.value = timestamp.value
        hideNotify()
      },
    },
  ]

  const forceSignOut = async (checkBlockers = true) => {
    clearAll()
    destroyWatchers()
    await hideNotify()
    await userStore.signOut(checkBlockers)
  }

  const automaticallySignOut = async () => {
    forceSignOut(false)
    await warn({
      message: 'You were automatically signed out due to inactivity',
      description: 'Re-enter your credentials to continue',
    })
  }

  const destroyWatchers = () => {
    stopLastActiveWatch?.()
    stopIsTimeUpWatch?.()
    stopGetNotifyDescriptionWatch?.()
  }

  return {
    start,
  }
}
