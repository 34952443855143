<template>
  <UIPanel
    v-model="clearedSearch"
    v-model:storage="searchStorage"
    v-bind="{ disabled }"
    placeholder="Search linked transactions..."
    fill-on-mobile
  >
    <template #default="{ size, ButtonExport, ButtonGroup }">
      <UIFilter
        v-model="clearedFilter"
        v-model:storage="filterStorage"
        v-bind="{ disabled, size }"
        :fields="filterFields"
      />
      <UISort
        v-model="clearedSort"
        v-model:storage="sortStorage"
        v-bind="{ disabled, size }"
        :fields="sortFields"
      />
      <template v-if="selectedIds.length">
        <UIDropdown :items="groupActions" value-key="label">
          <component :is="ButtonGroup" v-bind="{ disabled }" />
        </UIDropdown>
        <component
          :is="ButtonExport"
          v-bind="{ disabled }"
          @click="handleExport"
        />
      </template>
    </template>
  </UIPanel>
  <UIGridSkeleton
    v-if="isLoading"
    v-bind="{ columns, sm }"
    wrapped
    selectable
    message="Loading transactions..."
  />
  <UIGrid
    v-else
    ref="gridRef"
    v-bind="{ columns, items, readonly, sm, valuePaster }"
    v-model:sort="sortStorage"
    v-model:selected="selectedIds"
    data-refid="linkedTransactionsList"
    wrapped
    selectable
    scrollable
    editable
    @pasted="handlePasted"
  >
    <template #actions="{ item, size }">
      <UIButton
        v-if="!checkTransactionHasExcludeTag(item)"
        v-bind="{ size }"
        label="Exclude"
        variant="light-red"
        @click="handleExclude(item)"
      />
      <UIButton
        v-if="!readonly"
        v-bind="{ size }"
        label="Create rule"
        variant="light-secondary"
        @click="handleCreateRule(item)"
      />
      <UIButton
        v-if="!readonly"
        v-bind="{ size }"
        label="Map transaction"
        variant="light-secondary"
        @click="handleMapTransaction(item)"
      />
      <UIButton
        v-bind="{ size }"
        label="Source view"
        variant="light-gray"
        :icon="ArrowTopRightOnSquareIcon"
        hide-label-on-mobile
        @click="handleOpenJSON(item)"
      />
    </template>
    <template #cell="{ columnName, displayValue, editCell, item }">
      <UILabeledField
        v-if="editCell"
        :model-value="item[columnName]"
        :type="columnName === 'amount' ? 'number' : undefined"
        size="small"
        focus-on-load
        disable-focus-delay
        @mouseup.stop
        @mousedown.stop
        @click:enter="handleEditClickEnter"
        @click:tab="handleEditClickTab"
        @reset="handleEditReset"
        @update:model-value="
          handleUpdateModelValue(
            item.id,
            columnName as string,
            $event as string,
          )
        "
      />
      <template v-else>{{ displayValue }}</template>
    </template>
    <template #cellDate="{ displayValue, editCell, item }">
      <UIDatePickerField
        v-if="editCell"
        :model-value="item.date"
        size="small"
        focus-on-load
        disable-focus-delay
        disable-recovery-value
        @mouseup.stop
        @mousedown.stop
        @click:enter="handleEditClickEnter"
        @click:tab="handleEditClickTab"
        @reset="handleEditReset"
        @update:model-value="
          handleUpdateModelValue(item.id, 'date', $event as string)
        "
      />
      <template v-else>{{ displayValue }}</template>
    </template>
    <template #cellTags="{ displayValue: tags, item }">
      <ListTags
        v-bind="{ isStoreUsed, tags }"
        :id="item.id"
        ref="listTagsRef"
        @click:tag="handleClickTag"
        @click:assign="handleClickAssign"
        @update="handleUpdate"
      />
    </template>
  </UIGrid>
  <UIPagination
    v-if="!isLoading"
    v-bind="{ disabled, pageNo }"
    :page-size="pagination?.page_size"
    :total="pagination?.total"
    @click:page="handleClickPage"
  />
  <Teleport to="body">
    <RulesPopup ref="rulesPopupRef" @refresh="handleRefresh" />
    <ListItemJson ref="jsonRef" />
    <UIRemoveDialog
      v-model="pasteDialogShown"
      v-bind="{
        title: 'Update selected columns',
        message: 'Are you sure you want to update selected fields?',
        label: 'Update',
      }"
      @remove="handleBulkUpdate"
    />
  </Teleport>
  <ListBulk
    ref="listBulkRef"
    v-bind="{ isStoreUsed }"
    :model-value="items"
    @update:model-value="handleUpdateItems"
  />
</template>

<script setup lang="ts">
import { computed, inject, nextTick, onBeforeMount, ref, watch } from 'vue'
import { isEqual } from 'lodash'
import { debouncedWatch, watchPausable } from '@vueuse/core'

import {
  Filter,
  FilterComparison,
  FilterLogic,
  LinkedDataTransaction,
  LinkedDataTransactionUpdate,
  ModalEvent,
  PaginatedMeta,
  ReadonlyMode,
  Sort,
  TransactionRule,
  TransactionRuleType,
} from '@types'
import { PresetFilter } from '../utils/types'

import { LINKED_TRANSACTION_FIELDS } from '../utils/const'
import { DEBOUNCE_DELAY, READONLY_MODE } from '@/const/common'
import { PREFILLED_ID } from '../../Rules/utils/const'
import { MAX_STRING_LENGTH } from '@/helpers/validate'

import { numberFormat, numberParse } from '@/helpers/numbers'
import { convertDateToISO } from '@/helpers/dates'
import { handleCatchedError } from '@/helpers/common'
import { getLinkedDataTransactionsFilters } from '../utils/helpers'
import { updateStoreListItem } from '@/store/utils/helpers'

import { useGridCheckedExport } from '@/components/hooks/gridExport'

import { useLinkedDataMappingRulesStore } from '@/store/linkedData/rules'
import { useLinkedDataStore } from '@/store/linkedData'
import { useLinkedDataTransactionsStore } from '@/store/linkedData/transactions'
import { useLinkedDataTransactionsTagsStore } from '@/store/linkedData/transactionsTags'
import { useModalsStore } from '@/store/modals'
import { useRepositoriesStore } from '@/store/repositories'
import { useTransactionsBunchStore } from '@/store/transactions/bunch'
import { useTransactionsSettingsStore } from '@/store/transactions/settings'

import {
  ArrowTopRightOnSquareIcon,
  TagIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'
import {
  UIButton,
  UIDatePickerField,
  UIDropdown,
  UIFilter,
  UIGrid,
  UIGridSkeleton,
  UILabeledField,
  UIPagination,
  UIPanel,
  UIRemoveDialog,
  UISort,
} from '@ui'
import ListItemJson from './ListItemJson.vue'
import ListTags from './ListTags.vue'
import ListBulk from './ListBulk.vue'
import RulesPopup from '../../Rules/RulesPopup.vue'

type Props = {
  presetFilter?: PresetFilter
  assetId?: string
  excludedColumns?: string[]
}

const props = defineProps<Props>()

const searchStorage = defineModel<string>('search')
const filterStorage = defineModel<Filter>('filter')
const sortStorage = defineModel<Sort[]>('sort')

const linkedDataMappingRulesStore = useLinkedDataMappingRulesStore()
const linkedDataStore = useLinkedDataStore()
const linkedDataTransactionsStore = useLinkedDataTransactionsStore()
const linkedDataTransactionsTagsStore = useLinkedDataTransactionsTagsStore()
const modalsStore = useModalsStore()
const repositoriesStore = useRepositoriesStore()
const transactionsBunchStore = useTransactionsBunchStore()
const transactionsSettingsStore = useTransactionsSettingsStore()

const filterFields = getLinkedDataTransactionsFilters()

const gridRef = ref<any>()
const rulesPopupRef = ref<typeof RulesPopup>()
const jsonRef = ref<typeof ListItemJson>()
const listTagsRef = ref<typeof ListTags>()
const listBulkRef = ref<typeof ListBulk>()

const internalItems = ref<LinkedDataTransaction[]>()
const internalPagination = ref<PaginatedMeta>()
const internalLoading = ref(true)

const clearedSearch = ref<string>()
const clearedFilter = ref<Filter>()
const clearedSort = ref<Sort[]>()

const pageNo = ref(0)

const selectedIds = ref<string[]>([])

const currentSaveModel = ref<{
  id: string
  field: string
  value: string | number
}>()

const bulkUpdateData = ref<Map<string, LinkedDataTransactionUpdate>>(new Map())

const pasteDialogShown = ref(false)

const readonly = inject<ReadonlyMode>(READONLY_MODE)

const isStoreUsed = computed(() => !props.presetFilter && !props.assetId)

const mergedFilter = computed(() => {
  if (props.presetFilter) {
    const params = props.presetFilter
    if (clearedFilter.value) {
      params.push(clearedFilter.value)
    }
    return {
      logic: FilterLogic.AND,
      params,
    }
  }
  return clearedFilter.value
})

const fetchOptions = computed(() => ({
  search: clearedSearch.value,
  filter: mergedFilter.value,
  sort: clearedSort.value,
  asset_id: props.assetId,
}))

const isInit = computed(
  () =>
    !isStoreUsed.value ||
    (isStoreUsed.value && linkedDataTransactionsStore.initFlag),
)
const isLoading = computed(
  () =>
    (!isStoreUsed.value && internalLoading.value) ||
    (isStoreUsed.value && linkedDataTransactionsStore.loading),
)

const disabled = computed(() => !isInit.value)

const pagination = computed(
  () =>
    (!isStoreUsed.value && internalPagination.value) ||
    linkedDataTransactionsStore.getMeta,
)

const groupActions = computed(() => [
  {
    label: 'Add tags',
    icon: TagIcon,
    action: (hide: () => void) => {
      hide()
      listBulkRef.value?.assign(selectedIds.value)
    },
  },
  {
    label: 'Remove tags',
    icon: XMarkIcon,
    action: (hide: () => void) => {
      hide()
      listBulkRef.value?.remove(selectedIds.value)
    },
  },
])

const sm = '1fr 1fr'
const initialColumns = computed(() => [
  {
    name: LINKED_TRANSACTION_FIELDS.CONNECTOR_NAME.field,
    caption: LINKED_TRANSACTION_FIELDS.CONNECTOR_NAME.label,
    uneditable: true,
    cellClasses: 'ui-grid__cell--span-2',
  },
  {
    name: LINKED_TRANSACTION_FIELDS.METHOD.field,
    caption: LINKED_TRANSACTION_FIELDS.METHOD.label,
    default: '.75fr',
    uneditable: true,
  },
  {
    name: LINKED_TRANSACTION_FIELDS.ACCOUNT_NAME.field,
    caption: LINKED_TRANSACTION_FIELDS.ACCOUNT_NAME.label,
    default: '2fr',
    uneditable: true,
  },
  {
    name: LINKED_TRANSACTION_FIELDS.ACCOUNT_NUMBER.field,
    caption: LINKED_TRANSACTION_FIELDS.ACCOUNT_NUMBER.label,
    uneditable: true,
    cellClasses: 'ui-grid__cell--span-2',
  },
  {
    name: LINKED_TRANSACTION_FIELDS.ACCOUNT_TYPE.field,
    caption: LINKED_TRANSACTION_FIELDS.ACCOUNT_TYPE.label,
    uneditable: true,
  },
  {
    name: LINKED_TRANSACTION_FIELDS.DATE.field,
    caption: LINKED_TRANSACTION_FIELDS.DATE.label,
    default: '.85fr',
  },
  {
    name: LINKED_TRANSACTION_FIELDS.DESCRIPTION.field,
    caption: LINKED_TRANSACTION_FIELDS.DESCRIPTION.label,
    lineClamp: 3,
    cellClasses: 'ui-grid__cell--span-2',
    default: '2fr',
  },
  {
    name: LINKED_TRANSACTION_FIELDS.TYPE.field,
    caption: LINKED_TRANSACTION_FIELDS.TYPE.label,
  },
  {
    name: LINKED_TRANSACTION_FIELDS.AMOUNT.field,
    caption: LINKED_TRANSACTION_FIELDS.AMOUNT.label,
    formatter: numberFormat,
    headerValueClasses: 'linked-transactions__rtl',
    cellValueClasses: 'linked-transactions__rtl',
  },
  {
    name: LINKED_TRANSACTION_FIELDS.TAGS.field,
    caption: LINKED_TRANSACTION_FIELDS.TAGS.label,
    unsortable: true,
    uneditable: true,
    tooltip: null,
    cellClasses: 'ui-grid__cell--span-2 linked-transactions__tags',
    cellValueClasses: 'linked-transactions__tags-value',
  },
  {
    name: LINKED_TRANSACTION_FIELDS.CATEGORY.field,
    caption: LINKED_TRANSACTION_FIELDS.CATEGORY.label,
  },
  {
    name: LINKED_TRANSACTION_FIELDS.SUBCATEGORY.field,
    caption: LINKED_TRANSACTION_FIELDS.SUBCATEGORY.label,
  },
  {
    name: LINKED_TRANSACTION_FIELDS.COUNTERPARTY.field,
    caption: LINKED_TRANSACTION_FIELDS.COUNTERPARTY.label,
    lineClamp: 3,
    default: '2fr',
  },
  {
    name: LINKED_TRANSACTION_FIELDS.STATUS.field,
    caption: LINKED_TRANSACTION_FIELDS.STATUS.label,
  },
])
const columns = computed(() =>
  initialColumns.value.filter(
    column => !props.excludedColumns?.includes(column.name),
  ),
)

const items = computed(
  () =>
    (!isStoreUsed.value && internalItems.value) ||
    linkedDataTransactionsStore.getList ||
    [],
)

const sortFields = computed(() =>
  columns.value
    .filter(column => !column.unsortable)
    .map(column => ({
      key: column.name,
      value: column.caption,
    })),
)

const tagsList = computed(() => linkedDataTransactionsTagsStore.getList)
const excludeTag = computed(() =>
  tagsList.value.find(tag => tag.name === 'exclude'),
)

const handleClickTag = (value: string) => {
  searchStorage.value = value
}

const handleClickAssign = (id: string) => {
  listBulkRef.value?.assign([id])
}

const handleUpdate = (data: LinkedDataTransaction[]) => {
  if (isStoreUsed.value) return
  data.forEach(value => {
    if (!internalItems.value) return
    updateStoreListItem(internalItems.value, value)
  })
}

const handleExclude = (transaction: LinkedDataTransaction) => {
  listTagsRef.value?.exclude(transaction)
}

const checkTransactionHasExcludeTag = (transaction: LinkedDataTransaction) => {
  return (
    readonly?.value ||
    transaction.tags.map(t => t.id).includes(excludeTag.value?.id)
  )
}

const handleCreateRule = async (transaction: LinkedDataTransaction) => {
  const criteria: TransactionRule['data']['criteria'] = []
  const actions: TransactionRule['data']['actions'] = []
  if (transaction.description) {
    criteria.push({
      type: TransactionRuleType.DESCRIPTION,
      comparison: FilterComparison.CONTAINS,
      value: transaction.description,
    })
  }
  if (transaction.account_name) {
    criteria.push({
      type: TransactionRuleType.ACCOUNT,
      comparison: FilterComparison.EQ,
      value: transaction.account_name,
    })
  }
  if (transaction.amount) {
    criteria.push({
      type: TransactionRuleType.AMOUNT,
      comparison: FilterComparison.EQ,
      value: transaction.amount,
    })
  }
  if (transaction.type) {
    criteria.push({
      type: TransactionRuleType.TRANSACTION_TYPE,
      comparison: FilterComparison.EQ,
      value: transaction.type,
    })
  }
  if (transaction.category) {
    criteria.push({
      type: TransactionRuleType.CATEGORY,
      comparison: FilterComparison.EQ,
      value: transaction.category,
    })
  }
  const order = linkedDataMappingRulesStore.getList.at(-1)?.order || 0
  rulesPopupRef.value
    ?.init({
      id: PREFILLED_ID,
      order,
      data: {
        criteria,
        actions,
      },
    })
    .toggle(true)
}

const handleMapTransaction = (transaction: LinkedDataTransaction) => {
  const repoMap = linkedDataStore.getList.find(
    account => account.id === transaction.account_id,
  )?.repo_map
  const account = repoMap?.[repositoriesStore.currentRepositoryId || '']
  let instance = transactionsBunchStore.getElementById(transaction.id)
  let link = false
  if (!instance) {
    instance = transactionsBunchStore.createElement()
    instance.set({
      date: transaction.date,
      entries: [
        {
          account_id: account || '',
          amount: Number(transaction.amount),
          asset_id: '',
        },
      ],
      description: transaction.description.slice(0, MAX_STRING_LENGTH),
    })
    link = true
  }
  const modalInstance = modalsStore.init(instance.id, instance as any)
  modalInstance?.open(modalsStore.getZIndex(), { link })
  modalInstance?.addEventListener(ModalEvent.CLOSE, () => {
    if (link) instance?.cancel()
  })
}

const handleOpenJSON = async (transaction: LinkedDataTransaction) => {
  try {
    const json = await linkedDataTransactionsStore.getJSON(transaction.id)
    jsonRef.value?.setData(json).toggle(true)
  } catch (e) {
    handleCatchedError(e as string, { transactionId: transaction.id })
  }
}

const handleClickPage = (page: number) => {
  pageNo.value = page
}

const handleExport = () => {
  if (!gridRef.value) return
  useGridCheckedExport(gridRef.value, 'linkedDataTransactions')
}

const handleRefresh = () => {
  fetch()
  linkedDataMappingRulesStore.fetch()
}

const fetch = async () => {
  selectedIds.value = []
  internalLoading.value = true
  const options = { ...fetchOptions.value, page_no: pageNo.value }
  try {
    const result = await linkedDataTransactionsStore.fetch(
      options,
      isStoreUsed.value,
    )
    if (isStoreUsed.value) return
    internalItems.value = result.data
    internalPagination.value = result.meta
    internalLoading.value = false
  } catch (e) {
    handleCatchedError(e as string, options)
  }
}

const handleEditClickEnter = async (event: Event) => {
  event.stopPropagation()
  await updateTransaction()
  gridRef.value?.release()
  gridRef.value?.clickEnter()
}

const handleEditClickTab = async (event: Event) => {
  event.stopPropagation()
  event.preventDefault()
  await updateTransaction()
  gridRef.value?.release()
  gridRef.value?.clickTab()
}

const handleEditReset = () => {
  gridRef.value?.release()
}

const handleUpdateModelValue = (
  id: string,
  field: string,
  value: string | number,
) => {
  currentSaveModel.value = { id, field, value }
}

const updateTransaction = async () => {
  if (!currentSaveModel.value) return
  const { id, field, value } = currentSaveModel.value
  const transaction = items.value.find(item => item.id === id)
  if (
    !transaction ||
    transaction[field as keyof LinkedDataTransaction] === value
  )
    return
  currentSaveModel.value = undefined
  const result = await linkedDataTransactionsStore.bulkUpdate([
    { id: transaction.id, [field]: value },
  ])
  if (!internalItems.value || !result[0]) return
  updateStoreListItem(internalItems.value, result[0])
}

const valuePaster = (
  field: string,
  item: any,
  value: number | string,
  check?: boolean,
) => {
  if (columns.value.find(column => column.name === field)?.uneditable) return
  if (check) {
    switch (field) {
      case 'date': {
        const date = convertDateToISO(value.toString())
        if (date) {
          value = date
        } else {
          return
        }
        break
      }
      case 'amount': {
        const amount = numberParse(value)
        value = amount
        break
      }
    }
  }
  const key = field as keyof LinkedDataTransactionUpdate
  const data = bulkUpdateData.value.get(item.id)
  if (data) {
    bulkUpdateData.value.set(item.id, {
      ...data,
      [key]: value,
    })
  } else {
    bulkUpdateData.value.set(item.id, { id: item.id, [key]: value })
  }
}

const handlePasted = () => {
  if (!bulkUpdateData.value.size) return
  pasteDialogShown.value = true
}

const handleBulkUpdate = async () => {
  if (!bulkUpdateData.value.size) return
  const data = Array.from(bulkUpdateData.value.values())
  await listBulkRef.value?.process(data)
  gridRef.value?.deselect()
}

const handleUpdateItems = (data: LinkedDataTransaction[]) => {
  if (isStoreUsed.value) return
  internalItems.value = data
}

watch(pasteDialogShown, value => {
  if (value) return
  bulkUpdateData.value = new Map()
})

const { pause, resume } = watchPausable(pageNo, fetch)

debouncedWatch(
  fetchOptions,
  (value, prev) => {
    if (isEqual(value, prev)) return
    pause()
    pageNo.value = 0
    fetch()
    nextTick(resume)
  },
  {
    deep: true,
    immediate: true,
    debounce: DEBOUNCE_DELAY,
  },
)

onBeforeMount(() => {
  if (!transactionsSettingsStore.initFlag) {
    transactionsSettingsStore.fetch()
  }
})
</script>

<script lang="ts">
export default {
  name: 'TransactionsList',
}
</script>

<style lang="postcss">
.linked-transactions {
  &__rtl {
    @apply sm:text-right;
  }

  &__tags {
    @apply !min-h-[auto];

    &-value {
      @apply py-0.5;
      @apply !overflow-visible;
    }
  }
}
.group-actions {
  &__item {
    @apply w-36;
    @apply flex items-center;
    @apply px-4 py-2;
    @apply text-xs;
  }

  &__icon {
    @apply w-4 h-4;
    @apply mr-1.5 -ml-1;
    @apply text-gray-400;
  }
}
</style>
