<template>
  <div v-if="!isRepositoriesInit" class="main-layout">
    <Header
      ref="headerRef"
      @repository:create="handleRepositoryCreate"
      @repository:import="handleRepositoryImport"
    />
    <div class="main-layout__container">
      <MainSidebar v-if="isSidebarVisible" />
      <main class="main-layout__area" :class="mainClasses">
        <template v-if="!emptyData">
          <PageName v-if="isMobile" />
          <AppAlertGroup />
          <Menu v-if="!isMobile" />
          <UIProgressBar
            :progress="analyticsProgress"
            class="main-layout__progress"
          />
          <router-view v-slot="{ Component }">
            <component :is="Component" />
          </router-view>
        </template>
        <UILayoutNoData
          v-else
          class="main-layout__no-data"
          @click:help="intercom.help(5480500)"
        >
          <template #text-description
            >Get started by creating a new repository</template
          >
          <template #default>
            <UIButton
              :icon="PlusIcon"
              label="Create"
              full
              @click="handleRepositoryCreate"
            />
            <UIButton
              :icon="FolderArrowDownIcon"
              label="Import"
              full
              variant="light"
              class="mt-2"
              @click="handleRepositoryImport"
            />
          </template>
          <template #text-question>What is a repository?</template>
        </UILayoutNoData>
      </main>
    </div>
  </div>
  <UILoading
    v-if="isRepositoriesInit"
    full
    message="Allposit loading your repositories..."
  />
  <UIModal
    v-if="!isSidebarVisible"
    :is-open="sidebarOpen"
    :type="ModalType.SLIDEPANEL"
    hide-minimise-button
    wrapper-class="flex-auto"
    @hide="sidebarOpen = false"
  >
    <template #title>
      <AppLogo with-text substrate :size="LogoSize.SMALL" />
    </template>
    <div class="main-layout__tree">
      <MainTree />
    </div>
  </UIModal>
  <UIModal
    :is-open="dialogInviteOpen"
    title="You have invitation to collaboration"
    :type="ModalType.DIALOG"
    :uncloseable="unAnsweredInvitesCount"
    size="lg"
    wrapper-class="flex-auto -mt-6 -mb-4 lg:px-2"
    @hide="handleHideInvite"
  >
    <MainInvites @close="handleHideInvite" />
  </UIModal>
</template>

<script setup lang="ts">
import {
  computed,
  inject,
  markRaw,
  provide,
  ref,
  watch,
  watchEffect,
} from 'vue'
import { useActiveElement, useMagicKeys, whenever } from '@vueuse/core'

import { LogoSize, ModalType } from '@types'

import { handleCatchedError } from '@/helpers/common'

import { useIntercom } from '@/plugins/intercom'
import { useResponsive } from '@/plugins/responsiveUI'

import useAnalyticsStore from '@/store/analytics'
import { useFloatingPanelStore } from '@/store/floatingPanel'
import { useMainStore } from '@/store/main'
import { useModalsStore } from '@/store/modals'
import { useRepositoriesStore } from '@/store/repositories'
import { useSearchStore } from '@/store/search'
import { useSidebarStore } from '@/store/sidebar'
import { useUserStore } from '@/store/user'

import { PlusIcon, FolderArrowDownIcon } from '@heroicons/vue/24/outline'
import {
  UIButton,
  UILayoutNoData,
  UILoading,
  UIModal,
  UIProgressBar,
} from '@ui'
import { AppLogo } from '@app'

import AppAlertGroup from '@/plugins/alerts/AlertGroup.vue'

import Header from './components/Header/Header.vue'
import MainInvites from './MainInvites.vue'
import MainSidebar from './MainSidebar.vue'
import MainTree from './MainTree.vue'
import Menu from './components/Menu/Menu.vue'
import PageName from './components/PageName.vue'

import RepositorySlideover from '@/views/Repositories/RepositorySlideover.vue'
import RepositoryImportDialog from '@/views/Repositories/RepositoryImportDialog.vue'

const analyticsStore = useAnalyticsStore()
const floatingPanelStore = useFloatingPanelStore()
const mainStore = useMainStore()
const modalsStore = useModalsStore()
const repositoriesStore = useRepositoriesStore()
const sidebarStore = useSidebarStore()
const searchStore = useSearchStore()
const userStore = useUserStore()

const intercom = useIntercom()

const { isMobile, isTablet } = useResponsive()

const headerRef = ref<typeof Header>()

const emptyData = computed(() => repositoriesStore.list.length === 0)

const dialogInviteOpen = ref(false)

const analyticsProgress = computed(() => analyticsStore.progress)

const isRepositoriesInit = computed(() => !repositoriesStore.initFlag)

const currentRepositoryId = computed(
  () => repositoriesStore.getCurrentRepository?.id,
)

const sidebarOpen = computed({
  get() {
    return sidebarStore.open
  },
  set(value: boolean) {
    sidebarStore.open = value
  },
})

watch(
  currentRepositoryId,
  async (value, oldValue) => {
    if (!value || value === oldValue) return
    if (oldValue != null) {
      await mainStore.cancelFetch()
      await mainStore.clearState()
    }

    await mainStore.fetchCurrentRepositoryData()
  },
  { immediate: true },
)

const userEmail = computed(() => userStore.getEmail)

provide('userEmail', userEmail)

const setCurrentRepository = inject<(id?: string) => void>(
  'setCurrentRepository',
)

watch(
  userEmail,
  async value => {
    if (!value) return
    await mainStore.fetchRepositoryData()
  },
  {
    immediate: true,
  },
)

const isSidebarVisible = computed(() => !isMobile.value && !isTablet.value)

const mainClasses = computed(() => ({
  'main-layout__area--shifted': sidebarOpen.value && isSidebarVisible.value,
}))

const activeElement = useActiveElement()
const notUsingInput = computed(
  () =>
    activeElement.value?.tagName !== 'INPUT' &&
    activeElement.value?.tagName !== 'TEXTAREA',
)

const { escape, q, meta, k } = useMagicKeys()

const handleRepositoryCreate = () => {
  const modalInstance = modalsStore.init(
    'repository-create',
    markRaw(RepositorySlideover),
  )
  modalInstance?.open(modalsStore.getZIndex(), {
    focusOnLoad: true,
  })
}

const handleRepositoryImport = () => {
  const modalInstance = modalsStore.init(
    'repository-import',
    markRaw(RepositoryImportDialog),
  )
  modalInstance?.open(modalsStore.getZIndex())
}

const handleHideInvite = async (repositoryId?: string) => {
  if (unAnsweredInvitesCount.value) return

  dialogInviteOpen.value = false
  try {
    await repositoriesStore.fetchRepositories()
    setCurrentRepository && setCurrentRepository(repositoryId)
  } catch (e) {
    handleCatchedError(e as string)
  }
}

watchEffect(() => {
  if (meta.value && k.value) {
    searchStore.open = true
  }
})

whenever(
  () => q.value && notUsingInput.value,
  () => {
    sidebarOpen.value = !sidebarOpen.value
  },
)

watch(escape, value => {
  if (!notUsingInput.value) return
  if (searchStore.open) {
    searchStore.open = false
    return
  }
  if (value) {
    floatingPanelStore.closeLatest()
  }
})

const unAnsweredInvitesCount = computed(
  () =>
    !!repositoriesStore.invitesList?.filter(
      invite => invite.accepted === undefined,
    ).length,
)
const loadingInvites = computed(() => repositoriesStore.loadingInvites)

watch(
  () => ({
    loadingInvites: loadingInvites.value,
    invitesCount: unAnsweredInvitesCount.value,
  }),
  value => {
    if (value.loadingInvites || dialogInviteOpen.value) return
    if (value.invitesCount) {
      dialogInviteOpen.value = true
    }
  },
  {
    immediate: true,
  },
)
</script>

<script lang="ts">
export default {
  name: 'LayoutMain',
}
</script>

<style scoped lang="postcss">
.main-layout {
  @apply h-screen flex flex-col;
  @apply bg-gray-100 dark:bg-gray-750;
  @apply md:overflow-hidden;

  &__container {
    @apply flex flex-auto flex-row;
    @apply md:overflow-hidden;
  }

  &__area {
    @apply w-full md:w-auto;
    @apply relative;
    @apply flex flex-col flex-auto;
    @apply px-4 sm:px-6 lg:px-8 pb-0;
    @apply bg-white dark:bg-gray-800;
    @apply shadow dark:shadow-gray-600;
    @apply md:overflow-auto;

    &--shifted {
      @apply rounded-l-xl;
    }
  }

  &__no-data {
    @apply m-auto;
  }

  &__progress {
    @apply -mt-4 sm:-mt-5 first:mt-0 -mx-4 sm:-mx-6 lg:-mx-8 mb-4;
  }

  &__tree {
    @apply h-full;
    @apply flex;
    @apply -mx-6 -mt-2;
  }
}
</style>
