import { DirectiveBinding, RendererNode } from 'vue'

type HTMLInteractiveElements =
  | HTMLInputElement
  | HTMLSelectElement
  | HTMLButtonElement

const onFocus = (element: Element): void => {
  switch (element.tagName) {
    case 'INPUT':
      ;(element as HTMLInputElement).select()
      break
    case 'SELECT':
      ;(element as HTMLSelectElement).focus()
      break
    case 'BUTTON':
      ;(element as HTMLButtonElement).focus()
      break
    case 'A':
      ;(element as HTMLLinkElement).focus()
      break
  }
}

class tabElements {
  private list: Element[] = []
  private referenceNext: (e: Event) => void = () => undefined

  getList() {
    return this.list
  }

  next(e: Event) {
    const event = e as KeyboardEvent
    if (
      (!event.shiftKey && event.key === 'Tab') ||
      (event.key === 'Enter' && (e.target as HTMLElement)?.tagName === 'INPUT')
    ) {
      e.preventDefault()
      const activeEls = this.list.filter(element => {
        return (
          (element as HTMLInteractiveElements).offsetParent != null &&
          !(element as HTMLInteractiveElements).disabled
        )
      })

      const currentElId = activeEls.findIndex(element => element === e.target)
      onFocus(
        activeEls[currentElId === activeEls.length - 1 ? 0 : currentElId + 1],
      )
    }
    if (event.shiftKey && event.key === 'Tab') {
      e.preventDefault()
      const activeEls = this.list.filter(element => {
        return (
          (element as HTMLInteractiveElements).offsetParent != null &&
          !(element as HTMLInteractiveElements).disabled
        )
      })
      const currentElId = activeEls.findIndex(element => element === e.target)
      onFocus(
        activeEls[currentElId === 0 ? activeEls.length - 1 : currentElId - 1],
      )
    }
  }

  public init(addedList: Element[]) {
    this.release()
    addedList?.forEach(element => {
      const index = this.list.findIndex(
        searchElement => searchElement === element,
      )
      if (!~index) {
        this.list.push(element)
      }
    })
    this.assign()
  }

  public assign() {
    if (this.list.length < 2) return
    this.referenceNext = e => this.next(e)
    this.list.forEach(node => {
      node.addEventListener('keydown', this.referenceNext, false)
    })
  }

  public release() {
    if (this.list.length < 2) return
    this.list.forEach(node => {
      node.removeEventListener('keydown', this.referenceNext)
    })
  }
}

type CustomNode = Element & {
  mutationObserverId: `observer-${string}`
}
type CustomMutationObserver = MutationObserver & {
  tabber?: tabElements
}
type Observers = Record<string, CustomMutationObserver>

const observers: Observers = {}

const mutationCallback = (
  mutationsList: MutationRecord[],
  observer: CustomMutationObserver,
) => {
  for (const mutation of mutationsList) {
    if (mutation.type === 'childList') {
      const addedElements = []
      for (const node of mutation.addedNodes) {
        const elements = node.parentElement?.querySelectorAll(
          'input:not([tabindex="-1"]):not([type="hidden"]), button:not([tabindex="-1"]), select:not([tabindex="-1"]), a:not([tabindex="-1"])',
        )
        elements && addedElements.push(...Array.from(elements))
      }

      observer.tabber?.init(addedElements)
    }
  }
}

const mutationConfig: MutationObserverInit = {
  childList: true,
  subtree: true,
  attributes: true,
}

const circularTab = {
  created(el: RendererNode): void {
    el.mutationObserverId =
      'observer-' + Date.now() + '-' + Math.floor(Math.random() * 1000)
    observers[el.mutationObserverId] = new MutationObserver(mutationCallback)
  },
  mounted(el: CustomNode, binding: DirectiveBinding): void {
    observers[el.mutationObserverId].observe(el, mutationConfig)
    const elements = el.querySelectorAll(
      'input:not([tabindex="-1"]):not([type="hidden"]), button:not([tabindex="-1"]), select:not([tabindex="-1"]), a:not([tabindex="-1"])',
    )
    observers[el.mutationObserverId].tabber = new tabElements()
    observers[el.mutationObserverId].tabber?.init(Array.from(elements))
    if (binding.value?.autofocus) {
      const bindedEl =
        observers[el.mutationObserverId].tabber?.getList()[
          binding.value.autofocus
        ]
      bindedEl && onFocus(bindedEl)
    }
    if (binding.value?.firstAutoFocus) {
      const bindedEl = observers[el.mutationObserverId].tabber?.getList()[0]
      bindedEl && onFocus(bindedEl)
    }
  },
  beforeUnmount(el: RendererNode): void {
    observers[el.mutationObserverId].tabber?.release()
    observers[el.mutationObserverId].disconnect()
  },
}

export default circularTab
