<template>
  <TransitionRoot
    appear
    :show="!!notifications.length"
    class="fixed inset-0 flex items-end px-4 py-6 sm:p-6 pointer-events-none sm:items-start"
    :style="{ zIndex }"
  >
    <TransitionChild
      v-if="showOverlay"
      enter="transition-opacity ease-linear duration-300"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity ease-linear duration-300"
      leave-from="opacity-100"
      leave-to="opacity-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity pointer-events-auto"
      />
    </TransitionChild>
    <TransitionChild
      :show="notifications.length >= 0"
      enter-active-class="transform ease-out duration-300 transition"
      enter-from-class="translate-y-2 sm:translate-y-0 sm:translate-x-2"
      enter-to-class="translate-y-0 sm:translate-x-0"
      leave-active-class="transition ease-in duration-100"
      leave-from-class=""
      leave-to-class=""
      class="w-full flex flex-col items-center"
      :style="{ zIndex: zIndex + 1 }"
    >
      <notification
        v-for="item in notifications"
        :key="item?.instance.id"
        :notification="item"
      />
    </TransitionChild>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { useNotifications } from '@/plugins/notification/index'

import { useModalsStore } from '@/store/modals'

import Notification from './Notification.vue'
import { TransitionRoot, TransitionChild } from '@headlessui/vue'

const { notifications } = useNotifications()
const modalsStore = useModalsStore()

const showOverlay = computed(
  () => !!notifications.value.filter(item => item.actionRequired).length,
)
const ZINDEX_START = 10000

const zIndex = ref(ZINDEX_START)

watch(
  () => notifications.value.length,
  v => {
    if (v) {
      zIndex.value = modalsStore.getZIndex() + ZINDEX_START
    }
  },
)
</script>

<script lang="ts">
export default {
  name: 'AppAsyncNotification',
}
</script>
