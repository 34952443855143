<template>
  <UIModal v-bind="{ isOpen }" :type="ModalType.DIALOG" @hide="handleHide">
    <div class="account-akoya__content">
      <div class="account-akoya__logo">
        <img :src="connectorAccount?.logo" />
      </div>
      <div class="account-akoya__text">
        You are connecting to {{ connectorAccount?.title }} account using Akoya
      </div>
    </div>
    <div class="account-akoya__agreement">
      <input
        id="akoya-agreements"
        v-model="agreement"
        type="checkbox"
        class="checkbox"
      />
      <label for="akoya-agreements" class="account-akoya__agreement-text">
        I have read and agreed to the following terms of the account information
        sharing. Account information is retrieved via Akoya Data Access Network.
        The information includes account balances and positions. The information
        is retrieve only. The information will not be shared, rented, exchanged
        or sold without your consent.
      </label>
    </div>
    <template #buttons>
      <div class="account-akoya__buttons">
        <UIButton label="Cancel" variant="light" @click="handleCancel" />
        <UIButton v-bind="{ disabled }" label="Next" @click="handleClickNext" />
      </div>
    </template>
  </UIModal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { ModalType } from '@types'
import { ConnectorAccount } from '../../utils/types'

import { UIButton, UIModal } from '@ui'

type Emits = {
  cancel: []
}

const emit = defineEmits<Emits>()

defineExpose({
  show(account: ConnectorAccount, url: string) {
    connectorAccount.value = account
    connectorUrl.value = url
    isOpen.value = true
  },
})

const isOpen = ref(false)

const connectorAccount = ref<ConnectorAccount>()
const connectorUrl = ref<string>()
const agreement = ref(false)

const disabled = computed(() => !agreement.value || !connectorUrl.value)

const handleHide = () => {
  isOpen.value = false
  agreement.value = false
}

const handleCancel = () => {
  handleHide()
  emit('cancel')
}

const handleClickNext = () => {
  if (!connectorUrl.value) return
  location.href = connectorUrl.value
}
</script>

<script lang="ts">
export default {
  name: 'ConnectorsAddAccountAkoya',
}
</script>

<style lang="postcss">
.account-akoya {
  &__content {
    @apply flex flex-col items-center;
    @apply mb-8;
  }

  &__text {
    @apply text-stone-950 dark:text-gray-300;
    @apply text-center;
  }

  &__logo {
    @apply mb-4;
    @apply bg-white;
  }

  &__agreement {
    @apply flex items-start mb-1;
  }

  &__agreement-text {
    @apply ml-3;
    @apply font-medium text-xs;
  }

  &__buttons {
    @apply flex justify-end;
    @apply gap-x-2;
  }
}
</style>
