<template>
  <div class="skeleton-tree">
    <DataField />
    <div class="skeleton-tree__area">
      <TreeItem />
      <TreeItem :depth="1" first />
      <TreeItem :depth="2" first />
      <TreeItem :depth="2" />
      <TreeItem :depth="1" />
      <TreeItem :depth="1" />
      <div class="skeleton-tree__message">{{ message }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TreeItem from './TreeItem.vue'
import DataField from './DataField.vue'

type Props = {
  message?: string
}

defineProps<Props>()
</script>

<script lang="ts">
export default {
  name: 'UISkeletonTree',
}
</script>

<style scoped lang="postcss">
.skeleton-tree {
  @apply flex flex-col flex-auto;
  @apply py-2 px-4;
  @apply gap-y-[1.5rem];

  &__area {
    @apply relative;
    @apply flex flex-col;
  }

  &__message {
    @apply absolute top-1/2 left-1/2;
    @apply -translate-x-1/2;
    @apply text-xs;
    @apply text-gray-400 dark:text-gray-500;
    @apply text-center;
  }
}
</style>
