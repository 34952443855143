import { Document } from './utils/types'
import { Entity } from '../utils/common'
import { EntityEvent } from '../utils/enums'

import { ASSET_FIELD } from '../assets/utils/const'
import { DOCUMENT_DEFAULT_DATA, DOCUMENT_FIELD } from './utils/const'
import { EMPTY_VALUE_PLACEHOLDER } from '@/const/common'

import { rules } from '@/helpers/validate'
import { getAssetById } from '@/views/Analytics/utils/helpers'

import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useDocumentsStore } from '@/store/documents/index'
import { useDocumentsBunchStore } from '@/store/documents/bunch'
import { useModalsStore } from '@/store/modals'

import DocumentDialog from './components/DocumentDialog.vue'
import DocumentFormAsset from './components/DocumentFormAsset.vue'
import DocumentFormName from './components/DocumentFormName.vue'
import DocumentFormPath from './components/DocumentFormPath.vue'
import DocumentFormTags from './components/DocumentFormTags.vue'
import DocumentSlideover from './components/DocumentSlideover.vue'

export class DocumentClass extends Entity<Document> {
  private documentsStore
  private bunchStore
  private assetsBunchStore
  private modalsStore

  constructor(data: Partial<Document>, created = false) {
    super(data, DOCUMENT_DEFAULT_DATA, created)
    this.documentsStore = useDocumentsStore()
    this.bunchStore = useDocumentsBunchStore()
    this.assetsBunchStore = useAssetsBunchStore()
    this.modalsStore = useModalsStore()
    this.cachedData = { key: '' }
  }

  getModal() {
    return this.isDialog ? DocumentDialog : DocumentSlideover
  }

  getFormName() {
    return DocumentFormName
  }

  getFormPath() {
    return DocumentFormPath
  }

  getFormAssetId() {
    return DocumentFormAsset
  }

  getFormTagsField() {
    return DocumentFormTags
  }

  validate() {
    return {
      name: rules.required,
      path: rules.required,
    }
  }

  get displayAssetId() {
    this.prepareAssetId()
    return this.cachedData?.value
  }

  private removeFromBunch() {
    this.bunchStore.getList.delete(this.id)
  }

  private removeFromForms() {
    this.modalsStore.getList.delete(this.id)
  }

  remove() {
    this.removeFromBunch()
    this.removeFromForms()
  }

  private assigntToAsset(data: Document, prev?: Document) {
    if (prev?.asset_id === data.asset_id) return
    if (prev) {
      this.removeFromAsset(prev)
    }
    if (data.asset_id) {
      const asset = this.assetsBunchStore.getElementById(data.asset_id)
      const docs = asset?.field<string[]>(ASSET_FIELD.DOCS)
      if (!docs?.value) return
      if (!docs?.value.includes(data.id)) {
        docs.value.push(data.id)
      }
      asset?.update()
    }
  }

  private removeFromAsset(data: Document) {
    if (data.asset_id) {
      const asset = this.assetsBunchStore.getElementById(data.asset_id)
      const docs = asset?.field<string[]>(ASSET_FIELD.DOCS)
      if (!docs?.value) return
      docs.value = docs?.value.filter(id => id !== data.id)
      asset?.update()
    }
  }

  private prepareAssetId() {
    if (!this.assetsBunchStore?.getList.size) return
    const key = this.field<string>(DOCUMENT_FIELD.ASSET_ID).value
    if (this.cachedData.key !== key) {
      const asset = getAssetById(key, this.assetsBunchStore.getList)
      const value =
        asset?.field<string>(DOCUMENT_FIELD.NAME) || EMPTY_VALUE_PLACEHOLDER
      this.cachedData = { key, value }
    }
  }

  resetAssetId() {
    this.cachedData = {
      key: '',
      value: '',
    }
    this.prepareAssetId()
  }

  async store() {
    try {
      const data = this.get()
      const result = await this.documentsStore.store(data)
      this.remove()
      if (result !== undefined) {
        this.assigntToAsset(result)
        this.reset(result)
        this.bunchStore.unshiftElement(this)
        this.callEvent(EntityEvent.STORED)
      }
    } catch (e) {
      throw Error(e as string)
    }
  }

  async update() {
    try {
      const result = await this.documentsStore.update(this.get())
      if (result === undefined) {
        this.cancel()
      } else {
        this.assigntToAsset(result, this.original)
        this.reset(result)
        this.callEvent(EntityEvent.UPDATED)
      }
    } catch (e) {
      throw Error(e as string)
    }
  }

  async destroy() {
    try {
      if (!this.isNew) {
        await this.documentsStore.destroy(this.id)
        this.callEvent(EntityEvent.DESTROYED)
      }
      this.remove()
    } catch (e) {
      throw Error(e as string)
    }
  }
}
