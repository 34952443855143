const convertHexToRGB = (hex: string) => {
  hex = hex.replace('#', '')
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(char => char + char)
      .join('')
  }
  const hexNumber = parseInt(hex, 16)
  const r = (hexNumber >> 16) & 255
  const g = (hexNumber >> 8) & 255
  const b = hexNumber & 255

  return `rgb(${r}, ${g}, ${b})`
}

export const generateRGBPalette = (
  start: string,
  end: string,
  steps: number,
) => {
  if (start.startsWith('#')) {
    start = convertHexToRGB(start)
  }
  if (end.startsWith('#')) {
    end = convertHexToRGB(end)
  }
  const colorStart = start
    .replace('rgb(', '')
    .replace(')', '')
    .split(',')
    .map(Number)
  const colorEnd = end
    .replace('rgb(', '')
    .replace(')', '')
    .split(',')
    .map(Number)
  const step = colorEnd.map(
    (colorEnd, i) => (colorEnd - colorStart[i]) / (steps - 1),
  )
  const palette = Array(steps)
    .fill(null)
    .map((_, i) =>
      colorStart
        .map((colorStart, j) => Math.round(colorStart + step[j] * i))
        .join(','),
    )

  return palette.map(color => `rgb(${color})`)
}
