import { appendScript } from '@/helpers/common'

type DropboxFile = {
  id: string
  name: string
  link: string
  bytes: number
  icon: string
  thumbnailLink: string
  isDir: boolean
}

let onSelect: (result: DropboxFile) => void

const { VITE_APP_DROPBOX_API_KEY } = import.meta.env

function openDropbox() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Dropbox.choose({
    success: function (files: DropboxFile[]) {
      onSelect(files[0])
    },
    cancel: function () {
      return
    },
    linkType: 'direct',
    multiselect: false,
    extensions: ['.pdf', '.doc', '.docx', '.jpg', '.jpeg', '.png'],
    folderselect: false,
    // sizeLimit: 1024,
  })
}

export const initDropboxPicker = (
  callback: (result: DropboxFile) => void,
): void => {
  onSelect = callback
  appendScript('https://www.dropbox.com/static/api/2/dropins.js', 'dropboxjs', {
    'data-app-key': VITE_APP_DROPBOX_API_KEY,
  }).then(openDropbox)
}
