import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import { TransactionSettings } from '@types'

import { prepareResponseError } from '../utils/helpers'
import { getTransactionsTypes } from './utils/helpers'

import api from '@/store/api'

export const useTransactionsSettingsStore = defineStore(
  'transactions-settings',
  () => {
    // INIT

    const list = ref<TransactionSettings[]>([])

    const initFlag = ref(false)

    let abortController = new AbortController()

    // GETTERS

    const getList = computed<TransactionSettings[]>(() => list.value)

    const getTypes = computed(() => getTransactionsTypes(getList.value))

    // SETTERS

    const setList = (data: TransactionSettings[]) => {
      list.value = data
    }
    const fetch = async (): Promise<void> => {
      try {
        const result = await api.get<TransactionSettings[]>(
          `transactions_settings`,
        )
        initFlag.value = true
        setList(result.data)
      } catch (e) {
        throw Error(prepareResponseError(e))
      }
    }

    const cancel = () => {
      abortController.abort()
      abortController = new AbortController()
    }

    const clear = () => {
      initFlag.value = false
      setList([])
    }

    return {
      initFlag,
      list,

      getList,
      getTypes,

      fetch,

      cancel,
      clear,
    }
  },
)
