import { TaskEntry, TaskStatus, TaskType } from '@types'

const parseError = (task: TaskEntry<any>) => {
  if (task.linked_type) {
    return `Linked  Account sync for ${task.linked_type}`
  }
  return task.error
}

const prepareNotifyMessage = (task: TaskEntry<any>) => {
  return task.meta_data?.deletedAccounts || parseError(task) || ''
}

export const filterNotify = (task: TaskEntry<any>) => {
  return (
    task.status !== TaskStatus.SUCCEEDED &&
    ![TaskType.EOD_SEARCH, TaskType.ANALYTICS_COMPUTE].includes(task.type)
  )
}

export const prepareNotify = (task: TaskEntry<any>) => ({
  id: task.id,
  datetime: task.created_at,
  message: prepareNotifyMessage(task),
  status: task.status,
})
