import { ModalEvent } from './enums'
import { ModalEventCallback, ModalEvents } from './types'

export const callEvent = (
  events: ModalEvents,
  name: ModalEvent,
  data?: any,
) => {
  if (!events[name]?.length) return
  const newEventsArray: ModalEventCallback[] = []
  events[name]?.forEach(event => {
    if (event(data)) {
      newEventsArray.push(event)
    }
  })
  events[name] = newEventsArray
}

export const addEventListener = (
  events: ModalEvents,
  name: ModalEvent,
  callback: ModalEventCallback,
) => {
  if (events[name] && Array.isArray(events[name])) {
    events[name]?.push(callback)
  } else {
    events[name] = [callback]
  }
  return events
}

export const removeEventListener = (
  events: ModalEvents,
  name: ModalEvent,
  callback: ModalEventCallback,
) => {
  const eventIndex = events[name]?.findIndex(value => value === callback)
  if (eventIndex === undefined || eventIndex === -1) return events
  events[name]?.splice(eventIndex, 1)
  return events
}
