import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import {
  CommonTag,
  LinkedDataTransaction,
  LinkedDataTransactionUpdate,
  PaginatedFetch,
  PaginatedMeta,
} from '@types'

import { PAGE_SIZE } from '@/const/common'

import { prepareResponseError, updateStoreListItem } from '../utils/helpers'

import { useRepositoriesStore } from '../repositories'
import { useLinkedDataTransactionsPivotStore } from './transactionsPivot'

import api, { getPaginatedRequest } from '@/store/api'

export const useLinkedDataTransactionsStore = defineStore(
  'linked-data-transactions',
  () => {
    // INIT
    const repositoriesStore = useRepositoriesStore()
    const linkedDataTransactionsPivotStore =
      useLinkedDataTransactionsPivotStore()

    const list = ref<LinkedDataTransaction[]>([])

    const initFlag = ref(false)

    const loading = ref(true)
    const loadingAction = ref(false)

    const meta = ref<PaginatedMeta>()

    let abortController = new AbortController()

    const repositoryId = computed(() => repositoriesStore.currentRepositoryId)

    // GETTERS
    const getList = computed(() => list.value)

    const getMeta = computed(() => meta.value)

    // SETTERS
    const setList = (
      data: LinkedDataTransaction[],
      metaData?: PaginatedMeta,
    ) => {
      list.value = data
      meta.value = metaData
    }

    // ACTIONS

    const fetch: PaginatedFetch = async (options, store = true) => {
      if (store) loading.value = true
      try {
        const { data, meta } = await getPaginatedRequest<LinkedDataTransaction>(
          'linked_data/transactions',
          {
            repository_id: repositoryId.value,
            page_size: PAGE_SIZE,
            ...options,
          },
        )
        if (store) {
          setList(data, meta)
          initFlag.value = true
        } else {
          return { data, meta }
        }
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        if (store) loading.value = false
      }
    }

    const bulkUpdate = async (
      data: LinkedDataTransactionUpdate[],
      store = true,
    ) => {
      if (store) loadingAction.value = true
      try {
        const result = await api.put<LinkedDataTransaction[]>(
          'linked_data/transactions',
          {
            data: data,
            repository_id: repositoryId.value,
          },
        )
        result.data.forEach(item => {
          updateStoreListItem(list.value, item)
        })
        linkedDataTransactionsPivotStore.clear()
        return result.data
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        if (store) loadingAction.value = false
      }
    }

    const getJSON = async (id: string): Promise<any> => {
      try {
        const result = await api.get(`linked_data/transactions/${id}`, {
          params: { repository_id: repositoryId.value },
          signal: abortController.signal,
        })
        return result.data
      } catch (e) {
        throw Error(prepareResponseError(e))
      }
    }

    const removeTag = (id: string, tagId: string) => {
      const index = list.value.findIndex(item => item.id === id)
      list.value[index].tags = list.value[index].tags.filter(
        tag => tag.id !== tagId,
      )
    }

    const assignTags = (id: string, tags: CommonTag[]) => {
      const index = list.value.findIndex(item => item.id === id)
      list.value[index].tags = tags
    }

    const cancel = () => {
      abortController.abort()
      abortController = new AbortController()
    }

    const clear = () => {
      initFlag.value = false

      loading.value = true

      setList([], undefined)
    }

    return {
      initFlag,
      loading,
      loadingAction,

      getList,
      getMeta,

      fetch,
      bulkUpdate,

      getJSON,

      removeTag,
      assignTags,

      cancel,
      clear,
    }
  },
)
