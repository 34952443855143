<template>
  <UIInputDropdown v-model="criteriaComparison" v-bind="{ size, data }" />
  <UILabeledField
    v-if="isFieldVisible"
    v-model="criteriaValue"
    v-bind="{ size }"
  />
</template>

<script setup lang="ts">
import { computed, onBeforeMount, watch } from 'vue'

import { FilterComparison, TransactionRule } from '@types'

import { CONTROL_SIZE as size } from '../utils/const'

import { getComparisonData } from '@/components/UI/Filter/components/utils/helpers'

import { UIInputDropdown, UILabeledField } from '@ui'

const modelValue = defineModel<TransactionRule['data']['criteria'][number]>({
  required: true,
})

const criteriaComparison = computed({
  get() {
    return modelValue.value.comparison
  },
  set(value) {
    modelValue.value.comparison = value
  },
})

const criteriaValue = computed({
  get() {
    return modelValue.value.value
  },
  set(value) {
    modelValue.value.value = value
  },
})

const data = computed(() =>
  getComparisonData().map(key => ({
    key,
    value: key,
  })),
)

const isFieldVisible = computed(
  () =>
    criteriaComparison.value &&
    ![FilterComparison.EMPTY, FilterComparison.NOTEMPTY].includes(
      criteriaComparison.value,
    ),
)

watch(isFieldVisible, value => {
  criteriaValue.value = value ? '' : undefined
})

onBeforeMount(() => {
  if (criteriaComparison.value) return
  criteriaComparison.value = FilterComparison.CONTAINS
})
</script>

<script lang="ts">
export default {
  name: 'LinkedDataRulesCriteriaText',
}
</script>
