import { UserPlan } from '@types'
import { DashboardWidgetName } from './emits'
import { DashboardWidgetParams } from './types'

export const WIDGETS_LIST: DashboardWidgetParams[] = [
  {
    label: 'Value Pie Chart',
    name: DashboardWidgetName.ValueHistoryPieChart,
    access: [UserPlan.PREMIUM, UserPlan.PREMIUM_PLUS, UserPlan.ULTRA],
  },
  {
    label: 'Value Table',
    name: DashboardWidgetName.ValueHistoryTable,
    access: [UserPlan.PREMIUM, UserPlan.PREMIUM_PLUS, UserPlan.ULTRA],
  },
  {
    label: 'Value History',
    name: DashboardWidgetName.ValueHistoryBarsChart,
    access: [UserPlan.PREMIUM, UserPlan.PREMIUM_PLUS, UserPlan.ULTRA],
  },
  {
    label: 'Value Pivot',
    name: DashboardWidgetName.ValuePivot,
    access: [UserPlan.PREMIUM, UserPlan.PREMIUM_PLUS, UserPlan.ULTRA],
  },
  {
    label: 'Income Table',
    name: DashboardWidgetName.IncomeHistoryTable,
    access: [UserPlan.PREMIUM, UserPlan.PREMIUM_PLUS, UserPlan.ULTRA],
  },
  {
    label: 'Income History',
    name: DashboardWidgetName.IncomeHistoryBarsChart,
    access: [UserPlan.PREMIUM, UserPlan.PREMIUM_PLUS, UserPlan.ULTRA],
  },
  {
    label: 'Income Pivot',
    name: DashboardWidgetName.IncomePivot,
    access: [UserPlan.PREMIUM_PLUS, UserPlan.ULTRA],
  },
  {
    label: 'Performance Pivot',
    name: DashboardWidgetName.PerformancePivot,
    access: [UserPlan.PREMIUM_PLUS, UserPlan.ULTRA],
  },
  {
    label: 'Linked Transactions',
    name: DashboardWidgetName.LinkedTransactions,
    access: [UserPlan.PREMIUM, UserPlan.PREMIUM_PLUS, UserPlan.ULTRA],
  },
  {
    label: 'Linked Transactions Pivot',
    name: DashboardWidgetName.LinkedTransactionsPivot,
    access: [UserPlan.PREMIUM_PLUS, UserPlan.ULTRA],
  },
  {
    label: 'Commitments',
    name: DashboardWidgetName.Commitments,
    access: [UserPlan.ULTRA],
  },
  {
    label: 'Section Delimiter',
    name: DashboardWidgetName.SectionDelimiter,
    access: [UserPlan.PREMIUM, UserPlan.PREMIUM_PLUS, UserPlan.ULTRA],
  },
  {
    label: 'Value Change Snapshot',
    name: DashboardWidgetName.ValueChangeSnapshot,
    access: [UserPlan.PREMIUM, UserPlan.PREMIUM_PLUS, UserPlan.ULTRA],
  },
  {
    label: 'Income Change Snapshot',
    name: DashboardWidgetName.IncomeChangeSnapshot,
    access: [UserPlan.PREMIUM, UserPlan.PREMIUM_PLUS, UserPlan.ULTRA],
  },
]

export const KEY_SEPARATOR = '/'

export const CHART_MAX_SLICES = 7
