<template>
  <div v-if="items.length" class="alert-group">
    <Alert v-for="item in items" :key="item?.instance.id" :alert="item" />
  </div>
</template>

<script setup lang="ts">
import { useAlerts } from '@/plugins/alerts'
import { computed } from 'vue'

import Alert from './Alert.vue'

const { alerts } = useAlerts()

const items = computed(() => [
  ...new Map(alerts.value.map(alert => [alert.message, alert])).values(),
])
</script>

<script lang="ts">
export default {
  name: 'AppAlertGroup',
}
</script>

<style scoped>
.alert-group {
  @apply my-2 space-y-2;
}
</style>
