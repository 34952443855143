import { TreeNodeDescriptionGetter, TreeNodeFormatter } from './types'

import { HASH_DELIMITER, INFO_SEPARATOR } from './const'

export const clearHighlighted = () => {
  const highlighted = document.querySelector('.ui-tree__item--highlighted')
  if (highlighted) {
    highlighted.classList.remove('ui-tree__item--highlighted')
  }
}

export const scrollToNode = (index: number, smooth = true) => {
  const el = document.querySelectorAll('.ui-tree__item--found')[index - 1]
  if (!el) return
  el.classList.add('ui-tree__item--highlighted')
  el?.scrollIntoView(
    smooth
      ? {
          behavior: 'smooth',
          block: 'end',
        }
      : undefined,
  )
}

export const generateHashData = (
  obj: any,
  meta?: string[],
  formatter?: TreeNodeFormatter,
  getDescription?: TreeNodeDescriptionGetter,
  isRoot = true,
  prefix = '',
) => {
  return Object.keys(obj).reduce((acc, k) => {
    const isMeta = meta?.includes(k)
    if (isMeta && !['string', 'number'].includes(typeof obj[k])) return acc
    let value
    if (isMeta || isRoot) {
      value = k
    } else {
      value = formatter ? formatter(k) : k
      if (getDescription) {
        const description = getDescription(k)
        if (value !== description) {
          value += `${INFO_SEPARATOR}${description}`
        }
      }
    }
    const key = `${prefix}${prefix && HASH_DELIMITER}${value}`.toLowerCase()
    if (typeof obj[k] === 'object' && obj[k] !== null) {
      Object.assign(
        acc,
        generateHashData(obj[k], meta, formatter, getDescription, false, key),
      )
    } else {
      acc[isMeta ? prefix : key] = obj[k]
    }
    return acc
  }, {} as any)
}
