<template>
  <UITags
    v-bind="{ disabled, loading, loadingAction, readonly, selected, tagsList }"
    @update="handleUpdateTags"
    @create="handleCreateTag"
    @item:update="handleUpdateTag"
    @item:delete="handleDeleteTag"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { DocumentClass } from '..'
import { DataFieldSizes } from '@types'

import { DOCUMENT_FIELD } from '../utils/const'

import { handleCatchedError } from '@/helpers/common'

import { useDocumentsTagsStore } from '@/store/documents/tags'

import { UITags } from '@ui'

type Props = {
  instance: DocumentClass
  disabled?: boolean
  readonly?: boolean
  label?: string
  dataRefid?: string
  size?: DataFieldSizes
}

const props = defineProps<Props>()

const documentsTagsStore = useDocumentsTagsStore()

const selected = computed({
  get() {
    return props.instance.field<string[]>(DOCUMENT_FIELD.TAGS).value
  },
  set(value) {
    props.instance.field<string[]>(DOCUMENT_FIELD.TAGS).value = [...value]
  },
})

const tagsList = computed(() => documentsTagsStore.getList)

const loading = computed(() => documentsTagsStore.loading)
const loadingAction = computed(() => documentsTagsStore.loadingAction)

const handleCreateTag = async (tag: string) => {
  if (!tag) return
  try {
    const result = await documentsTagsStore.store(tag)
    selected.value.push(result.id)
  } catch (e) {
    handleCatchedError(e as string, { tag })
  }
}

const handleUpdateTags = (tags: string[]) => {
  selected.value = tags
}

const handleUpdateTag = async (key: string) => {
  try {
    await documentsTagsStore.destroy(key)
  } catch (e) {
    handleCatchedError(e as string, key)
  }
}

const handleDeleteTag = async (key: string) => {
  try {
    await documentsTagsStore.destroy(key)
  } catch (e) {
    handleCatchedError(e as string, key)
  }
}
</script>

<script lang="ts">
export default {
  name: 'DocumentFormTags',
}
</script>
