import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { AxiosResponse } from 'axios'

import orderBy from 'lodash/orderBy'

import { Contact } from '@/entities/contacts/utils/types'
import { ApiListResponse } from '../utils/types'

import {
  addStoreListItem,
  prepareResponseError,
  removeStoreListItem,
  updateStoreListItem,
} from '../utils/helpers'

import api from '@/store/api'
import { useRepositoriesStore } from '@/store/repositories'

export const useContactsStore = defineStore('contacts', () => {
  // INIT
  const repositoriesStore = useRepositoriesStore()

  const list = ref<Contact[]>([])

  const initFlag = ref<boolean>(false)

  const loading = ref<boolean>(true)
  const loadingAction = ref<boolean>(false)

  let abortController = new AbortController()

  const repositoryId = computed(() => repositoriesStore.currentRepositoryId)

  // GETTERS
  const getList = computed<Contact[]>(() =>
    orderBy(list.value, [item => item.name.toLowerCase()]),
  )

  // SETTERS

  const setList = (data: Contact[]) => {
    list.value = data
  }

  const fetch = async (): Promise<Contact[]> => {
    loading.value = true
    try {
      const result = await api.get<ApiListResponse<Contact[]>>('contact_info', {
        params: {
          repository_id: repositoryId.value,
        },
        signal: abortController.signal,
      })
      const data = result.data.data
      setList(data)
      initFlag.value = true
      return data
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loading.value = false
    }
  }

  const store = async (contact: Contact): Promise<Contact> => {
    loadingAction.value = true
    try {
      const result = await api.post<Contact, AxiosResponse<Contact>, Contact>(
        'contact_info',
        { ...contact, repository_id: repositoryId.value },
      )
      const data = result.data
      addStoreListItem<Contact>(list.value, data)
      return data
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loadingAction.value = false
    }
  }

  const update = async (contact: Contact): Promise<Contact | undefined> => {
    loadingAction.value = true
    try {
      const { id } = contact
      const result = await api.put<Contact, AxiosResponse<Contact>, Contact>(
        `contact_info/${id}`,
        contact,
      )
      const data = result.data
      updateStoreListItem<Contact>(list.value, data)
      return data
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loadingAction.value = false
    }
  }

  const destroy = async (id: string): Promise<void> => {
    loadingAction.value = true
    try {
      await api.delete(`contact_info/${id}`)
      removeStoreListItem<Contact>(list.value, id)
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loadingAction.value = false
    }
  }

  const cancel = () => {
    abortController.abort()
    abortController = new AbortController()
  }

  const clear = () => {
    initFlag.value = false
    list.value = []
  }

  return {
    initFlag,
    loading,
    loadingAction,

    getList,

    fetch,

    store,
    update,
    destroy,

    cancel,
    clear,
  }
})
