<template>
  <UILabeledField
    v-model="modelStorage"
    :leading="{ icon: MagnifyingGlassIcon }"
    clearable
  />
</template>

<script setup lang="ts">
import { watch } from 'vue'

import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline'

import { UILabeledField } from '@ui'

const modelValue = defineModel<string>()
const modelStorage = defineModel<string>('storage')

watch(
  modelStorage,
  value => {
    modelValue.value = value?.trim() || undefined
  },
  { immediate: true },
)
</script>

<script lang="ts">
export default {
  name: 'UIPanelSearch',
}
</script>

<style scoped lang="postcss"></style>
