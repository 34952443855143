<template>
  <UIButton
    v-if="isButtonVisible"
    v-bind="{
      class: 'asset-eod__button',
      full: true,
      label: buttonLabel,
      variant: 'secondary',
      size: 'small',
    }"
    @click="handleToggle"
  />
  <div v-if="isFieldVisible" class="asset-eod__field">
    <EODSearch
      v-bind="{ disabled }"
      ref="inputRef"
      v-model="securityValue"
      :search-on-load="isToggled"
      placeholder="Tap to search asset"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, inject, nextTick, ref, watch } from 'vue'

import { AssetSecurityMaster } from '@types'
import { AssetClass } from '..'

import { ASSET_FIELD } from '../utils/const'
import {
  ASSET_CRYPTO_CURRENCY_TYPE,
  ASSET_CURRENCY_TYPE,
} from '@/entities/assets/utils/const'

import { useAssetsStore } from '@/store/assets'

import EODSearch from '@/modules/EODSearch/EODSearch.vue'

import { UIButton } from '@ui'

type Props = {
  instance: AssetClass
  disabled?: boolean
  focusOnLoad?: boolean
  isToggled?: boolean
}

const props = defineProps<Props>()

const assetsStore = useAssetsStore()

const typeValue = props.instance.field<string>(ASSET_FIELD.TYPE)
const nameValue = props.instance.field<string>(ASSET_FIELD.NAME)
const tickerValue = props.instance.field<string>(ASSET_FIELD.TICKER)
const currencyValue = props.instance.field<string>(ASSET_FIELD.CURRENCY)

const inputRef = ref<typeof EODSearch | null>(null)

const securityValue = ref<AssetSecurityMaster>()

const toggledFlag = ref(!!props.isToggled)

const assetFormSaveFocus = inject<() => void>('asset-save-focus')

const isButtonVisible = computed(() => props.isToggled && toggledFlag.value)

const buttonLabel = computed(
  () =>
    `Search security master${
      nameValue.value ? ` for: ${nameValue.value}` : ''
    }`,
)

const isFieldVisible = computed(() => !toggledFlag.value)

const isCurrency = computed(() =>
  [ASSET_CURRENCY_TYPE, ASSET_CRYPTO_CURRENCY_TYPE].includes(typeValue.value),
)

const handleToggle = async () => {
  toggledFlag.value = !toggledFlag.value
  await nextTick()
  inputRef.value?.search(nameValue.value)
}

watch(securityValue, value => {
  if (value === undefined) return
  nameValue.value = value.name
  tickerValue.value = value.code
  if (assetsStore.listTypes.includes(value.type)) {
    typeValue.value = value.type
  }
  if (!isCurrency.value) {
    currencyValue.value = value.currency
  }
  assetFormSaveFocus && assetFormSaveFocus()
})
</script>

<script lang="ts">
export default {
  name: 'AssetFormEODSearch',
}
</script>

<style lang="postcss">
.asset-eod__button {
  @apply -mt-3;
}
</style>
