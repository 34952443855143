import { computed, ref, watch } from 'vue'
import { defineStore } from 'pinia'

import { SearchSummary } from '@/store/search/utils/types'

import { ROUTE_NAME } from '@/const'
import { SEARCH_TAG_ALL } from '@/const/common'

import { useAssetsStore } from '@/store/assets'
import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useDocumentsBunchStore } from '@/store/documents/bunch'
import { useDocumentsStore } from '@/store/documents/index'
import { useTagsBunchStore } from '@/store/tags/bunch'
import { useTagsStore } from '@/store/tags'
import {
  filterFunction,
  prepareAccountsSearchList,
  prepareAssetsSearchList,
  prepareDocumentsSearchList,
  prepareTagsSearchList,
} from './utils/helpers'

export const useSearchStore = defineStore('search', () => {
  const assetsBunchStore = useAssetsBunchStore()
  const assetsStore = useAssetsStore()
  const documentsBunchStore = useDocumentsBunchStore()
  const documentsStore = useDocumentsStore()
  const tagsBunchStore = useTagsBunchStore()
  const tagsStore = useTagsStore()

  const string = ref('')
  const open = ref(false)

  const summaryAssets = computed(() => {
    const data = Array.from(assetsBunchStore.getCommonList.entries())
    return data.map(prepareAssetsSearchList('Assets', ROUTE_NAME.ASSETS_ITEM))
  })

  const getSearchSummaryAssets = computed(() =>
    summaryAssets.value.filter(item => filterFunction(item, string.value)),
  )

  const summaryTags = computed(() => {
    const data = Array.from(tagsBunchStore.getCommonList.entries())
    return data.map(prepareTagsSearchList('Tags', ROUTE_NAME.TAGS_ITEM))
  })

  const getSearchSummaryTags = computed(() =>
    summaryTags.value.filter(item => filterFunction(item, string.value)),
  )

  const summaryDocuments = computed(() => {
    const data = Array.from(documentsBunchStore.getList.entries())
    return data.map(
      prepareDocumentsSearchList(
        'Documents',
        ROUTE_NAME.DOCUMENTS_ITEM,
        assetsBunchStore.getList,
      ),
    )
  })

  const getSearchSummaryDocuments = computed(() =>
    summaryDocuments.value.filter(item => filterFunction(item, string.value)),
  )

  const summaryIncomeAccounts = computed(() => {
    const data = Array.from(assetsBunchStore.getIncomeAccountsList.entries())
    return data.map(
      prepareAccountsSearchList(
        'Income accounts',
        ROUTE_NAME.INCOME_ACCOUNTS_ITEM,
      ),
    )
  })

  const getSearchSummaryIncomeAccounts = computed(() =>
    summaryIncomeAccounts.value.filter(item =>
      filterFunction(item, string.value),
    ),
  )

  const summaryExpenseAccounts = computed(() => {
    const data = Array.from(assetsBunchStore.getExpenseAccountsList.entries())
    return data.map(
      prepareAccountsSearchList(
        'Expense accounts',
        ROUTE_NAME.EXPENSE_ACCOUNTS_ITEM,
      ),
    )
  })

  const getSearchSummaryExpenseAccounts = computed(() =>
    summaryExpenseAccounts.value.filter(item =>
      filterFunction(item, string.value),
    ),
  )

  const tags = computed(() => {
    const list: string[] = []
    if (string.value.trim() === '') return [SEARCH_TAG_ALL]
    if (getSearchSummaryAssets.value.length) {
      list.push('Assets')
    }
    if (getSearchSummaryTags.value.length) {
      list.push('Tags')
    }
    if (getSearchSummaryDocuments.value.length) {
      list.push('Documents')
    }
    if (getSearchSummaryIncomeAccounts.value.length) {
      list.push('Income')
    }
    if (getSearchSummaryExpenseAccounts.value.length) {
      list.push('Expense')
    }
    if (list.length !== 1) {
      list.unshift(SEARCH_TAG_ALL)
    }
    return list
  })

  const currentTag = ref(SEARCH_TAG_ALL)

  watch(tags, values => {
    if (!values.includes(currentTag.value)) {
      currentTag.value = SEARCH_TAG_ALL
    }
  })

  const getSearchSummary = computed(() => {
    if (
      !assetsStore.initFlag ||
      !tagsStore.initFlag ||
      !documentsStore.initFlag
    )
      return []
    return [
      ...getSearchSummaryAssets.value,
      ...getSearchSummaryTags.value,
      ...getSearchSummaryDocuments.value,
      ...getSearchSummaryExpenseAccounts.value,
      ...getSearchSummaryIncomeAccounts.value,
    ]
      .filter(
        (item: SearchSummary) =>
          currentTag.value === SEARCH_TAG_ALL ||
          item.type.startsWith(currentTag.value),
      )
      .slice(0, 9)
  })
  return { string, open, tags, currentTag, getSearchSummary }
})
