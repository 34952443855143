import { Intercom } from './type'

declare global {
  interface Window {
    Intercom: Intercom
  }
}

export const callFunction: Intercom = async (funcName, options, metadata) => {
  if (!window.Intercom) return
  return (await window.Intercom(funcName, options, metadata)) as string
}
