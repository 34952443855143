import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import { AnalyticsIrrMoic } from './utils/types'

import useAnalyticsStore from '../index'

export default defineStore('analytics-irr_moic', () => {
  // INIT
  const analyticsStore = useAnalyticsStore()

  const list = ref<AnalyticsIrrMoic[]>()

  const loading = ref(false)
  const deprecated = ref<boolean>()

  // GETTERS

  const isInit = computed(() => deprecated.value !== undefined)
  const getList = computed(() => {
    if (analyticsStore.getError) return new Map()
    return new Map(
      list.value?.map(({ account_id, asset_id, irr, moic }) => [
        `${account_id}-${asset_id}`,
        { irr, moic },
      ]),
    )
  })

  const getDatesRange = computed(() => {
    return list.value && Object.keys(list.value)
  })

  // SETTERS

  const setList = (data: { irr_moic_view: AnalyticsIrrMoic[] }) => {
    if (deprecated.value === false) return
    list.value = data.irr_moic_view
    deprecated.value = false
  }

  // ACTIONS

  const clear = () => {
    loading.value = false
    deprecated.value = undefined

    list.value = undefined
  }

  return {
    isLoading: loading,
    isDeprecated: deprecated,

    isInit,

    getList,
    getDatesRange,

    setList,

    clear,
  }
})
