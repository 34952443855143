<template>
  <LinkedDataTransactionsList
    v-model:search="search"
    v-model:filter="filter"
    v-model:sort="sort"
    v-bind="{ assetId }"
    :excluded-columns="[
      'connector_name',
      'account_name',
      'account_number',
      'account_type',
      'access_method',
    ]"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { Filter, Sort } from '@types'

import LinkedDataTransactionsList from '@/views/LinkedData/Transactions/components/List.vue'

type Props = {
  assetId?: string
}

defineProps<Props>()

const search = ref<string>()
const filter = ref<Filter>()
const sort = ref<Sort[]>()
</script>

<script lang="ts">
export default {
  name: 'AccountConnectorTransactions',
}
</script>

<style scoped lang="postcss"></style>
