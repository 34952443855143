import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { AxiosResponse } from 'axios'

import { CommonTag } from '@types'

import {
  addStoreListItem,
  prepareResponseError,
  removeStoreListItem,
  updateStoreListItem,
} from '../utils/helpers'

import api from '@/store/api'
import { useRepositoriesStore } from '@/store/repositories'
import { useLinkedDataTransactionsStore } from './transactions'
import { useLinkedDataTransactionsPivotStore } from './transactionsPivot'

export const useLinkedDataTransactionsTagsStore = defineStore(
  'linked-data-transactions-tags',
  () => {
    // INIT
    const repositoriesStore = useRepositoriesStore()
    const linkedDataTransactionsStore = useLinkedDataTransactionsStore()
    const linkedDataTransactionsPivotStore =
      useLinkedDataTransactionsPivotStore()

    const list = ref<CommonTag[]>([])

    const initFlag = ref(false)

    const loading = ref(true)
    const loadingAction = ref(false)

    let abortController = new AbortController()

    const repositoryId = computed(() => repositoriesStore.currentRepositoryId)

    // GETTERS
    const getList = computed(() => list.value)

    // SETTERS
    const setList = (data: CommonTag[]) => {
      list.value = data
    }

    // ACTIONS
    const fetch = async () => {
      loading.value = true
      try {
        const result = await api.get<CommonTag[]>(
          `linked_data/transaction_tags?repository_id=${repositoryId.value}`,
          {
            signal: abortController.signal,
          },
        )
        setList(result.data)
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loading.value = false
      }
    }

    const store = async (name: string): Promise<CommonTag | undefined> => {
      loadingAction.value = true
      try {
        const result = await api.post<
          CommonTag,
          AxiosResponse<CommonTag>,
          CommonTag
        >(`linked_data/transaction_tags`, {
          name,
          repository_id: repositoryId.value,
        })
        const data = result.data
        addStoreListItem<CommonTag>(list.value, data)
        return data
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loadingAction.value = false
      }
    }

    const update = async (
      tag: Partial<CommonTag>,
    ): Promise<CommonTag | undefined> => {
      loadingAction.value = true
      try {
        const result = await api.put<CommonTag>(
          `linked_data/transaction_tags/${tag.id}`,
          {
            ...tag,
            repository_id: repositoryId.value,
          },
        )
        updateStoreListItem(list.value, result.data)
        linkedDataTransactionsPivotStore.clear()
        return result.data
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loadingAction.value = false
      }
    }

    const destroy = async (id: string): Promise<void> => {
      loadingAction.value = true
      try {
        const result = await api.delete(`linked_data/transaction_tags/${id}`)
        removeStoreListItem(list.value, id)
        if (Array.isArray(result.data)) {
          result.data.forEach(transactionId => {
            linkedDataTransactionsStore.removeTag(transactionId, id)
          })
        }
        linkedDataTransactionsPivotStore.clear()
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loadingAction.value = false
      }
    }

    const cancel = () => {
      abortController.abort()
      abortController = new AbortController()
    }

    const clear = () => {
      initFlag.value = false

      loading.value = true
      loadingAction.value = false

      setList([])
    }

    return {
      initFlag,
      loading,
      loadingAction,

      getList,

      fetch,

      store,
      update,
      destroy,

      cancel,
      clear,
    }
  },
)
