import { computed, ref, watch } from 'vue'
import { defineStore } from 'pinia'
import { sumBy } from 'lodash'

import { AnalyticsValueGroupFields, AnalyticsValueHistory } from './utils/types'
import { AnalyticsValueSources } from './utils/enums'

import { ANALYTICS_ASSET_ID, ANALYTICS_DATE_STORAGE_KEY } from '../utils/const'
import { UNKNOWN_VALUE } from '@/const/common'

import useAnalyticsStore from '../index'

import { useUISettings } from '@/hooks/uiSettings'
import { useRepoLocalStorage } from '@/hooks/repoLocalStorage'

import { useAssetsBunchStore } from '@/store/assets/bunch'
import { prepareAsset } from './utils/helpers'

export default defineStore('analytics-value_history', () => {
  // INIT
  const analyticsStore = useAnalyticsStore()
  const assetsBunchStore = useAssetsBunchStore()

  const list = ref<Record<string, AnalyticsValueHistory[]>>()

  const loading = ref(false)
  const deprecated = ref<boolean>()

  const assetsPage = ref(0)
  const liabilitiesPage = ref(0)

  const source = useUISettings<AnalyticsValueSources>(
    ['analytics', 'dashboard', 'value', 'source'],
    AnalyticsValueSources.VALUE,
  )
  const groupField = useUISettings<AnalyticsValueGroupFields>(
    ['analytics', 'dashboard', 'value', 'group_field'],
    ANALYTICS_ASSET_ID,
  )
  const groupValue = useUISettings<string>(
    ['analytics', 'dashboard', 'value', 'group_value'],
    '',
  )
  const selectedAsset = useUISettings<string>(
    ['analytics', 'dashboard', 'value', 'selected_asset'],
    '',
  )

  const storageDate = useRepoLocalStorage<string | undefined>(
    ANALYTICS_DATE_STORAGE_KEY,
    undefined,
  )

  // GETTERS

  const isInit = computed(() => deprecated.value !== undefined)

  const getSource = computed(() => source.value)
  const getGroupField = computed(() => groupField.value)
  const getGroupValue = computed(() => groupValue.value)
  const getSelectedAsset = computed(() => selectedAsset.value)
  const getAmountField = computed(() => {
    switch (getSource.value) {
      case AnalyticsValueSources.VALUE_CHANGE:
        return 'd_position_close'
      case AnalyticsValueSources.GAIN_CHANGE:
        return 'd_position_gain'
      case AnalyticsValueSources.GAIN:
        return 'position_gain'
      default:
        return 'position_close'
    }
  })

  const getAssetsPage = computed(() => assetsPage.value)
  const getLiabilitiesPage = computed(() => liabilitiesPage.value)

  const getList = computed(() => {
    if (analyticsStore.getError) return {}
    return list.value
  })

  const getDatesRange = computed(() => {
    return list.value && Object.keys(list.value)
  })

  const getDate = computed(() => {
    if (!storageDate.value) return
    return storageDate.value
  })

  const getValues = computed(() => {
    if (!getDate.value) return []
    return getList.value?.[getDate.value] || []
  })

  const getNetWorth = computed(() => {
    if (!getValues.value?.length) return 0
    return sumBy(getValues.value, 'position_close')
  })

  const previousDate = computed(() => {
    if (!getDate.value) return
    const dateIndex = getDatesRange.value?.indexOf(getDate.value)
    if (!dateIndex || dateIndex < 1) return
    return getDatesRange.value?.[dateIndex - 1]
  })

  const previousDateSum = computed(() => {
    if (!previousDate.value) return 0
    const scope = getList.value?.[previousDate.value]
    if (!scope) return 0
    return sumBy(scope, 'position_close')
  })

  const getChangeTotal = computed(
    () => getNetWorth.value - previousDateSum.value,
  )

  const getChangeValue = computed(() => {
    if (!previousDateSum.value) return
    const result = getChangeTotal.value / previousDateSum.value
    return Math.round(result * 10000) / 100
  })

  const getMappedData = computed(() => {
    const result: Record<string, number> = {}
    const data = getValues.value
    for (let i = 0; i < data.length; i++) {
      const amount = data[i][getAmountField.value]
      if (typeof amount !== 'number') continue
      let key = data[i].asset_id
      if (groupField.value === ANALYTICS_ASSET_ID) {
        result[key] = amount
        continue
      }
      const preparedItem = prepareAsset(data[i], assetsBunchStore.getList)
      const value =
        preparedItem[groupField.value as keyof AnalyticsValueHistory] ||
        UNKNOWN_VALUE
      if (groupValue.value !== '' && value !== groupValue.value) continue
      if (groupValue.value === '') {
        key = value as string
      }
      if (!result[key]) {
        result[key] = 0
      }
      result[key] += amount
    }
    return result
  })

  const positiveEntries = computed(() =>
    Object.entries(getMappedData.value).filter(([, amount]) => amount >= 0),
  )

  const negativeEntries = computed(() =>
    Object.entries(getMappedData.value).filter(([, amount]) => amount < 0),
  )

  const positiveTotal = computed(() =>
    sumBy(positiveEntries.value, ([, amount]) => amount),
  )

  const negativeTotal = computed(() =>
    sumBy(negativeEntries.value, ([, amount]) => amount),
  )

  const getTotal = computed(() => positiveTotal.value + negativeTotal.value)

  const getAssets = computed(() => {
    return positiveEntries.value.map(([key, amount]) => {
      const percent = positiveTotal.value
        ? (amount / positiveTotal.value) * 100
        : 0
      return {
        key,
        amount,
        percent,
      }
    })
  })

  const getLiabilities = computed(() => {
    return negativeEntries.value.map(([key, amount]) => {
      const percent = negativeTotal.value
        ? (amount / negativeTotal.value) * 100
        : 0
      return {
        key,
        amount,
        percent,
      }
    })
  })

  // SETTERS

  const setList = (data: {
    value_history: Record<string, AnalyticsValueHistory[]>
  }) => {
    list.value = data.value_history
    deprecated.value = false
  }

  const setAssetsPage = (value: number) => {
    assetsPage.value = value
  }

  const setLiabilitiesPage = (value: number) => {
    liabilitiesPage.value = value
  }

  const setSource = (data: AnalyticsValueSources) => {
    source.value = data
  }
  const setGroupField = (data: AnalyticsValueGroupFields) => {
    groupField.value = data
  }
  const setGroupValue = (data?: string) => {
    groupValue.value = data || ''
  }
  const setSelectedAsset = (data: string) => {
    selectedAsset.value = data
  }

  // ACTIONS

  const clear = () => {
    loading.value = false
    deprecated.value = undefined

    list.value = undefined
  }

  watch(getGroupField, () => {
    if (!isInit.value) return
    setGroupValue('')
    setSelectedAsset('')
  })

  return {
    isLoading: loading,
    isDeprecated: deprecated,

    isInit,
    getSource,
    getGroupField,
    getGroupValue,
    getSelectedAsset,
    getAmountField,

    getAssetsPage,
    getLiabilitiesPage,

    getList,
    getDatesRange,

    getValues,
    getNetWorth,
    getChangeTotal,
    getChangeValue,

    getMappedData,
    getTotal,
    getAssets,
    getAssetsTotal: positiveTotal,
    getLiabilities,
    getLiabilitiesTotal: negativeTotal,

    setList,

    setAssetsPage,
    setLiabilitiesPage,
    setSource,
    setGroupField,
    setGroupValue,
    setSelectedAsset,

    clear,
  }
})
