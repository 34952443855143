import { Tag } from './utils/types'
import { Entity } from '../utils/common'
import { EntityEvent } from '../utils/enums'
import { AssetAccountTag } from '../assets/utils/enums'

import { TAG_DEFAULT_DATA, TAG_FIELD } from './utils/const'

import { rules } from '@/helpers/validate'

import { useTagsStore } from '@/store/tags/'
import { useTagsBunchStore } from '@/store/tags/bunch'
import { useModalsStore } from '@/store/modals'

import TagDialog from './components/TagDialog.vue'
import TagSlideover from './components/TagSlideover.vue'
import TagFormName from './components/TagFormName.vue'
import TagFormValue from './components/TagFormValue.vue'

export class TagClass extends Entity<Tag> {
  private tagsStore
  private bunchStore
  private modalsStore

  constructor(data: Partial<Tag>, created = false) {
    super(data, TAG_DEFAULT_DATA, created)
    this.tagsStore = useTagsStore()
    this.bunchStore = useTagsBunchStore()
    this.modalsStore = useModalsStore()
  }

  get isCategory() {
    return (
      this.field<string>(TAG_FIELD.TAG_NAME).value?.toLowerCase() ===
      AssetAccountTag.CATEGORY.toLowerCase()
    )
  }

  get isSubcategory() {
    return (
      this.field<string>(TAG_FIELD.TAG_NAME).value?.toLowerCase() ===
      AssetAccountTag.SUBCATEGORY.toLowerCase()
    )
  }

  get isCommon() {
    return !this.isCategory && !this.isSubcategory
  }

  getModal() {
    return this.isDialog ? TagDialog : TagSlideover
  }

  getFormTagName() {
    return TagFormName
  }

  getFormTagValue() {
    return TagFormValue
  }

  validate() {
    return {
      tag_name: rules.required,
      tag_value: rules.required,
    }
  }

  get pair() {
    return [
      this.field<string>(TAG_FIELD.TAG_NAME).value,
      this.field<string>(TAG_FIELD.TAG_VALUE).value,
    ]
  }

  get displayPair() {
    return this.pair.join(': ')
  }

  private removeFromBunch() {
    this.bunchStore.getList.delete(this.id)
  }

  private removeFromForms() {
    this.modalsStore.getList.delete(this.id)
  }

  remove() {
    this.removeFromBunch()
    this.removeFromForms()
  }

  async store() {
    try {
      const data = this.get()
      const result = await this.tagsStore.store(data)
      this.remove()
      if (result !== undefined) {
        this.reset(result)
        this.bunchStore.unshiftElement(this)
        this.callEvent(EntityEvent.STORED)
      }
    } catch (e) {
      throw Error(e as string)
    }
  }

  async update() {
    try {
      const result = await this.tagsStore.update(this.get())
      if (result === undefined) {
        this.cancel()
      } else {
        this.reset(result)
        this.callEvent(EntityEvent.UPDATED)
      }
    } catch (e) {
      throw Error(e as string)
    }
  }

  async destroy() {
    try {
      if (!this.isNew) {
        await this.tagsStore.destroy(this.id)
        this.callEvent(EntityEvent.DESTROYED)
      }
      this.remove()
    } catch (e) {
      this.cancel()
      throw Error(e as string)
    }
  }
}
