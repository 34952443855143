export const ISO_DATE_SHORT_FORMAT = /^\d{4}-\d{2}-\d{2}$/
export const ISO_DATE_FORMAT =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/

export const ISO_DATE_CUT_FORMAT = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/

export const DATE_FORMATS = ['yyyy-MM-dd', 'MM/dd/yyyy', 'dd/MM/yyyy']

export const TIME_FORMAT = 'HH:mm:ss'

export const LEAP_YEAR_EXAMPLE = 2008
export const MILLENIUM_CHANGE_YEAR = 30
