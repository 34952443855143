import { createI18n } from 'vue-i18n'

import { I18nInstance } from './utils/types'

import { handleLoadedLocales } from './utils/helpers'

let i18n: I18nInstance

export const i18nInit = async () => {
  const data = (import.meta as any).glob('../../**/*.json')

  const messages = await handleLoadedLocales(data)

  i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
    warnHtmlInMessage: 'off',
  })
}

export const useLocale = (prefix?: string) => {
  return {
    t: (path: string) => i18n?.global.t(`${prefix ? `${prefix}.` : ''}${path}`),
  }
}
