import { EntityFields } from '@/entities/utils/types'
import { Contact } from './types'

export { CONTACT_ID_PREFIX } from '@/const/common'

export const CONTACT_FIELD: EntityFields<Contact> = {
  ID: 'id',
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone',
  NOTES: 'notes',
  REPOSITORY_ID: 'repository_id',
  ASSETS: 'assets',
  CREATED_AT: 'created_at',
  CREATED_BY: 'created_by',
  UPDATED_AT: 'updated_at',
  UPDATED_BY: 'updated_by',
}

export const CONTACT_DEFAULT_DATA = {
  assets: [],
  id: '',
  name: '',
  email: '',
  phone: '',
  notes: '',
  repository_id: '',
}

export const CONTACT_FIELD_LABEL = {
  [CONTACT_FIELD.NAME]: 'Name',
  [CONTACT_FIELD.EMAIL]: 'Email',
  [CONTACT_FIELD.PHONE]: 'Phone',
  [CONTACT_FIELD.NOTES]: 'Notes',
  [CONTACT_FIELD.ASSETS]: 'Linked Assets',
  [CONTACT_FIELD.CREATED_AT]: 'Created at',
  [CONTACT_FIELD.UPDATED_AT]: 'Updated at',
}
