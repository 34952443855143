<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UISlidePanel
      v-bind="{ zIndex, isMinimized, title }"
      size="md"
      @minimize="handleMinimize"
      @hide="handleClose"
    >
      <AssetForm v-bind="{ instance: modal.entity }" @close="handleClose" />
      <AssetTabs v-if="isSavedAsset" v-bind="{ instance: modal.entity }" />
    </UISlidePanel>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed, provide } from 'vue'
import { TransitionRoot } from '@headlessui/vue'

import { AssetClass } from '..'
import { ModalClass, ModalState } from '@types'

import { ASSET_MODAL_TITLE, ASSET_MODAL_TITLE_NEW } from './utils/const'
import { ASSET_ID_PREFIX } from '../utils/const'

import { useModalsStore } from '@/store/modals'

import { UISlidePanel } from '@ui'
import AssetForm from './AssetForm.vue'
import AssetTabs from './AssetTabs.vue'

type Props = {
  modal: ModalClass<AssetClass>
  restrictions?: string[]
}

const props = defineProps<Props>()

const modalsStore = useModalsStore()

const title = computed(() =>
  props.modal.entity.isNew ? ASSET_MODAL_TITLE_NEW : ASSET_MODAL_TITLE,
)

const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const isMinimized = computed(() => props.modal.state === ModalState.HIDDEN)

const isSavedAsset = computed(() =>
  props.modal.entity.id.startsWith(ASSET_ID_PREFIX),
)

const zIndex = computed(() => props.modal.zIndex)

provide('restrictions', props.restrictions)

const handleMinimize = (flag: boolean) => {
  if (flag) {
    props.modal.hide()
  } else {
    props.modal.show(modalsStore.getZIndex())
  }
}

const handleClose = () => {
  props.modal.conditionalClose()
}
</script>

<script lang="ts">
export default {
  name: 'AssetSlideover',
}
</script>

<style scoped>
@import url('./styles/asset.css');
</style>
