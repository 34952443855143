<template>
  <UIButton
    v-if="!isReadonly"
    v-bind="{ size }"
    :icon="Square2StackIcon"
    label="Duplicate"
    variant="light-secondary"
    hide-label-on-mobile
  />
</template>

<script setup lang="ts">
import { inject } from 'vue'

import { ButtonSizes, ReadonlyMode } from '@types'

import { READONLY_MODE } from '@/const/common'

import { Square2StackIcon } from '@heroicons/vue/24/outline'

import { UIButton } from '@ui'

const size = inject<ButtonSizes>('size')
const isReadonly = inject<ReadonlyMode>(READONLY_MODE)
</script>

<script lang="ts">
export default {
  name: 'PanelDuplicate',
}
</script>

<style scoped lang="postcss"></style>
