<template>
  <Grid v-bind="{ collapseWidth, columns, items }" disabled>
    <template #headerCheckbox>
      <SkeletonValue v-bind="{ static }" square dark />
    </template>
    <template #header><SkeletonValue v-bind="{ static }" dark /></template>
    <template #checkbox><SkeletonValue v-bind="{ static }" square /></template>
    <template #cell><SkeletonValue v-bind="{ static }" /></template>
    <template #footer><SkeletonValue v-bind="{ static }" dark /></template>
    <template v-if="message" #bottom>
      <div class="ui-grid-skeleton__message">{{ message }}</div>
    </template>
  </Grid>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { GridColumn } from './utils/types'

import Grid from './Grid.vue'
import SkeletonValue from './components/SkeletonValue.vue'

type Props = {
  columns: GridColumn[]
  message?: string
  collapseWidth?: number
  static?: boolean
}

const props = defineProps<Props>()

const columns = computed(() =>
  props.columns.map(column => ({
    ...column,
    disabled: true,
  })),
)

const items = computed(() => Array(5))
</script>

<script lang="ts">
export default {
  name: 'UIGridSkeleton',
}
</script>

<style scoped lang="postcss">
.ui-grid-skeleton {
  &__message {
    @apply absolute top-[9.65rem] left-1/2;
    @apply -translate-x-1/2;
    @apply text-xs;
    @apply text-gray-400 dark:text-gray-500;
    @apply text-center;
    @apply z-20;
  }
}
</style>
