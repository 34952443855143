import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import { AnalyticsCommitment } from './utils/types'

import useAnalyticsStore from '../index'

export default defineStore('analytics-commitments', () => {
  // INIT
  const analyticsStore = useAnalyticsStore()

  const list = ref<AnalyticsCommitment[]>([])

  const loading = ref(false)
  const deprecated = ref<boolean>()

  const irrMoicData = computed(() => analyticsStore.module.irr_moic.getList)

  // GETTERS

  const isInit = computed(() => deprecated.value !== undefined)

  const getList = computed(() => {
    if (analyticsStore.getError) return []
    if (list.value && irrMoicData.value.size) {
      return list.value.map(item => {
        const { account_id, asset_id } = item
        const irrMoic = irrMoicData.value.get(`${account_id}-${asset_id}`)
        return {
          ...item,
          ...irrMoic,
        }
      })
    }
    return list.value
  })

  // SETTERS

  const setList = (data: { commitments_view: AnalyticsCommitment[] }) => {
    list.value = data.commitments_view
    deprecated.value = false
  }

  // ACTIONS

  const clear = () => {
    list.value = []
    deprecated.value = undefined
  }

  return {
    isLoading: loading,
    isDeprecated: deprecated,

    isInit,
    getList,

    setList,

    fetch,

    clear,
  }
})
