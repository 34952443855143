<template>
  <ul
    :class="`header__notifications${
      wrapped ? ' header__notifications--wrapped' : ''
    }`"
  >
    <li v-for="task in notifies" :key="task.id">
      <div class="hover:bg-gray-50 dark:hover:bg-gray-850">
        <div class="header__notifications__item">
          <div class="flex items-center justify-between">
            <p class="header__notifications__date">
              {{ stringToLocalDateString(task.datetime) }}
            </p>
            <div class="ml-2 flex flex-shrink-0">
              <XCircleIcon
                class="h-4 w-4 shrink-0 text-red-400 hover:text-red-600 cursor-pointer"
                aria-hidden="true"
                @click="removeTask(task.id)"
              />
            </div>
          </div>
          <div class="mt-2 sm:flex sm:justify-between">
            <div class="sm:flex">
              <p class="header__notifications__message">
                {{ task.message }}
              </p>
            </div>
            <div class="header__notifications__status">
              {{ task.status }}
            </div>
          </div>
        </div>
      </div>
    </li>
    <li v-for="(error, index) in errors" :key="index">
      <router-link
        :to="{
          name: ROUTE_NAME.TRANSACTIONS_ITEM,
          params: { id: error.id },
        }"
        class="block hover:bg-gray-50"
        @click="handleClickItem"
      >
        <div class="header__notifications__item">
          <div class="flex items-center justify-between">
            <p class="header__notifications__date">
              {{ error.date }}
            </p>
          </div>
          <div class="mt-2 sm:flex sm:justify-between">
            <div class="sm:flex">
              <p class="header__notifications__message">
                {{ error.message }}
              </p>
            </div>
            <div class="header__notifications__status">
              {{ error.type }}
            </div>
          </div>
        </div>
      </router-link>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { TaskNotify, TransactionErrorNotify } from '@types'

import { ROUTE_NAME } from '@/const'

import { stringToLocalDateString } from '@/helpers/dates'
import { handleCatchedError } from '@/helpers/common'

import { useTasksStore } from '@/store/tasks'

import { XCircleIcon } from '@heroicons/vue/24/outline'

type Props = {
  notifies: TaskNotify[]
  errors?: TransactionErrorNotify[]
  wrapped?: boolean
}

type Emits = {
  'click:item': []
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const tasksStore = useTasksStore()

const removeTask = (taskId: string) => {
  try {
    tasksStore.deleteEntry(taskId)
  } catch (e) {
    handleCatchedError(e as string, { taskId })
  }
}

const handleClickItem = () => {
  emit('click:item')
}
</script>

<script lang="ts">
export default {
  name: 'HeaderNotificationsList',
}
</script>

<style>
.header__notifications {
  @apply divide-y divide-gray-200 dark:divide-gray-700;
  @apply -mx-4 sm:-mx-6;
}
.header__notifications--wrapped {
  @apply w-[20rem] max-h-[20rem];
  @apply bg-white dark:bg-transparent !mx-0;
  @apply overflow-auto;
}
.header__notifications__item {
  @apply px-4 py-4 sm:px-6;
}
.header__notifications__date {
  @apply truncate text-xs font-medium text-indigo-600;
}
.header__notifications__message {
  @apply text-sm text-gray-500;
}
.header__notifications__status {
  @apply mt-2 sm:mt-0;
  @apply flex items-center;
  @apply text-xs text-gray-400 whitespace-nowrap;
}
</style>
