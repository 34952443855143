<template>
  <UIButton
    v-if="!isReadonly"
    v-bind="{ size }"
    :icon="ArrowDownOnSquareIcon"
    label="Save changes"
    variant="red"
  />
</template>

<script setup lang="ts">
import { inject } from 'vue'

import { ButtonSizes, ReadonlyMode } from '@types'

import { READONLY_MODE } from '@/const/common'

import { ArrowDownOnSquareIcon } from '@heroicons/vue/24/outline'

import { UIButton } from '@ui'

const size = inject<ButtonSizes>('size')
const isReadonly = inject<ReadonlyMode>(READONLY_MODE)
</script>

<script lang="ts">
export default {
  name: 'PanelSaveChanges',
}
</script>

<style scoped lang="postcss"></style>
