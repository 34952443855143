<template>
  <TransactionFormAccount
    ref="fieldRef"
    v-bind="{ ...$attrs, instance }"
    :entry-index="2"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { TransactionClass } from '..'

import TransactionFormAccount from './TransactionFormAccount.vue'

type Props = {
  instance: TransactionClass
}

defineProps<Props>()

defineExpose({
  focus() {
    fieldRef.value?.focus()
  },
})

const fieldRef = ref<typeof TransactionFormAccount>()
</script>

<script lang="ts">
export default {
  name: 'TransactionFormAccount3',
}
</script>
