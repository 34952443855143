import { DocumentTag } from '@/entities/documents/utils/types'
import { useDocumentsTagsStore } from '@/store/documents/tags'

export function getDocumentTagName(id: string | undefined): string {
  const documentsTagsStore = useDocumentsTagsStore()

  const tag = documentsTagsStore.getList.find(
    (item: DocumentTag) => item.id === id,
  )
  return tag?.name || ''
}

export function getUrlService(url: string, isNotLink = false): string {
  const a = document.createElement('a')
  a.href = url
  a.style.display = 'none'
  document.body.appendChild(a)
  const service = a.hostname
  document.body.removeChild(a)
  return isNotLink ? url : service
}

export const isPathClickable = (path: string) => {
  const clickablePathStartsWith = ['http', 'ftp://', 'sftp://']
  return clickablePathStartsWith.some(prefix => path.startsWith(prefix))
}
