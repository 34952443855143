<template>
  <form
    v-circular-tab
    class="repositories-form-invite"
    @submit.prevent="onSubmit"
  >
    <div class="flex-auto">
      <UILabeledField
        v-model="emailValue"
        :error="emailError"
        placeholder="Email"
        data-refid="RepositoryInviteFormEmail"
        :disabled="disabled"
      />
    </div>
    <UIInputDropdown
      v-model="accessTypeValue"
      :error="accessTypeError"
      data-refid="RepositoryInviteFormAccessType"
      :disabled="disabled"
      :data="accessTypes"
      id-key="key"
    />
    <UIButton
      label="Send invite"
      :disabled="disabled || !meta.valid"
      :loading="disabled"
      data-refid="RepositoryInviteFormSave"
      class="min-w-28"
    />
  </form>
</template>

<script setup lang="ts">
import { useField, useForm } from 'vee-validate'

import { RepositoryInvitePost } from '@types'
import { ACCESS_TYPES, ACCESS_TYPE_OWNER } from '@/const/repositories'

import { orderedList } from '@/helpers/common'
import { rules } from '@/helpers/validate'

import { UIButton, UIInputDropdown, UILabeledField } from '@ui'

type Props = {
  repositoryId: string
  disabled?: boolean
}

type Emits = {
  submit: [data: RepositoryInvitePost]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const accessTypes = orderedList(
  ACCESS_TYPES.filter((_, index) => index !== ACCESS_TYPE_OWNER - 1).map(
    (item, index) => ({
      index: index + 1,
      item,
    }),
  ),
  'index',
  'item',
)

const { meta, handleSubmit, resetForm } = useForm<RepositoryInvitePost>({
  initialValues: {
    email: '',
    access_type: 1,
    repository_id: props.repositoryId,
  },
  validationSchema: {
    email: rules.required,
    access_type: rules.required,
    repository_id: rules.required,
  },
})

const { value: emailValue, errorMessage: emailError } =
  useField<string>('email')
const { value: accessTypeValue, errorMessage: accessTypeError } =
  useField<string>('access_type')

const onSubmit = handleSubmit(values => {
  resetForm()
  emit('submit', values)
})
</script>

<script lang="ts">
export default {
  name: 'RepositoriesFormInvite',
}
</script>

<style scoped>
.repositories-form-invite {
  @apply mb-4 flex flex-col space-y-2;
  @apply sm:space-y-0 sm:flex-row sm:space-x-2;
}
</style>
