<template>
  <div :style="{ zIndex }">
    <TransitionChild
      :enter="`transform transition ease-in-out duration-${DEFAULT_OVERLAY_DURATION}`"
      :leave="`transform transition ease-in-out duration-${DEFAULT_OVERLAY_DURATION}`"
      appear
      as="template"
      enter-from="translate-x-full"
      enter-to="translate-x-0"
      leave-from="translate-x-0"
      leave-to="translate-x-full"
    >
      <div
        ref="slidepanel"
        class="slidepanel__wrapper"
        :class="slidepanelWrapperClasses"
        :style="{
          zIndex,
          ...(slidepanelWidth ? { width: `${slidepanelWidth}px` } : {}),
        }"
      >
        <div
          v-if="!isMinimized"
          ref="resizer"
          class="resizer"
          @mousedown="mouseDownResizer"
        >
          <div class="resizer-body"></div>
        </div>
        <div v-show="!isMinimized" v-circular-tab class="panel">
          <div class="panel__container">
            <component :is="title" v-if="typeof title === 'object'" />
            <div v-else class="title-wrapper">
              <slot name="title" />
              <template v-if="!$slots.title">
                <span>{{ title }}</span>
                <span v-if="subTitle" class="subtitle">{{ subTitle }}</span>
              </template>
            </div>

            <div class="title-buttons">
              <button
                v-if="!hideMinimiseButton"
                v-tooltip="'Fold this panel to the right '"
                class="fold"
                data-refid="slidePanelFold"
                tabindex="-1"
                @click="() => handleMinimize(true)"
              >
                <span class="sr-only">Fold panel</span>
                <ArrowRightOnRectangleIcon aria-hidden="true" class="h-6 w-6" />
              </button>
              <button
                class="close"
                data-refid="slidePanelClose"
                type="button"
                tabindex="-1"
                @click="handleHide"
              >
                <span class="sr-only">Close panel</span>
                <XMarkIcon aria-hidden="true" class="h-6 w-6" />
              </button>
            </div>
          </div>
          <div class="main-wrapper" :class="wrapperClass">
            <slot />
          </div>
        </div>
        <div v-show="isMinimized" class="container-minimised">
          <div class="h-7 mt-5 flex justify-center">
            <button
              class="close"
              data-refid="slidePanelCloseMinimised"
              @click="handleHide"
            >
              <span class="sr-only">Close panel</span>
              <XMarkIcon aria-hidden="true" class="h-6 w-6" />
            </button>
          </div>
          <div class="h-7 mt-3 flex justify-center">
            <button
              v-tooltip="'Fold this panel to the right bar'"
              class="fold transform rotate-180"
              @click="() => handleMinimize(false)"
            >
              <span class="sr-only">Fold panel</span>
              <ArrowRightOnRectangleIcon aria-hidden="true" class="h-6 w-6" />
            </button>
          </div>

          <div
            :style="{
              'writing-mode': 'vertical-rl',
            }"
            class="container-minimised__title"
          >
            {{ title }}
          </div>
        </div>
      </div>
    </TransitionChild>
  </div>
</template>

<script lang="ts" setup>
import { computed, provide, ref, VNode } from 'vue'

import { TransitionChild } from '@headlessui/vue'
import { ArrowRightOnRectangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'

import { ModalSize } from './utils/types'
import { useCheckDirtyForm } from '@/components/hooks/useCheckDirtyForm'
import {
  DEFAULT_LEFT_MINIMUM_MARGIN,
  DEFAULT_MIN_SLIDER_WIDTH,
  DEFAULT_OVERLAY_DURATION,
} from './utils/const'

type Props = {
  title?: string | VNode
  size?: ModalSize

  subTitle?: string
  hideMinimiseButton?: boolean
  zIndex: number

  wrapperClass?: string

  isMinimized?: boolean
}
const { size = 'sm', isMinimized } = defineProps<Props>()

type Emits = {
  hide: []
  minimize: [data: boolean]
}
const emit = defineEmits<Emits>()

const slidepanel = ref<HTMLDivElement>()
const resizer = ref<HTMLDivElement>()
const slidepanelWidth = ref(0)

const slidepanelWrapperClasses = computed(() => {
  if (isMinimized) {
    return 'slidepanel__wrapper--minimized'
  }
  return `slidepanel__wrapper--${size}`
})

const checkDirty = useCheckDirtyForm()
provide('modal-mark-dirty', checkDirty.onDirtyChange(true))

const handleMinimize = (flag: boolean) => {
  emit('minimize', flag)
}

const handleHide = () => {
  emit('hide')
}

const x = ref(0),
  w = ref(0)

const mouseDownResizer = (e: MouseEvent) => {
  e.preventDefault()
  x.value = e.x
  const rect = slidepanel.value?.getBoundingClientRect()
  if (rect) {
    w.value = rect.width
  }
  window.addEventListener('mousemove', mouseMoveResizer)
  window.addEventListener('mouseup', mouseUpResizer)
}
const mouseUpResizer = () => {
  window.removeEventListener('mousemove', mouseMoveResizer)
  window.removeEventListener('mouseup', mouseUpResizer)

  if (slidepanelWidth.value < DEFAULT_MIN_SLIDER_WIDTH) {
    handleMinimize(true)
    slidepanelWidth.value = 0
  }
}
const mouseMoveResizer = (e: MouseEvent) => {
  e.preventDefault()
  const dx = e.x - x.value
  if (w.value - dx >= DEFAULT_MIN_SLIDER_WIDTH) {
    slidepanelWidth.value = Math.min(
      w.value - dx,
      window.innerWidth - DEFAULT_LEFT_MINIMUM_MARGIN,
    )
  }
}
</script>
<script lang="ts">
export default {
  name: 'UISlidePanel',
  inheritAttrs: false,
}
</script>
<style scoped lasn="postcss">
.slidepanel__wrapper {
  @apply fixed flex inset-0 left-auto max-w-full h-screen;
  @apply pointer-events-auto;

  &--minimized {
    @apply !w-[3rem];
    @apply right-0;
    @apply bg-white dark:bg-gray-800;
  }

  &--sm {
    @apply w-[28rem];
  }
  &--md {
    @apply w-[36rem];
  }
  &--lg {
    @apply w-[56rem];
  }
  &--full {
    @apply w-[calc(100vw-8rem)];
  }
}

.panel {
  @apply flex h-full w-full flex-col shadow-xl;
  @apply bg-white dark:bg-gray-800;

  &__container {
    @apply flex items-start shrink-0 justify-between;
    @apply px-6 py-4;
  }
}

.main-wrapper {
  @apply flex flex-col flex-auto;
  @apply px-6 overflow-y-auto;
}
.container-minimised {
  @apply flex flex-col h-full w-full shadow-xl;
}
.container-minimised__title {
  @apply flex mt-3 transform rotate-180 items-center font-medium;
}

.content {
  @apply flex-grow px-4 sm:px-6 pb-2 overflow-y-auto;
}

.close {
  @apply bg-white dark:bg-gray-800 rounded-md;
  @apply text-gray-400 hover:text-gray-500;
  @apply dark:text-gray-500 dark:hover:text-gray-400;
  @apply focus:outline-none focus:ring-2 focus:ring-offset-2;
  @apply focus:ring-indigo-500 dark:focus:ring-gray-500;
}

.fold {
  @apply bg-white dark:bg-gray-800 rounded-md;
  @apply text-gray-400 hover:text-gray-500;
  @apply dark:text-gray-500 dark:hover:text-gray-400;
  @apply focus:outline-none focus:ring-2 focus:ring-offset-2;
  @apply focus:ring-indigo-500 dark:focus:ring-gray-500;
}

.resizer {
  @apply absolute hidden sm:flex shrink-0 h-full w-2;
  @apply cursor-col-resize z-10;
}

.resizer-body {
  @apply h-full w-px;
  @apply border-r border-gray-200 dark:border-gray-700;
  @apply border-dashed;
}
.title-wrapper {
  @apply flex flex-col w-full;
  @apply text-lg font-medium;
  @apply text-gray-900 dark:text-gray-200;
}
.subtitle {
  @apply text-sm leading-none;
  @apply text-gray-500 dark:text-gray-400;
}
.title-buttons {
  @apply flex gap-2;
  @apply text-gray-400 hover:text-gray-500;
  @apply dark:text-gray-500 dark:hover:text-gray-400;
}
</style>
