<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UISlidePanel
      v-bind="{ isMinimized, title, zIndex }"
      size="md"
      @hide="handleClose"
      @minimize="handleMinimize"
    >
      <div class="repository-settings">
        <RepositoryForm
          v-model="repositoryModel"
          v-bind="{ focusOnLoad, wrapped }"
          @close="handleClose"
        />
        <template v-if="repository">
          <div class="repository-settings__separator" />
          <div class="repository-settings__analytics">
            <span>Analytics</span>
            <UIButton
              label="Clear cache"
              variant="secondary"
              :loading="isClearCacheButtonDisabled"
              :disabled="isClearCacheButtonDisabled"
              @click="handleClearCache"
            />
          </div>
          <div class="repository-settings__separator" />
          <RepositoriesCollaborators
            v-bind="{ readonly }"
            :repository="repository"
          />
        </template>
      </div>
    </UISlidePanel>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'
import { useMagicKeys } from '@vueuse/core'

import { ModalClass, ModalState, Repository } from '@types'

import { ACCESS_TYPE_READONLY } from '@/const/repositories'

import useAnalyticsStore from '@/store/analytics'
import { useModalsStore } from '@/store/modals'
import { useRepositoriesStore } from '@/store/repositories'

import { handleCatchedError } from '@/helpers/common'

import { TransitionRoot } from '@headlessui/vue'
import { UIButton, UISlidePanel } from '@ui'
import RepositoryForm from './RepositoryForm.vue'
import RepositoriesCollaborators from './RepositoriesCollaborators.vue'

const { escape } = useMagicKeys()

type Props = {
  modal: ModalClass<any>
  focusOnLoad?: boolean
  repository?: Repository
  wrapped?: boolean
}
const props = defineProps<Props>()

type Emits = {
  'update:repository': [data: Repository]
}
const emit = defineEmits<Emits>()

const analyticsStore = useAnalyticsStore()
const modalsStore = useModalsStore()
const repositoriesStore = useRepositoriesStore()

const isMinimized = computed(() => props.modal.state === ModalState.HIDDEN)
const zIndex = computed(() => props.modal.zIndex)
const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const repositoryModel = computed<Repository | undefined>({
  get() {
    return props.repository
  },
  set(value: Repository | undefined) {
    if (!value) {
      return
    }
    emit('update:repository', value)
  },
})

const isClearCacheButtonDisabled = computed(() => repositoriesStore.deleteCache)

const readonly = computed(
  () =>
    !!props.repository?.user_repo_settings?.access_type &&
    ACCESS_TYPE_READONLY === props.repository?.user_repo_settings?.access_type,
)

const title = computed(() =>
  props.repository
    ? `${props.repository.name} repository settings`
    : 'New repository',
)

const handleClose = () => {
  props.modal.conditionalClose()
}

const handleMinimize = (flag: boolean) => {
  if (flag) {
    props.modal.hide()
  } else {
    props.modal.show(modalsStore.getZIndex())
  }
}

const handleClearCache = async () => {
  if (!props.repository) return
  try {
    await repositoriesStore.clearCache(props.repository.id)
    await analyticsStore.markAsDeprecated()
  } catch (e) {
    handleCatchedError(e as string, { repositoryId: props.repository.id })
  }
}

watch(escape, value => {
  if (value) {
    handleClose()
  }
})
</script>

<script lang="ts">
export default {
  name: 'RepositorySlideover',
}
</script>

<style scoped lang="postcss">
.repository-settings {
  @apply min-w-96;
  @apply space-y-4 -m-1;

  &__separator {
    @apply border-t border-gray-200 dark:border-gray-700;
  }

  &__analytics {
    @apply flex w-full items-center justify-between;
  }
}
</style>
