<template>
  <UIInputDropdown
    v-bind="{ loading, ...$attrs }"
    ref="inputRef"
    v-model="dropdownValue"
    :data="assetsList"
    id-key="code"
    value-key="code"
    disable-search
    clearable
    @search="handleSearch"
    @select="handleSelect"
  >
    <template #trailing>
      <MagnifyingGlassIcon />
    </template>
    <template #item="{ item }">
      <div class="eod-search__row" :class="rowClasses">
        <div
          v-tooltip="item.name"
          class="eod-search__cell eod-search__cell--name"
        >
          {{ item.name }}
        </div>
        <div class="eod-search__cell">{{ item.code }}</div>
        <template v-if="!short">
          <div v-tooltip="item.type" class="eod-search__cell">
            {{ item.type }}
          </div>
          <div class="eod-search__cell">{{ item.exchange }}</div>
        </template>
      </div>
    </template>
  </UIInputDropdown>
</template>

<script setup lang="ts">
import { computed, inject, nextTick, ref, watch } from 'vue'
import { useDebounceFn } from '@vueuse/core'

import { AssetSecurityMaster } from '@types'
import { WSInstance, WSResponse } from '@/services/ws/utils/types'

import { THROTTLE_DELAY } from './utils/const'

import { useUserStore } from '@/store/user'

import { UIInputDropdown } from '@ui'
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline'

type Props = {
  disabled?: boolean
  short?: boolean
}

const props = defineProps<Props>()

const modelValue = defineModel<AssetSecurityMaster>()

defineExpose({
  async search(data: string) {
    dropdownValue.value = data
    handleSearch(data)
    await nextTick()
    inputRef.value?.toggle(true)
  },
  async cancel(data?: string) {
    dropdownValue.value = data
    if (data) return
  },
})

const userStore = useUserStore()

const ws = inject<WSInstance>('ws')

const inputRef = ref<typeof UIInputDropdown | null>(null)

const dropdownValue = ref<string>()
const loading = ref(false)
const uniqueId = ref()

const assetsList = ref<AssetSecurityMaster[]>([])

const rowClasses = computed(() => ({ 'eod-search__row--short': props.short }))

const handleSearch = (data: string) => {
  assetsList.value = []
  uniqueId.value = data
  if (data === '') {
    assetsList.value = []
    loading.value = false
    return
  }
  loading.value = true
  fetch(data)
}

const fetch = useDebounceFn(async (data: string) => {
  ws?.send('security_master', 'eod_search', data, { symbol: data })
}, THROTTLE_DELAY)

const handleSelect = (value: AssetSecurityMaster) => {
  modelValue.value = value
}

watch(
  () => userStore.isAuthenticated,
  value => {
    if (!value) return
    ws?.subscribe(
      'security_master',
      'eod_search',
      async (message: WSResponse<AssetSecurityMaster[]>, requestId: string) => {
        if (requestId !== uniqueId.value) return
        loading.value = false
        assetsList.value = message.data || []
      },
    )
  },
  { immediate: true },
)
</script>

<script lang="ts">
export default {
  name: 'EODSearch',
}
</script>

<style lang="postcss">
.eod-search {
  &__row {
    @apply flex-auto;
    @apply grid gap-1;
    @apply grid-cols-[minmax(5rem,1fr)_minmax(2rem,5rem)_minmax(2rem,8rem)_minmax(2rem,3rem)];

    &--short {
      @apply grid-cols-[minmax(6rem,1fr)_minmax(2rem,1fr)];
    }
  }
  &__cell {
    @apply truncate;

    &--name {
      @apply font-medium;
    }
  }
}
</style>
