import { EntityFields } from '@/entities/utils/types'
import { Asset } from './types'
import { AssetAccountTag } from './enums'

import { ANALYTICS_INCOME_TRANSFER_TYPES } from '@/store/analytics/utils/const'
export { ASSET_ID_PREFIX } from '@/const/common'

export const ASSET_FIELD: EntityFields<Asset> = {
  ID: 'id',
  NAME: 'name',
  TICKER: 'ticker',
  CURRENCY: 'currency',
  TYPE: 'type',
  DESCRIPTION: 'description',
  CURRENCY_ASSET: 'currency_asset',
  REPOSITORY_ID: 'repository_id',
  HOLDINGS_ID: 'holdings_id',
  TAGS: 'tags',
  CONTACTS: 'contacts',
  DOCS: 'docs',
  LINKED_ID: 'linked_id',
  LINKED_SECURITY_ID: 'linked_security_id',
  LINKED_ACCOUNT_ID: 'linked_account_id',
  CONNECTOR_STATUS: 'connector_status',
  CONNECTOR_STATUS_MESSAGE: 'connector_status_message',
  SECURITY_INFO: 'security_info',
  PROPERTIES: 'properties',
  CREATED_AT: 'created_at',
  CREATED_BY: 'created_by',
  UPDATED_AT: 'updated_at',
  UPDATED_BY: 'updated_by',
}

export const ASSET_DEFAULT_DATA = {
  id: '',
  name: '',
  ticker: '',
  currency: '',
  type: '',
  description: '',
  repository_id: '',
}

export const ASSET_FIELD_LABEL = {
  [ASSET_FIELD.TYPE]: 'Type',
  [ASSET_FIELD.NAME]: 'Name',
  [ASSET_FIELD.TICKER]: 'Ticker',
  [ASSET_FIELD.TAGS]: 'Tags',
  [ASSET_FIELD.CURRENCY]: 'Currency',
  [ASSET_FIELD.DESCRIPTION]: 'Description',
  [ASSET_FIELD.CREATED_AT]: 'Created at',
  [ASSET_FIELD.UPDATED_AT]: 'Updated at',
  [AssetAccountTag.CATEGORY]: 'Category',
  [AssetAccountTag.SUBCATEGORY]: 'Subcategory',
}

export const ASSET_CURRENCY_TYPE = 'currency'
export const ASSET_CRYPTO_CURRENCY_TYPE = 'cryptocurrency'

export const ASSET_CURRENCY_DEPARTURE = 'USD'

export const ASSET_ACCOUNT_PRE_CREATED = [
  'market gain & loss',
  'cash dividend',
  'cash interest',
  'transfer in',
  'transfer out',
  'current income',
  'realized gain or loss',
  'interest expense',
]

export const ASSET_ACCOUNT_INVESTMENT = ['market gain & loss']
export const ASSET_ACCOUNT_TRANSFER = ANALYTICS_INCOME_TRANSFER_TYPES
