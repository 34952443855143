import { Duration } from 'luxon'

import { AnalyticsRequest } from './types'
import { RepositoryTimeline } from '@types'
import { isEqual } from 'lodash'

export const prepareDatesRangeSlice = (dates: string[], periods?: number) => {
  if (!dates.length || !periods) return []

  if (dates.length > periods) {
    dates = dates.slice(-periods)
  }
  return dates
}

export const convertToSeconds = (millis: number) =>
  Math.ceil(Duration.fromMillis(millis).as('seconds'))

export const compareRequestWithResponse = (
  request: AnalyticsRequest | undefined,
  repositoryId: string | undefined,
  timeline: RepositoryTimeline,
  rootId: string | undefined,
) => {
  if (!request || !repositoryId || !rootId) return false
  const compareRepositoryId = request.rid === repositoryId
  const compareTimelines = isEqual(request.timeline, timeline)
  const compareParams =
    (request.data === 'position' &&
      request.params.accounts === JSON.stringify([rootId])) ||
    request.params.root === rootId
  return compareRepositoryId && compareTimelines && compareParams
}
