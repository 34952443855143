import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { orderBy } from 'lodash'

import { Asset } from '@/entities/assets/utils/types'
import { ApiListResponse } from '../utils/types'

import api from '@/store/api'

import { prepareResponseError, removeStoreListItem } from '../utils/helpers'

import { useRepositoriesStore } from '@/store/repositories'
import { useLinkedDataStore } from '@/store/linkedData'

import { ASSET_CURRENCY_TYPE } from '@/entities/assets/utils/const'
import { AssetCategoryAccount } from '@/entities/assets/utils/enums'

export const useAssetsStoreV2 = defineStore('assets-v2', () => {
  // INIT
  const repositoriesStore = useRepositoriesStore()
  const linkedDataStore = useLinkedDataStore()

  const deleted = ref<Asset[]>([])

  let abortController = new AbortController()

  const repositoryId = computed(() => repositoriesStore.currentRepositoryId)

  // GETTERS

  const getDeleted = computed(() => orderBy(deleted.value, ['date'], ['desc']))
  const getCommonDeleted = computed(() =>
    getDeleted.value.filter(
      item =>
        ![
          ASSET_CURRENCY_TYPE,
          AssetCategoryAccount.INCOME,
          AssetCategoryAccount.EXPENSE,
        ].includes(item.type),
    ),
  )
  const getCurrenciesDeleted = computed(() =>
    getDeleted.value.filter(item => item.type === ASSET_CURRENCY_TYPE),
  )
  const getIncomeAccountsDeleted = computed(() =>
    getDeleted.value.filter(item => item.type === AssetCategoryAccount.INCOME),
  )
  const getExpenseAccountsDeleted = computed(() =>
    getDeleted.value.filter(item => item.type === AssetCategoryAccount.EXPENSE),
  )

  const getDeletedCounts = computed(() => ({
    common: getCommonDeleted.value.length,
    currencies: getCurrenciesDeleted.value.length,
    income: getIncomeAccountsDeleted.value.length,
    expense: getExpenseAccountsDeleted.value.length,
  }))

  // SETTERS

  const setDeleted = (data: Asset[]) => {
    deleted.value = data
  }

  // ACTIONS

  const fetchDeleted = async (): Promise<void> => {
    try {
      const result = await api.get<ApiListResponse<Asset[]>>('assets', {
        signal: abortController.signal,
        params: {
          repository_id: repositoryId.value,
          show_deleted: true,
        },
      })
      setDeleted(result.data.data)
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
  }

  const restore = async (id: string): Promise<Asset | undefined> => {
    try {
      const result = await api.post(`assets/${id}/restore`)
      removeStoreListItem(deleted.value, id)
      return result.data
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
  }

  const permanentDelete = async (id: string): Promise<void> => {
    try {
      await api.delete(`assets/${id}?is_hard=1`)
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
    linkedDataStore.fetch()
  }

  const cancel = () => {
    abortController.abort()
    abortController = new AbortController()
  }

  const clear = () => {
    setDeleted([])
  }

  return {
    getDeleted,
    getCommonDeleted,
    getCurrenciesDeleted,
    getIncomeAccountsDeleted,
    getExpenseAccountsDeleted,
    getDeletedCounts,

    setDeleted,

    fetchDeleted,
    restore,
    permanentDelete,

    cancel,
    clear,
  }
})
