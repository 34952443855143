import {
  FRACTION_DIGITS,
  FRACTION_DIGITS_MAX,
  FRACTION_DIGITS_PRICE_MAX,
} from '@/const/common'

const LOCALE = 'en-US'

export function numberParse(stringNumber: any) {
  const thousandSeparator = Intl.NumberFormat(LOCALE)
    .format(11111)
    .replace(/\p{Number}/gu, '')
  const decimalSeparator = Intl.NumberFormat(LOCALE)
    .format(1.1)
    .replace(/\p{Number}/gu, '')

  return parseFloat(
    stringNumber
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + decimalSeparator), '.'),
  )
}

export function numberFormat(
  number: string | number | { value: number },
  params?: {
    currency?: string
    fractionDigits?: number
    fractionDigitsMax?: number
  },
): string {
  const minimumFractionDigits = params?.fractionDigits ?? FRACTION_DIGITS
  const numberFormatter = new Intl.NumberFormat(LOCALE, {
    minimumFractionDigits,
    maximumFractionDigits: params?.fractionDigitsMax ?? minimumFractionDigits,
  })

  if (typeof number === 'string') {
    number = parseFloat(number)
  }
  if (typeof number === 'object' && number !== null) {
    number = number.value
  }
  if (isNaN(number) || number === null) return ''
  if (number === 0) number = Math.abs(number)

  if (params?.currency) {
    const value = numberFormatter.format(Math.abs(number))
    const sign = Object.is(Math.abs(number), +number) ? '' : '-'
    return `${sign}${params.currency}${value}`
  } else {
    return numberFormatter.format(number)
  }
}

export const amountFormatter = (number: number) =>
  numberFormat(number, {
    fractionDigitsMax: FRACTION_DIGITS_MAX,
  })

export const priceFormatter = (number: number) =>
  numberFormat(number, {
    fractionDigitsMax: FRACTION_DIGITS_PRICE_MAX,
  })
