<template>
  <UIInputDropdown
    v-model="modelValue"
    :data-refid="dataRefid"
    v-bind="{
      data,
      disabled,
      error,
      focusOnLoad,
      label,
      silentError,
      size,
    }"
    placeholder="Find an option"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'

import { DataFieldSizes } from '@types'
import { AssetClass } from '..'

import { ASSET_FIELD } from '../utils/const'

import { orderedList } from '@/helpers/common'

import { useRepositoriesStore } from '@/store/repositories'

import { UIInputDropdown } from '@ui'

type Props = {
  instance: AssetClass
  disabled?: boolean
  label?: string
  focusOnLoad?: boolean
  dataRefid?: string
  size?: DataFieldSizes
}

const props = defineProps<Props>()

const repositoriesStore = useRepositoriesStore()

const modelValue = props.instance.field<string>(ASSET_FIELD.CURRENCY)

const isSavedAsset = inject('isSavedAsset', ref(false))

const error = computed(() => props.instance.errors.currency)
const silentError = computed(() => !isSavedAsset.value)

const data = computed(() =>
  orderedList(repositoriesStore.currenciesList, 'ticker', 'ticker'),
)
</script>

<script lang="ts">
export default {
  name: 'AssetFormCurrency',
}
</script>
