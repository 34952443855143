<template>
  <UILabeledField
    v-model="modelValue"
    :data-refid="dataRefid"
    autocomplete="off"
    v-bind="{
      disabled,
      error,
      focusOnLoad,
      label,
      silentError,
      size,
    }"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'

import { ContactClass } from '../'
import { DataFieldSizes } from '@types'

import { CONTACT_FIELD } from '../utils/const'

import { UILabeledField } from '@ui'

type Props = {
  instance: ContactClass
  disabled?: boolean
  label?: string
  focusOnLoad?: boolean
  dataRefid?: string
  size?: DataFieldSizes
}

const props = defineProps<Props>()

const modelValue = props.instance.field<string>(CONTACT_FIELD.NAME)

const isSavedContact = inject('isSavedContact', ref(false))

const error = computed(() => props.instance.errors.name)
const silentError = computed(() => !isSavedContact.value)
</script>

<script lang="ts">
export default {
  name: 'ContactFormName',
}
</script>
