<template>
  <div v-if="items?.length" ref="mainRef" class="main-menu">
    <nav ref="containerRef" class="main-menu__container">
      <MenuItem
        v-for="(item, index) of items"
        :key="`${item.routeName}${item.routeParams?.slug || ''}`"
        v-bind="{ item }"
        ref="menuItemRef"
        :invisible="index >= visibleCount"
      />
    </nav>
    <div data-refid="actionsContainer" class="main-menu__actions" />
    <UIDropdown
      v-if="isDropdownVisible"
      :model-value="modelValue"
      :items="dropdownItems"
      placement="bottom-end"
      id-key="key"
      value-key="name"
      class="main-menu__more"
      :style="moreStyles"
      @click:item="handleClickDropdownItem"
    >
      <EllipsisHorizontalIcon aria-hidden="true" class="main-menu__dots" />
    </UIDropdown>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useResizeObserver } from '@vueuse/core'

import { NavigationItem } from '@types'

import { useNavigationStore } from '@/store/navigation'

import { EllipsisHorizontalIcon } from '@heroicons/vue/20/solid'
import MenuItem from './MenuItem.vue'
import { UIDropdown } from '@ui'

const route = useRoute()
const router = useRouter()

const navigationStore = useNavigationStore()

const mainRef = ref<any>()
const containerRef = ref<HTMLDivElement>()
const menuItemRef = ref<(typeof MenuItem)[]>()

const visibleCount = ref(0)
const dotsLeft = ref(0)

const items = computed(() => navigationStore.getCurrentChildNavigation)

const modelValue = computed(
  () => `${route.name?.toString()}${JSON.stringify(route.params)}`,
)

const dropdownItems = computed(() =>
  items.value?.slice(visibleCount.value).map(item => ({
    ...item,
    key: `${item.routeName}${JSON.stringify(item.routeParams || {})}`,
  })),
)

const isDropdownVisible = computed(
  () => items.value && visibleCount.value < items.value.length,
)

const moreStyles = computed(() => ({ left: `${dotsLeft.value}px` }))

const handleClickDropdownItem = (item: NavigationItem) => {
  router.push({ name: item.routeName, params: item.routeParams })
}

useResizeObserver(containerRef, entries => {
  const container = entries[0]
  if (!container) return
  const style = mainRef.value
    ? mainRef.value.currentStyle || window.getComputedStyle(mainRef.value)
    : 0
  const mainPadding = Number(style.paddingLeft.replace('px', ''))
  const mainLeft = mainRef.value.getBoundingClientRect().left
  const containerWidth =
    container.target.getBoundingClientRect().width + mainPadding + mainLeft
  visibleCount.value = 0
  dotsLeft.value = mainPadding
  menuItemRef.value?.forEach(item => {
    const itemEl = item.getEl()
    const itemRect = itemEl.getBoundingClientRect()
    if (itemRect.left + itemRect.width <= containerWidth) {
      visibleCount.value++
      const style = itemEl.currentStyle || window.getComputedStyle(itemEl)
      dotsLeft.value +=
        itemRect.width + Number(style.marginLeft.replace('px', ''))
    }
  })
})
</script>

<script lang="ts">
export default {
  name: 'MainMenu',
}
</script>

<style scoped lang="postcss">
.main-menu {
  @apply relative;
  @apply flex;
  @apply mb-5 -mt-4 sm:mt-0 -mx-4 sm:-mx-6 lg:-mx-8;
  @apply px-4 sm:px-6 lg:px-8;
  @apply border-b border-gray-200 dark:border-gray-600;

  &__container {
    @apply flex space-x-4;
    @apply flex-auto;
    @apply mr-4;
    @apply overflow-hidden;
  }

  &__actions {
    @apply flex items-center space-x-2;
  }

  &__more {
    @apply w-8 h-8;
    @apply absolute top-1/2;
    @apply flex items-center justify-center;
    @apply shrink-0;
    @apply translate-x-2;
    @apply -translate-y-1/2;
    @apply cursor-pointer;
  }

  &__dots {
    @apply w-5 h-5;
    @apply text-gray-400 hover:text-gray-600;
  }
}
</style>
