import { ROUTE_NAME } from '@/const'
import { NavigationPreset } from './types'

export const DASHBOARD_NAME = 'Dashboards'

export const HEADER_NAVIGATION: NavigationPreset[] = [
  {
    name: DASHBOARD_NAME,
    routeName: ROUTE_NAME.DASHBOARDS,
  },
  {
    name: 'Data',
  },
  {
    name: 'Connectors',
  },
  {
    name: 'Analytics',
  },
]

export const CHILDREN_NAVIGATION: Record<string, NavigationPreset[]> = {
  Data: [
    {
      name: 'Transactions',
      routeName: ROUTE_NAME.TRANSACTIONS,
      additional: [ROUTE_NAME.TRANSACTIONS_ITEM],
    },
    {
      name: 'Assets',
      routeName: ROUTE_NAME.ASSETS,
      additional: [ROUTE_NAME.ASSETS_ITEM],
    },
    {
      name: 'Tags',
      routeName: ROUTE_NAME.TAGS,
      additional: [ROUTE_NAME.TAGS_ITEM],
    },
    {
      name: 'Income accounts',
      routeName: ROUTE_NAME.INCOME_ACCOUNTS,
      additional: [ROUTE_NAME.INCOME_ACCOUNTS_ITEM],
    },
    {
      name: 'Expense accounts',
      routeName: ROUTE_NAME.EXPENSE_ACCOUNTS,
      additional: [ROUTE_NAME.EXPENSE_ACCOUNTS_ITEM],
    },
    {
      name: 'Currencies',
      routeName: ROUTE_NAME.CURRENCIES,
      additional: [ROUTE_NAME.CURRENCIES_ITEM],
    },
    {
      name: 'Documents',
      routeName: ROUTE_NAME.DOCUMENTS,
      additional: [ROUTE_NAME.DOCUMENTS_ITEM],
    },
    {
      name: 'Contacts',
      routeName: ROUTE_NAME.CONTACTS,
      additional: [ROUTE_NAME.CONTACTS_ITEM],
    },
  ],
  Connectors: [
    {
      name: 'Accounts',
      routeName: ROUTE_NAME.LINKED_DATA,
      additional: [
        ROUTE_NAME.LINKED_DATA_ACCOUNT,
        ROUTE_NAME.LINKED_DATA_CONNECTOR,
      ],
    },
    {
      name: 'Transactions',
      routeName: ROUTE_NAME.LINKED_DATA_TRANSACTIONS,
    },
    {
      name: 'Mapping rules',
      routeName: ROUTE_NAME.LINKED_DATA_MAPPING_RULES,
    },
  ],
  Analytics: [
    {
      name: 'Value',
      routeName: ROUTE_NAME.ANALYTICS_VALUE,
    },
    {
      name: 'Value Change',
      routeName: ROUTE_NAME.ANALYTICS_VALUE_CHANGE,
    },
    {
      name: 'Income',
      routeName: ROUTE_NAME.ANALYTICS_INCOME,
    },
    {
      name: 'Position',
      routeName: ROUTE_NAME.ANALYTICS_POSITION,
    },
  ],
}
