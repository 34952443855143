<template>
  <div
    v-if="isSlotFilled"
    class="ui-grid__actions"
    :class="mainClasses"
    @mouseup.stop
    @mousedown.stop
  >
    <div class="ui-grid__actions-button" @click="handleClick">
      <XMarkIcon
        v-if="isOpen"
        aria-hidden="true"
        class="ui-grid__actions-icon"
      />
      <EllipsisVerticalIcon
        v-else
        aria-hidden="true"
        class="ui-grid__actions-icon"
      />
    </div>
    <div v-if="isOpen" class="ui-grid__actions-area" :class="areaClasses">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Ref, computed, inject, ref, useSlots } from 'vue'

import { EllipsisVerticalIcon, XMarkIcon } from '@heroicons/vue/24/outline'

const slots = useSlots()

const isHovered = inject<Ref<boolean>>('isHovered')

const isOpen = ref(false)

const isSlotFilled = computed(() => slots.default?.()?.[0]?.children?.length)

const mainClasses = computed(() => ({
  'ui-grid__actions--opened': isOpen.value,
  'ui-grid__actions--opened-hovered': isOpen.value && isHovered?.value,
}))

const areaClasses = computed(() => ({
  'ui-grid__actions-area--hovered': isHovered?.value,
}))

const handleClick = () => {
  isOpen.value = !isOpen.value
}
</script>

<script lang="ts">
export default {
  name: 'GridRowCellActions',
}
</script>

<style scoped lang="postcss">
.ui-grid__actions {
  @apply absolute right-0.5 -bottom-1.5;
  @apply flex items-center;
  @apply z-10;

  &--opened {
    @apply bg-white dark:bg-gray-800;

    &-hovered {
      @apply bg-gray-50 dark:bg-gray-900;
    }
  }

  &-button {
    @apply w-8 h-8;
    @apply flex items-center justify-center;
    @apply text-gray-500 hover:text-indigo-700;
    @apply dark:text-gray-400 dark:hover:text-indigo-300;
    @apply !bg-opacity-80 hover:bg-indigo-50 dark:hover:bg-indigo-900;
    @apply cursor-pointer;
  }

  &-icon {
    @apply h-5 w-5;
  }

  &-area {
    @apply absolute inset-y-0 right-full;
    @apply flex items-center;
    @apply gap-2 px-1;
    @apply bg-white dark:bg-gray-800;

    @apply after:content-[''];
    @apply after:w-10;
    @apply after:absolute after:inset-y-0 after:right-full;
    @apply after:bg-gradient-to-l;
    @apply after:from-white dark:after:from-gray-800;

    :deep() .btn {
      @apply whitespace-nowrap;
    }
  }
}

.ui-grid:not(.ui-grid--collapsed) {
  .ui-grid__actions {
    @apply inset-y-0 right-auto left-0;

    &-button {
      @apply w-6 lg:w-8;
    }

    &-area {
      @apply right-auto left-full;

      @apply after:right-auto after:left-full;
      @apply after:bg-gradient-to-r;

      &--hovered {
        @apply bg-gray-50 dark:bg-gray-900;
        @apply after:from-gray-50 dark:after:from-gray-900;
      }
    }
  }
}
</style>
