import { computed, h, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { defineStore } from 'pinia'

import { useMainStore } from '@/store/main'

import { AssetClass } from '@/entities/assets'
import { ErrorNodes } from '@types'

import { ASSET_FIELD } from '@/entities/assets/utils/const'
import { ROUTE_NAME } from '@/const'
import { NOTIFICATION_DELAY } from '@/const/common'

import { generateId } from '@/entities/utils/helpers'
import { checkForErrors } from './utils/helpers'
import { isDirtyList, unsavedGridBlocker } from '../utils/helpers'

import { useNotifications } from '@/plugins/notification'

import { useAssetsStore } from '../assets'
import { useContactsBunchStore } from '../contacts/bunch'
import { useDocumentsBunchStore } from '../documents/bunch'
import { useRepositoriesStore } from '../repositories'

export const useAssetsBunchStore = defineStore('assets-bunch', () => {
  // INIT

  const mainStore = useMainStore()
  const assetsStore = useAssetsStore()
  const contactsBunchStore = useContactsBunchStore()
  const documentsBunchStore = useDocumentsBunchStore()
  const repositoryStore = useRepositoriesStore()

  const router = useRouter()
  const { error } = useNotifications()

  const repositoryId = computed(() => repositoryStore.getCurrentRepository?.id)
  const repositoryCurrency = computed(
    () => repositoryStore.getCurrentRepositoryCurrency,
  )

  // STORE

  const initFlag = ref(false)
  const list = ref<Map<string, AssetClass>>(new Map())
  const errorsCommon = ref<ErrorNodes[]>()
  const errorsCurrencies = ref<ErrorNodes[]>()
  const errorsIncomeAccounts = ref<ErrorNodes[]>()
  const errorsExpenseAccounts = ref<ErrorNodes[]>()

  // ACTIONS

  const createElement = () => {
    const id = generateId()
    const element = new AssetClass(
      {
        id,
        currency: repositoryCurrency.value,
        repository_id: repositoryId.value,
        tags: [],
      },
      true,
    )
    const entries = Array.from(list.value.entries())
    list.value = new Map([[id, element], ...entries])
    return element
  }

  const unshiftElement = (element: AssetClass) => {
    const entries = Array.from(list.value.entries())
    list.value = new Map([[element.id, element], ...entries])
    return element
  }

  const duplicateElement = (id: string) => {
    const entries = Array.from(list.value.entries())
    const index = entries.findIndex(item => item[0] === id)
    if (index > -1) {
      const id = generateId()
      const entry = entries[index][1]
      const data = entry.get()
      const element = new AssetClass({ ...data, id }, true)
      const prefixBunch = entries.slice(0, index + 1)
      const postfixBunch = entries.slice(index + 1)
      list.value = new Map([...prefixBunch, [id, element], ...postfixBunch])
    }
  }

  const showErrors = (result: ErrorNodes[] | undefined) => {
    if (!result) return
    error(
      { message: h('div', { class: 'space-y-2 text-xs' }, result) },
      NOTIFICATION_DELAY,
    )
  }

  const checkForCommonErrors = () => {
    const result = checkForErrors(
      getCommonList.value,
      'Asset',
      'Assets',
      router,
    )
    errorsCommon.value = result
    showErrors(result)
    return !!result
  }

  const checkForCurrenciesErrors = () => {
    const result = checkForErrors(
      getCurrenciesList.value,
      'Currency',
      'Assets',
      router,
    )
    errorsCurrencies.value = result
    showErrors(result)
    return !!result
  }

  const checkForIncomeAccountsErrors = () => {
    const result = checkForErrors(
      getIncomeAccountsList.value,
      'Income account',
      'Assets',
      router,
      false,
    )
    errorsIncomeAccounts.value = result
    showErrors(result)
    return !!result
  }

  const checkForExpenseAccountsErrors = () => {
    const result = checkForErrors(
      getExpenseAccountsList.value,
      'Expense Account',
      'Assets',
      router,
      false,
    )
    errorsExpenseAccounts.value = result
    showErrors(result)
    return !!result
  }

  // GETTERS

  const getList = computed<Map<string, AssetClass>>(() =>
    list.value.size ? list.value : new Map(),
  )
  const getEntries = computed(() => Array.from(getList.value.entries()))
  const getCommonList = computed(() => {
    return new Map(getEntries.value.filter(([, instance]) => instance.isCommon))
  })
  const getCurrenciesList = computed(() => {
    return new Map(
      getEntries.value.filter(([, instance]) => instance.isCurrency),
    )
  })
  const getIncomeAccountsList = computed(() => {
    return new Map(
      getEntries.value.filter(([, instance]) => instance.isIncomeAccount),
    )
  })
  const getExpenseAccountsList = computed(() => {
    return new Map(
      getEntries.value.filter(([, instance]) => instance.isExpenseAccount),
    )
  })
  const getUnlinkedList = computed(() => {
    return new Map(
      getEntries.value.filter(([, instance]) => !instance.isLinked),
    )
  })

  const assetsList = computed(() => assetsStore.list)

  const getCommonErrors = computed(() => errorsCommon.value)
  const getCurrenciesErrors = computed(() => errorsCurrencies.value)
  const getIncomeAccountsErrors = computed(() => errorsIncomeAccounts.value)
  const getExpenseAccountsErrors = computed(() => errorsExpenseAccounts.value)

  const getElementByName = (name: string, skippedId?: string) => {
    return Array.from(getList.value.values()).find(
      instance =>
        instance.field<string>(ASSET_FIELD.NAME).value?.toLowerCase() ===
          name.toLowerCase() && instance.id !== skippedId,
    )
  }

  const getElementByTicker = (ticker: string, skippedId?: string) => {
    return Array.from(getList.value.values()).find(
      instance =>
        instance.field<string>(ASSET_FIELD.TICKER).value?.toLowerCase() ===
          ticker.toLowerCase() && instance.id !== skippedId,
    )
  }

  const getElementById = (id: string) => {
    return getList.value.get(id) as AssetClass
  }

  const getNameById = (id: string) => {
    return getElementById(id)?.field<string>(ASSET_FIELD.NAME).value
  }

  const getTypeById = (id: string) => {
    return getElementById(id)?.field<string>(ASSET_FIELD.TYPE).value
  }

  const getTagsById = (id: string) => {
    return getElementById(id)?.field<string[]>(ASSET_FIELD.TAGS).value
  }

  const isCommonDirty = computed(() => isDirtyList(getCommonList.value))
  const isCurrenciesDirty = computed(() => isDirtyList(getCurrenciesList.value))
  const isIncomeAccountsDirty = computed(() =>
    isDirtyList(getIncomeAccountsList.value),
  )
  const isExpenseAccountsDirty = computed(() =>
    isDirtyList(getExpenseAccountsList.value),
  )

  // FILL STORE

  watch(assetsList, value => {
    const isFirstLoading = list.value.size === 0
    initFlag.value = false
    value.forEach(item => {
      const asset = list.value.get(item.id)
      if (!asset) {
        const instance = new AssetClass(item)
        if (isFirstLoading) {
          list.value.set(item.id, instance)
        } else {
          unshiftElement(instance)
        }
      } else {
        asset.field(ASSET_FIELD.CONNECTOR_STATUS).value = item.connector_status
        asset.field(ASSET_FIELD.CONNECTOR_STATUS_MESSAGE).value =
          item.connector_status_message
        asset.field(ASSET_FIELD.LINKED_ACCOUNT_ID).value =
          item.linked_account_id
      }
    })
    initFlag.value = true
  })

  // CLEAR STORE

  const clear = () => {
    initFlag.value = false
    list.value = new Map()
    errorsCommon.value = undefined
    errorsCurrencies.value = undefined
    errorsIncomeAccounts.value = undefined
    errorsExpenseAccounts.value = undefined
  }

  watch(getList, () => {
    for (const instance of contactsBunchStore.getList.values()) {
      instance.resetAssets()
    }
    for (const instance of documentsBunchStore.getList.values()) {
      instance.resetAssetId()
    }
  })

  // ADD BLOCKER IF DIRTY

  watch(isCommonDirty, value => {
    if (value) {
      mainStore.addBlocker(
        'asstetsHasChanges',
        unsavedGridBlocker('asstets', () =>
          router.push({ name: ROUTE_NAME.ASSETS }),
        ),
      )
    } else {
      mainStore.removeBlocker('asstetsHasChanges')
    }
  })

  watch(isCurrenciesDirty, value => {
    if (value) {
      mainStore.addBlocker(
        'currenciesHasChanges',
        unsavedGridBlocker('currencies', () =>
          router.push({ name: ROUTE_NAME.CURRENCIES }),
        ),
      )
    } else {
      mainStore.removeBlocker('currenciesHasChanges')
    }
  })

  watch(isIncomeAccountsDirty, value => {
    if (value) {
      mainStore.addBlocker(
        'incomeAccountsHasChanges',
        unsavedGridBlocker('incomeAccounts', () =>
          router.push({ name: ROUTE_NAME.INCOME_ACCOUNTS }),
        ),
      )
    } else {
      mainStore.removeBlocker('incomeAccountsHasChanges')
    }
  })

  watch(isExpenseAccountsDirty, value => {
    if (value) {
      mainStore.addBlocker(
        'expenseAccountsHasChanges',
        unsavedGridBlocker('expenseAccounts', () =>
          router.push({ name: ROUTE_NAME.EXPENSE_ACCOUNTS }),
        ),
      )
    } else {
      mainStore.removeBlocker('expenseAccountsHasChanges')
    }
  })

  return {
    initFlag,

    createElement,
    unshiftElement,
    duplicateElement,
    checkForCommonErrors,
    checkForCurrenciesErrors,
    checkForIncomeAccountsErrors,
    checkForExpenseAccountsErrors,

    getList,
    getCommonList,
    getCurrenciesList,
    getIncomeAccountsList,
    getExpenseAccountsList,
    getUnlinkedList,
    getCommonErrors,
    getCurrenciesErrors,
    getIncomeAccountsErrors,
    getExpenseAccountsErrors,

    getElementByName,
    getElementByTicker,
    getElementById,
    getNameById,
    getTypeById,
    getTagsById,

    isCommonDirty,
    isCurrenciesDirty,
    isIncomeAccountsDirty,
    isExpenseAccountsDirty,

    clear,
  }
})
