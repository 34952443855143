<template>
  <UIDropdown
    :model-value="currentRouteName"
    v-bind="{ items }"
    reverse
    placement="bottom-end"
    id-key="key"
    value-key="label"
    class="header-user"
    data-refid="headerUser"
  >
    <AppHeaderIcon :icon="UserCircleIcon" class="header-user__toggler" />
    <template #before>
      <div class="header-user__name">{{ userEmail }}</div>
    </template>
  </UIDropdown>
</template>

<script setup lang="ts">
import { computed, ComputedRef, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useDark, useToggle } from '@vueuse/core'

import { ROUTE_NAME } from '@/const'

import { useUserStore } from '@/store/user'

import { AppHeaderIcon } from '@app'
import {
  ArrowRightEndOnRectangleIcon,
  Cog6ToothIcon,
  MoonIcon,
  SunIcon,
  UserCircleIcon,
} from '@heroicons/vue/24/outline'
import { UIDropdown } from '@/components/UI'

const userStore = useUserStore()

const route = useRoute()
const router = useRouter()

const isDark = useDark()
const toggleDark = useToggle(isDark)

const userEmail = inject<ComputedRef<string>>('userEmail')

const darkThemeIcon = computed(() => (isDark.value ? SunIcon : MoonIcon))

const items = computed(() => [
  {
    label: isDark.value ? 'Light Theme' : 'Dark Theme',
    icon: darkThemeIcon.value,
    action: (hide: () => void) => {
      hide()
      toggleDark()
    },
  },
  {
    key: 'settings',
    label: 'Settings',
    icon: Cog6ToothIcon,
    action: (hide: () => void) => {
      hide()
      router.push({ name: ROUTE_NAME.SETTINGS })
    },
  },
  {
    label: 'Sign out',
    icon: ArrowRightEndOnRectangleIcon,
    action: async () => {
      await userStore.signOut()
    },
    attrs: {
      'data-refid': 'headerSignout',
    },
  },
])

const currentRouteName = computed(
  () =>
    (route.matched[0]?.name ||
      route.matched[0]?.path?.replace('/', '')) as string,
)
</script>

<script lang="ts">
export default {
  name: 'HeaderUser',
}
</script>

<style lang="postcss">
.header-user {
  &__toggler {
    @apply text-indigo-700 hover:text-indigo-600;
    @apply dark:text-indigo-300 dark:hover:text-indigo-400;
  }

  &__name {
    @apply w-72 md:w-52;
    @apply px-4 pt-2 pb-3 mb-1;
    @apply border-b border-gray-100 dark:border-gray-600;
    @apply text-gray-500 dark:text-gray-400;
    @apply text-sm;
    @apply text-right;
    @apply truncate;
  }
}
</style>
