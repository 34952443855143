<template>
  <nav aria-label="Tree" class="ui-tree">
    <TreeSearch
      v-model="searchStorage"
      v-model:search="clearedSearch"
      v-bind="{ numberFound, isLoading, placeholder, size }"
    />
    <div class="ui-tree__container">
      <TreeItem
        v-bind="{ collapsed, node }"
        :key-value="root"
        path=""
        is-root
        is-opened-container
        @click:name="handleClickName"
        @on:contextmenu="handleOnContextmenu"
      >
        <template #default="slotProps">
          <slot name="meta" v-bind="slotProps" />
        </template>
        <template #name>
          <slot name="root" />
        </template>
        <template #description="slotProps">
          <slot name="description" v-bind="slotProps" />
        </template>
        <template v-if="$slots.actions" #actions="slotProps">
          <slot name="actions" v-bind="slotProps" />
        </template>
      </TreeItem>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { computed, nextTick, provide, ref, watch } from 'vue'
import { get } from 'lodash'
import { debouncedRef, watchPausable } from '@vueuse/core'

import {
  Tree,
  TreeNodeDescriptionGetter,
  TreeNodeFormatter,
} from './utils/types'
import { InputSize } from '../Input/utils/types'

import { DEBOUNCE_DELAY, HASH_DELIMITER } from './utils/const'

import { waitForNode } from '@/helpers/common'
import {
  clearHighlighted,
  generateHashData,
  scrollToNode,
} from './utils/helpers'

import TreeItem from './components/TreeItem.vue'
import TreeSearch from './components/TreeSearch.vue'

type Props = {
  data?: Tree
  root: string

  metaFields?: string[]
  wide?: boolean
  collapsed?: boolean
  searchInMeta?: boolean

  placeholder?: string
  size?: InputSize

  formatter?: TreeNodeFormatter
  getDescription?: TreeNodeDescriptionGetter

  sortField?: string
  sortDir?: 'asc' | 'desc'
}

type Emits = {
  'click:name': [item: Tree, isLeaf: boolean]
  'on:contextmenu': [item: Tree, isLeaf: boolean]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const searchStorage = defineModel<string>('search', { default: '' })

const clearedSearch = ref('')
const debouncedSearch = debouncedRef(clearedSearch, DEBOUNCE_DELAY)

provide('metaFields', props.metaFields)
provide('search', debouncedSearch)
provide('wide', props.wide)
provide('formatter', props.formatter)
provide('getDescription', props.getDescription)
provide('sortField', props.sortField)
provide('sortDir', props.sortDir || 'desc')

const isLoading = ref(false)

const loaderTimeout = ref<NodeJS.Timeout>()

const highlightedNodeIndex = ref(0)

provide('highlightedNodeIndex', highlightedNodeIndex)

const node = computed(() => get(props.data, [props.root]))

const hashData = computed(() =>
  generateHashData(
    props.data,
    props.metaFields,
    props.formatter,
    props.getDescription,
    true,
  ),
)
const foundData = computed(() =>
  debouncedSearch.value
    ? Object.fromEntries(
        Object.entries(hashData.value).filter(([key, value]) => {
          if (key === props.root) return
          const search = debouncedSearch.value.toLowerCase()
          const keys = key.split(HASH_DELIMITER)
          return (
            keys.at(-1)?.includes(search) ||
            (props.searchInMeta && `${value}`.toLowerCase().includes(search))
          )
        }),
      )
    : {},
)
const numberFound = computed(() => Object.keys(foundData.value).length)
provide('foundData', foundData)

const handleOnContextmenu = (item: Tree, isLeaf: boolean) => {
  emit('on:contextmenu', item, isLeaf)
}

const handleClickName = (item: Tree, isLeaf: boolean) => {
  emit('click:name', item, isLeaf)
}

watch(searchStorage, () => {
  loaderTimeout.value && clearTimeout(loaderTimeout.value)
  isLoading.value = true
  pause()
  nextTick(resume)
})

watch(debouncedSearch, () => {
  loaderTimeout.value = setTimeout(() => {
    isLoading.value = false
  }, DEBOUNCE_DELAY * 2)
  if (!highlightedNodeIndex.value) return
  clearHighlighted()
  highlightedNodeIndex.value = 0
})

const { pause, resume } = watchPausable(numberFound, value => {
  loaderTimeout.value && clearTimeout(loaderTimeout.value)
  isLoading.value = false
  if (!value) return
  waitForNode('.ui-tree__item--found').then(() => {
    highlightedNodeIndex.value = 1
    scrollToNode(highlightedNodeIndex.value)
  })
})
</script>

<script lang="ts">
export default {
  name: 'UITree',
}
</script>

<style lang="postcss">
.ui-tree {
  @apply flex flex-col flex-auto;
  @apply max-w-full;

  &__container {
    @apply flex-auto;
    @apply md:h-0;
    @apply overflow-y-auto;
    @apply overflow-x-hidden;
  }
}
</style>
