<template>
  <UIButton
    ref="buttonRef"
    class="min-w-28"
    label="Close"
    type="button"
    variant="light"
    tabindex="-1"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { UIButton } from '@ui'

const buttonRef = ref<typeof UIButton | null>(null)

defineExpose({
  focus() {
    buttonRef.value?.focus()
  },
})
</script>

<script lang="ts">
export default {
  name: 'UIButtonClose',
}
</script>
