<template>
  <div
    class="ui-grid__menu"
    :class="mainClasses"
    @click="handleClickMenu"
    @mouseup.stop
    @mousedown.stop
  >
    <EllipsisVerticalIcon aria-hidden="true" class="ui-grid__menu-icon" />
  </div>
</template>

<script setup lang="ts">
import { Ref, computed, inject } from 'vue'

import { EllipsisVerticalIcon } from '@heroicons/vue/20/solid'

type Emits = {
  click: []
}

const emit = defineEmits<Emits>()

const isHovered = inject<Ref<boolean>>('isHovered')
const editable = inject<boolean>('editable')

const mainClasses = computed(() => ({
  'ui-grid__menu--invisible': editable,
  'ui-grid__menu--hovered': isHovered?.value,
}))

const handleClickMenu = (event: MouseEvent) => {
  event.stopImmediatePropagation()
  emit('click')
}
</script>

<script lang="ts">
export default {
  name: 'GridRowCellMenu',
}
</script>

<style scoped lang="postcss">
.ui-grid__menu {
  @apply w-6 lg:w-8 h-8;
  @apply absolute right-0.5 -bottom-1.5;
  @apply flex items-center justify-center;
  @apply text-gray-500 hover:text-indigo-700;
  @apply dark:text-gray-400 dark:hover:text-indigo-300;
  @apply !bg-opacity-80 hover:bg-indigo-50 dark:hover:bg-indigo-950;

  &-icon {
    @apply h-5 w-5;
  }
}

.ui-grid:not(.ui-grid--collapsed) {
  .ui-grid__menu {
    @apply right-auto left-0 top-1/2 -translate-y-1/2;

    &--invisible {
      @apply invisible;
    }
    @apply cursor-pointer;

    &--hovered {
      @apply visible;
    }
  }
}
</style>
