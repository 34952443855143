import {
  TransactionRule,
  TransactionRuleActionRuleType,
  TransactionRuleType,
} from '@types'

export const PREFILLED_ID = 'prefilled'

export const ACTION_TYPE_TITLE = {
  [TransactionRuleActionRuleType.ADD_TAG]: 'Add tags',
  [TransactionRuleActionRuleType.SET_CATEGORY]: 'Set Category',
  [TransactionRuleActionRuleType.SET_SUB_CATEGORY]: 'Set Subcategory',
  [TransactionRuleActionRuleType.SET_COUNTERPARTY]: 'Set Counterparty',
  [TransactionRuleActionRuleType.SET_SIGN]: 'Set Sign',
}

export const CONTROL_SIZE = 'small'

export const DEFAULT_CRITERIA = Object.fromEntries(
  Object.values(TransactionRuleType).map(type => [
    type,
    {
      type,
      comparison: undefined,
      value: undefined,
    } as TransactionRule['data']['criteria'][number],
  ]),
)

export const DEFAULT_ACTIONS = Object.fromEntries(
  Object.values(TransactionRuleActionRuleType).map(type => [
    type,
    {
      type,
      value: undefined,
      tag_ids: undefined,
    } as TransactionRule['data']['actions'][number],
  ]),
)
