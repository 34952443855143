<template>
  <label :for="props.for" class="input-field__label" :class="mainClasses">
    <span>
      {{ label }}
      <span
        v-if="isErrorVisible"
        class="input-field__label__error"
        :class="errorClasses"
        v-text="error"
      />
    </span>
    <div v-if="helpDescription" class="input-field__label__tooltip">
      <QuestionMarkCircleIcon v-tooltip="helpDescription" aria-hidden="true" />
    </div>
  </label>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { CommonProps } from '../utils/types'

import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline'

type Props = CommonProps & {
  for?: string
}

const props = defineProps<Props>()

const isErrorVisible = computed(() => !props.float && props.error)

const mainClasses = computed(() => ({
  [`input-field__label--${props.size}`]: props.size,
  'input-field__label--float': props.float,
}))

const errorClasses = computed(() => ({
  'input-field__label__error--silent': props.silentError,
}))
</script>

<script lang="ts">
export default {
  name: 'InputFieldLabel',
}
</script>

<style lang="postcss">
.input-field {
  &__label {
    @apply mb-1;
    @apply flex justify-between;
    @apply text-sm font-medium;
    @apply text-gray-700 dark:text-gray-300;

    &__error {
      @apply mr-auto;
      @apply text-red-600 dark:text-red-400;
      @apply text-xs;

      &--silent {
        @apply text-gray-400 dark:text-gray-500;
      }
    }

    &__tooltip {
      @apply invisible group-hover:visible;
      @apply mr-1;

      svg {
        @apply h-4 w-4;
        @apply text-indigo-400 dark:text-indigo-300;
      }
    }
  }
}
</style>
