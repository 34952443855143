import { unparse } from 'papaparse'

import { getCurrentDate } from '@/helpers/dates'

import { GridColumn, GridItem } from '@types'

import { CSV_SEPARATOR, EMPTY_VALUE_PLACEHOLDER } from '@/const/common'

import { UIGrid } from '@ui'

const downloadFile = (csv: string, fileName: string) => {
  const blob = new Blob([csv], { type: 'text/csv;charset=UTF-8' })
  const url = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute(
    'download',
    `${fileName}${getCurrentDate({ withTime: true })}.csv`,
  )
  document.body.appendChild(link)
  link.click()
  link.remove()
}

const parseTagsCell = (cell: HTMLDivElement) => {
  const isTagCol = !!cell.querySelector('.ui-tags')
  if (!isTagCol) return null
  const tags = []
  const tagsNodes = cell.querySelectorAll('.ui-tag')
  for (let j = 0; j < tagsNodes.length; j++) {
    tags.push(tagsNodes[j].textContent?.trim())
  }
  if (tags.length) {
    return tags.join(',')
  }
  return undefined
}

export const useGridCheckedExport = (grid: typeof UIGrid, fileName: string) => {
  const target = (grid as any)?.getContainer()
  if (!target) return

  const computerStyle = window.getComputedStyle(target)
  const shift =
    target.querySelectorAll('.ui-grid__header-value--checkbox').length +
    target.querySelectorAll('.ui-grid__header--state').length +
    target.querySelectorAll('.ui-grid__header--edit').length
  const count =
    computerStyle.getPropertyValue('grid-template-columns').split(' ').length -
    shift

  const cells = target.querySelectorAll(
    '[data-cell][data-checked]:not(.ui-grid__header--state):not(.ui-grid__cell--state)',
  ) as HTMLDivElement[]
  if (!cells.length) return

  const fields = Array.from(cells)
    .slice(0, count)
    .map(cell => cell.textContent || '')

  const items = Array.from(cells).slice(count)
  const data: string[][] = []

  let row = 0
  for (let i = 0; i < items.length; i++) {
    if (!data[row]) data[row] = []
    let value = parseTagsCell(items[i])
    if (value === null) {
      value = items[i].textContent
    }
    if (value === EMPTY_VALUE_PLACEHOLDER) value = ''
    data[row].push(value || '')
    if ((i + 1) % count === 0) row++
  }

  const csv = unparse(
    {
      fields,
      data,
    },
    {
      delimiter: CSV_SEPARATOR,
    },
  )
  downloadFile(csv, fileName)
}

export const useHandledGridExport = (
  columns: GridColumn[],
  items: GridItem[],
  fileName: string,
) => {
  const fields = columns.map(column => column.name)
  const data: string[][] = []
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    const row = []
    for (let j = 0; j < columns.length; j++) {
      const column = columns[j]
      const value = item[column.name]
      row.push(column.formatter ? column.formatter(value, item) : value)
    }
    data.push(row)
  }
  const csv = unparse(
    {
      fields,
      data,
    },
    {
      delimiter: CSV_SEPARATOR,
    },
  )
  downloadFile(csv, fileName)
}
