export const NODATA_TEXT = 'Get started by creating a new transaction'
export const SEARCH_TEXT = 'Search transactions...'

export const TRANSACTIONS_FILTER_KEY = ['transactions', 'list', 'filter']
export const TRANSACTIONS_SEARCH_KEY = ['transactions', 'list', 'search']
export const TRANSACTIONS_SORT_KEY = ['transactions', 'list', 'sort']

export const IMPORT_DIALOG_TITLE = 'Import transactions'
export const IMPORT_HEADERS_EXAMPLE =
  'type,date,account1,amount1,asset1,account2,amount2,asset2,account3,account4,description'
