import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import { AnalyticsPosition } from './utils/types'

import useAnalyticsStore from '../index'

export default defineStore('analytics-position', () => {
  // INIT
  const analyticsStore = useAnalyticsStore()

  const list = ref<AnalyticsPosition[]>([])

  const loading = ref(false)
  const deprecated = ref<boolean>()

  // GETTERS

  const isInit = computed(() => deprecated.value !== undefined)

  const getList = computed(() => {
    if (analyticsStore.getError) return []
    return list.value
  })

  // SETTERS

  const setList = (data: { position_view: AnalyticsPosition[] }) => {
    list.value = data.position_view
    deprecated.value = false
  }

  // ACTIONS

  const clear = () => {
    list.value = []
    deprecated.value = undefined
  }

  return {
    isLoading: loading,
    isDeprecated: deprecated,

    isInit,
    getList,

    setList,

    fetch,

    clear,
  }
})
