import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import { AnalyticsPerformance } from './utils/types'

import useAnalyticsStore from '../index'

export default defineStore('analytics-performance', () => {
  // INIT
  const analyticsStore = useAnalyticsStore()

  const list = ref<Record<string, AnalyticsPerformance[]>>()

  const loading = ref(false)
  const deprecated = ref<boolean>()

  // GETTERS

  const isInit = computed(() => deprecated.value !== undefined)

  const getList = computed(() => {
    if (analyticsStore.getError) return {}
    return list.value
  })

  const getDatesRange = computed(() => {
    return list.value && Object.keys(list.value)
  })

  // SETTERS

  const setList = (data: {
    performance_view: Record<string, AnalyticsPerformance[]>
  }) => {
    list.value = data.performance_view
    deprecated.value = false
  }

  // ACTIONS

  const clear = () => {
    list.value = undefined
    loading.value = false
    deprecated.value = undefined
  }

  return {
    isLoading: loading,
    isDeprecated: deprecated,

    isInit,
    getList,
    getDatesRange,

    setList,

    fetch,

    clear,
  }
})
