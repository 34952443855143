import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import { PivotFetchData } from '@types'

import { prepareResponseError } from '../utils/helpers'

import { useRepositoriesStore } from '../repositories'

import api from '@/store/api'

export const useLinkedDataTransactionsPivotStore = defineStore(
  'linked-data-transaction-pivot',
  () => {
    // INIT
    const repositoriesStore = useRepositoriesStore()

    const list = ref<Record<string, any>[]>()

    const initFlag = ref(false)

    const loading = ref(true)

    let abortController = new AbortController()

    const repositoryId = computed(() => repositoriesStore.currentRepositoryId)

    // GETTERS
    const getList = computed(() => list.value)

    // SETTERS

    const setList = (data: any) => {
      list.value = data
    }

    // ACTIONS

    const fetch = async (data: PivotFetchData): Promise<void> => {
      loading.value = true
      try {
        const result = await api.get(`linked_data/transactions_pivot`, {
          params: {
            repository_id: repositoryId.value,
            rows: JSON.stringify(data.rows),
            columns: JSON.stringify(data.cols),
            values: JSON.stringify(data.values),
            filter: JSON.stringify(data.filter),
          },
          signal: abortController.signal,
        })
        setList(result.data)
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        initFlag.value = true
        loading.value = false
      }
    }

    const cancel = () => {
      abortController.abort()
      abortController = new AbortController()
    }

    const clear = () => {
      initFlag.value = false

      loading.value = true

      setList([])
    }

    return {
      initFlag,
      loading,

      fetch,

      getList,

      cancel,
      clear,
    }
  },
)
