<template>
  <UIButton ref="buttonRef" v-bind="{ label }" class="min-w-28" />
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { UIButton } from '@ui'

type Props = {
  label?: string
}

const { label = 'Save' } = defineProps<Props>()

const buttonRef = ref<typeof UIButton | null>(null)

defineExpose({
  focus() {
    buttonRef.value?.focus()
  },
})
</script>

<script lang="ts">
export default {
  name: 'UIButtonSave',
}
</script>
