<template>
  <div class="file-field">
    <UILabeledField
      :model-value="fileName"
      v-bind="$attrs"
      :leading="{ icon: PaperClipIcon }"
      readonly
      clearable
      @update:model-value="handleUdpate"
    />
    <input
      ref="file"
      type="file"
      :data-refid="dataRefid"
      :accept="accept"
      class="file-field__input"
      @change="handleUploadFile"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import { PaperClipIcon } from '@heroicons/vue/24/outline'
import { UILabeledField } from '..'

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget
}

type Props = {
  accept?: string
  dataRefid?: string
}

type Emits = {
  'update:error': []
  'update:loading': [data: boolean]
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<FormData>()
const fileName = defineModel<string>('fileName')

const file = ref<HTMLInputElement | null>(null)

const handleUploadFile = (e: Event) => {
  const event = e as HTMLInputEvent
  if (!event || !event.target.files || !event.target.files.length) return
  emit('update:loading', true)
  const file = event.target.files[0]
  const formData = new FormData()
  formData.append('file', file)
  formData.append('filename', file.name)
  fileName.value = file.name
  modelValue.value = formData
  emit('update:loading', false)
}

const handleUdpate = () => {
  if (!file.value) return
  file.value.value = ''
  fileName.value = undefined
  modelValue.value = undefined
}
</script>

<script lang="ts">
export default {
  name: 'UIFileField',
  inheritAttrs: false,
}
</script>

<style lang="postcss">
.file-field {
  @apply relative;

  &__input {
    @apply absolute inset-0 z-0;
    @apply opacity-0;
  }

  .field__clear {
    @apply !z-10;
  }
}
</style>
