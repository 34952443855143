<template>
  <div class="ui-tags">
    <TagItem
      v-for="tag in tagsList"
      :key="tag.id"
      v-bind="{
        readonly,
        hideMenu,
        list,
        tag,
      }"
      @item:click="handleItemClick"
      @item:remove="handleRemove"
      @item:rename="handleItemRename"
    />
    <div
      v-if="!readonly"
      class="ui-tags__plus"
      :class="tagsPlusClasses"
      @click="emit('plus:click')"
    >
      <div v-if="modelValue.length === 0" class="ui-tags__plus-text">
        Add tag
      </div>
      <XMarkIcon v-if="opened" />
      <PlusIcon v-else />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { orderBy } from 'lodash'

import TagItem from './TagItem.vue'

import { CommonTag } from '@types'

import { PlusIcon } from '@heroicons/vue/24/outline'
import { XMarkIcon } from '@heroicons/vue/20/solid'

type Props = {
  opened?: boolean
  hideMenu?: boolean
  list?: CommonTag[]

  readonly?: boolean
}

type Emits = {
  'item:click': [data: string]
  'item:rename': [id: string, name: string]
  'plus:click': []
}

const { list } = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<CommonTag[]>({ required: true })

const tagsList = computed(() =>
  orderBy(modelValue.value, ['is_system', 'name'], ['desc', 'asc']),
)

const tagsPlusClasses = computed(() => {
  return {
    'tags__plus--active': modelValue.value.length === 0,
  }
})

const handleItemClick = (name: string) => {
  emit('item:click', name)
}

const handleItemRename = (id: string, name: string) => {
  emit('item:rename', id, name)
}

const handleRemove = (id?: string) => {
  modelValue.value = modelValue.value.filter(item => item.id !== id)
}
</script>

<script lang="ts">
export default {
  name: 'UITagsList',
}
</script>

<style lang="postcss">
.ui-tags {
  @apply flex items-center flex-wrap;
  @apply gap-1;

  &__plus {
    @apply h-[1.25rem];
    @apply inline-flex items-center justify-center shrink-0;
    @apply px-1 gap-1;
    @apply rounded-full;
    @apply bg-gray-200 text-indigo-300 hover:bg-gray-300 hover:text-indigo-400;
    @apply dark:bg-gray-700 dark:hover:bg-gray-600 dark:hover:text-indigo-200;
    @apply cursor-pointer;

    &--active {
      @apply bg-indigo-100 text-indigo-700;
      @apply dark:bg-indigo-600 dark:text-indigo-200;
      @apply hover:bg-indigo-200 hover:text-indigo-800;
      @apply dark:hover:bg-indigo-500 dark:hover:text-indigo-100;
    }

    &-text {
      @apply pl-1.5;
      @apply text-xs;
      @apply text-indigo-400;
    }

    svg {
      @apply w-3 h-3;
    }
  }
}
</style>
