<template>
  <nav ref="mainRef" aria-label="HeaderMenu" class="header-menu">
    <div ref="containerRef" class="header-menu__container">
      <HeaderMenuItem
        v-for="(item, index) of items"
        :key="index"
        v-bind="{ item }"
        ref="menuItemRef"
        :invisible="index >= visibleCount"
      />
    </div>
    <UIDropdown
      v-if="isDropdownVisible"
      :model-value="route.name?.toString()"
      :items="dropdownItems"
      placement="bottom-end"
      id-key="routeName"
      value-key="name"
      class="header-menu__more"
      :style="moreStyles"
      @click:item="handleClickItem"
    >
      <EllipsisHorizontalIcon aria-hidden="true" class="header-menu__dots" />
    </UIDropdown>
  </nav>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useResizeObserver } from '@vueuse/core'

import { NavigationItem } from '@types'

import { useNavigationStore } from '@/store/navigation'

import { EllipsisHorizontalIcon } from '@heroicons/vue/20/solid'
import HeaderMenuItem from './HeaderMenuItem.vue'
import { UIDropdown } from '@ui'

const route = useRoute()
const router = useRouter()

const navigationStore = useNavigationStore()

const mainRef = ref<HTMLDivElement>()
const containerRef = ref<HTMLDivElement>()
const menuItemRef = ref<(typeof HeaderMenuItem)[]>()

const visibleCount = ref(0)
const dotsLeft = ref(0)

const items = computed(() => navigationStore.getHeaderNavigation)

const dropdownItems = computed(() => items.value.slice(visibleCount.value))

const isDropdownVisible = computed(
  () => visibleCount.value < items.value.length,
)

const moreStyles = computed(() => ({ left: `${dotsLeft.value}px` }))

const handleClickItem = (item: NavigationItem) => {
  router.push({ name: item.routeName })
}

useResizeObserver(containerRef, entries => {
  const container = entries[0]
  if (!container) return
  const mainLeft = mainRef.value?.getBoundingClientRect().left || 0
  const containerWidth =
    container.target.getBoundingClientRect().width + mainLeft
  visibleCount.value = 0
  dotsLeft.value = 0
  menuItemRef.value?.forEach(item => {
    const itemRect = item.getEl().getBoundingClientRect()
    if (itemRect.left + itemRect.width <= containerWidth) {
      visibleCount.value++
      dotsLeft.value += itemRect.width
    }
  })
})
</script>

<script lang="ts">
export default {
  name: 'HeaderMenu',
}
</script>

<style scoped lang="postcss">
.header-menu {
  @apply flex h-full;
  @apply relative;
  @apply md:ml-2;
  @apply pr-8;
  @apply overflow-hidden;

  &__container {
    @apply flex;
    @apply overflow-hidden;
  }

  &__more {
    @apply w-8 h-8;
    @apply absolute top-1/2;
    @apply flex items-center justify-center;
    @apply shrink-0;
    @apply -translate-y-1/2;
    @apply cursor-pointer;
  }

  &__dots {
    @apply w-5 h-5;
    @apply text-gray-400 hover:text-gray-600;
  }
}
</style>
