<template>
  <div class="asset-tab__form">
    <div class="asset-tab__form__input">
      <UIDatePickerField
        v-model="formData.date"
        v-bind="{
          disabled,
          placeholder: 'Date',
        }"
      />
    </div>
    <div class="asset-tab__form__input">
      <UILabeledField
        v-model="formData.close"
        data-refid="assetPricesFormClose"
        v-bind="{
          disabled,
          focusOnLoad: true,
          placeholder: 'Close',
          type: 'number',
        }"
      />
    </div>
    <UIButton
      data-refid="assetPricesFormAdd"
      v-bind="{
        disabled: isButtonDisabled,
        icon: PlusIcon,
        label: '',
      }"
      @click="handleSubmit"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'

import { AssetPriceForm } from './utils/types'

import { getCurrentDate } from '@/helpers/dates'

import { UIButton, UILabeledField, UIDatePickerField } from '@ui'
import { PlusIcon } from '@heroicons/vue/24/outline'

type Props = {
  disabled?: boolean
}

type Emits = {
  submit: [data: AssetPriceForm]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const formData = reactive<AssetPriceForm>({
  date: getCurrentDate({}),
  close: null,
})

const isButtonDisabled = computed(
  () => props.disabled || !formData.date || !formData.close,
)

const handleSubmit = () => {
  emit('submit', formData)
}
</script>

<script lang="ts">
export default {
  name: 'AssetTabPricesForm',
}
</script>

<style scoped>
@import url('./styles/asset.tabs.css');
</style>
