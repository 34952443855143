import { set } from 'lodash'

import { I18nLocales } from './types'

export const handleLoadedLocales = async (
  data: Record<string, () => Promise<any>>,
) => {
  const locales: I18nLocales = {}
  const pathes = Object.keys(data)

  await Promise.all(
    pathes.map(path => {
      const parts = path.split('/locales/')
      if (parts.length < 2) return
      const prefix = parts[0].split('/').filter(part => part !== '..')
      const lang = parts[1].replace('.json', '')
      return new Promise(resolve => {
        data[path]().then(result => {
          const json = result.default
          Object.keys(json).forEach(key => {
            set(locales, [lang, ...prefix, key], json[key])
          })
          resolve(true)
        })
      })
    }),
  )

  return locales
}
