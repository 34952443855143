<template>
  <UIButton v-bind="{ icon, size }" label="" variant="light-red" />
</template>

<script setup lang="ts">
import { inject } from 'vue'

import { ButtonSizes } from '@types'

import { UIButton } from '@ui'
import { TrashIcon as icon } from '@heroicons/vue/24/outline'

const size = inject<ButtonSizes>('size')
</script>

<script lang="ts">
export default {
  name: 'FilterRemoveButton',
}
</script>

<style scoped lang="postcss"></style>
