import { h } from 'vue'
import { Router } from 'vue-router'

import { AssetClass } from '@/entities/assets'
import { ErrorNodes } from '@types'
import { AssetCategoryAccount } from '@/entities/assets/utils/enums'
import { EntityState } from '@/entities/utils/enums'
import { ASSET_CURRENCY_TYPE } from '@/entities/assets/utils/const'
import { ROUTE_NAME } from '@/const'

import { createLink } from '@/store/utils/helpers'

const getRouterName = (item: AssetClass) => {
  if (item.isCurrency) {
    return ROUTE_NAME.CURRENCIES_ITEM
  } else if (item.isIncomeAccount) {
    return ROUTE_NAME.INCOME_ACCOUNTS_ITEM
  } else if (item.isExpenseAccount) {
    return ROUTE_NAME.EXPENSE_ACCOUNTS_ITEM
  } else {
    return ROUTE_NAME.ASSETS_ITEM
  }
}

export const checkForErrors = (
  list: Map<string, AssetClass>,
  singular: string,
  plural: string,
  router: Router,
  checkTicker = true,
) => {
  const result: ErrorNodes[] = []
  const listValues = Array.from(list.values())
  const excludedIds: string[] = listValues
    .filter(instance => instance.state === EntityState.DELETED)
    .map(instance => instance.id)
  listValues.forEach(item => {
    excludedIds.push(item.id)
    if (item.state === EntityState.DELETED) return
    const itemType = item.get().type
    const itemName = item.get().name
    const itemTicker = item.get().ticker
    const errorEntries = Object.entries(item.errors)
    const routerName = getRouterName(item)
    if (errorEntries.length) {
      const errors = errorEntries.map(
        item => `${item[0]?.toUpperCase()}: ${String(item[1]).toLowerCase()}`,
      )
      const message = h('div', [
        `${singular} `,
        createLink(item.id, itemName, router, routerName),
        ` has validation error${errors.length > 1 ? 's' : ''}: `,
        errors.join(', '),
      ])
      result.push(message)
    }
    const duplicate = checkTicker
      ? listValues.filter(element => {
          if (excludedIds.includes(element.id)) return false
          return (
            itemType !== '' &&
            element.get().type === itemType &&
            itemTicker !== '' &&
            element.get().ticker === itemTicker
          )
        })
      : []
    if (duplicate.length) {
      const links = duplicate.map((item, index) => {
        excludedIds.push(item.id)
        const routerName = getRouterName(item)
        return createLink(item.id, `[${index + 2}] `, router, routerName)
      })
      const message = h('div', [
        `${plural} `,
        createLink(item.id, '[1] ', router, routerName),
        ...links,
        `have the same pair "${itemType}" and ticker "${itemTicker}"`,
      ])
      result.push(message)
    }
  })
  if (result.length) {
    return result
  } else {
    return undefined
  }
}

export const isItCommotType = (type: string) => {
  return (
    !Object.values(AssetCategoryAccount).includes(
      type as AssetCategoryAccount,
    ) && type !== ASSET_CURRENCY_TYPE
  )
}
