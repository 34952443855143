<template>
  <UIInputDropdown
    ref="fieldRef"
    v-model="modelValue"
    v-bind="{
      ...$attrs,
      data,
      dataRefid,
      disabled,
      error,
      label,
      silentError,
      size,
    }"
    id-key="key"
    placeholder="Find an option"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue'

import { TransactionClass } from '..'
import { DataFieldSizes } from '@types'

import { TRANSACTION_FIELD } from '../utils/const'

import { prepareList } from '@/helpers/common'
import { compareEntries } from './utils/helpers'

import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useRepositoriesStore } from '@/store/repositories'
import { useTransactionsSettingsStore } from '@/store/transactions/settings'

import { UIInputDropdown } from '@ui'

type Props = {
  dataRefid?: string
  instance: TransactionClass
  label?: string
  size?: DataFieldSizes
  disabled?: boolean
  restrictions?: string[]
}

const props = defineProps<Props>()

defineExpose({
  focus() {
    fieldRef.value?.focus()
  },
})

const fieldRef = ref<typeof UIInputDropdown>()

const assetsBunchStore = useAssetsBunchStore()
const repositoriesStore = useRepositoriesStore()
const transactionsSettingsStore = useTransactionsSettingsStore()

const modelValue = props.instance.field<string>(TRANSACTION_FIELD.TYPE)

const isSavedTransaction = inject('isSavedTransaction', ref(false))

const error = computed(() => props.instance.errors.type)
const silentError = computed(() => !isSavedTransaction.value)

const data = computed(() => {
  let list = transactionsSettingsStore.getTypes
  if (props.restrictions) {
    list = list.filter(item => props.restrictions?.includes(item))
  }
  return prepareList(list)
})

const currencyID = computed(() => {
  const asset =
    assetsBunchStore.getElementByName(
      repositoriesStore.getCurrentRepositoryCurrency,
    ) ||
    assetsBunchStore.getElementByTicker(
      repositoriesStore.getCurrentRepositoryCurrency,
    )
  return asset?.id
})

watch(modelValue, (value, oldValue) => {
  if (value === oldValue) return
  compareEntries(props.instance, assetsBunchStore, currencyID.value)
})
</script>

<script lang="ts">
export default {
  name: 'TransactionFormType',
}
</script>
