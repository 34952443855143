<template>
  <UIButton
    v-bind="{ disabled, label, ...$attrs }"
    :class="mainClasses"
    @click="handleClick"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { UIButton } from '@ui'

type Props = {
  page: number
  pageNo: number
}

type Emits = {
  click: [data: number]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const label = computed(() => (props.page === 0 ? '...' : props.page.toString()))
const disabled = computed(() => props.page === 0)

const mainClasses = computed(() => ({
  'ui-pagination__paging__button--current': props.page === props.pageNo + 1,
}))

const handleClick = () => {
  if (props.page === props.pageNo + 1 || props.page === 0) return
  emit('click', props.page - 1)
}
</script>

<script lang="ts">
export default {
  name: 'PaginationButton',
  inheritAttrs: false,
}
</script>
