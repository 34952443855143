<template>
  <UIModal
    v-bind="{ isOpen, isMinimized }"
    :type="ModalType.SLIDEPANEL"
    title="JSON data"
    @hide="handleHide"
    @minimize="handleMinimize"
  >
    <UIJsonTree v-bind="{ json }" size="xxsmall">JSON data</UIJsonTree>
  </UIModal>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { ModalType } from '@types'

import { UIJsonTree, UIModal } from '@ui'

const exposeObj = {
  setData(value: any) {
    json.value = value
    return exposeObj
  },
  toggle(flag: boolean) {
    isOpen.value = flag
    return exposeObj
  },
}

defineExpose(exposeObj)

const json = ref()

const isOpen = ref(false)
const isMinimized = ref(false)

const handleHide = () => {
  isOpen.value = false
}

const handleMinimize = (data: boolean) => {
  isMinimized.value = data
}
</script>

<script lang="ts">
export default {
  name: 'TransactionsListItemJson',
}
</script>

<style lang="postcss"></style>
