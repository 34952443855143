<template>
  <UILabeledField
    v-model="modelValue"
    :data-refid="dataRefid"
    v-bind="{
      disabled,
      error,
      focusOnLoad,
      label,
      silentError,
      size,
    }"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'

import { AssetClass } from '..'
import { DataFieldSizes } from '@types'

import { ASSET_FIELD } from '../utils/const'

import { UILabeledField } from '@ui'

type Props = {
  instance: AssetClass
  disabled?: boolean
  label?: string
  focusOnLoad?: boolean
  dataRefid?: string
  size?: DataFieldSizes
}

const props = defineProps<Props>()

const modelValue = props.instance.field<string>(ASSET_FIELD.DESCRIPTION)

const isSavedAsset = inject('isSavedAsset', ref(false))

const error = computed(() => props.instance.errors.description)
const silentError = computed(() => !isSavedAsset.value)
</script>

<script lang="ts">
export default {
  name: 'AssetFormDescription',
}
</script>
