export const LINKED_TRANSACTION_FIELDS = {
  CONNECTOR_NAME: {
    label: 'Connector',
    field: 'connector_name',
  },
  ACCOUNT_NAME: {
    label: 'Account name',
    field: 'account_name',
  },
  ACCOUNT_NUMBER: {
    label: 'Account number',
    field: 'account_number',
  },
  ACCOUNT_TYPE: {
    label: 'Account type',
    field: 'account_type',
  },
  TAGS: {
    label: 'Tags',
    field: 'tags',
  },
  YEAR: {
    label: 'Year',
    field: 'year',
  },
  QUARTER: {
    label: 'Quarter',
    field: 'quarter',
  },
  MONTH: {
    label: 'Month',
    field: 'month',
  },
  DATE: {
    label: 'Date',
    field: 'date',
  },
  TYPE: {
    label: 'Transaction type',
    field: 'type',
  },
  METHOD: {
    label: 'Method',
    field: 'access_method',
  },
  CATEGORY: {
    label: 'Category',
    field: 'category',
  },
  SUBCATEGORY: {
    label: 'Subcategory',
    field: 'subcategory',
  },
  COUNTERPARTY: {
    label: 'Counterparty',
    field: 'counterparty',
  },
  DESCRIPTION: {
    label: 'Description',
    field: 'description',
  },
  AMOUNT: {
    label: 'Amount',
    field: 'amount',
  },
  STATUS: {
    label: 'Status',
    field: 'status',
  },
  ACCESS_METHOD: {
    label: 'Access method',
    field: 'access_method',
  },
}

export const LINKED_TRANSACTIONS_SEARCH_KEY = [
  'linked_transactions',
  'list',
  'search',
]
export const LINKED_TRANSACTIONS_FILTER_KEY = [
  'linked_transactions',
  'list',
  'filter',
]
export const LINKED_TRANSACTIONS_SORT_KEY = [
  'linked_transactions',
  'list',
  'sort',
]
