export enum AssetTab {
  SECURITY_MASTER = 'SecurityMaster',
  PRICES = 'Prices',
  TAGS = 'Tags',
  DOCUMENTS = 'Documents',
  CONTACTS = 'Contacts',
}

export enum AssetPriceSource {
  ALL = 'all',
  MANUAL = 'manual',
  CONNECTOR = 'connector',
  SECURITY_MASTER = 'security master',
  SECURITY_MASTER_ADJ = 'security master adjusted',
}
