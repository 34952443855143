export enum AnalyticsIncomeSources {
  MARKET_GAIN_AND_LOSS = 'Market Gain & Loss',
  INCOME_AND_EXPENSE = 'Income & Expense',
  EXCLUDE_TRANSFER = 'Exclude Transfer',
}

export enum AnalyticIncomeGroupFields {
  CATEGORY_ACCOUNT = 'category_account_id',
  CATEGORY = 'Category',
  SUBCATEGORY = 'Subcategory',
}
