<template>
  <button
    class="h-full px-4 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
    @click="modelValue = !modelValue"
  >
    <span class="sr-only">Open menu</span>
    <HeaderIcon :icon="Bars3Icon" class="!text-gray-500" />
  </button>
</template>

<script setup lang="ts">
import HeaderIcon from './HeaderIcon.vue'

import { Bars3Icon } from '@heroicons/vue/24/outline'

const modelValue = defineModel<boolean>({ required: true })
</script>

<script lang="ts">
export default {
  name: 'HeaderBurger',
}
</script>
