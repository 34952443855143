<template>
  <AppHeader v-model:mobileMenu="showMobileMenu" v-bind="{ userEmail }">
    <div class="header__panel" :class="panelClasses">
      <AppLogo :size="LogoSize.SMALL" substrate />
      <div class="header__panel-container">
        <AppSelectRepository
          v-if="currentRepository?.id"
          v-bind="{ currentRepository, repositoriesList }"
          @import="handleImportRepository"
          @create="handleCreateRepository"
          @export="handleExportRepository"
          @edit="handleEditRepository"
          @delete="handleDeleteRepository"
        />
        <HeaderSidebarToggler
          v-if="isTogglerVisible"
          class="header-sidebar-toggler--desktop"
        />
      </div>
    </div>
    <HeaderMenu v-if="!isMobile" />
    <template #actions>
      <HeaderNotifications />
      <HeaderSearch />
      <HeaderSidebarToggler
        v-if="isTogglerVisible"
        reverse
        class="header-sidebar-toggler--mobile"
      />
      <HeaderUser v-if="isUserIconVisible" />
    </template>
  </AppHeader>
  <HeaderSidebarMenu
    v-model:show="showMobileMenu"
    v-bind="{ breakpoint }"
    @click:signout="handleSignOut"
  />
</template>

<script setup lang="ts">
import { computed, ComputedRef, inject, markRaw, ref } from 'vue'

import { LogoSize, Repository } from '@types'

import { NOTIFICATION_DELAY } from '@/entities/utils/const'

import { downloadAsFile, handleCatchedError } from '@/helpers/common'

import { useResponsive } from '@/plugins/responsiveUI'
import { useNotifications } from '@/plugins/notification'

import { useModalsStore } from '@/store/modals'
import { useRepositoriesStore } from '@/store/repositories'
import { useSidebarStore } from '@/store/sidebar'
import { useUserStore } from '@/store/user'

import { AppHeader, AppLogo, AppSelectRepository } from '@app'

import HeaderMenu from './HeaderMenu.vue'
import HeaderNotifications from './HeaderNotifications.vue'
import HeaderSearch from './HeaderSearch.vue'
import HeaderSidebarMenu from './HeaderSidebarMenu.vue'
import HeaderSidebarToggler from './HeaderSidebarToggler.vue'
import HeaderUser from './HeaderUser.vue'

import RepositorySlideover from '@/views/Repositories/RepositorySlideover.vue'

type Emits = {
  'repository:import': []
  'repository:create': []
}
const emit = defineEmits<Emits>()

const modalsStore = useModalsStore()
const repositoriesStore = useRepositoriesStore()
const sidebarStore = useSidebarStore()
const userStore = useUserStore()

const { progress, update } = useNotifications()
const { isMobile, isLargeDesktop, isLargestDesktop } = useResponsive()

const userEmail = inject<ComputedRef<string>>('userEmail')

const showMobileMenu = ref(false)

const sidebarOpen = computed(() => sidebarStore.open)

const panelClasses = computed(() => ({
  'header__panel--opened': sidebarOpen.value,
}))

const isShortView = computed(() => !repositoriesStore.currentRepositoryId)

const breakpoint = computed(
  () => (!isLargestDesktop.value && sidebarOpen.value) || !isLargeDesktop.value,
)

const isTogglerVisible = computed(() => !isShortView.value)

const isUserIconVisible = computed(() => userEmail?.value && !isMobile.value)

const currentRepository = computed(() => repositoriesStore.getCurrentRepository)
const repositoriesList = computed(() => repositoriesStore.list)

const handleSignOut = async () => {
  await userStore.signOut()
}

const handleImportRepository = () => {
  emit('repository:import')
}

const handleCreateRepository = () => {
  emit('repository:create')
}

const handleExportRepository = async (
  data: Repository,
  callback?: () => void,
) => {
  const { id, name } = data
  const progressId = await progress({
    message: `${name} repository export`,
    description:
      'Please wait a moment, we are preparing a repository for you. With large repositories it can take several minutes',
  })
  try {
    const data = await repositoriesStore.exportRepository(id)
    if (data) {
      await update(
        progressId,
        {
          type: 'success',
          message: `${name} repository export successfully completed`,
          description: undefined,
        },
        NOTIFICATION_DELAY,
      )
      downloadAsFile(data, name)
    } else {
      throw Error()
    }
  } catch {
    await update(progressId, {
      type: 'error',
      message: `${name} repository export failed`,
    })
  } finally {
    callback?.()
  }
}

const handleEditRepository = async (id: string) => {
  const repository = repositoriesStore.list.find(item => item.id === id)
  const modalInstance = modalsStore.init(id, markRaw(RepositorySlideover))

  modalInstance?.open(modalsStore.getZIndex(), {
    repository,
    wrapped: true,
  })
}

const handleDeleteRepository = async (id: string) => {
  try {
    await repositoriesStore.deleteRepository(id)
    await repositoriesStore.fetchRepositories()
  } catch (e) {
    handleCatchedError(e as string, { id })
  }
}
</script>

<script lang="ts">
export default {
  name: 'MainHeader',
}
</script>

<style scoped lang="postcss">
.header {
  &__title {
    @apply text-sm font-medium;
    @apply text-gray-700 dark:text-gray-300;
  }

  &__panel {
    @apply md:w-auto lg:w-auto;
    @apply flex justify-between items-center shrink-0;
    @apply sm:px-4 gap-x-4;

    &--opened {
      @apply md:w-80 lg:w-96;
    }
  }

  &__panel-container {
    @apply flex items-center justify-between;
    @apply flex-auto flex-row-reverse sm:flex-row;
    @apply gap-x-2;
  }

  &__container {
    @apply flex items-center;
  }
}
</style>
