<template>
  <button
    v-if="isLauncherVisible"
    class="intercom-launcher"
    @click="handleClick"
  >
    <component :is="icon" aria-hidden="true" class="intercom-launcher__icon" />
    <span v-if="isCountVisible" class="intercom-launcher__count">
      {{ unread }}
    </span>
  </button>
</template>

<script setup type="ts">
import { computed } from 'vue'

import { useIntercom } from '@/plugins/intercom'

import { QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/vue/24/outline'

defineOptions({
  name: 'IntercomLauncher',
})

const { hide, inited, show, unread, visible } = useIntercom()

const isLauncherVisible = computed(() => inited.value)
const isCountVisible = computed(() => unread.value > 0)

const icon = computed(() => visible.value ? XMarkIcon : QuestionMarkCircleIcon)

const handleClick = () => {
  visible.value ? hide() : show()
}
</script>

<style scoped lang="postcss">
.intercom-launcher {
  @apply fixed bottom-6 right-6;
  @apply flex;
  @apply p-2;
  @apply border border-transparent rounded-full shadow-sm;
  @apply text-white bg-indigo-600 hover:bg-indigo-700;
  @apply focus:outline-none focus:ring-2 focus:ring-offset-2;
  @apply focus:ring-indigo-500 dark:focus:ring-indigo-400;
  @apply z-30;

  &__icon {
    @apply w-6 h-6;
  }

  &__count {
    @apply w-6 h-6;
    @apply absolute -right-2 -top-2;
    @apply flex items-center justify-center;
    @apply p-1;
    @apply rounded-2xl;
    @apply text-xs;
    @apply text-white;
    @apply bg-red-400 dark:bg-red-500;
  }
}
</style>
