import { FilterOptionsResponse } from './utils/types'

import {
  FILTER_OPTION_RESOURCE,
  FILTER_OPTION_ASSETS,
  FILTER_OPTION_CONTACTS,
  FILTER_OPTION_CURRENCIES,
  FILTER_OPTION_DOCUMENTS,
  FILTER_OPTION_EXPENSE_ACCOUNTS,
  FILTER_OPTION_LINKED_DATA_TRANSACTIONS,
  FILTER_OPTION_INCOME_ACCOUNTS,
  FILTER_OPTION_TAGS,
  FILTER_OPTION_TRANSACTIONS,
} from './utils/const'

import { handleCatchedError } from '@/helpers/common'

import { useFilterStore } from '@/store/filter'
import { useRepositoriesStore } from '@/store/repositories'
import { getFilterOptionKey } from '@/store/filter/utils/helpers'

export default () => {
  const filterStore = useFilterStore()
  const repositoriesStore = useRepositoriesStore()

  function fetchOptions(
    resource: typeof FILTER_OPTION_RESOURCE.ASSETS,
    field: (typeof FILTER_OPTION_ASSETS)[keyof typeof FILTER_OPTION_ASSETS],
  ): FilterOptionsResponse
  function fetchOptions(
    resource: typeof FILTER_OPTION_RESOURCE.CONTACTS,
    field: (typeof FILTER_OPTION_CONTACTS)[keyof typeof FILTER_OPTION_CONTACTS],
  ): FilterOptionsResponse
  function fetchOptions(
    resource: typeof FILTER_OPTION_RESOURCE.CURRENCIES,
    field: (typeof FILTER_OPTION_CURRENCIES)[keyof typeof FILTER_OPTION_CURRENCIES],
  ): FilterOptionsResponse
  function fetchOptions(
    resource: typeof FILTER_OPTION_RESOURCE.DOCUMENTS,
    field: (typeof FILTER_OPTION_DOCUMENTS)[keyof typeof FILTER_OPTION_DOCUMENTS],
  ): FilterOptionsResponse
  function fetchOptions(
    resource: typeof FILTER_OPTION_RESOURCE.EXPENSE_ACCOUNTS,
    field: (typeof FILTER_OPTION_EXPENSE_ACCOUNTS)[keyof typeof FILTER_OPTION_EXPENSE_ACCOUNTS],
  ): FilterOptionsResponse
  function fetchOptions(
    resource: typeof FILTER_OPTION_RESOURCE.LINKED_DATA_TRANSACTIONS,
    field: (typeof FILTER_OPTION_LINKED_DATA_TRANSACTIONS)[keyof typeof FILTER_OPTION_LINKED_DATA_TRANSACTIONS],
  ): FilterOptionsResponse
  function fetchOptions(
    resource: typeof FILTER_OPTION_RESOURCE.INCOME_ACCOUNTS,
    field: (typeof FILTER_OPTION_INCOME_ACCOUNTS)[keyof typeof FILTER_OPTION_INCOME_ACCOUNTS],
  ): FilterOptionsResponse
  function fetchOptions(
    resource: typeof FILTER_OPTION_RESOURCE.TAGS,
    field: (typeof FILTER_OPTION_TAGS)[keyof typeof FILTER_OPTION_TAGS],
  ): FilterOptionsResponse
  function fetchOptions(
    resource: typeof FILTER_OPTION_RESOURCE.TRANSACTIONS,
    field: (typeof FILTER_OPTION_TRANSACTIONS)[keyof typeof FILTER_OPTION_TRANSACTIONS],
  ): FilterOptionsResponse
  async function fetchOptions(
    resource: string,
    field: string,
  ): FilterOptionsResponse {
    const key = getFilterOptionKey(resource, field)
    const repository_id = repositoriesStore.getCurrentRepository?.id
    if (!repository_id) return []
    try {
      await filterStore.fetchList({ repository_id, resource, field })
    } catch (e) {
      handleCatchedError(e as string, { repository_id, resource, field })
    }
    return filterStore.getList[key]
  }

  return {
    fetchOptions,
  }
}
