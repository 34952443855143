<template>
  <TransitionRoot as="template" v-bind="{ show }">
    <Dialog
      as="div"
      static
      class="header-sidebar-menu"
      :class="dialogClasses"
      :open="show"
      @close="handleHide"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogOverlay class="header-sidebar-menu__overlay" />
      </TransitionChild>
      <TransitionChild
        as="template"
        enter="transition ease-in-out duration-300 transform"
        enter-from="-translate-x-full"
        enter-to="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leave-from="translate-x-0"
        leave-to="-translate-x-full"
      >
        <div class="header-sidebar-menu__container">
          <TransitionChild
            as="template"
            enter="ease-in-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in-out duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <button
              class="header-sidebar-menu__close-button"
              @click="handleHide"
            >
              <span class="sr-only">Close sidebar</span>
              <XMarkIcon aria-hidden="true" />
            </button>
          </TransitionChild>
          <div class="header-sidebar-menu__logo">
            <AppLogo with-text substrate :size="LogoSize.SMALL" />
            <slot name="header" />
          </div>
          <nav class="header-sidebar-menu__list no-scrollbar">
            <HeaderSidebarMenuItem
              v-for="item in items"
              :key="item.routeName"
              v-bind="{ item }"
              @click="handleHide"
            />
            <template v-if="userEmail">
              <div class="header-sidebar-menu-item">
                <UserCircleIcon class="header-sidebar-menu-item__icon" />
                <span class="header-sidebar-menu-item__span">
                  {{ userEmail }}
                </span>
              </div>
              <div class="header-sidebar-menu-item__children">
                <router-link
                  :to="{ name: ROUTE_NAME.SETTINGS }"
                  active-class=""
                  exact-active-class=""
                  class="header-sidebar-menu-item__child"
                  :class="{
                    'header-sidebar-menu-item__child--active':
                      route.name === ROUTE_NAME.SETTINGS,
                  }"
                  @click="handleHide"
                >
                  Settings
                </router-link>
                <button
                  class="header-sidebar-menu-item__child"
                  @click="handleToggleMode"
                >
                  {{ togglerLabel }}
                </button>
                <button
                  class="header-sidebar-menu-item__child"
                  @click="handleSignOut"
                >
                  Sign out
                </button>
              </div>
            </template>
          </nav>
        </div>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed, ComputedRef, inject } from 'vue'
import { useRoute } from 'vue-router'
import { useDark, useToggle } from '@vueuse/core'

import { LogoSize } from '@types'

import { ROUTE_NAME } from '@/const'

import { useNavigationStore } from '@/store/navigation'

import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { AppLogo } from '@app'

import { UserCircleIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import HeaderSidebarMenuItem from './HeaderSidebarMenuItem.vue'

const route = useRoute()

type Props = {
  breakpoint: boolean
}

type Emits = {
  'click:signout': []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const show = defineModel<boolean>('show', { required: true })

const isDark = useDark()
const toggleDark = useToggle(isDark)

const navigationStore = useNavigationStore()

const userEmail = inject<ComputedRef<string>>('userEmail')

const dialogClasses = computed(() => ({
  'header-sidebar-menu--hideable': !props.breakpoint,
}))

const items = computed(() => navigationStore.getHeaderNavigation)

const togglerLabel = computed(() =>
  isDark.value ? 'Light theme' : 'Dark theme',
)

const handleHide = () => {
  show.value = false
}

const handleToggleMode = () => {
  toggleDark()
}

const handleSignOut = () => {
  emit('click:signout')
}
</script>

<script lang="ts">
export default {
  name: 'HeaderSidebarMenu',
}
</script>

<style lang="postcss">
.header-sidebar-menu {
  @apply fixed;
  @apply inset-0 flex;
  @apply z-40;

  &--hideable {
    @apply md:hidden;
  }

  &__overlay {
    @apply fixed inset-0;
    @apply bg-gray-600 bg-opacity-75;
    @apply dark:bg-gray-900 dark:bg-opacity-75;
    @apply z-20;
  }

  &__container {
    @apply relative flex-1 flex flex-col;
    @apply max-w-xs w-full p-4 pt-3;
    @apply bg-white dark:bg-gray-800;
    @apply z-30;
  }

  &__close-button {
    @apply h-8 w-8 sm:h-10 sm:w-10;
    @apply fixed top-2 right-2;
    @apply flex items-center justify-center;
    @apply translate-x-1;

    svg {
      @apply h-6 w-6;
      @apply text-white dark:text-gray-300;
    }
  }

  &__logo {
    @apply h-14;
    @apply flex items-center justify-between;
  }

  &__list {
    @apply py-2 space-y-1 overflow-y-auto;
  }
}
</style>
