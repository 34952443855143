<template>
  <div v-if="isEditMode" class="ui-tag__edit">
    <input ref="editInputRef" :value="tagName" @keyup="handleKeyup" />

    <CheckIcon
      class="ui-tag__edit-button ui-tag__edit-button--apply"
      @click.stop="handleApply"
    />
    <XMarkIcon
      class="ui-tag__edit-button ui-tag__edit-button--cancel"
      @click.stop="handleCancel"
    />
  </div>
  <div v-else class="ui-tag group" :class="tagClasses" @click="handleItemClick">
    {{ tagName }}
    <div class="ui-tag__actions">
      <UIDropdown
        v-if="isMenuVisible"
        :items="menuItems"
        value-key="label"
        placement="bottom-start"
        @click:item="handleClickItem"
      >
        <button class="ui-tag__menu" aria-hidden="true" @click.stop>
          <EllipsisHorizontalIcon />
        </button>
      </UIDropdown>
      <button
        v-if="!readonly"
        class="ui-tag__remove"
        @click.stop="handleRemove"
      >
        <XMarkIcon />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref } from 'vue'

import { CommonTag, DropdownItem } from '@types'

import { TagMenuItemLabel } from './utils/enums'

import { PencilIcon, TrashIcon } from '@heroicons/vue/24/outline'
import {
  CheckIcon,
  EllipsisHorizontalIcon,
  XMarkIcon,
} from '@heroicons/vue/20/solid'

import { UIDropdown } from '@ui'

type Props = {
  tag: CommonTag
  readonly?: boolean
  hideMenu?: boolean
  list?: CommonTag[]
}

type Emits = {
  'item:click': [data: string]
  'item:rename': [id: string, name: string]
  'item:remove': [data?: string]
}

const { list, tag, ...props } = defineProps<Props>()
const emit = defineEmits<Emits>()

const isEditMode = ref(false)
const editInputRef = ref<HTMLInputElement>()

const isMenuVisible = computed(() => !props.hideMenu && !props.readonly)

const tagName = computed(() => {
  return list?.find(item => item.id === tag.id)?.name || tag.name
})

const tagClasses = computed(() => ({
  'ui-tag--system': tag.is_system,
}))

const menuItems = computed(() => [
  ...(tag.is_system
    ? []
    : [
        {
          label: 'Rename',
          icon: PencilIcon,
        },
      ]),
  {
    label: 'Remove',
    icon: TrashIcon,
  },
])

const handleClickItem = (data: DropdownItem<any>) => {
  switch (data.label) {
    case TagMenuItemLabel.RENAME:
      isEditMode.value = true
      nextTick(() => {
        editInputRef.value?.select()
      })
      break
    case TagMenuItemLabel.REMOVE:
      handleRemove()
      break
  }
}

const handleKeyup = (e: KeyboardEvent) => {
  if (e.key === 'Enter') {
    handleApply()
  } else if (e.key === 'Escape') {
    handleCancel()
  }
}

const handleApply = () => {
  const value = editInputRef.value?.value
  if (!value || !tag.id) {
    return
  }
  if (value === tag.name) {
    isEditMode.value = false
    return
  }

  emit('item:rename', tag.id, value)
  isEditMode.value = false
}

const handleCancel = () => {
  isEditMode.value = false
}

const handleItemClick = () => {
  if (isEditMode.value) return
  emit('item:click', tag.name)
}

const handleRemove = () => {
  emit('item:remove', tag.id)
}
</script>

<script lang="ts">
export default {
  name: 'TagItem',
}
</script>

<style lang="postcss">
.ui-tag {
  @apply min-w-10;
  @apply inline-flex items-center;
  @apply relative overflow-hidden;
  @apply hover:overflow-visible;
  @apply py-0.5 px-2;
  @apply rounded-full;
  @apply text-xs font-medium;
  @apply bg-indigo-100 text-indigo-600;
  @apply dark:bg-indigo-600 dark:text-indigo-200;

  &--system {
    @apply bg-yellow-400 text-gray-700;
    @apply dark:bg-orange-600 dark:text-gray-100;

    .ui-tag {
      &__actions {
        @apply bg-yellow-500 dark:bg-orange-500;
      }

      &__menu,
      &__remove {
        @apply hover:bg-yellow-600 hover:dark:bg-orange-400;
      }
    }
  }

  &__actions {
    @apply flex items-center;
    @apply absolute right-0 top-1/2;
    @apply transform -translate-y-1/2;
    @apply opacity-0 group-hover:opacity-100;
    @apply bg-indigo-200 dark:bg-indigo-500;
    @apply rounded-full;
  }

  &__menu,
  &__remove {
    @apply h-5 w-5;
    @apply inline-flex items-center justify-center shrink-0;
    @apply rounded-full;
    @apply text-indigo-400 hover:bg-indigo-300 hover:text-indigo-500;
    @apply dark:text-indigo-200 dark:hover:bg-indigo-400 dark:hover:text-indigo-100;
    @apply focus:outline-none focus:bg-indigo-500 focus:text-white;
    @apply dark:focus:bg-indigo-500 dark:focus:text-white;
    @apply cursor-pointer;
  }

  &__dropdown {
    @apply flex items-center;
    @apply gap-1;
  }

  &__dropdown-icon {
    @apply w-4 h-4;
    @apply text-gray-400 dark:text-gray-500;
  }

  &__edit {
    @apply w-20;
    @apply py-0.5 pl-1;
    @apply inline-flex items-center;
    @apply border border-indigo-500;
    @apply rounded-full;
    @apply overflow-hidden;

    input {
      @apply w-10;
      @apply p-0 shrink;
      @apply border-none;
      @apply bg-transparent;
      @apply text-indigo-600 dark:text-indigo-200;
      @apply focus:ring-0;
      @apply text-xs;
      @apply focus:outline-none;
    }
  }

  &__edit-button {
    @apply h-4 w-4;
    @apply inline-flex items-center justify-center;
    @apply rounded-full;
    @apply cursor-pointer;

    &--apply {
      @apply text-green-500 hover:bg-green-200 hover:text-green-600;
    }
    &--cancel {
      @apply text-red-500 hover:bg-red-200 hover:text-red-600;
    }
  }

  svg {
    @apply w-3 h-3;
  }
}
</style>
