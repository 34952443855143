<template>
  <UILabeledField
    v-model="modelValue"
    :data-refid="dataRefid"
    v-bind="{
      disabled,
      error,
      focusOnLoad,
      label,
      silentError,
      size,
      readonly,
    }"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'

import { AssetClass } from '..'
import { DataFieldSizes } from '@types'

import { ASSET_FIELD } from '../utils/const'

import { UILabeledField } from '@ui'

type Props = {
  instance: AssetClass
  disabled?: boolean
  label?: string
  focusOnLoad?: boolean
  dataRefid?: string
  size?: DataFieldSizes
}

const props = defineProps<Props>()

const modelValue = computed({
  get() {
    return props.instance.field<string>(ASSET_FIELD.NAME).value
  },
  set(value) {
    props.instance.field(ASSET_FIELD.NAME).value = value
    if (props.instance.isAccount) {
      props.instance.field(ASSET_FIELD.TICKER).value = value
    }
  },
})

const isSavedAsset = inject('isSavedAsset', ref(false))

const readonly = computed(() => props.instance.isReadonly)
const error = computed(() => props.instance.errors.name)
const silentError = computed(() => !isSavedAsset.value)
</script>

<script lang="ts">
export default {
  name: 'AssetFormName',
}
</script>
