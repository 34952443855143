import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { orderBy } from 'lodash'
import { AxiosResponse } from 'axios'

import { LinkedDataConnector, PlaidToken } from './utils/types'
import { LinkedDataConnectors } from '@/views/LinkedData/utils/enums'

import api from '@/store/api'
import {
  addStoreListItem,
  prepareResponseError,
  removeStoreListItem,
  updateStoreListItem,
} from '../utils/helpers'

import { useRepositoriesStore } from '../repositories'

export const useLinkedDataConnectorsStore = defineStore(
  'linked-data-connectors',
  () => {
    // INIT
    const repositoriesStore = useRepositoriesStore()

    const list = ref<LinkedDataConnector[]>([])

    const initFlag = ref(false)

    const loading = ref(true)
    const loadingAction = ref(false)

    const repositoryId = computed(() => repositoriesStore.currentRepositoryId)

    let abortController = new AbortController()

    // GETTERS
    const getList = computed(() =>
      orderBy(list.value, item => item.name.toLowerCase(), 'asc'),
    )

    // SETTERS
    const setList = (data: LinkedDataConnector[]) => {
      list.value = data
    }

    // ACTIONS
    const fetch = async (): Promise<void> => {
      loading.value = true
      try {
        const result = await api.get('linked_data/connectors', {
          params: { repository_id: repositoryId.value },
          signal: abortController.signal,
        })
        setList(result.data)
        initFlag.value = true
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loading.value = false
      }
    }

    const fetchStrangers = async (): Promise<LinkedDataConnector[]> => {
      try {
        const result = await api.get<LinkedDataConnector[]>(
          'linked_data/connectors',
          {
            params: {
              exclude_repositories_id: JSON.stringify([repositoryId.value]),
            },
            signal: abortController.signal,
          },
        )
        return result.data
      } catch (e) {
        throw Error(prepareResponseError(e))
      }
    }

    const getUrl = async (
      connector: LinkedDataConnectors,
      params?: Record<string, string>,
    ): Promise<string> => {
      const result = await api.get(
        `linked_data/authorization_url/${connector.toLowerCase()}`,
        {
          params: { ...params, repository_id: repositoryId.value },
          signal: abortController.signal,
        },
      )
      return result.data?.url
    }

    const getPlaidToken = async (connectorId?: string): Promise<PlaidToken> => {
      const result = await api.get('linked_data/authorization_url/plaid', {
        params: {
          repository_id: repositoryId.value,
          ...(connectorId && { connector_id: connectorId }),
        },
        signal: abortController.signal,
      })
      return result.data
    }

    const store = async (
      connector: LinkedDataConnector,
    ): Promise<LinkedDataConnector | undefined> => {
      loadingAction.value = true
      try {
        const result = await api.post<
          LinkedDataConnector,
          AxiosResponse<LinkedDataConnector>,
          LinkedDataConnector
        >(`linked_data/connectors`, connector)
        addStoreListItem(list.value, result.data)
        return result.data
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loadingAction.value = false
      }
    }

    const update = async (id: string, name: string): Promise<void> => {
      loadingAction.value = true
      try {
        const result = await api.patch(
          `linked_data/connectors/${id}?repository_id=${repositoryId.value}`,
          {
            name,
          },
        )
        updateStoreListItem(list.value, result.data)
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loadingAction.value = false
      }
    }

    const destroy = async (id: string): Promise<void> => {
      loadingAction.value = true
      try {
        await api.delete(`linked_data/connectors/${id}`)
        removeStoreListItem(list.value, id)
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loadingAction.value = false
      }
    }

    const map = async (id: string) => {
      loadingAction.value = true
      try {
        await api.post(`linked_data/connectors/${id}/map_repository`, {
          repository_id: repositoryId.value,
        })
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loadingAction.value = false
      }
    }

    const importData = async (id: string): Promise<void> => {
      loadingAction.value = true
      try {
        await api.get(`linked_data/connectors/${id}/import`, {
          params: { repository_id: repositoryId.value },
          signal: abortController.signal,
        })
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loadingAction.value = false
      }
    }

    const cancel = () => {
      abortController.abort()
      abortController = new AbortController()
    }

    const clear = () => {
      initFlag.value = false

      loading.value = true
      loadingAction.value = false

      setList([])
    }

    return {
      initFlag,
      loading,

      getList,

      fetch,
      fetchStrangers,
      getUrl,
      getPlaidToken,

      store,
      update,
      destroy,
      map,

      importData,

      cancel,
      clear,
    }
  },
)
