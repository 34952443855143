<template>
  <GroupsItemParamValueDropdown
    v-if="isDropdownField || isArrayField"
    ref="inputRef"
    v-bind="{ comparison, fieldSettings }"
  />
  <GroupsItemParamValueDate
    v-else-if="isDateField"
    ref="inputRef"
    v-bind="{ comparison, fieldSettings }"
  />
  <GroupsItemParamValueInput
    v-else-if="isFieldVisible"
    ref="inputRef"
    v-bind="{ comparison, fieldSettings }"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { FilterComparison } from '../utils/enums'
import { FilterField } from '../utils/types'

import GroupsItemParamValueDate from './GroupsItemParamValueDate.vue'
import GroupsItemParamValueDropdown from './GroupsItemParamValueDropdown.vue'
import GroupsItemParamValueInput from './GroupsItemParamValueInput.vue'

type Props = {
  fieldSettings?: FilterField
  comparison?: FilterComparison
}

const props = defineProps<Props>()

defineExpose({
  start() {
    inputRef.value?.start()
  },
  initData() {
    inputRef.value?.init?.()
  },
})

const inputRef = ref<
  | typeof GroupsItemParamValueDate
  | typeof GroupsItemParamValueDropdown
  | typeof GroupsItemParamValueInput
  | null
>(null)

const isDropdownField = computed(() => {
  if (!props.comparison || !props.fieldSettings) return false
  if (props.comparison === FilterComparison.LAST) return true
  if (
    (props.fieldSettings.list || props.fieldSettings.getter) &&
    [FilterComparison.EQ, FilterComparison.NOTEQ].includes(props.comparison)
  )
    return true
  return false
})

const isArrayField = computed(() => {
  return isFieldVisible.value && props.fieldSettings?.isArray
})

const isDateField = computed(() => {
  return isFieldVisible.value && props.fieldSettings?.isDate
})

const isFieldVisible = computed(
  () =>
    props.comparison &&
    ![FilterComparison.EMPTY, FilterComparison.NOTEMPTY].includes(
      props.comparison,
    ),
)
</script>

<script lang="ts">
export default {
  name: 'FilterGroupsItemParamValue',
}
</script>

<style scoped lang="postcss"></style>
