export enum ValueHistoryDataTabs {
  Assets,
  Liabilities,
}

export enum DashboardWidgetName {
  ValueHistoryPieChart = 'ValueHistoryPieChart',
  ValueHistoryTable = 'ValueHistoryTable',
  ValueHistoryBarsChart = 'ValueHistoryBarsChart',
  IncomeHistoryTable = 'IncomeHistoryTable',
  IncomeHistoryBarsChart = 'IncomeHistoryBarsChart',
  LinkedTransactions = 'LinkedTransactions',
  LinkedTransactionsPivot = 'LinkedTransactionsPivot',
  ValuePivot = 'ValuePivot',
  IncomePivot = 'IncomePivot',
  PerformancePivot = 'PerformancePivot',
  SectionDelimiter = 'SectionDelimiter',
  ValueChangeSnapshot = 'ValueChangeSnapshot',
  IncomeChangeSnapshot = 'IncomeChangeSnapshot',
  Commitments = 'Commitments',
}
