import { ConnectorAccount } from './types'

export const ALLOWED_TYPES = [
  'bank account',
  'credit card',
  'investment account',
  'loan',
  'other',
]

export const CONNECTOR_SYNC_STATUS_TEXT = [
  '', // statuses start from 1
  'Success',
  'Warning',
  'Error',
  'Error',
]

export const YODLEE_ONCLOSE_STATUSES = {
  ACTION_ABANDONED: 'ACTION_ABANDONED',
  FAILED: 'FAILED',
  MIGRATION_IN_PROGRESS: 'MIGRATION_IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  USER_CLOSE_ACTION: 'USER_CLOSE_ACTION',
}

export const AKOYA_ACCOUNTS = [
  {
    title: 'JPMorgan Chase',
    name: 'chase',
    logo: '/assets/img/connectors/chase.svg',
  },
  {
    title: 'Fidelity',
    name: 'fidelity',
    logo: '/assets/img/connectors/fidelity.svg',
  },
  {
    title: 'Bank Of America',
    name: 'bankofamerica',
    logo: '/assets/img/connectors/bankofamerica.svg',
  },
  {
    title: 'Citibank',
    name: 'citi',
    logo: '/assets/img/connectors/citi.svg',
  },
  {
    title: 'PNC Bank',
    name: 'pnc',
    logo: '/assets/img/connectors/pnc.svg',
  },
  {
    title: 'TD Bank',
    name: 'td',
    logo: '/assets/img/connectors/td.svg',
  },
  {
    title: 'Transamerica',
    name: 'transamerica',
    logo: '/assets/img/connectors/transamerica.svg',
  },
  {
    title: 'U.S. Bank',
    name: 'usbank',
    logo: '/assets/img/connectors/usbank.svg',
  },
  {
    title: 'Wells Fargo',
    name: 'wellsfargo',
    logo: '/assets/img/connectors/wellsfargo.svg',
  },
  {
    title: 'Capital One',
    name: 'capitalone',
    logo: '/assets/img/connectors/capitalone.svg',
  },
  {
    title: 'Mikomo',
    name: 'mikomo',
    logo: '/assets/img/connectors/mikomo.png',
    devOnly: true,
  },
]

export const ACCOUNTS_LIST: ConnectorAccount[] = [
  ...AKOYA_ACCOUNTS.map(connector => ({
    ...connector,
    provider: 'akoya',
  })),
  {
    title: 'Coinbase',
    name: 'coinbase',
    logo: '/assets/img/connectors/coinbase.svg',
    provider: 'coinbase',
  },
]
