import { Contact } from './utils/types'
import { Entity } from '../utils/common'
import { EntityEvent } from '../utils/enums'

import { CONTACT_DEFAULT_DATA, CONTACT_FIELD } from './utils/const'
import { EMPTY_VALUE_PLACEHOLDER } from '@/const/common'

import { rules } from '@/helpers/validate'

import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useContactsStore } from '@/store/contacts/'
import { useContactsBunchStore } from '@/store/contacts/bunch'
import { useModalsStore } from '@/store/modals'

import ContactDialog from './components/ContactDialog.vue'
import ContactSlideover from './components/ContactSlideover.vue'
import ContactFormName from './components/ContactFormName.vue'
import ContactFormEmail from './components/ContactFormEmail.vue'
import ContactFormPhone from './components/ContactFormPhone.vue'
import ContactFormNotes from './components/ContactFormNotes.vue'

export class ContactClass extends Entity<Contact> {
  private contactsStore
  private bunchStore
  private modalsStore
  private assetsBunchStore

  constructor(data: Partial<Contact>, created = false) {
    super(data, CONTACT_DEFAULT_DATA, created)
    this.contactsStore = useContactsStore()
    this.bunchStore = useContactsBunchStore()
    this.modalsStore = useModalsStore()
    this.assetsBunchStore = useAssetsBunchStore()
    this.cachedData = { key: '' }
  }

  getModal() {
    return this.isDialog ? ContactDialog : ContactSlideover
  }

  getFormName() {
    return ContactFormName
  }

  getFormEmail() {
    return ContactFormEmail
  }

  getFormPhone() {
    return ContactFormPhone
  }

  getFormNotes() {
    return ContactFormNotes
  }

  validate() {
    return {
      name: rules.required,
    }
  }

  get displayAssets() {
    this.prepareAssets()
    return this.cachedData?.value
  }

  private removeFromBunch() {
    this.bunchStore.getList.delete(this.id)
  }

  private removeFromForms() {
    this.modalsStore.getList.delete(this.id)
  }

  remove() {
    this.removeFromBunch()
    this.removeFromForms()
  }

  private prepareAssets() {
    if (!this.assetsBunchStore?.getList.size) return
    const assetsIds = this.field<string[]>(CONTACT_FIELD.ASSETS).value
    const key = JSON.stringify(assetsIds)
    if (assetsIds && this.cachedData.key !== key) {
      const value = assetsIds.map(id => {
        const asset = this.assetsBunchStore.getList.get(id)
        return (
          asset?.field<string>(CONTACT_FIELD.NAME) || EMPTY_VALUE_PLACEHOLDER
        )
      })
      this.cachedData = { key, value }
    }
  }

  resetAssets() {
    this.cachedData = {
      key: '',
      value: [],
    }
    this.prepareAssets()
  }

  async store() {
    try {
      const data = this.get()
      const result = await this.contactsStore.store(data)
      this.remove()
      if (result !== undefined) {
        this.reset(result)
        this.bunchStore.unshiftElement(this)
        this.callEvent(EntityEvent.STORED)
      }
    } catch (e) {
      throw Error(e as string)
    }
  }

  async update() {
    try {
      const result = await this.contactsStore.update(this.get())
      if (result === undefined) {
        this.cancel()
      } else {
        this.reset(result)
        this.callEvent(EntityEvent.UPDATED)
      }
    } catch (e) {
      throw Error(e as string)
    }
  }

  async destroy() {
    try {
      if (!this.isNew) {
        await this.contactsStore.destroy(this.id)
        this.callEvent(EntityEvent.DESTROYED)
      }
      this.remove()
    } catch (e) {
      this.cancel()
      throw Error(e as string)
    }
  }
}
