<template>
  <div ref="containerRef" :class="containerClass">
    <label v-if="label" :for="id" :class="labelClass">
      <span>
        {{ label }}
        <span v-if="error && !floatLabel" :class="labelErrorClass">
          {{ error }}
        </span>
      </span>
      <div :class="questionClass">
        <QuestionMarkCircleIcon
          v-tooltip="helpDescription"
          aria-hidden="true"
        />
      </div>
    </label>
    <div :class="groupClass">
      <div v-if="leading" class="field__leading">
        <component
          :is="leading.icon"
          v-if="leading.icon"
          class="field__leading__icon"
          aria-hidden="true"
          @click="handleClickLeading"
        />
        <span
          v-if="leading.text"
          class="field__leading__text"
          @click="handleClickLeading"
          >{{ leading.text }}</span
        >
      </div>
      <component
        :is="inputComponentName"
        ref="inputRef"
        v-bind="{
          ...$attrs,
          disabled,
          id,
          ...(type === 'number' ? { isAlwaysNegative, isAlwaysPositive } : {}),
          isPercentage,
          modelValue,
          readonly,
        }"
        :class="inputClasses"
        @update:model-value="handleUpdate"
        @keydown.enter="handleClickEnter"
        @keydown.tab="handleClickTab"
        @keyup="handleKeyUp"
        @blur="handleBlur"
        @focus="handleFocus"
      />
      <XMarkIcon
        v-if="clearButtonVisible"
        :class="clearClass"
        aria-hidden="true"
        @click="handleClearField"
        @mousedown.stop
      />
      <div
        v-if="error || trailing?.text || trailing?.icon"
        class="field__trailing cursor-pointer"
      >
        <ExclamationCircleIcon
          v-if="error && !silentError"
          class="field__trailing__icon"
          aria-hidden="true"
        />
        <span
          v-if="trailing?.text"
          class="field__trailing_text"
          @click="handleClickTrailing"
          >{{ trailing.text }}</span
        >
        <component
          :is="trailing?.icon"
          v-if="trailing?.icon"
          class="field__trailing__icon field__trailing__icon--clickable"
          aria-hidden="true"
          @click="handleClickTrailing"
          @mousedown.stop
        />
      </div>
    </div>
    <div v-if="error && floatLabel" :class="errorClass">
      {{ error }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance, nextTick, onMounted, ref } from 'vue'

import {
  DataFieldIcon,
  DataFieldSizes,
  DataFieldType,
  DataFieldValue,
} from './utils/types'

import { OVERLAY_DURATION } from '@/const/common'

import {
  QuestionMarkCircleIcon,
  ExclamationCircleIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'

type Props = {
  label?: string
  type?: DataFieldType
  error?: string
  silentError?: boolean
  leading?: DataFieldIcon
  trailing?: DataFieldIcon
  disabled?: boolean
  readonly?: boolean
  hasBackground?: string
  size?: DataFieldSizes
  focusOnLoad?: boolean
  disableFocusDelay?: boolean
  isAlwaysNegative?: boolean
  isAlwaysPositive?: boolean
  inputClass?: string
  helpDescription?: string
  lazyFocus?: boolean
  floatLabel?: boolean
  disableRecoveryValue?: boolean
  clearable?: boolean
}

type Emits = {
  'clear-field': []
  'click:leading': []
  'click:trailing': []
  'click:tab': [data: KeyboardEvent]
  'click:enter': [data: KeyboardEvent]
  'update:late': [data: DataFieldValue]
  reset: [data: DataFieldValue]
}

const { type = 'text', size = 'default', ...props } = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<DataFieldValue>()

const containerRef = ref<HTMLDivElement | null>(null)
const inputRef = ref<any>(null)

defineExpose({
  blur() {
    inputRef.value?.blur()
  },
  focus() {
    inputRef.value?.focus()
  },
  select() {
    inputRef.value?.select()
  },
  getContainer() {
    return containerRef.value
  },
})

const id = getCurrentInstance()?.uid.toString()

const currentValue = ref<DataFieldValue>(modelValue.value)
const savedValue = ref()

const isPercentage = computed(() => type === 'percent' || undefined)

const containerClass = computed(() => ({
  field__container: true,
  group: true,
  error: props.error && !props.silentError,
}))

const labelClass = computed(() => ({
  [`field__label field__label-${size}`]: true,
  'field__label--float': props.floatLabel,
}))

const labelErrorClass = computed(() => ({
  'field__error-message--silent': props.silentError,
  'field__error-message': !props.silentError,
}))

const questionClass = computed(() => ({
  field__question: true,
  'field__question--hidden': !props.helpDescription,
}))

const groupClass = computed(() => ({
  field__group: true,
  [`field__group--${size}`]: true,
  'mt-1': !!props.label,
}))

const inputClasses = computed(() => ({
  field__input: true,
  'field__input--disabled': props.disabled,
  'field__input--readonly': props.readonly,
  'field__input--error': props.error,
  'field__input--with-leading': props.leading?.icon,
  'field__input--with-trailing': props.trailing?.icon,
  'field__input--with-clear-icon': clearButtonVisible.value,
  [`focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-white focus:ring-offset-${props.hasBackground} focus:border-${props.hasBackground}`]:
    props.hasBackground,
  [props.inputClass || '']: props.inputClass,
}))

const clearClass = computed(() => ({
  field__clear: true,
  'field__clear--moved':
    props.error || props.trailing?.text || props.trailing?.icon,
}))

const errorClass = computed(() => ({
  'field__error-message--float': true,
  'field__error-message--silent': props.silentError,
  'field__error-message': !props.silentError,
}))

const inputComponentName = computed(() => {
  switch (type) {
    case 'email':
      return 'AppEmailField'
    case 'number':
    case 'percent':
      return 'AppNumberField'
    case 'password':
      return 'AppPasswordField'
    case 'date':
      return 'AppDateField'
    default:
      return 'AppTextField'
  }
})

const clearButtonVisible = computed(() => props.clearable && modelValue.value)

const handleClickLeading = () => {
  !props.disabled && emit('click:leading')
}

const handleClickTrailing = () => {
  !props.disabled && emit('click:trailing')
}

const setFocusOnField = async () => {
  inputRef.value?.focus()
  if (!props.lazyFocus) return
  await nextTick()
  inputRef.value?.begin()
}

const handleClickEnter = (event: KeyboardEvent) => {
  emit('click:enter', event)
}

const handleClickTab = (event: KeyboardEvent) => {
  emit('click:tab', event)
}

const handleKeyUp = (e: KeyboardEvent) => {
  if (e.key === 'Escape') {
    if (
      !props.disableRecoveryValue &&
      savedValue.value &&
      savedValue.value !== modelValue.value
    ) {
      e?.stopImmediatePropagation()
      modelValue.value = savedValue.value
    }
    emit('reset', savedValue.value)
    blur()
  }
}

const handleBlur = () => {
  if (currentValue.value === modelValue.value) return
  emit('update:late', currentValue.value)
}

const handleFocus = () => {
  inputRef.value?.focus()
}

const handleUpdate = (value: DataFieldValue) => {
  currentValue.value = value
  modelValue.value = value
}

const handleClearField = () => {
  modelValue.value = ''
  emit('clear-field')
}

onMounted(() => {
  savedValue.value = modelValue.value
  if (props.focusOnLoad) {
    setTimeout(setFocusOnField, props.disableFocusDelay ? 0 : OVERLAY_DURATION)
  }
})
</script>

<script lang="ts">
import AppNumberField from './NumberField.vue'
import AppDateField from './DateField.vue'
import AppTextField from './TextField.vue'
import AppEmailField from './EmailField.vue'
import AppPasswordField from './PasswordField.vue'

export default {
  name: 'UILabeledField',
  components: {
    AppNumberField,
    AppDateField,
    AppTextField,
    AppEmailField,
    AppPasswordField,
  },
  inheritAttrs: false,
}
</script>

<style scoped lang="postcss">
@import './styles/data-field.css';
@import './styles/data-field.icon.css';
@import './styles/data-field.input.css';
</style>
