<template>
  <div v-if="isPaginationVisible" class="ui-pagination">
    <template v-if="isMobilePagingVisible">
      <UIButton
        v-bind="mobileButtonAttrs"
        :disabled="isPreviousButtonDisabled"
        label="Previous"
        @click="handleClickPage(pageNo - 1)"
      />
      <UIButton
        v-bind="mobileButtonAttrs"
        :disabled="isNextButtonDisabled"
        label="Next"
        @click="handleClickPage(pageNo + 1)"
      />
    </template>
    <template v-else-if="!isMobile">
      <div class="ui-pagination__summary">
        <span>Showing</span>
        <strong v-text="summaryFrom" />
        <span>to</span>
        <strong v-text="summaryTo" />
        <span>of</span>
        <strong data-refid="paginationTotal" v-text="total" />
        <span>items</span>
      </div>
      <nav
        v-if="isPagingVisible"
        class="ui-pagination__paging"
        aria-label="Pagination"
      >
        <UIButton
          v-bind="desktopButtonAttrs"
          :icon="ChevronDoubleLeftIcon"
          :disabled="isFirstButtonDisabled"
          @click="handleClickPage(0)"
        />
        <UIButton
          v-bind="desktopButtonAttrs"
          :icon="ChevronLeftIcon"
          :disabled="isPreviousButtonDisabled"
          @click="handleClickPage(pageNo - 1)"
        />
        <PaginationButton
          v-for="page in pages"
          :key="page"
          v-bind="{ page, pageNo, ...desktopButtonAttrs }"
          @click="handleClickPage"
        />
        <UIButton
          v-bind="desktopButtonAttrs"
          :icon="ChevronRightIcon"
          :disabled="isNextButtonDisabled"
          @click="handleClickPage(pageNo + 1)"
        />
        <UIButton
          v-bind="desktopButtonAttrs"
          :icon="ChevronDoubleRightIcon"
          :disabled="isLastButtonDisabled"
          @click="handleClickPage(totalPages - 1)"
        />
      </nav>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { ButtonSizes, ButtonVariant } from '@types'

import { PAGINATION_PAGES_COUNT } from './utils/const'

import { useResponsive } from '@/plugins/responsiveUI'

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/vue/24/outline'
import { UIButton } from '@ui'
import PaginationButton from './components/Button.vue'

type Props = {
  pageNo?: number
  pageSize?: number
  total?: number
  disabled?: boolean
}

type Emits = {
  'click:page': [data: number]
}

const { pageNo = 0, pageSize = 0, total = 0, ...props } = defineProps<Props>()
const emit = defineEmits<Emits>()

const { isMobile } = useResponsive()

const isMobilePagingVisible = computed(
  () => isMobile.value && isPagingVisible.value,
)

const mobileButtonAttrs = computed(() => ({
  size: 'small' as ButtonSizes,
  variant: 'light' as ButtonVariant,
}))
const desktopButtonAttrs = computed(() => ({
  size: 'xsmall' as ButtonSizes,
  variant: 'light' as ButtonVariant,
  class: 'ui-pagination__paging__button',
  disabled: props.disabled,
}))

const summaryFrom = computed(() => pageNo * pageSize + 1)
const summaryTo = computed(() => {
  const to = summaryFrom.value - 1 + pageSize
  return to > total ? total : to
})
const totalPages = computed(() => Math.ceil(total / pageSize))
const pages = computed(() => {
  const arrayOfPages = Array.from(
    Array(totalPages.value),
    (_, index) => index + 1,
  )
  if (arrayOfPages.length <= PAGINATION_PAGES_COUNT) {
    return arrayOfPages
  }
  const halfOfCount = PAGINATION_PAGES_COUNT / 2
  let startPage = pageNo - Math.floor(halfOfCount)
  let endPage = pageNo + Math.ceil(halfOfCount)
  if (startPage < 0) {
    endPage += Math.abs(startPage)
    startPage = 0
  }
  if (endPage > arrayOfPages.length) {
    startPage += arrayOfPages.length - endPage
    endPage = arrayOfPages.length
  }
  const result = arrayOfPages.slice(startPage, endPage)
  if (startPage > 0) {
    result.unshift(0)
  }
  if (endPage < arrayOfPages.length) {
    result.push(0)
  }
  return result
})

const isPaginationVisible = computed(() => summaryTo.value >= summaryFrom.value)
const isPagingVisible = computed(() => pages.value.length > 1)

const isFirstButtonDisabled = computed(() => pageNo <= 1)
const isPreviousButtonDisabled = computed(() => pageNo <= 0)
const isNextButtonDisabled = computed(() => pageNo >= totalPages.value - 1)
const isLastButtonDisabled = computed(() => pageNo >= totalPages.value - 2)

const handleClickPage = (page: number) => {
  emit('click:page', page)
}
</script>

<script lang="ts">
export default {
  name: 'UIPagination',
}
</script>

<style scoped lang="postcss">
.ui-pagination {
  @apply flex sm:grid sm:grid-cols-[1fr_4fr_1fr];
  @apply py-3 sm:py-2;
  @apply gap-3;

  &__summary {
    @apply flex items-center;
    @apply gap-1;
    @apply text-xs text-gray-500 dark:text-gray-400;

    strong {
      @apply font-medium;
    }
  }

  &__paging {
    @apply isolate flex justify-center;
    @apply -space-x-px;

    &__button {
      @apply focus:z-20;
      @apply first:rounded-r-none last:rounded-l-none;

      &:not(:first-child):not(:last-child) {
        @apply rounded-none;
      }

      &--current {
        @apply !bg-indigo-50 !border-indigo-200;
        @apply dark:!bg-indigo-900 dark:!border-indigo-800;
        @apply z-10;
      }
    }
  }
}
</style>
