<template>
  <UIButton
    v-bind="{ size }"
    :icon="XMarkIcon"
    label="Cancel"
    variant="light"
    hide-label-on-mobile
  />
</template>

<script setup lang="ts">
import { inject } from 'vue'

import { ButtonSizes } from '@types'

import { XMarkIcon } from '@heroicons/vue/24/outline'

import { UIButton } from '@ui'

const size = inject<ButtonSizes>('size')
</script>

<script lang="ts">
export default {
  name: 'PanelCancel',
}
</script>

<style scoped lang="postcss"></style>
