import { WSAction, WSService } from './types'

export const getKey = (service: WSService, action: WSAction) =>
  `${service}:${action}`

export const getChunkKey = (prefix: string, requestId: string) =>
  `${prefix}:${requestId}`

export const generateUniqueRequestId = () => {
  return (Math.random() + 1).toString(36).substring(7)
}
