import { sortBy } from 'lodash'

import { FilterField } from '@/components/UI/Filter/utils/types'
import { FilterComparison } from '@/components/UI/Filter/utils/enums'

import { LINKED_TRANSACTION_FIELDS } from './const'

import useFiltersService from '@/services/filters'

export const getLinkedDataTransactionsFilters = (): FilterField[] => {
  const { fetchOptions } = useFiltersService()

  return sortBy(
    [
      {
        name: LINKED_TRANSACTION_FIELDS.ACCESS_METHOD.field,
        caption: LINKED_TRANSACTION_FIELDS.ACCESS_METHOD.label,
        getter: () => fetchOptions('LinkedDataTransactions', 'access_method'),
      },
      {
        name: LINKED_TRANSACTION_FIELDS.ACCOUNT_NAME.field,
        caption: LINKED_TRANSACTION_FIELDS.ACCOUNT_NAME.label,
        getter: () => fetchOptions('LinkedDataTransactions', 'account_name'),
      },
      {
        name: LINKED_TRANSACTION_FIELDS.ACCOUNT_NUMBER.field,
        caption: LINKED_TRANSACTION_FIELDS.ACCOUNT_NUMBER.label,
        getter: () => fetchOptions('LinkedDataTransactions', 'account_number'),
      },
      {
        name: LINKED_TRANSACTION_FIELDS.ACCOUNT_TYPE.field,
        caption: LINKED_TRANSACTION_FIELDS.ACCOUNT_TYPE.label,
        getter: () => fetchOptions('LinkedDataTransactions', 'account_type'),
      },
      {
        name: LINKED_TRANSACTION_FIELDS.AMOUNT.field,
        caption: LINKED_TRANSACTION_FIELDS.AMOUNT.label,
        isAmount: true,
      },
      {
        name: LINKED_TRANSACTION_FIELDS.CATEGORY.field,
        caption: LINKED_TRANSACTION_FIELDS.CATEGORY.label,
        getter: () => fetchOptions('LinkedDataTransactions', 'category'),
      },
      {
        name: LINKED_TRANSACTION_FIELDS.CONNECTOR_NAME.field,
        caption: LINKED_TRANSACTION_FIELDS.CONNECTOR_NAME.label,
        getter: () => fetchOptions('LinkedDataTransactions', 'custodian'),
      },
      {
        name: LINKED_TRANSACTION_FIELDS.COUNTERPARTY.field,
        caption: LINKED_TRANSACTION_FIELDS.COUNTERPARTY.label,
        getter: () => fetchOptions('LinkedDataTransactions', 'counterparty'),
      },
      {
        name: LINKED_TRANSACTION_FIELDS.DATE.field,
        caption: LINKED_TRANSACTION_FIELDS.DATE.label,
        isDate: true,
      },
      {
        name: LINKED_TRANSACTION_FIELDS.DESCRIPTION.field,
        caption: LINKED_TRANSACTION_FIELDS.DESCRIPTION.label,
      },
      {
        name: LINKED_TRANSACTION_FIELDS.MONTH.field,
        caption: LINKED_TRANSACTION_FIELDS.MONTH.label,
        isMonth: true,
        getter: () => fetchOptions('LinkedDataTransactions', 'month'),
      },
      {
        name: LINKED_TRANSACTION_FIELDS.QUARTER.field,
        caption: LINKED_TRANSACTION_FIELDS.QUARTER.label,
        getter: () => fetchOptions('LinkedDataTransactions', 'quarter'),
      },
      {
        name: LINKED_TRANSACTION_FIELDS.SUBCATEGORY.field,
        caption: LINKED_TRANSACTION_FIELDS.SUBCATEGORY.label,
        getter: () => fetchOptions('LinkedDataTransactions', 'subcategory'),
      },
      {
        name: LINKED_TRANSACTION_FIELDS.TAGS.field,
        caption: LINKED_TRANSACTION_FIELDS.TAGS.label,
        defaultComparison: FilterComparison.CONTAINS,
        comparison: [FilterComparison.CONTAINS, FilterComparison.NOTCONTAINS],
        getter: () => fetchOptions('LinkedDataTransactions', 'tags'),
      },
      {
        name: LINKED_TRANSACTION_FIELDS.TYPE.field,
        caption: LINKED_TRANSACTION_FIELDS.TYPE.label,
        getter: () => fetchOptions('LinkedDataTransactions', 'type'),
      },
      {
        name: LINKED_TRANSACTION_FIELDS.YEAR.field,
        caption: LINKED_TRANSACTION_FIELDS.YEAR.label,
        isSimple: true,
        getter: () => fetchOptions('LinkedDataTransactions', 'year'),
      },
      {
        name: LINKED_TRANSACTION_FIELDS.STATUS.field,
        caption: LINKED_TRANSACTION_FIELDS.STATUS.label,
        defaultComparison: FilterComparison.EQ,
        comparison: [FilterComparison.EQ, FilterComparison.NOTEQ],
        getter: () => fetchOptions('LinkedDataTransactions', 'status'),
      },
    ],
    'caption',
  )
}
