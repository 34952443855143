<template>
  <EChart v-bind="{ option, selectedIndex }" @click:item="handleClickItem" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useLocalStorage } from '@vueuse/core'

import { EChartOption } from 'echarts'

import { BLUR_DIGITAL_VALUES } from '@/const/storage'

import EChart from './EChart.vue'

type Props = {
  series: EChartOption.Series[]
  data: (string | number)[][]

  valueFormatter?: (data: number, fractionDigits?: number) => string
}

const props = defineProps<Props>()

const selectedIndex = defineModel<number>('index')

const blurDigitalValues = useLocalStorage(BLUR_DIGITAL_VALUES, false)

const category = computed(() => props.data.map(item => item[0]))

const data = computed(() => props.data.map(item => item.slice(1)))

const option = computed(() => {
  return {
    legend: {
      selectedMode: true,
      bottom: 0,
    },
    tooltip: {
      formatter: (
        params: EChartOption.Tooltip.Format | EChartOption.Tooltip.Format[],
      ) => {
        const { color, name, seriesName, value } =
          params as EChartOption.Tooltip.Format
        const result = props.valueFormatter?.(Number(value)) || value
        return `<div style="color:${color}">${seriesName}</div><div style="color:${color};opacity:0.75">${name}</div><span class="blurable-number">${result}</span>`
      },
    },
    xAxis: {
      data: category.value,
      axisLabel: { interval: 0, rotate: 30 },
    },
    yAxis: {
      axisLabel: {
        formatter: (value: number) =>
          blurDigitalValues.value
            ? '? ??? ???'
            : props.valueFormatter?.(value, 0) || value,
      },
    },
    series: props.series.map((item, index) => ({
      ...item,
      data: data.value.map(el => el[index]),
    })),
    grid: {
      left: 0,
      right: 0,
      bottom: '10%',
      containLabel: true,
      height: '95%',
    },
  }
})

const handleClickItem = (index: number) => {
  selectedIndex.value = index
}
</script>

<script lang="ts">
export default {
  name: 'UIComboChart',
}
</script>

<style lang="postcss"></style>
