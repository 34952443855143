import { DashboardsItem, DashboardWidgetName, UserPlan } from '@types'

export const DEFAULT_DESKTOP_WIDGETS: DashboardsItem[] = [
  {
    name: 'Portfolio',
    slug: 'portfolio',
    widgets: [
      {
        id: `${DashboardWidgetName.SectionDelimiter}1`,
        name: DashboardWidgetName.SectionDelimiter,
        title: 'Value History',
        attrs: {},
      },
      {
        id: DashboardWidgetName.ValueHistoryPieChart,
        name: DashboardWidgetName.ValueHistoryPieChart,
        title: 'Value Pie Chart',
        attrs: {},
      },
      {
        id: DashboardWidgetName.ValueHistoryTable,
        name: DashboardWidgetName.ValueHistoryTable,
        title: 'Value Table',
        attrs: {},
        tall: true,
      },
      {
        id: DashboardWidgetName.ValueHistoryBarsChart,
        name: DashboardWidgetName.ValueHistoryBarsChart,
        title: 'Value History',
        attrs: {},
      },
      {
        id: `${DashboardWidgetName.SectionDelimiter}2`,
        name: DashboardWidgetName.SectionDelimiter,
        title: 'Income History',
        attrs: {},
      },
      {
        id: DashboardWidgetName.IncomeHistoryBarsChart,
        name: DashboardWidgetName.IncomeHistoryBarsChart,
        title: 'Income History',
        attrs: {},
      },
      {
        id: DashboardWidgetName.IncomeHistoryTable,
        name: DashboardWidgetName.IncomeHistoryTable,
        title: 'Income Table',
        attrs: {},
      },
      {
        id: DashboardWidgetName.ValueChangeSnapshot,
        name: DashboardWidgetName.ValueChangeSnapshot,
        title: 'Value Change Shapshot',
        attrs: {},
        wide: true,
      },
      {
        id: DashboardWidgetName.IncomeChangeSnapshot,
        name: DashboardWidgetName.IncomeChangeSnapshot,
        title: 'Income Change Snapshot',
        attrs: {},
        wide: true,
      },
    ],
  },
  {
    name: 'Value Pivot',
    slug: 'value-pivot',
    widgets: [
      {
        id: DashboardWidgetName.ValuePivot,
        name: DashboardWidgetName.ValuePivot,
        title: '',
        attrs: {},
        tall: true,
        wide: true,
      },
    ],
  },
]

export const PREMIUM_PLUS_WIDGETS: DashboardsItem[] = [
  {
    name: 'Income Pivot',
    slug: 'income-pivot',
    widgets: [
      {
        id: DashboardWidgetName.IncomePivot,
        name: DashboardWidgetName.IncomePivot,
        title: '',
        attrs: {},
        tall: true,
        wide: true,
      },
    ],
  },
  {
    name: 'Performance Pivot',
    slug: 'performance-pivot',
    widgets: [
      {
        id: DashboardWidgetName.PerformancePivot,
        name: DashboardWidgetName.PerformancePivot,
        title: '',
        attrs: {},
        tall: true,
        wide: true,
      },
    ],
  },
  {
    name: 'Transactions Pivot',
    slug: 'transactions-pivot',
    widgets: [
      {
        id: DashboardWidgetName.LinkedTransactionsPivot,
        name: DashboardWidgetName.LinkedTransactionsPivot,
        title: '',
        attrs: {},
        tall: true,
        wide: true,
      },
    ],
  },
  {
    name: 'Commitments',
    slug: 'commitments',
    widgets: [
      {
        id: DashboardWidgetName.Commitments,
        name: DashboardWidgetName.Commitments,
        title: '',
        attrs: {},
        tall: true,
        wide: true,
      },
    ],
  },
]

export const DEFAULT_APP_WIDGETS: DashboardsItem[] = [
  {
    name: 'Portfolio',
    slug: 'portfolio',
    widgets: [
      {
        id: DashboardWidgetName.ValueHistoryPieChart,
        name: DashboardWidgetName.ValueHistoryPieChart,
        title: 'Value History',
        attrs: {},
      },
      {
        id: DashboardWidgetName.ValueChangeSnapshot,
        name: DashboardWidgetName.ValueChangeSnapshot,
        title: 'Value Change Shapshot',
        attrs: {},
        wide: true,
      },
    ],
  },
]

export const DEFAULT_DESKTOP_DASHBOARDS: Record<UserPlan, DashboardsItem[]> = {
  [UserPlan.PREMIUM]: DEFAULT_DESKTOP_WIDGETS,
  [UserPlan.PREMIUM_PLUS]: [
    ...DEFAULT_DESKTOP_WIDGETS,
    ...PREMIUM_PLUS_WIDGETS,
  ],
  [UserPlan.ULTRA]: [...DEFAULT_DESKTOP_WIDGETS, ...PREMIUM_PLUS_WIDGETS],
}

export const DEFAULT_APP_DASHBOARDS: Record<UserPlan, DashboardsItem[]> = {
  [UserPlan.PREMIUM]: DEFAULT_APP_WIDGETS,
  [UserPlan.PREMIUM_PLUS]: [...DEFAULT_APP_WIDGETS, ...PREMIUM_PLUS_WIDGETS],
  [UserPlan.ULTRA]: [...DEFAULT_APP_WIDGETS, ...PREMIUM_PLUS_WIDGETS],
}
