import { markRaw } from 'vue'
import { isEqual, omit } from 'lodash'

import { ModalEvent } from '@types'
import { Asset, AssetPrice } from './utils/types'
import { Entity } from '../utils/common'
import { EntityEvent } from '../utils/enums'

import {
  ASSET_ACCOUNT_INVESTMENT,
  ASSET_ACCOUNT_PRE_CREATED,
  ASSET_ACCOUNT_TRANSFER,
  ASSET_CURRENCY_TYPE,
  ASSET_DEFAULT_DATA,
  ASSET_FIELD,
} from './utils/const'
import { AssetCategoryAccount } from './utils/enums'

import { TAG_FIELD, TAG_ID_PREFIX } from '../tags/utils/const'

import { Rule, rules } from '@/helpers/validate'
import { getCurrencySymbol } from '@/helpers/common'

import { useAssetsStore } from '@/store/assets'
import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useModalsStore } from '@/store/modals'
import { useTagsBunchStore } from '@/store/tags/bunch'

import AssetDialog from './components/AssetDialog.vue'
import AssetFormType from './components/AssetFormType.vue'
import AssetFormName from './components/AssetFormName.vue'
import AssetFormTicker from './components/AssetFormTicker.vue'
import AssetFormCurrency from './components/AssetFormCurrency.vue'
import AssetFormDescription from './components/AssetFormDescription.vue'
import AssetSlideover from './components/AssetSlideover.vue'

import CurrencyDialog from './components/CurrencyDialog.vue'
import CurrencySlideover from './components/CurrencySlideover.vue'

import AccountDialog from './components/AccountDialog.vue'
import AccountSlideover from './components/AccountSlideover.vue'
import AccountFormCategory from './components/AccountFormCategory.vue'
import AccountFormSubcategory from './components/AccountFormSubcategory.vue'

import AccountConnector from './components/AccountConnector.vue'
import AssetSummary from './components/AssetSummary.vue'

export class AssetClass extends Entity<Asset> {
  private assetsStore
  private bunchStore
  private modalsStore
  private tagsBunchStore

  private priceList?: AssetPrice[] = undefined

  constructor(data: Partial<Asset>, created = false) {
    if (data.description === null) {
      data.description = ''
    }
    super(data, ASSET_DEFAULT_DATA, created)
    this.assetsStore = useAssetsStore()
    this.bunchStore = useAssetsBunchStore()
    this.modalsStore = useModalsStore()
    this.tagsBunchStore = useTagsBunchStore()
    this.cachedData = { key: '' }
  }

  get isChanged() {
    const omitFields = [
      ASSET_FIELD.CONNECTOR_STATUS,
      ASSET_FIELD.CONNECTOR_STATUS_MESSAGE,
      ASSET_FIELD.LINKED_ACCOUNT_ID,
    ]

    const data = omit(this.data, omitFields)
    const original = omit(this.original, omitFields)

    return !isEqual(data, original)
  }

  get isCommon() {
    return !this.isCurrency && !this.isAccount
  }

  get isCurrency() {
    return this.field(ASSET_FIELD.TYPE).value === ASSET_CURRENCY_TYPE
  }

  get isExpenseAccount() {
    return this.field(ASSET_FIELD.TYPE).value === AssetCategoryAccount.EXPENSE
  }

  get isIncomeAccount() {
    return this.field(ASSET_FIELD.TYPE).value === AssetCategoryAccount.INCOME
  }

  get isAccount() {
    return this.isExpenseAccount || this.isIncomeAccount
  }

  get isPrecreatedAccount() {
    if (!this.isAccount) return false
    const name = this.field<string>(ASSET_FIELD.NAME).value?.toLowerCase()
    return ASSET_ACCOUNT_PRE_CREATED.includes(name)
  }

  get isInvestmentAccount() {
    if (!this.isAccount) return false
    const name = this.field<string>(ASSET_FIELD.NAME).value?.toLowerCase()
    return ASSET_ACCOUNT_INVESTMENT.includes(name)
  }

  get isTransferAccount() {
    if (!this.isAccount) return false
    const name = this.field<string>(ASSET_FIELD.NAME).value?.toLowerCase()
    return ASSET_ACCOUNT_TRANSFER.includes(name)
  }

  get isReadonly() {
    return this.isAccount && this.isPrecreatedAccount
  }

  get isLinked() {
    return !!this.field<string | null>(ASSET_FIELD.LINKED_ACCOUNT_ID).value
  }

  getModal() {
    if (this.isCurrency) {
      return this.isDialog ? CurrencyDialog : CurrencySlideover
    }
    if (this.isAccount) {
      return this.isDialog ? AccountDialog : AccountSlideover
    }
    return this.isDialog ? AssetDialog : AssetSlideover
  }

  openSummary(
    accountId?: string,
    leaf?: boolean,
    amount?: number,
    callback?: () => void,
    path?: string[],
  ) {
    const key = `AssetSummary${this.id}`
    const modalInstance = this.modalsStore.init(key, markRaw(AssetSummary))
    modalInstance?.open(this.modalsStore.getZIndex(), {
      instance: this,
      accountId,
      leaf,
      amount,
      path,
    })
    callback && modalInstance?.addEventListener(ModalEvent.CLOSE, callback)
  }

  openConnectorInfo(assetId?: string, callback?: () => void) {
    const key = `AccountConnector${this.id}`
    const modalInstance = this.modalsStore.init(key, markRaw(AccountConnector))
    modalInstance?.open(this.modalsStore.getZIndex(), {
      instance: this,
      assetId,
    })
    callback && modalInstance?.addEventListener(ModalEvent.CLOSE, callback)
  }

  getFormType() {
    return AssetFormType
  }

  getFormName() {
    return AssetFormName
  }

  getFormTicker() {
    return AssetFormTicker
  }

  getFormCurrency() {
    return AssetFormCurrency
  }

  getFormDescription() {
    return AssetFormDescription
  }

  getFormCategory() {
    return AccountFormCategory
  }

  getFormSubcategory() {
    return AccountFormSubcategory
  }

  validate() {
    const result: Record<string, Rule> = {
      type: rules.required,
      name: rules.required,
      currency: rules.required,
      description: rules.limited,
    }
    if (this.isAccount) {
      result.tags = rules.accountTags
    } else {
      result.ticker = rules.required
    }
    return result
  }

  get currencySymbol(): string {
    return getCurrencySymbol(this.field<string>(ASSET_FIELD.CURRENCY).value)
  }

  get prices() {
    return this.priceList
  }

  set prices(values: AssetPrice[] | undefined) {
    this.priceList = values
  }

  get displayCategory() {
    this.prepareTags()
    return this.cachedData.Category
  }

  get displaySubcategory() {
    this.prepareTags()
    return this.cachedData.Subcategory
  }

  get categoryId() {
    this.prepareTags()
    return this.cachedData.CategoryId
  }

  get subcategoryId() {
    this.prepareTags()
    return this.cachedData.SubcategoryId
  }

  get tags() {
    this.prepareTags()
    return this.cachedData
  }

  get displayTags() {
    return Object.keys(this.tags).reduce((acc, key) => {
      if (key === 'key') return acc
      if (key.endsWith('Id') && this.tags[key].startsWith(TAG_ID_PREFIX))
        return acc
      if (acc) acc += ', '
      return acc + `${key}: ${this.tags[key]}`
    }, '')
  }

  private prepareTags() {
    if (!this.tagsBunchStore?.getList.size) return
    const tagIds = this.field<string[]>(ASSET_FIELD.TAGS).value
    const key = JSON.stringify(tagIds)
    if (tagIds && this.cachedData.key !== key) {
      this.cachedData = { key }
      tagIds.forEach(tagId => {
        const tag = this.tagsBunchStore?.getList.get(tagId)
        if (tag) {
          let tagName
          if (this.isAccount) {
            tagName = tag.field<string>(TAG_FIELD.TAG_NAME).value
            this.cachedData[tagName] = tag.field<string>(TAG_FIELD.TAG_VALUE)
          } else {
            tagName = tag.pair[0]
            this.cachedData[tagName] = tag.pair[1]
          }
          this.cachedData[`${tagName}Id`] = tagId
        }
      })
    }
  }

  resetTags() {
    this.cachedData.key = ''
    this.prepareTags()
  }

  private removeFromBunch() {
    this.bunchStore.getList.delete(this.id)
  }

  private removeFromForms() {
    this.modalsStore.getList.delete(this.id)
  }

  remove() {
    this.removeFromBunch()
    this.removeFromForms()
  }

  async store() {
    try {
      const data = this.get()
      const result = await this.assetsStore.createAsset(data, false)
      this.remove()
      if (result !== undefined) {
        this.reset(result)
        this.bunchStore.unshiftElement(this)
        this.callEvent(EntityEvent.STORED)
      }
    } catch (e) {
      throw Error(e as string)
    }
  }

  async update() {
    try {
      const result = await this.assetsStore.updateAsset(this.get(), false)
      if (result === undefined) {
        this.cancel()
      } else {
        this.reset(result)
        this.callEvent(EntityEvent.UPDATED)
      }
    } catch (e) {
      throw Error(e as string)
    }
  }

  async destroy() {
    try {
      if (!this.isNew) {
        await this.assetsStore.deleteAsset(this.id, false)
        this.callEvent(EntityEvent.DESTROYED)
      }
      this.remove()
    } catch (e) {
      throw Error(e as string)
    }
  }

  async assignTags(tags: string[]) {
    this.field(ASSET_FIELD.TAGS).value = tags
    await this.update()
  }
}
