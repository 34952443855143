export const getInputValue = (e: Event): string => {
  const target = e.target as HTMLInputElement
  return target.value
}

export const isNumber = (e: KeyboardEvent) => {
  const charCode = e.which ? e.which : e.keyCode
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
    e.preventDefault()
  }
}

export const padZero = (value: number | string | null) => {
  if (value === null) {
    return ''
  }

  return value.toString().padStart(2, '0').slice(-2)
}

export const getTextWidth = (
  text: string,
  fontSize: string,
  fontFamily: string,
): number => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  context!.font = `${fontSize} ${fontFamily}`
  const metrics = context!.measureText(text)
  return metrics.width
}
