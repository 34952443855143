<template>
  <component :is="icon" class="app-header-icon" aria-hidden="true" />
</template>

<script setup lang="ts">
import type { Component } from 'vue'

type Props = {
  icon: Component
}
defineProps<Props>()
</script>

<script lang="ts">
export default {
  name: 'AppHeaderIcon',
}
</script>

<style lang="postcss">
.app-header-icon {
  @apply w-7 h-7 sm:h-6 sm:w-6;
  @apply text-gray-400 hover:text-gray-500;
  @apply cursor-pointer;
}
</style>
