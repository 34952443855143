<template>
  <div v-if="isBlockVisible" class="ui-filter__logic">
    <div v-if="isWhereBlock" class="ui-filter__logic__where">
      {{ t('Where') }}
    </div>
    <div v-else-if="isValueBlock" class="ui-filter__logic__value">
      {{ modelValue }}
    </div>
    <UIInputDropdown
      v-else
      v-model="modelValue"
      v-bind="{ container, data, size }"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'

import { I18nTranslate } from '@types'
import { FilterLogic } from '../utils/enums'

import { UIInputDropdown } from '@ui'

type Props = {
  index: number
  paramsLength: number
}

const props = defineProps<Props>()

const modelValue = defineModel<FilterLogic>()

const container = inject<string>('container')
const size = inject<string>('size')
const t = inject<I18nTranslate>('t', (data: string) => data)

const data = computed(() =>
  Object.values(FilterLogic).map(value => ({ value })),
)

const isBlockVisible = computed(() => props.paramsLength > 1)
const isWhereBlock = computed(() => props.index === 0)
const isValueBlock = computed(() => props.index > 1)
</script>

<script lang="ts">
export default {
  name: 'FilterLogic',
}
</script>

<style scoped lang="postcss">
.ui-filter__logic {
  @apply w-16;
  @apply flex-shrink-0;

  &__where,
  &__value {
    @apply text-gray-950 dark:text-gray-200;
    @apply px-2;
    @apply text-sm;
    @apply text-center;
  }

  &__value {
    @apply text-gray-400 dark:text-gray-500;
  }
}
</style>
