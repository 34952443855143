import { Entity } from './common'
import { camelCase, uniqueId } from 'lodash'

import { EntityEvent, EntityState } from './enums'
import { EntityEventCallback, EntityEvents } from './types'

export const generateId = () => `${Date.now().toString()}_${uniqueId()}`

export const getCellValue = (
  fieldName: string,
  instance: Entity<any>,
  formatter?: (data: any) => any,
  handleArray = false,
) => {
  const key = camelCase(`display_${fieldName}`) as keyof Entity<any>
  let value = instance[key] || instance.field<string>(fieldName).value
  if (formatter) {
    value = formatter(value)
  }
  if (handleArray && Array.isArray(value)) {
    value = value.join(',')
  }
  return value
}

export const cancelGridChanges = <T extends { id: string }>(
  map: Map<string, Entity<T> | null>,
  sourceMap?: Map<string, Entity<T> | null>,
) => {
  for (let instance of map.values()) {
    if (instance?.isNew) {
      const id = instance.id
      instance = null
      if (sourceMap) {
        sourceMap.delete(id)
      } else {
        map.delete(id)
      }
    } else {
      instance?.cancel()
    }
  }
}

export const saveGridChanges = <T extends { id: string }>(
  map: Map<string, Entity<T>>,
  bulk?: { create?: boolean; update?: boolean; delete?: boolean },
): { promises: Promise<any>[]; bulk: Record<string, T[]> } => {
  const promises = []
  const bulkObject: Record<string, T[]> = { create: [], update: [], delete: [] }
  for (const instance of map.values()) {
    switch (instance.state) {
      case EntityState.NEW:
        if (bulk?.create) {
          bulkObject.create.push(instance.get())
        } else {
          promises.push(instance.store())
        }
        break
      case EntityState.CHANGED:
        if (bulk?.update) {
          bulkObject.update.push(instance.get())
        } else {
          promises.push(instance.update())
        }
        break
      case EntityState.DELETED:
        if (bulk?.delete) {
          bulkObject.delete.push(instance.get())
        } else {
          promises.push(instance.destroy())
        }
        break
      case EntityState.INVALID:
        if (instance.isNew) {
          instance.destroy()
        }
        break
    }
  }
  return { promises, bulk: bulkObject }
}

export const markGridItemsAsDeleted = <T extends { id: string }>(
  map: Map<string, Entity<T>>,
  ids: string[],
) => {
  ids.forEach(id => {
    const instance = map.get(id)
    instance?.markDelete()
  })
}

export const callEvent = (
  events: EntityEvents,
  name: EntityEvent,
  data?: any,
) => {
  if (!events[name]?.length) return
  const newEventsArray: EntityEventCallback[] = []
  events[name]?.forEach(event => {
    if (event(data)) {
      newEventsArray.push(event)
    }
  })
  events[name] = newEventsArray
}

export const addEventListener = (
  events: EntityEvents,
  name: EntityEvent,
  callback: EntityEventCallback,
) => {
  if (events[name] && Array.isArray(events[name])) {
    events[name]?.push(callback)
  } else {
    events[name] = [callback]
  }
  return events
}

export const removeEventListener = (
  events: EntityEvents,
  name: EntityEvent,
  callback: EntityEventCallback,
) => {
  const eventIndex = events[name]?.findIndex(value => value === callback)
  if (eventIndex === undefined || eventIndex === -1) return events
  events[name]?.splice(eventIndex, 1)
  return events
}
