<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UISlidePanel
      v-bind="{ zIndex, isMinimized }"
      :title="name"
      size="lg"
      @hide="handleClose"
      @minimize="handleMinimize"
    >
      <template #title>
        <AccountConnectorTitle v-bind="{ type, name }" />
      </template>
      <AccountConnectorInfo
        v-bind="{ account, connector }"
        :loading="isLoading"
      />
      <UITabs v-model="tab" v-bind="{ tabs }" bottom-border />
      <div
        v-if="isInitTab.Transactions"
        v-show="tab === TABS.TRANSACTIONS"
        class="account-connector"
      >
        <AccountConnectorTransactions v-bind="{ assetId }" />
      </div>
      <div
        v-if="isInitTab.Positions"
        v-show="tab === TABS.POSITIONS"
        class="account-connector"
      >
        <AccountConnectorPositions v-bind="{ linkedAccountId }" />
      </div>
    </UISlidePanel>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, reactive, ref, watch } from 'vue'
import { TransitionRoot } from '@headlessui/vue'

import { LinkedDataAccount, ModalClass, ModalState } from '@types'
import { AssetClass } from '..'

import { ASSET_FIELD } from '../utils/const'

import { useModalsStore } from '@/store/modals'
import { useLinkedDataStore } from '@/store/linkedData'
import { useLinkedDataConnectorsStore } from '@/store/linkedData/connectors'

import { UISlidePanel, UITabs } from '@ui'
import AccountConnectorInfo from './AccountConnectorInfo.vue'
import AccountConnectorPositions from './AccountConnectorPositions.vue'
import AccountConnectorTitle from './AccountConnectorTitle.vue'
import AccountConnectorTransactions from './AccountConnectorTransactions.vue'

enum TABS {
  TRANSACTIONS = 'Transactions',
  POSITIONS = 'Positions',
}

type Props = {
  modal: ModalClass<AssetClass>
  instance: AssetClass
  assetId?: string
}

const props = defineProps<Props>()

const modalsStore = useModalsStore()
const linkedDataStore = useLinkedDataStore()
const linkedDataConnectorsStore = useLinkedDataConnectorsStore()

const type = props.instance.field<string>(ASSET_FIELD.TYPE)
const name = props.instance.field<string>(ASSET_FIELD.NAME)

const isLoading = ref(false)
const account = ref<LinkedDataAccount>()

const tab = ref<TABS>(TABS.TRANSACTIONS)

const isInitTab = reactive<{ [key in TABS]: boolean }>({
  [TABS.TRANSACTIONS]: false,
  [TABS.POSITIONS]: false,
})

const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)
const isMinimized = computed(() => props.modal.state === ModalState.HIDDEN)
const zIndex = computed(() => props.modal.zIndex)

const linkedAccountId = computed(
  () =>
    props.instance.field<string | null>(ASSET_FIELD.LINKED_ACCOUNT_ID).value,
)

const tabs = computed(() =>
  Object.values(TABS).map(name => ({
    name,
  })),
)

const connector = computed(() =>
  linkedDataConnectorsStore.getList.find(
    connector => connector.id === account.value?.connector_id,
  ),
)

const handleClose = () => {
  props.modal.close()
}

const handleMinimize = (flag: boolean) => {
  if (flag) {
    props.modal.hide()
  } else {
    props.modal.show(modalsStore.getZIndex())
  }
}

watch(
  tab,
  value => {
    isInitTab[value] = true
  },
  { immediate: true },
)

onBeforeMount(async () => {
  if (!linkedDataConnectorsStore.initFlag) {
    linkedDataConnectorsStore.fetch()
  }
  if (!linkedAccountId.value) return
  isLoading.value = true
  account.value = await linkedDataStore.getAccount(linkedAccountId.value)
  isLoading.value = false
})
</script>

<script lang="ts">
export default {
  name: 'AccountConnector',
}
</script>

<style scoped lang="postcss">
.account-connector {
  @apply min-h-80;
  @apply mt-3;
  @apply lg:px-2;
  @apply flex flex-col;
  @apply flex-auto;
}
</style>
