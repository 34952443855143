<template>
  <UIPanel
    v-model="clearedSearch"
    v-model:storage="searchStorage"
    placeholder="Search transactions..."
  >
    <template #default="{ ButtonNew }">
      <UIDropdown
        v-if="transactionTypes"
        v-model="transactionType"
        :items="transactionTypes"
        placement="bottom-end"
      >
        <component :is="ButtonNew" />
      </UIDropdown>
    </template>
  </UIPanel>
  <UIGridSkeleton
    v-if="isLoading"
    v-bind="{ collapseWidth, columns, sm }"
    wrapped
    message="Loading transactions..."
  />
  <UIGrid
    v-else
    v-bind="{ collapseWidth, columns, items, sm }"
    v-model:sort="sort"
    scrollable
    wrapped
  >
    <template #actions="{ item, size }">
      <UIButton
        v-if="item"
        v-bind="{ size }"
        :label="editButtonLabel"
        :icon="editButtonIcon"
        variant="light-secondary"
        @click="handleClickEditTransaction(item)"
      />
    </template>
    <template #cellType="{ item }">
      <TransactionCompact :item="item as Transaction" />
    </template>
  </UIGrid>
  <UIPagination
    v-bind="{ disabled, pageNo }"
    :page-size="pagination?.page_size"
    :total="pagination?.total"
    @click:page="handleClickPage"
  />
</template>

<script setup lang="ts">
import { computed, inject, onMounted, ref, watch } from 'vue'
import { isEqual } from 'lodash'
import { debouncedWatch } from '@vueuse/core'

import type {
  Transaction,
  TransactionEntry,
  PaginatedMeta,
  Sort,
  ReadonlyMode,
} from '@types'
import { TransactionClass } from '@/entities/transactions'

import {
  ASSET_TRANSACTIONS_PAGE_SIZE,
  DEBOUNCE_DELAY,
  READONLY_MODE,
} from '@/const/common'
import { INVESTMENT_TYPES, TRANSACTION_TYPES } from './utils/const'
import { ASSET_FIELD } from '../utils/const'

import { handleCatchedError } from '@/helpers/common'
import { getAccountCurrency } from './utils/helpers'

import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useModalsStore } from '@/store/modals'
import { useTransactionsStore } from '@/store/transactions'
import { useTransactionsSettingsStore } from '@/store/transactions/settings'
import { useTransactionsBunchStore } from '@/store/transactions/bunch'

import {
  UIButton,
  UIDropdown,
  UIGrid,
  UIGridSkeleton,
  UIPagination,
  UIPanel,
} from '@ui'
import { EyeIcon, PencilIcon } from '@heroicons/vue/24/outline'
import TransactionCompact from '@/views/Data/Transactions/components/TransactionCompact.vue'

type Props = {
  assetId?: string
  type?: string
  data: Partial<TransactionEntry>
}

const props = defineProps<Props>()

const assetsBunchStore = useAssetsBunchStore()
const modalsStore = useModalsStore()
const transactionsStore = useTransactionsStore()
const transactionsBunchStore = useTransactionsBunchStore()
const transactionsSettingsStore = useTransactionsSettingsStore()

const items = ref<Transaction[]>([])
const pagination = ref<PaginatedMeta>()

const searchStorage = ref<string>()
const clearedSearch = ref<string>()
const sort = ref<Sort[]>()
const pageNo = ref(0)

const isLoading = ref(false)

const transactionType = ref<string>()

const isReadonly = inject<ReadonlyMode>(READONLY_MODE)

const editButtonLabel = computed(() =>
  isReadonly?.value ? 'View transaction' : 'Edit transaction',
)
const editButtonIcon = computed(() =>
  isReadonly?.value ? EyeIcon : PencilIcon,
)
const disabled = computed(() => isLoading.value)

const transactionTypes = computed<string[]>(() => {
  const key = props.type as keyof typeof TRANSACTION_TYPES
  return TRANSACTION_TYPES[key]
})

const collapseWidth = 400
const sm = '1fr'
const columns = computed(() => [
  {
    name: 'type',
    caption: 'Action',
    cellClasses: 'ui-grid__cell--span-2',
  },
  {
    name: 'description',
    caption: 'Description',
    cellClasses: 'ui-grid__cell--span-2',
    default: '10rem',
  },
])

const fetchOptions = computed(() => ({
  search: clearedSearch.value,
  sort: sort.value?.map((item, index) => ({ ...item, index })),
  asset_id: props.assetId,
}))

const fetch = async () => {
  try {
    const response = await transactionsStore.fetch(
      {
        ...fetchOptions.value,
        page_no: pageNo.value,
        page_size: ASSET_TRANSACTIONS_PAGE_SIZE,
      },
      false,
    )
    items.value = response.data
    pagination.value = response.meta
  } catch (e) {
    handleCatchedError(e as string, fetchOptions.value)
  }
}

const handleClickPage = (page: number) => {
  pageNo.value = page
}

const handleClickEditTransaction = (transaction: Transaction) => {
  let instance = transactionsBunchStore.getElementById(transaction.id)
  if (!instance) {
    instance = new TransactionClass(transaction as any)
  }
  const modalInstance = modalsStore.init(instance.id, instance)
  modalInstance?.open(modalsStore.getZIndex())
}

watch(transactionType, type => {
  if (!type || !props.assetId) return
  const instance = transactionsBunchStore.createElement()
  const account1 = props.data.account_id || ''
  let amount1 = props.data.amount || null
  let asset1 = props.data.asset_id || ''
  if (type === 'sell' && amount1) {
    amount1 = amount1 > 0 ? -amount1 : amount1
  }
  let account2 = ''
  const asset2 = getAccountCurrency(account1, assetsBunchStore)
  if (INVESTMENT_TYPES.includes(type)) {
    account2 = asset1
    asset1 = ''
  }
  const entries = [
    {
      account_id: account1,
      amount: amount1,
      asset_id: asset1,
    },
    {
      account_id: account2,
      amount: null,
      asset_id: asset2,
    },
  ]
  instance.set({
    type,
    entries,
  })

  const account = assetsBunchStore.getElementById(account1)
  const accountName = account?.field(ASSET_FIELD.NAME).value

  const asset = assetsBunchStore.getElementById(props.data.asset_id || '')
  const assetName = asset?.field(ASSET_FIELD.NAME).value

  const title = `${type} for: ${accountName}${
    amount1 ? ` ${amount1} ${assetName}` : ''
  }`
  const modalInstance = modalsStore.init(instance.id, instance)
  modalInstance?.open(modalsStore.getZIndex(), {
    title,
    shortened: true,
    restrictions: transactionTypes.value,
  })
})

watch(pageNo, fetch)

debouncedWatch(
  fetchOptions,
  (value, prev) => {
    if (isEqual(value, prev)) return
    fetch()
  },
  {
    deep: true,
    debounce: DEBOUNCE_DELAY,
  },
)

onMounted(() => {
  fetch()
  if (!transactionsSettingsStore.initFlag) {
    transactionsSettingsStore.fetch()
  }
})
</script>

<script lang="ts">
export default {
  name: 'AssetSummaryTransactions',
}
</script>
