<template>
  <div class="app-header" :class="appHeaderClasses">
    <div class="app-header__menu">
      <HeaderBurger v-model="mobileMenu" class="app-header__menu-burger" />
      <slot />
    </div>
    <div class="app-header__actions">
      <slot name="actions" />
      <UIButton
        v-if="!userEmail"
        label="Sign in"
        variant="light"
        :to="{ name: ROUTE_NAME.SIGNIN }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { ROUTE_NAME } from '@/const'

import HeaderBurger from './HeaderBurger.vue'

import { UIButton } from '@ui'

type Props = {
  userEmail?: string
  sticky?: boolean
}

const props = defineProps<Props>()

const mobileMenu = defineModel<boolean>('mobileMenu', { default: false })

const appHeaderClasses = computed(() => ({
  'app-header--sticky': props.sticky,
}))
</script>

<script lang="ts">
export default {
  name: 'AppHeader',
}
</script>

<style lang="postcss" scoped>
.app-header {
  @apply w-full h-12 z-20 relative;
  @apply flex shrink-0 justify-between;

  &--sticky {
    @apply sticky top-0;
  }

  &__menu {
    @apply flex items-center;
    @apply sm:overflow-hidden;
  }

  &__menu-burger {
    @apply sm:hidden;
  }

  &__actions {
    @apply flex items-center;
    @apply pr-4 md:pr-6;
    @apply gap-x-4;
  }
}
</style>
