<template>
  <TransactionAssetField
    ref="fieldRef"
    v-model="modelValue"
    v-bind="{
      ...$attrs,
      data,
      error,
      helpDescription,
      settings,
    }"
    @create="handleCreate"
  />
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'
import { set } from 'lodash'

import { TransactionClass } from '..'

import { getAssetListValues, onCreateAsset, preSetAsset } from './utils/helpers'

import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useRepositoriesStore } from '@/store/repositories'
import { useModalsStore } from '@/store/modals'

import TransactionAssetField from './TransactionAssetField.vue'

type Props = {
  entryIndex: number
  instance: TransactionClass
}

const props = defineProps<Props>()

defineExpose({
  focus() {
    fieldRef.value?.focus()
  },
})

const fieldRef = ref<typeof TransactionAssetField>()

const assetsBunchStore = useAssetsBunchStore()
const repositoriesStore = useRepositoriesStore()
const modalsStore = useModalsStore()

const modelValue = computed({
  get() {
    return props.instance.entries[props.entryIndex]?.asset_id || undefined
  },
  set(value) {
    set(
      props.instance.data,
      ['entries', props.entryIndex, 'asset_id'],
      value || null,
    )
    props.instance.validateData()
  },
})

const entrySettings = computed(
  () => props.instance.settings?.entries[props.entryIndex],
)
const settings = computed(() => entrySettings.value?.asset)

const error = computed(
  () => props.instance.errors[`entries[${props.entryIndex}].asset_id`],
)

const helpDescription = computed(() => entrySettings?.value?.assetDescription)

const assetsList = computed(() =>
  Array.from(assetsBunchStore.getList.values()).map(instance => instance.get()),
)

const currencyID = computed(() => {
  const asset =
    assetsBunchStore.getElementByName(
      repositoriesStore.getCurrentRepositoryCurrency,
    ) ||
    assetsBunchStore.getElementByTicker(
      repositoriesStore.getCurrentRepositoryCurrency,
    )
  return asset?.id
})

const data = computed(() =>
  getAssetListValues(
    settings.value,
    assetsList.value,
    props.instance.entries[props.entryIndex]?.account_id,
  ),
)

const handleCreate = (value: string) => {
  const instance = assetsBunchStore.createElement()
  onCreateAsset(
    value,
    settings.value?.includes,
    instance,
    modalsStore,
    (data: string) => {
      modelValue.value = data
    },
  )
}

onBeforeMount(() => {
  if (modelValue.value) return
  modelValue.value =
    preSetAsset(
      modelValue.value,
      settings.value,
      assetsBunchStore,
      currencyID.value,
    ) || ''
})
</script>

<script lang="ts">
export default {
  name: 'TransactionFormAsset',
}
</script>
