<template>
  <div class="account-connector-title">
    <div class="account-connector-title__name">{{ name }}</div>
    <div v-if="type" class="account-connector-title__type">{{ type }}</div>
  </div>
</template>

<script setup lang="ts">
type Props = {
  name: string
  ticker?: string
  type?: string
}
defineProps<Props>()
</script>

<script lang="ts">
export default {
  name: 'AccountConnectorTitle',
}
</script>

<style lang="postcss">
.account-connector-title {
  @apply mr-4 space-y-2;

  &__name {
    @apply text-xl leading-none;
    @apply text-gray-500 dark:text-gray-400;
  }

  &__type {
    @apply text-xs leading-none;
    @apply text-gray-400 dark:text-gray-500;
  }
}
</style>
