<template>
  <UIDropdown
    v-bind="{ items }"
    :disabled="dropdownDisabled"
    class="ui-button-dropdown"
    @click:item="handleClickItem"
  >
    <template #default="{ isShown }">
      <UIButton
        v-bind="{ ...$attrs, disabled, size, variant }"
        class="ui-button-dropdown__btn"
        :class="buttonClasses"
        @click="handleClick"
      />
      <div
        class="ui-button-dropdown__toggler"
        :class="chevronClasses"
        @click.stop=""
      >
        <component
          :is="getChevronComponent(isShown)"
          class="ui-button-dropdown__chevron"
          aria-hidden="true"
        />
      </div>
    </template>
  </UIDropdown>
</template>

<script setup lang="ts" generic="T">
import { computed } from 'vue'

import { DropdownItem } from '@types'

import { ButtonSizes, ButtonVariant } from './utils/types'

import { UIButton, UIDropdown } from '@ui'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/outline'

type Props = {
  items?: DropdownItem<T>[]

  size?: ButtonSizes
  variant?: ButtonVariant

  disabled?: boolean
}

type Emits = {
  'click:main': [event?: MouseEvent]
  'click:item': [data: DropdownItem<T>, event?: MouseEvent]
}
const emit = defineEmits<Emits>()

const { size = 'default', variant = 'primary', ...props } = defineProps<Props>()

const chevronClasses = computed(() => ({
  [`ui-button-dropdown__toggler--${size} ui-button-dropdown__toggler--${variant}`]:
    true,
  'ui-button-dropdown__toggler--disabled': dropdownDisabled.value,
}))

const buttonClasses = computed(() => ({
  [`ui-button-dropdown__btn--${size}`]: true,
}))

const dropdownDisabled = computed(() => !props.items?.length)

const getChevronComponent = (isShown?: boolean) => {
  return isShown ? ChevronUpIcon : ChevronDownIcon
}

const handleClick = (event?: MouseEvent) => {
  emit('click:main', event)
}

const handleClickItem = (item: DropdownItem<T>, event: MouseEvent) => {
  emit('click:item', item, event)
}
</script>

<script lang="ts">
export default {
  name: 'UIButtonDropdown',
}
</script>

<style scoped lang="postcss">
.ui-button-dropdown {
  @apply flex;

  &__btn {
    @apply rounded-r-none;

    &--xxsmall,
    &--xsmall {
      @apply pr-1.5;
    }
    &--small {
      @apply pr-1;
    }
    &--default,
    &--large {
      @apply pr-2;
    }
    &--xlarge {
      @apply pr-3;
    }
  }

  &__toggler {
    @apply flex items-center justify-center;
    @apply px-0.5;
    @apply cursor-pointer;

    &--disabled {
      @apply opacity-50;
      @apply cursor-default;
    }

    &--xxsmall,
    &--xsmall {
      @apply rounded-r;
    }
    &--small,
    &--default,
    &--large,
    &--xlarge {
      @apply rounded-r-md;
    }

    &--primary {
      @apply text-white dark:text-gray-200;
      @apply bg-indigo-600 hover:bg-indigo-700;
      @apply dark:bg-indigo-500 dark:hover:bg-indigo-600;
      @apply focus:ring-2 focus:ring-offset-2;
      @apply focus:ring-indigo-500 dark:focus:ring-indigo-400;
    }
    &--secondary {
      @apply text-indigo-700 bg-indigo-100 hover:bg-indigo-200;
      @appply dark:bg-indigo-800 dark:hover:bg-indigo-700;
      @apply focus:ring-2 focus:ring-offset-2;
      @apply focus:ring-indigo-500 dark:focus:ring-indigo-400;
    }
    &--light-secondary {
      @apply text-indigo-700 dark:text-indigo-300;
      @apply hover:bg-indigo-50 focus:bg-indigo-100;
      @apply dark:hover:bg-indigo-900 dark:focus:bg-indigo-800;
    }
    &--light {
      @apply border border-l-0 border-gray-300 dark:border-gray-700;
      @apply text-gray-700 dark:text-gray-300;
      @apply bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700;
      @apply focus:ring-2 focus:ring-offset-2;
      @apply focus:ring-gray-500 dark:focus:ring-gray-400;
    }
    &--light-gray {
      @apply text-gray-500 dark:text-gray-400;
      @apply hover:bg-gray-100 focus:bg-gray-200;
      @apply dark:hover:bg-gray-700 dark:focus:bg-gray-600;
    }
    &--dark {
      @apply text-white dark:text-gray-200;
      @apply bg-gray-800 hover:bg-gray-900;
      @apply dark:bg-gray-700 dark:hover:bg-gray-600;
      @apply focus:ring-2 focus:ring-offset-2;
      @apply focus:ring-gray-700 dark:focus:ring-gray-600;
    }
    &--red {
      @apply text-white dark:text-gray-200;
      @apply bg-red-600 hover:bg-red-700;
      @apply dark:bg-red-700 dark:hover:bg-red-800;
      @apply focus:ring-2 focus:ring-offset-2;
      @apply focus:ring-red-500 dark:focus:ring-red-400;
    }
    &--light-red {
      @apply text-red-500 dark:text-red-400;
      @apply hover:bg-red-50 focus:bg-red-100;
      @apply dark:hover:bg-red-900 dark:focus:bg-red-800;
    }

    &--simple {
      @apply text-gray-500 hover:text-gray-600;
      @apply dark:text-gray-400 dark:hover:text-gray-300;
    }
  }

  &__chevron {
    @apply w-4 h-4;
  }
}
</style>
