import { defineStore } from 'pinia'

import { UploadFileParams, UploadFileResponse } from './utils/types'

import { prepareResponseError } from '../utils/helpers'

import api from '@/store/api'
import { useNotifications } from '@/plugins/notification'

export const useFilesStore = defineStore('files', () => {
  const { error } = useNotifications()

  const fetchUploadURL = async (
    data: UploadFileParams,
  ): Promise<UploadFileResponse> => {
    try {
      const result = await api.post('file_upload', data)
      if (!result.data) {
        await error({
          message: 'Something is wrong during the file upload',
        })
      }
      return result.data
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
  }

  const uploadFile = async ({
    url,
    payload,
  }: {
    url: string
    payload: FormData
  }): Promise<boolean> => {
    try {
      await api.post(url, payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      return true
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
  }

  return {
    fetchUploadURL,
    uploadFile,
  }
})
