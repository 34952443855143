import { FilterComparison } from './enums'
import { FilterGroup, FilterParam } from './types'

import { generateRandomKey } from '../components/utils/helpers'

export const isActiveParam = (param: FilterParam) => {
  const { comparison, value } = param
  return (
    !!value ||
    (comparison &&
      [FilterComparison.EMPTY, FilterComparison.NOTEMPTY].includes(comparison))
  )
}

export const isActiveGroup = (group: FilterGroup) => !!group.params.length

export const normalizeOldData = (data: any) => {
  if (!data) return
  if (!Array.isArray(data.params)) {
    return {
      logic: data.logic,
      params: [
        {
          key: generateRandomKey(),
          logic: data.logic,
          params: [
            {
              ...data.params,
              key: generateRandomKey(),
            },
          ],
        },
      ],
    }
  } else if (data.params?.[0]?.field) {
    return {
      logic: data.logic,
      params: (data.params as any[]).map(param => ({
        key: generateRandomKey(),
        logic: data.logic,
        params: [
          {
            ...param,
            key: generateRandomKey(),
          },
        ],
      })),
    }
  } else {
    return data
  }
}
