<template>
  <Teleport to="body">
    <ListTagAssign
      v-bind="{ items }"
      ref="tagAssignRef"
      @process="processTransactions"
    />
    <ListTagRemove
      v-bind="{ items }"
      ref="tagRemoveRef"
      @process="processTransactions"
    />
  </Teleport>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { LinkedDataTransaction, LinkedDataTransactionUpdate } from '@types'

import { NOTIFICATION_DELAY } from '@/const/common'

import { handleCatchedError } from '@/helpers/common'
import { updateStoreListItem } from '@/store/utils/helpers'

import { useNotifications } from '@/plugins/notification'

import { useLinkedDataTransactionsStore } from '@/store/linkedData/transactions'

import ListTags from './ListTags.vue'
import ListTagAssign from './ListTagAssign.vue'
import ListTagRemove from './ListTagRemove.vue'

type Props = {
  isStoreUsed?: boolean
}

const props = defineProps<Props>()

defineExpose({
  assign(ids: string[]) {
    tagAssignRef.value?.init(ids).toggle(true)
  },
  remove(ids: string[]) {
    tagRemoveRef.value?.init(ids).toggle(true)
  },
  exclude(transaction: LinkedDataTransaction) {
    listTagsRef.value?.exclude(transaction)
  },
  async process(data: LinkedDataTransactionUpdate[], callback?: () => void) {
    await processTransactions(data)
    callback?.()
  },
})

const items = defineModel<LinkedDataTransaction[]>({ required: true })

const { progress, remove, update } = useNotifications()

const linkedDataTransactionsStore = useLinkedDataTransactionsStore()

const tagAssignRef = ref<typeof ListTagAssign>()
const tagRemoveRef = ref<typeof ListTagRemove>()
const listTagsRef = ref<typeof ListTags>()

const processTransactions = async (
  data: LinkedDataTransactionUpdate[],
  callback?: () => void,
) => {
  const nid = await progress({
    message: 'Process transactions',
  })
  try {
    const result = await linkedDataTransactionsStore.bulkUpdate(data)
    if (nid) {
      await update(
        nid,
        {
          type: 'success',
          message: 'Transactions processed',
        },
        NOTIFICATION_DELAY,
      )
    }
    if (!props.isStoreUsed) {
      result.forEach(value => {
        if (!items.value) return
        updateStoreListItem(items.value, value)
      })
    }
  } catch (e) {
    if (nid) await remove(nid)
    handleCatchedError(e as string)
  } finally {
    callback && callback()
  }
}
</script>

<script lang="ts">
export default {
  name: 'TransactionsListBulk',
}
</script>

<style lang="postcss"></style>
