<template>
  <UILabeledField ref="inputRef" v-model="modelValue" v-bind="{ size }" />
</template>

<script setup lang="ts">
import { inject, ref } from 'vue'

import { ButtonSizes } from '@types'
import { FilterComparison } from '../utils/enums'
import { FilterField } from '../utils/types'

import { UILabeledField } from '@ui'

type Props = {
  fieldSettings?: FilterField
  comparison?: FilterComparison
}

defineProps<Props>()

const modelValue = defineModel<any>()

defineExpose({
  start() {
    inputRef.value?.select()
  },
})

const size = inject<ButtonSizes>('size')

const inputRef = ref<typeof UILabeledField | null>(null)
</script>

<script lang="ts">
export default {
  name: 'FilterGroupsItemParamValueInput',
}
</script>

<style scoped lang="postcss"></style>
