<template>
  <UIModal
    v-bind="{ isOpen }"
    :type="ModalType.DIALOG"
    title="Connect"
    size="md"
    @hide="handleClose"
  >
    <template v-if="isExistingConnectorsVisible">
      <div class="add-account__caption">Use existing connector:</div>
      <div class="add-account__list">
        <a
          v-for="stranger in strangers"
          :key="stranger.id"
          class="add-account__item"
          @click="handleClickExisting(stranger)"
        >
          <div class="add-account__item-text">
            {{ stranger.name }}
          </div>
        </a>
      </div>
      <div class="add-account__caption">or new:</div>
    </template>
    <div class="add-account__list">
      <a
        v-for="account in accountsList"
        :key="account.name"
        class="add-account__item"
        @click="handleClickAccount(account)"
      >
        <img :src="account.logo" :alt="account.title" :title="account.title" />
      </a>
    </div>
    <div class="add-account__buttons">
      <UIButton
        v-for="button in buttonsList"
        :key="button.label"
        :label="button.label"
        full
        variant="secondary"
        @click="button.action()"
      />
    </div>
  </UIModal>
  <AddAccountArchlab ref="archlabRef" @cancel="handleCancel" />
  <AddAccountAkoya ref="akoyaRef" @cancel="handleCancel" />
  <AddAccountJPM ref="jpmRef" @cancel="handleCancel" />
  <AddAccountOFX ref="ofxRef" @cancel="handleCancel" />
  <AddAccountYodlee ref="yodleeRef" />
</template>

<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import { LinkedDataConnector, LinkedDataManualLoader, ModalType } from '@types'
import { LinkedDataConnectors } from '../../utils/enums'
import { ConnectorAccount } from '../../utils/types'

import { ACCOUNTS_LIST } from '../../utils/const'
import { ROUTE_NAME } from '@/const'
import { IS_PROD_MODE } from '@/const/common'

import { handleCatchedError } from '@/helpers/common'

import { useNotifications } from '@/plugins/notification'
import { useLinkedDataConnector } from '../../hooks/useLinkedDataConnector'

import { useLinkedDataConnectorsStore } from '@/store/linkedData/connectors'
import { useLinkedDataStore } from '@/store/linkedData'

import { UIButton, UIModal } from '@ui'
import AddAccountAkoya from './AddAccountAkoya.vue'
import AddAccountArchlab from './AddAccountArchlab.vue'
import AddAccountJPM from './AddAccountJPM.vue'
import AddAccountOFX from './AddAccountOFX.vue'
import AddAccountYodlee from './AddAccountYodlee.vue'

defineExpose({
  show() {
    isOpen.value = true
  },
  add(accessMethod?: string) {
    switch (accessMethod) {
      case 'manual':
        isOpen.value = false
        ofxRef.value?.show()
        break
      case 'yodlee':
        handleYodlee()
        break
      default:
        isOpen.value = true
        break
    }
  },
  reconnect(connector: LinkedDataConnector) {
    handleReconnect(connector)
  },
  import(data: string) {
    switch (data as LinkedDataManualLoader) {
      case LinkedDataManualLoader.ARCHLAB:
        archlabRef.value?.show(true)
        break
      case LinkedDataManualLoader.JPM_CIB:
        jpmRef.value?.show(true)
        break
      case LinkedDataManualLoader.OFX:
        ofxRef.value?.show(true)
        break
    }
  },
  async callback(state: string, code: string) {
    const [providerName, connectorName] = state.split(',')
    if (providerName === undefined || !connectorsList.includes(providerName))
      return
    try {
      await linkedDataStore.callback(code, providerName, connectorName)
    } catch (e) {
      handleCatchedError(e as string)
    }
    router.push({ name: ROUTE_NAME.LINKED_DATA })
  },
})

const connectorsList = Object.values(LinkedDataConnectors).map(item =>
  item.toLowerCase(),
)

const linkedDataStore = useLinkedDataStore()
const linkedDataConnectorsStore = useLinkedDataConnectorsStore()

const router = useRouter()
const { error } = useNotifications()

const { plaid: handlePlaid, yodlee: handleYodlee } = useLinkedDataConnector(
  (flag: boolean) => {
    yodleeRef.value?.toggle(flag)
  },
  (flag: boolean) => {
    isOpen.value = flag
  },
)

const isProdMode = inject(IS_PROD_MODE)

const loading = ref(false)

const archlabRef = ref<typeof AddAccountArchlab>()
const akoyaRef = ref<typeof AddAccountAkoya>()
const jpmRef = ref<typeof AddAccountJPM>()
const ofxRef = ref<typeof AddAccountOFX>()
const yodleeRef = ref<typeof AddAccountYodlee>()

const isOpen = ref(false)

const strangers = ref<LinkedDataConnector[]>()

const isExistingConnectorsVisible = computed(() => strangers.value?.length)

const accountsList = computed(() => {
  if (!isProdMode) {
    return ACCOUNTS_LIST
  }

  return ACCOUNTS_LIST.filter(item => !item.devOnly)
})

const buttonsList = computed(() => {
  const list = [
    {
      label: `Use ${LinkedDataConnectors.PLAID} connector`,
      action: handlePlaid,
    },
    {
      label: `Use ${LinkedDataConnectors.YODLEE} connector`,
      action: handleYodlee,
    },
    {
      label: `Use ${LinkedDataConnectors.OFX} connector`,
      action: () => {
        isOpen.value = false
        ofxRef.value?.show()
      },
    },
  ]
  if (!isProdMode) {
    list.push(
      {
        label: `Use ${LinkedDataConnectors.JPM_CIB} connector`,
        action: () => {
          isOpen.value = false
          jpmRef.value?.show()
        },
      },
      {
        label: `Use ${LinkedDataConnectors.ARCHLAB} connector`,
        action: () => {
          isOpen.value = false
          archlabRef.value?.show()
        },
      },
    )
  }
  return list
})

const handleClose = () => {
  isOpen.value = false
}

const handleCancel = () => {
  isOpen.value = true
}

const handleClickExisting = async (connector: LinkedDataConnector) => {
  isOpen.value = false
  loading.value = true
  try {
    await linkedDataConnectorsStore.map(connector.id)
    await Promise.all([
      await linkedDataStore.fetch(),
      await linkedDataConnectorsStore.fetch(),
    ])
  } catch (e) {
    handleCatchedError(e as string)
    error({
      message: `Error while mapping Existing connector "${connector.name}"`,
    })
    return
  } finally {
    loading.value = false
  }
}

const handleClickAccount = async (account: ConnectorAccount) => {
  isOpen.value = false
  loading.value = true

  let connector: LinkedDataConnectors | undefined = undefined
  const params: Record<string, string> = {}

  switch (account.provider) {
    case 'akoya':
      connector = LinkedDataConnectors.AKOYA
      params.connector = account.name
      break
    case 'coinbase':
      connector = LinkedDataConnectors.COINBASE
      break
    default:
      break
  }

  if (!connector) {
    error({
      message: 'Connector not available',
    })
    loading.value = false
    return
  }

  const url = await linkedDataConnectorsStore.getUrl(connector, params)
  if (!url) {
    error({
      message: `${
        account.provider.charAt(0).toUpperCase() + account.provider.slice(1)
      } URL not available`,
    })
    loading.value = false
    return
  }

  loading.value = false

  if (connector === LinkedDataConnectors.AKOYA) {
    akoyaRef.value?.show(account, url)
    return
  }

  location.href = url
}

const handleReconnect = (connector: LinkedDataConnector) => {
  switch (connector.access_method) {
    case 'plaid':
      handlePlaid(connector.id)
      break
    case 'yodlee':
      handleYodlee()
      break
    default: {
      const account = accountsList.value.find(
        (item: ConnectorAccount) =>
          item.name === connector.source.toLowerCase(),
      )
      if (account) {
        handleClickAccount(account)
      } else {
        error({
          message: 'Connector not available. Please delete and add again',
        })
      }
    }
  }
}

watch(isOpen, async value => {
  if (!value) return
  try {
    const data = await linkedDataConnectorsStore.fetchStrangers()
    strangers.value = data
  } catch (e) {
    handleCatchedError(e as string)
    return
  }
})
</script>

<script lang="ts">
export default {
  name: 'ConnectorsAddAccount',
}
</script>

<style lang="postcss">
.add-account {
  &__caption {
    @apply mb-2 py-1;
    @apply rounded;
    @apply font-medium text-xs;
    @apply text-center;
    @apply bg-indigo-50 dark:bg-indigo-900;
    @apply text-indigo-600 dark:text-indigo-400;
  }

  &__list {
    @apply mb-4;
    @apply grid gap-4 sm:grid-cols-3;
  }

  &__item {
    @apply h-16 py-1 px-2;
    @apply relative;
    @apply flex items-center justify-center;
    @apply border hover:border-2;
    @apply bg-white;
    @apply border-gray-200 dark:border-gray-700;
    @apply overflow-hidden rounded cursor-pointer;

    img {
      @apply w-full;
      @apply max-w-[9rem] max-h-[5rem];
    }
  }

  &__item-text {
    @apply font-medium leading-tight;
    @apply text-gray-600;
    @apply text-center;
    @apply line-clamp-3;
  }

  &__buttons {
    @apply space-y-4;
  }
}
</style>
