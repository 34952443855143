<template>
  <div class="header-sidebar-toggler">
    <component
      :is="sidebarTogglerComponent"
      aria-hidden="true"
      class="header-sidebar-toggler__icon"
      @click="handleToggleSidebar"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useSidebarStore } from '@/store/sidebar'

import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/vue/20/solid'

type Props = {
  reverse?: boolean
}

const props = defineProps<Props>()

const sidebarStore = useSidebarStore()

const sidebarOpen = computed<boolean>({
  get() {
    return sidebarStore.open
  },
  set(value) {
    sidebarStore.open = value
  },
})

const openedIcon = computed(() =>
  props.reverse ? ChevronDoubleRightIcon : ChevronDoubleLeftIcon,
)

const closedIcon = computed(() =>
  props.reverse ? ChevronDoubleLeftIcon : ChevronDoubleRightIcon,
)

const sidebarTogglerComponent = computed(() =>
  sidebarOpen.value ? openedIcon.value : closedIcon.value,
)

const handleToggleSidebar = () => {
  sidebarOpen.value = !sidebarOpen.value
}
</script>

<script lang="ts">
export default {
  name: 'HeaderSidebarToggler',
}
</script>

<style scoped lang="postcss">
.header-sidebar-toggler {
  @apply w-7 h-7 sm:w-6 sm:h-6;
  @apply flex relative items-center justify-center shrink-0;
  @apply bg-white text-indigo-700;
  @apply dark:bg-gray-800 dark:text-indigo-300;
  @apply rounded-full shadow;
  @apply cursor-pointer z-20;

  &__icon {
    @apply w-5 h-5 sm:w-4 sm:h-4;
  }

  &--desktop {
    @apply hidden md:flex;
  }

  &--mobile {
    @apply flex md:hidden;
  }
}
</style>
