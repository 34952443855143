import { App, Plugin, ref, Ref } from 'vue'

import {
  Alert,
  AlertInstance,
  AlertTypes,
  MainAlert,
  TypedAlert,
} from './types'

import { MIN_DELAY } from './const'

import uuid from './uuid'

import AlertGroup from './AlertGroup.vue'

const alerts: Ref<MainAlert[]> = ref([])

const push = async (alert: TypedAlert): Promise<string> => {
  return new Promise(resolve => {
    const alertItem: MainAlert = {
      instance: {
        id: uuid(),
        created_at: new Date().getTime(),
      },
      ...alert,
    }
    alerts.value.push(alertItem)
    resolve(alertItem.instance.id)
  })
}

const alertCreator = async (type: AlertTypes, alert: Alert) => {
  const nid: AlertInstance = {
    id: '',
    created_at: new Date().getTime(),
  }
  nid.id = await push({
    ...alert,
    type,
  })
  return nid
}

const methods = {
  update: async (
    instance: AlertInstance,
    alert: Alert & { type?: AlertTypes },
    timeout?: number,
  ): Promise<void> => {
    return new Promise(resolve => {
      const alertIndex = alerts.value.findIndex(
        item => item.instance.id === instance.id,
      )
      if (~alertIndex) {
        alerts.value[alertIndex] = {
          ...alerts.value[alertIndex],
          ...alert,
        }
      }
      if (timeout) {
        setTimeout(async () => await methods.remove(instance), timeout)
      }
      resolve()
    })
  },
  remove: async (instance: AlertInstance): Promise<void> => {
    const diff = new Date().getTime() - instance.created_at
    if (diff < MIN_DELAY) {
      await new Promise(resolve => setTimeout(resolve, MIN_DELAY - diff))
    }
    return new Promise(resolve => {
      const removeIndex = alerts.value.findIndex(
        item => item.instance.id === instance.id,
      )
      ~removeIndex && alerts.value.splice(removeIndex, 1)
      resolve()
    })
  },
  success: async (alert: Alert): Promise<AlertInstance> =>
    alertCreator('success', alert),
  error: async (alert: Alert): Promise<AlertInstance> => {
    return alertCreator('error', alert)
  },
  warn: async (alert: Alert): Promise<AlertInstance> =>
    alertCreator('warn', alert),
  progress: async (alert: Alert): Promise<AlertInstance> =>
    alertCreator('progress', alert),
  clearAll: async () => {
    alerts.value = []
  },
}

type AlertsInstance = {
  alerts: Ref<MainAlert[]>
} & typeof methods

export const useAlerts = (): AlertsInstance => {
  return {
    alerts,
    ...methods,
  }
}

export default {
  install: function (app: App): void {
    app.component('AppAlertGroup', AlertGroup)
  },
} as Plugin
