export const ACCESS_TYPE_READONLY_TEXT = 'read only'
export const ACCESS_TYPE_READ_WRITE_TEXT = 'read/write'
export const ACCESS_TYPE_OWNER_TEXT = 'owner'
export const ACCESS_TYPES = [
  ACCESS_TYPE_READONLY_TEXT,
  ACCESS_TYPE_READ_WRITE_TEXT,
  ACCESS_TYPE_OWNER_TEXT,
]
export const ACCESS_TYPE_READONLY = 1
export const ACCESS_TYPE_READ_WRITE = 2
export const ACCESS_TYPE_OWNER = 3
import { RepositoryTimeline } from '@/@types'
import { getCurrentDate } from '@/helpers/dates'

export const REPOSITORY_DEFAULT_TIMELINE: RepositoryTimeline = {
  periods: 1,
  freq: 'day',
  end: getCurrentDate({}),
  include_end: true,
  end_is_today: true,
}
