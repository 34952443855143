export enum LinkedDataConnectors {
  AKOYA = 'Akoya',
  PLAID = 'Plaid',
  YODLEE = 'Yodlee',
  COINBASE = 'Coinbase',
  OFX = 'OFX',
  JPM_CIB = 'JPM CIB',
  ARCHLAB = 'Archlab',
}

export enum LinkedDataConnectorStatus {
  SUCCESS = 1,
  WARNING,
  ERROR,
  LOGIN_REQUIRED,
}
