<template>
  <div v-if="breadcrumbs" class="page-name">
    <template v-for="(item, index) in breadcrumbs" :key="item">
      <ChevronDoubleRightIcon
        v-if="index"
        aria-hidden="true"
        class="page-name__arrow"
      />
      <span>{{ item }}</span>
    </template>
    <div data-refid="actionsContainer" class="page-name__area" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useNavigationStore } from '@/store/navigation'

import { ChevronDoubleRightIcon } from '@heroicons/vue/24/outline'

const navigationStore = useNavigationStore()

const breadcrumbs = computed(() => navigationStore.getBreadcrumbs)
</script>

<script lang="ts">
export default {
  name: 'PageName',
}
</script>

<style scoped lang="postcss">
.page-name {
  @apply h-8;
  @apply flex items-center shrink-0;
  @apply px-4 -mx-4;
  @apply mb-4;
  @apply gap-1;
  @apply bg-gray-50 dark:bg-gray-850;
  @apply text-gray-500;
  @apply text-xs;
  @apply tracking-normal;

  &__arrow {
    @apply w-3 h-3;
    @apply text-gray-400;
  }

  &__area {
    @apply flex items-center;
    @apply space-x-2;
    @apply ml-auto;
  }
}
</style>
