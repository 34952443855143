import { appendScript } from '@/helpers/common'

type OneDriveFile = {
  id: string
  name: string
  size: number
  webUrl: string
}

let onSelect: (result: OneDriveFile) => void

const { VITE_APP_ONEDRIVE_CLIENT_ID } = import.meta.env

function openOneDrive(): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  OneDrive.open({
    clientId: VITE_APP_ONEDRIVE_CLIENT_ID,
    action: 'share',
    advanced: {
      redirectUri: `${window.location.origin}/onedrive.html`,
    },
    success: ({ value }: { value: OneDriveFile[] }) => {
      onSelect(value[0])
    },
    // cancel: () => {},
    error: (error: string) => {
      console.log(error)
    },
  })
}

export const initOneDrivePicker = (
  callback: (result: OneDriveFile) => void,
): void => {
  onSelect = callback
  appendScript('https://js.live.net/v7.2/OneDrive.js', 'onedrivejs').then(
    openOneDrive,
  )
}
