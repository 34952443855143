import { computed, ref, watch } from 'vue'
import { defineStore } from 'pinia'

import { AnalyticsIncomeHistory } from './utils/types'
import {
  AnalyticIncomeGroupFields,
  AnalyticsIncomeSources,
} from './utils/enums'

import { ANALYTICS_DATE_STORAGE_KEY } from '../utils/const'

import { useUISettings } from '@/hooks/uiSettings'
import { useRepoLocalStorage } from '@/hooks/repoLocalStorage'

import useAnalyticsStore from '../index'
import { filterBySource, parseSource } from './utils/helpers'

import { useAssetsBunchStore } from '@/store/assets/bunch'

export default defineStore('analytics-income_history', () => {
  // INIT
  const analyticsStore = useAnalyticsStore()
  const assetsBunchStore = useAssetsBunchStore()

  const list = ref<Record<string, AnalyticsIncomeHistory[]>>()

  const loading = ref(false)
  const deprecated = ref<boolean>()

  const source = useUISettings<AnalyticsIncomeSources[]>(
    ['analytics', 'dashboard', 'income', 'source'],
    Object.values(AnalyticsIncomeSources),
  )
  const groupField = useUISettings<AnalyticIncomeGroupFields>(
    ['analytics', 'dashboard', 'income', 'group_field'],
    AnalyticIncomeGroupFields.CATEGORY_ACCOUNT,
  )
  const groupValue = useUISettings<string>(
    ['analytics', 'dashboard', 'income', 'group_value'],
    '',
  )
  const selectedAccount = useUISettings<string>(
    ['analytics', 'dashboard', 'income', 'selected_account'],
    '',
  )

  const storageDate = useRepoLocalStorage<string | undefined>(
    ANALYTICS_DATE_STORAGE_KEY,
    undefined,
  )

  const assetsBunch = computed(() => assetsBunchStore.getList)

  // GETTERS

  const isInit = computed(() => deprecated.value !== undefined)

  const getSource = computed(() => {
    return source.value || Object.values(AnalyticsIncomeSources)
  })
  const getGroupField = computed(() => {
    return groupField.value || AnalyticIncomeGroupFields.CATEGORY_ACCOUNT
  })
  const getGroupValue = computed(() => groupValue.value)
  const getSelectedAccount = computed(() => selectedAccount.value)

  const getList = computed(() => {
    if (analyticsStore.getError) return {}
    return list.value
  })

  const getDatesRange = computed(() => {
    return list.value && Object.keys(list.value)
  })

  const getDate = computed(() => {
    if (!storageDate.value) return
    return storageDate.value
  })

  const getIncomes = computed(() => {
    if (!getDate.value) return []
    return getList.value?.[getDate.value] || []
  })

  const getSourceAttributes = computed(() => {
    return parseSource(getSource.value)
  })

  const getFilteredData = computed(() =>
    getIncomes.value.filter(item =>
      filterBySource(item, getSourceAttributes.value, assetsBunch.value),
    ),
  )

  // SETTERS

  const setList = (data: {
    income_history: Record<string, AnalyticsIncomeHistory[]>
  }) => {
    list.value = data.income_history
    deprecated.value = false
  }

  const setSource = (data: AnalyticsIncomeSources[]) => {
    source.value = data
  }
  const setGroupField = (data: AnalyticIncomeGroupFields) => {
    groupField.value = data
  }
  const setGroupValue = (data: string) => {
    groupValue.value = data
  }
  const setSelectedAccount = (data: string) => {
    selectedAccount.value = data
  }

  // ACTIONS

  const clear = () => {
    loading.value = false
    deprecated.value = undefined

    list.value = undefined
  }

  watch(getGroupField, () => {
    if (!isInit.value) return
    setGroupValue('')
    setSelectedAccount('')
  })

  return {
    isLoading: loading,
    isDeprecated: deprecated,

    isInit,

    getSource,
    getGroupField,
    getGroupValue,
    getSelectedAccount,

    getList,
    getDatesRange,

    getSourceAttributes,
    getFilteredData,

    setList,

    setSource,
    setGroupField,
    setGroupValue,
    setSelectedAccount,

    clear,
  }
})
