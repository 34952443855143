<template>
  <UIInputEditableDropdown
    v-model="modelValue"
    v-bind="{
      disabled,
      error,
      focusOnLoad,
      label,
      silentError,
      size,
      data,
      readonly,
    }"
    frozen
    id-key="key"
    @create="handleCreate"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'

import { AssetClass } from '..'
import { DataFieldSizes } from '@types'
import { AssetAccountTag } from '../utils/enums'

import { ASSET_FIELD } from '../utils/const'

import { prepareAccountList } from './utils/helpers'
import { handleCatchedError } from '@/helpers/common'

import { useTagsBunchStore } from '@/store/tags/bunch'

import { UIInputEditableDropdown } from '@ui'

type Props = {
  instance: AssetClass
  disabled?: boolean
  label?: string
  focusOnLoad?: boolean
  dataRefid?: string
  size?: DataFieldSizes
}

const props = defineProps<Props>()

const tagsBunchStore = useTagsBunchStore()

const tags = props.instance.field<string[] | undefined>(ASSET_FIELD.TAGS)

const isLoading = ref(false)

const disabled = computed(() => props.disabled || isLoading.value)

const categoryIndex = computed(() =>
  tags.value?.indexOf(props.instance.categoryId),
)

const modelValue = computed({
  get() {
    return props.instance.categoryId
  },
  set(value: string) {
    let newValue = [...(tags.value || [])]
    if (!newValue) {
      newValue = [value]
    } else if (newValue.length === 1) {
      newValue.push(value)
    } else if (categoryIndex.value !== undefined && categoryIndex.value > -1) {
      newValue[categoryIndex.value] = value
    } else {
      newValue[0] = value
    }
    tags.value = newValue
  },
})

const isSavedAsset = inject('isSavedAsset', ref(false))

const data = computed(() =>
  prepareAccountList(tagsBunchStore.getCategoriesList),
)

const readonly = computed(() => props.instance.isReadonly)
const error = computed(() => props.instance.errors.tags)
const silentError = computed(() => !isSavedAsset.value)

const handleCreate = async (tag_value: string) => {
  const data = {
    tag_name: AssetAccountTag.CATEGORY,
    tag_value,
  }
  try {
    isLoading.value = true
    const tag = await tagsBunchStore.createElement()
    tag.set(data)
    await tag.store()
    modelValue.value = tag.id
  } catch (e) {
    handleCatchedError(e as string, data)
  } finally {
    isLoading.value = false
  }
}
</script>

<script lang="ts">
export default {
  name: 'AccountFormCategory',
}
</script>
