<template>
  <SwitchGroup as="div" class="ui-switch" :class="mainClasses" @click.stop="">
    <SwitchLabel
      v-if="label"
      as="span"
      class="ui-switch__label-wrapper"
      :class="`ui-switch__label-wrapper--${labelPosition}`"
    >
      <span class="ui-switch__label">{{ label }}</span>
      <span v-if="description" class="ui-switch__description">
        {{ description }}
      </span>
    </SwitchLabel>
    <Switch
      :model-value="modelValue"
      v-bind="{ disabled, ...$attrs }"
      class="ui-switch__toggle"
      :class="switchToggleClasses"
      @update:model-value="handleUpdate"
    >
      <span class="sr-only">{{ label || 'Use setting' }}</span>
      <span
        aria-hidden="true"
        class="ui-switch__circle"
        :class="switchCircleClasses"
      />
    </Switch>
  </SwitchGroup>
  <p v-if="error" class="ui-switch__error">
    {{ error }}
  </p>
</template>

<script setup lang="ts">
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import { computed } from 'vue'

type Props = {
  label?: string
  error?: string
  description?: string
  labelPosition?: 'left' | 'right'
  disabled?: boolean
  full?: boolean
}

const { labelPosition = 'right', ...props } = defineProps<Props>()

const modelValue = defineModel<boolean>()

const mainClasses = computed(() => ({
  'ui-switch--full': props.full,
  'ui-switch--disabled': props.disabled,
}))

const switchToggleClasses = computed(() => ({
  'ui-switch__toggle--active': modelValue.value,
}))
const switchCircleClasses = computed(() => ({
  'ui-switch__circle--active': modelValue.value,
}))

const handleUpdate = (value: boolean) => {
  modelValue.value = value
}
</script>

<script lang="ts">
export default {
  name: 'UISwitch',
  inheritAttrs: false,
}
</script>

<style lang="postcss">
.ui-switch {
  @apply flex items-center;

  &--full {
    @apply justify-between;
  }

  &--disabled {
    @apply opacity-50;
    @apply cursor-default;
  }

  &__toggle {
    @apply relative inline-flex shrink-0 h-4 w-9;
    @apply border-2 border-transparent rounded-full cursor-pointer;
    @apply transition-colors ease-in-out duration-200;
    @apply focus:outline-none focus:ring-2 focus:ring-offset-2;
    @apply focus:ring-indigo-500 dark:focus:ring-indigo-400;
    @apply bg-gray-200 dark:bg-gray-600;

    &--active {
      @apply bg-indigo-600 dark:bg-indigo-400;
    }
  }

  &__circle {
    @apply inline-block h-3 w-3 rounded-full;
    @apply bg-white;
    @apply pointer-events-none shadow transform ring-0 transition ease-in-out duration-200;

    &--active {
      @apply translate-x-5;
    }
  }

  &__label-wrapper {
    @apply leading-none flex flex-col;

    &--left {
      @apply mr-3;
    }
    &--right {
      @apply ml-3;
      @apply order-1;
    }
  }
  &__label {
    @apply text-xs whitespace-nowrap;
    @apply text-gray-500 dark:text-gray-400;
  }
  &__description {
    @apply text-[0.625rem];
    @apply text-gray-500 dark:text-gray-400;
  }
  &__error {
    @apply mt-1 text-xs;
    @apply text-red-600 dark:text-red-400;
  }
}
</style>
