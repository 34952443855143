<template>
  <div class="tabs" v-bind="$attrs">
    <div class="tabs__wrapper" :class="tabsWrapperClasses">
      <nav class="tabs__nav" aria-label="Tabs">
        <template v-for="tab in tabs" :key="tab.name">
          <div v-if="!noSeparated" class="tabs__divider"></div>
          <router-link
            v-if="tab.route"
            :to="tab.route"
            exact
            exact-active-class="tabs__item--active"
            class="tabs__item"
          >
            {{ tab.name }}
          </router-link>
          <div
            v-else
            class="tabs__item"
            :class="{
              'tabs__item--active': modelValue === tab.name,
            }"
            @click="modelValue = tab.name"
          >
            {{ tab.name }}
          </div>
        </template>
      </nav>
      <div v-if="!noSeparated && tabs.length > 0" class="tabs__divider" />
      <slot />
    </div>
  </div>
  <Menu as="div" class="tabs__menu" v-bind="$attrs" @click.stop="">
    <MenuButton class="menu__button">
      <span class="sr-only">Open user menu</span>
      {{ currentTab }}
      <ChevronDownIcon aria-hidden="true" />
    </MenuButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems class="menu__items">
        <div class="py-1">
          <MenuItem v-for="tab in tabs" :key="tab.name" v-slot="{ active }">
            <router-link
              v-if="tab.route"
              :to="tab.route"
              exact
              exact-active-class="bg-gray-50 text-indigo-600 dark:bg-gray-800 dark:text-gray-200"
              :class="`${
                active
                  ? 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-200'
                  : 'text-gray-700 dark:text-gray-400'
              } block w-full text-left px-4 py-2 text-sm`"
            >
              {{ tab.name }}
            </router-link>
            <div
              v-else
              :class="`${
                modelValue === tab.name
                  ? 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-200'
                  : 'text-gray-700 dark:text-gray-400'
              } block w-full text-left px-4 py-2 text-sm`"
              @click="modelValue = tab.name"
            >
              {{ tab.name }}
            </div>
          </MenuItem>
          <slot />
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import { NavigationChildItem } from '@types'

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/24/outline'

type Props = {
  tabs: NavigationChildItem[]
  bottomBorder?: boolean
  noSeparated?: boolean
}

const props = defineProps<Props>()

const modelValue = defineModel<string>()

const route = useRoute()

const currentTab = computed<string>(
  () =>
    modelValue.value ||
    props.tabs.find(tab => tab.route?.toString() === route?.name)?.name ||
    'Menu',
)

const tabsWrapperClasses = computed(() => ({
  'tabs__wrapper--with-border': props.bottomBorder,
}))
</script>

<script lang="ts">
export default {
  name: 'UITabs',
}
</script>

<style scoped lang="postcss">
.tabs {
  @apply hidden sm:block;

  &__wrapper {
    @apply h-full flex w-full items-center gap-x-4;

    &--with-border {
      @apply border-b border-gray-200 dark:border-gray-700;
    }
  }

  &__nav {
    @apply -mb-px h-full flex items-center gap-x-4;
    @apply border-transparent border-t-2;
  }

  &__item {
    @apply h-full flex items-center py-4 px-1;
    @apply text-gray-500 hover:text-gray-700 hover:border-gray-300;
    @apply dark:text-gray-400 dark:hover:text-gray-300 dark:hover:border-gray-500;
    @apply border-transparent border-b-2;
    @apply whitespace-nowrap font-medium text-sm cursor-pointer;

    &--active {
      @apply border-indigo-500 dark:border-indigo-600 text-indigo-600 dark:text-indigo-500;
    }
  }

  &__divider {
    @apply block first:hidden sm:first:block;
    @apply h-6 border-l;
    @apply border-gray-200 dark:border-gray-700;
  }

  &__menu {
    @apply flex justify-center sm:hidden;
    @apply mt-1;
    @apply relative z-30;
  }
}

.menu {
  &__button {
    @apply py-1 px-2 max-w-xs flex items-center;
    @apply text-sm rounded;
    @apply text-gray-500 hover:text-gray-700;
    @apply dark:text-gray-400 dark:hover:text-gray-300;
    @apply focus:outline-none focus:ring-2 focus:ring-offset-2;
    @apply focus:ring-indigo-500;

    svg {
      @apply shrink-0 h-4 w-4 ml-2;
      @apply text-gray-400 dark:text-gray-300;
    }
  }

  &__items {
    @apply mt-2 w-40 origin-top-left fixed z-10;
    @apply rounded-md shadow-lg;
    @apply bg-white dark:bg-gray-800;
    @apply ring-1 ring-black dark:ring-gray-700 ring-opacity-5;
    @apply divide-y divide-gray-100 dark:divide-gray-700;
    @apply focus:outline-none;
  }
}
</style>
