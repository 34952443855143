<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UIDialog v-bind="{ zIndex }" title="Import repository" @hide="handleClose">
      <RepositoriesFormImport @close="handleClose" />
    </UIDialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { TransitionRoot } from '@headlessui/vue'

import { ModalClass, ModalState } from '@types'
import { UIDialog } from '@ui'
import RepositoriesFormImport from './RepositoriesFormImport.vue'

type Props = {
  modal: ModalClass<any>
}
const props = defineProps<Props>()

const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)
const zIndex = computed(() => props.modal.zIndex)

const handleClose = () => {
  props.modal.close()
}
</script>

<script lang="ts">
export default {
  name: 'RepositoryImportDialog',
}
</script>
