export const ANALYTICS_TIMEOUT_DELAY = 5000

export const ANALYTICS_DELAY_TO_NOTIFICATE = 5

export const ANALYTICS_DATE_STORAGE_KEY = 'ANALYTICS_DATE'

export const ANALYTICS_PERIOD_NAMING = {
  day: 'Daily',
  week: 'Weekly',
  month: 'Monthly',
  quarter: 'Quarterly',
  year: 'Yearly',
}
export const ANALYTICS_INCOME_TRANSFER_TYPES = ['transfer in', 'transfer out']

export const ANALYTICS_REPO_ROOT = 'repo_root'
export const ANALYTICS_ASSET_ID = 'asset_id'
export const ANALYTICS_ACCOUNT_ID = 'account_id'
export const ANALYTICS_ASSET_TYPE = 'asset_type'

export const ANALYTICS_ASSET_NAME = 'Asset'
export const ANALYTICS_ASSET_TYPE_NAME = 'Asset Type'

export const ANALYTICS_TIMEOUT_MESSAGE =
  'Analytics calculation timed out. Please reduce number of periods in the timeline and try again.'
