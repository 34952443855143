import { h, VNode } from 'vue'

import { NotificationError } from './'
import { AssetClass } from '@/entities/assets'

import { ASSET_FIELD } from '@/entities/assets/utils/const'

import { stringToLocalDateString } from '@/helpers/dates'

const DISPLAY_ERRORS = 5

export const prepareErrors = (
  errors: any,
  assetsBunch: Map<string, AssetClass>,
): VNode => {
  return Array.isArray(errors)
    ? prepareArrayOfErrors(errors, assetsBunch)
    : prepareObjectOfErrors(errors)
}

const prepareArrayOfErrors = (
  errors: NotificationError[],
  assetsBunch: Map<string, AssetClass>,
): VNode => {
  const sliceOfErrors = errors.splice(0, DISPLAY_ERRORS)
  const results: VNode[] = []
  sliceOfErrors.forEach(error => {
    if (typeof error === 'string') {
      return h('span', error)
    }
    if (error.asset_ids) {
      results.push(
        h(
          'span',
          { class: 'text-gray-400 font-light' },
          `for asset${error.asset_ids.length > 1 ? 's' : ''}: `,
        ),
      )
      results.push(
        h(
          'span',
          error.asset_ids
            .map(
              (asset: string) =>
                assetsBunch.get(asset)?.field(ASSET_FIELD.NAME).value,
            )
            .filter(item => item !== undefined)
            ?.join(', ') || '',
        ),
      )
    }
    if (error.date) {
      results.push(
        h('span', [
          h('span', { class: 'text-gray-400 font-light' }, ' on '),
          h(
            'span',
            { class: 'whitespace-nowrap' },
            `${stringToLocalDateString(error.date)}`,
          ),
        ]),
      )
    }
  })
  return h('span', results)
}

const getErrorNode = (field: string, message: string) =>
  h('div', { className: 'mt-2' }, [
    h('span', { className: 'uppercase' }, `${field}: `),
    h('span', message),
  ])

const prepareObjectOfErrors = (errors: Record<string, any>): VNode => {
  let objectOfErrors: Record<string, any>
  if (errors.errors && errors.errors.entries) {
    objectOfErrors = errors.errors.entries
  } else {
    objectOfErrors = errors
  }
  const sliceOfErrors = Object.entries(objectOfErrors).splice(0, DISPLAY_ERRORS)
  const results: VNode[] = []
  sliceOfErrors.forEach(([name, error]) => {
    if (typeof error === 'object' && !Array.isArray(error)) {
      const list: VNode[] = []
      Object.entries(error).forEach((entry, index) => {
        list.push(getErrorNode(`${entry[0]}${index + 1}`, `${entry[1]}`))
      })
      results.push(h('div', [h('div', { className: 'uppercase' }, name), list]))
    } else {
      results.push(getErrorNode(name, `${error}`))
    }
  })
  if (Object.values(errors).length > DISPLAY_ERRORS) {
    results.push(h('span', '...'))
  }
  return h('span', results)
}
