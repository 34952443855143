import { MIN_WIDTH } from './const'
import { GridColumn, GridColumnWidth } from './types'

export const getTemplateColumns = (
  columns: GridColumn[],
  columnsWidth: GridColumnWidth,
  template: string,
  selectable?: boolean,
) => {
  let result = 'grid-template-columns:'
  const preSetCols = template?.split(' ')
  if (selectable) {
    result += ' min-content'
  }
  columns.forEach((column, index) => {
    const width = columnsWidth?.[column.name]
    if (width) {
      result += ` ${width < MIN_WIDTH ? MIN_WIDTH : width}px`
    } else if (width === 0) {
      result += ' max-content'
    } else {
      result += ` ${preSetCols?.[index] || '1fr'}`
    }
  })
  return result
}

export const getSlotName = (name: string, prefix?: string) => {
  const value = name
    .replaceAll('_', '.')
    .split('.')
    .map(item => item.at(0)?.toUpperCase() + item.slice(1))
    .join('')
  return `${prefix}${value}`
}
export const pasteFromClipboard = (event: ClipboardEvent) => {
  if (!event.clipboardData) return []
  const htmlData = event.clipboardData.getData('text/html')
  const plainData = event.clipboardData.getData('text/plain')
  let dataArray: string[][] = []
  if (htmlData) {
    const rows = Array.from(htmlData.matchAll(/<tr.*?>(.*?)<\/tr>/g))
    dataArray = rows.map(row => {
      return Array.from(row[1].matchAll(/<td.*?>(.*?)<\/td>/g), cell =>
        cell[1].replace(/(<([^>]+)>)/gi, ''),
      )
    })
  }
  if (dataArray.length === 0 && plainData) {
    dataArray.push([plainData])
  }
  return dataArray
}
