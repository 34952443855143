import { computed, reactive, watch } from 'vue'
import { useSessionStorage } from '@vueuse/core'

import { useRepositoriesStore } from '@/store/repositories'

export const useRepoLocalStorage = <T>(key: string, defaultValue: T) => {
  const repositoriesStore = useRepositoriesStore()

  const internalValue = reactive<{ value: any }>({
    value: undefined,
  })

  const storageKey = computed(
    () => `${repositoriesStore.currentRepositoryId}_${key}`,
  )

  const data = computed({
    get() {
      if (!internalValue.value) return defaultValue
      try {
        return JSON.parse(internalValue.value) as T
      } catch {
        return defaultValue
      }
    },
    set(value) {
      internalValue.value = JSON.stringify(value)
    },
  })

  watch(
    storageKey,
    value => {
      if (!repositoriesStore.currentRepositoryId) return
      internalValue.value = useSessionStorage<string>(
        value,
        JSON.stringify(defaultValue),
      )
    },
    { immediate: true },
  )

  return data
}
