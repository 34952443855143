<template>
  <div class="select-repository-buttons">
    <UIButton
      data-refid="repositoryImport"
      label="Import"
      :icon="ArrowDownTrayIcon"
      variant="light"
      @click="handleImport"
    />
    <UIButton
      data-refid="repositoryCreate"
      label="Create new"
      :icon="PlusIcon"
      @click="handleCreate"
    />
  </div>
</template>

<script lang="ts" setup>
import { ArrowDownTrayIcon, PlusIcon } from '@heroicons/vue/24/outline'
import { UIButton } from '@ui'

type Props = {
  callback: () => void
}

type Emits = {
  import: []
  create: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const handleImport = () => {
  props.callback()
  emit('import')
}

const handleCreate = () => {
  props.callback()
  emit('create')
}
</script>

<script lang="ts">
export default {
  name: 'SelectRepositoryButtons',
}
</script>

<style scoped lang="postcss">
.select-repository-buttons {
  @apply flex items-center justify-between;
  @apply mt-1 -mb-1 py-3 px-4 gap-3;
  @apply border-t border-gray-100 dark:border-gray-600;

  :deep() .btn {
    @apply whitespace-nowrap;
  }
}
</style>
