<template>
  <Dropdown v-bind="{ placement, shown }" auto-boundary-max-size>
    <UIButton
      v-bind="{ disabled, label, numberLabel, variant, ...$attrs }"
      :icon="BarsArrowDownIcon"
      data-refid="sort-button"
      @click="handleToggle"
    />
    <template #popper>
      <div
        ref="popupRef"
        v-circular-tab
        class="popover__list"
        :data-refid="dataRefId"
      >
        <draggable
          v-model="computedValue"
          v-bind="dragOptions"
          handle=".handle"
          item-key="key"
          tag="div"
          class="ui-sort"
        >
          <template #item="{ element, index }">
            <SortItem
              v-bind="{ data, disallowDrag, element, fields, index, total }"
              :container="`[data-refid='${dataRefId}']`"
              @update="handleUpdate"
              @remove="handleRemove"
            />
          </template>
        </draggable>
        <UIButton
          label="Add sort"
          size="small"
          :icon="PlusIcon"
          :disabled="buttonDisabled"
          @click="handleAdd"
        />
      </div>
    </template>
  </Dropdown>
</template>

<script lang="ts" setup>
import { computed, getCurrentInstance, ref, watch } from 'vue'
import { Dropdown, Placement } from 'floating-vue'
import { isEqual } from 'lodash'
import draggable from 'vuedraggable'

import { ListItem, SortDirection } from '@types'
import { Sort } from './utils/types'

import { generateRandomKey } from './utils/helpers'

import { UIButton } from '@ui'
import { BarsArrowDownIcon, PlusIcon } from '@heroicons/vue/24/outline'
import SortItem from './SortItem.vue'

type Props = {
  buttonLabel?: string
  placement?: Placement
  fields?: ListItem[]
  disabled?: boolean
  disallowDrag?: boolean
}

const {
  placement = 'bottom-end',
  fields = [],
  disallowDrag,
  ...props
} = defineProps<Props>()

const modelValue = defineModel<Sort[]>()
const modelStorage = defineModel<Sort[]>('storage')

const shown = ref(false)

const uniqueId = computed(() => getCurrentInstance()?.uid.toString())
const dataRefId = computed(() => `sort-popper-${uniqueId.value}`)

const computedValue = computed({
  get() {
    const key = generateRandomKey()
    return modelStorage.value?.length
      ? modelStorage.value
      : [
          {
            key,
            field: '',
            sortDirection: SortDirection.ASC,
          },
        ]
  },
  set(value) {
    modelStorage.value = value
  },
})

const data = computed(() => {
  const selected = modelValue.value?.map(sort => sort.field)
  return fields?.filter(field => !selected?.includes(field.key.toString()))
})

const total = computed(() => modelStorage.value?.length || 0)

const numberLabel = computed(() => modelValue.value?.length || undefined)
const label = computed(() =>
  numberLabel.value ? props.buttonLabel : props.buttonLabel || 'Sort',
)
const variant = computed(() =>
  numberLabel.value ? 'light-secondary' : 'light-gray',
)
const buttonDisabled = computed(
  () => modelStorage.value?.length === fields.length,
)

const dragOptions = computed(() => ({
  animation: 200,
  disabled: disallowDrag,
}))

const escapeHandler = (event: KeyboardEvent) => {
  if (event.key !== 'Escape') return
  shown.value = false
}

const handleToggle = () => {
  shown.value = !shown.value
}

const handleUpdate = (data: Sort, key: string | number) => {
  if (!modelStorage.value?.length) {
    modelStorage.value = [data]
    return
  }
  modelStorage.value = modelStorage.value.map((item, index) => {
    if (
      (typeof key === 'string' && item.key === key) ||
      (typeof key === 'number' && index === key)
    ) {
      return { ...item, ...data }
    } else {
      return item
    }
  })
}

const handleRemove = (key: string | number) => {
  modelStorage.value = modelStorage.value?.filter((item, index) =>
    typeof key === 'string' ? item.key !== key : index !== key,
  )
}

const handleAdd = () => {
  computedValue.value = [
    ...(computedValue.value || []),
    {
      key: generateRandomKey(),
      field: '',
      sortDirection: SortDirection.ASC,
    },
  ]
}

watch(shown, value => {
  if (value) {
    window.addEventListener('keyup', escapeHandler)
  } else {
    window.removeEventListener('keyup', escapeHandler)
  }
})

watch(
  modelStorage,
  (value, prev) => {
    if (isEqual(value, prev)) return
    modelValue.value = value
      ?.filter(item => !!item.field)
      .map(({ field, sortDirection }, index) => ({
        field,
        sortDirection,
        index,
      }))
  },
  { deep: true, immediate: true },
)
</script>

<script lang="ts">
export default {
  name: 'UISort',
}
</script>

<style scoped lang="postcss">
.ui-sort {
  @apply flex flex-col;
  @apply gap-2;
  @apply mb-4;
}
</style>

<style scoped>
@import '@/assets/styles/popover.css';
</style>
