<template>
  <UIDropdown v-bind="{ items }" value-key="label" placement="bottom-start">
    <UIButton
      v-bind="{ size }"
      :icon="ArrowUpTrayIcon"
      label="Export"
      variant="light-gray"
      hide-label-on-mobile
    />
  </UIDropdown>
</template>

<script setup lang="ts">
import { inject } from 'vue'

import { ButtonSizes } from '@types'

import { ArrowUpTrayIcon } from '@heroicons/vue/24/outline'

import { UIButton, UIDropdown } from '@ui'

type Props = {
  exportAllLabel?: string
}
const { exportAllLabel = 'Export All' } = defineProps<Props>()

type Emits = {
  'export:all': []
  'export:selected': []
}
const emit = defineEmits<Emits>()

const size = inject<ButtonSizes>('size')

const items = [
  {
    label: exportAllLabel,
    action: () => {
      emit('export:all')
    },
  },
  {
    label: 'Export Selected',
    action: () => {
      emit('export:selected')
    },
  },
]
</script>

<script lang="ts">
export default {
  name: 'PanelExportAdvanced',
}
</script>

<style scoped lang="postcss"></style>
