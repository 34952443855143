<template>
  <UILoading v-if="loading" message="Loading tags..." class="items-start" />
  <UITagsList
    v-model="selectedList"
    :opened="show"
    v-bind="{ readonly }"
    hide-menu
    @plus:click="show = !show"
  />
  <UIInputEditableDropdown
    v-if="show && !loadingAction"
    v-model:search="tag"
    v-bind="{
      data,
      disabled,
    }"
    focus-on-load
    id-key="key"
    size="small"
    class="mt-2"
    placeholder="Find or create new"
    @create="handleCreateTag"
    @select="handleSelectTag"
    @click:enter="handleCreateTag"
    @update="handleUpdateTag"
    @delete="handleDeleteTag"
  />
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'

import { CommonTag } from '@types'
import { DataFieldValue } from '../DataField/utils/types'

import { orderedList } from '@/helpers/common'

import { UIInputEditableDropdown, UILoading, UITagsList } from '@ui'
import { UNKNOWN_VALUE } from '@/const/common'

type Props = {
  tagsList: CommonTag[]
  selected?: string[]
  disabled?: boolean
  readonly?: boolean
  loading?: boolean
  loadingAction?: boolean
}

type Emits = {
  update: [data: string[]]
  create: [data: string]
  'item:update': [data: string]
  'item:delete': [data: string]
}

const { selected = [], ...props } = defineProps<Props>()
const emit = defineEmits<Emits>()

const show = ref(false)

const data = computed(() =>
  orderedList(
    props.tagsList.filter(item => !selected.includes(item.id || '')),
    'id',
    'name',
  ),
)
const tag = ref('')

const selectedList = computed<CommonTag[]>({
  get() {
    return selected.map(id => ({
      id,
      name: props.tagsList.find(tag => tag.id === id)?.name || UNKNOWN_VALUE,
    }))
  },
  set(values) {
    const tagIds = values.map(value => `${value.id}`)
    emit('update', tagIds)
  },
})

const handleCreateTag = () => {
  emit('create', tag.value)
}

const handleUpdateTag = (key: string) => {
  emit('item:update', key)
}

const handleDeleteTag = (key: string) => {
  emit('item:delete', key)
}

const handleSelectTag = (selectedTag: DataFieldValue) => {
  if (!selectedTag) return
  const value = selected
  value.push(selectedTag.toString())
  tag.value = ''
  emit('update', value)
}
</script>

<script lang="ts">
export default {
  name: 'UITags',
}
</script>
