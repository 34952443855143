<template>
  <teleport to="body">
    <div v-if="isShown" class="account-yodlee__back" @click="handleClose" />
    <div class="account-yodlee" :class="containerClasses">
      <div class="account-yodlee__container">
        <div :id="containerId" class="account-yodlee__fastlink" />
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance, ref } from 'vue'

defineExpose({
  toggle(flag = true) {
    isShown.value = flag
  },
})

const isShown = ref(false)

const containerId = computed(
  () => `container-fastlink-${getCurrentInstance()?.uid.toString()}`,
)

const containerClasses = computed(() => ({
  'account-yodlee--visible': isShown.value,
}))

const handleClose = () => {
  isShown.value = false
  ;(window as Window & typeof globalThis & { fastlink: any }).fastlink.close()
}
</script>

<script lang="ts">
export default {
  name: 'ConnectorsAddAccountYodlee',
}
</script>

<style lang="postcss">
.account-yodlee {
  @apply absolute inset-0;
  @apply h-screen;
  @apply hidden;
  @apply z-[9999];

  &--visible {
    @apply block;
  }

  &__back {
    @apply fixed inset-0;
    @apply bg-opacity-75 bg-gray-500;
    @apply dark:bg-opacity-10 dark:bg-gray-200;
    @apply transition-opacity z-20;
    @apply z-[9999];
  }

  &__container {
    @apply w-4/5 md:w-3/5 h-5/6;
    @apply absolute inset-x-0;
    @apply p-2 mt-2 mx-auto;
    @apply rounded-md shadow-lg;
    @apply bg-white dark:bg-gray-800;
    @apply ring-1 ring-opacity-5;
    @apply ring-gray-500 dark:ring-gray-600;
    @apply focus:outline-none;
    @apply overflow-hidden;
    @apply z-30;
  }

  &__fastlink {
    @apply h-full;
    @apply overflow-y-auto;
  }
}

#container-fastlink > div {
  @apply relative h-full;
}
</style>
