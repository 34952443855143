<template>
  <AppSidebar
    v-model:is-open="sidebarOpen"
    :is-short-view="!repositoriesStore.currentRepositoryId"
  >
    <MainTree />
  </AppSidebar>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'

import useAnalyticsStore from '@/store/analytics'
import { useRepositoriesStore } from '@/store/repositories'
import { useSidebarStore } from '@/store/sidebar'

import { AppSidebar } from '@app'

import MainTree from './MainTree.vue'

const analyticsStore = useAnalyticsStore()
const repositoriesStore = useRepositoriesStore()
const sidebarStore = useSidebarStore()

const sidebarOpen = computed({
  get(): boolean {
    return sidebarStore.open && !!repositoriesStore.currentRepositoryId
  },
  set(value: boolean): void {
    sidebarStore.open = value
  },
})

watch(
  sidebarOpen,
  value => {
    if (value) {
      analyticsStore.setActive('MainSidebar', ['tree'])
    } else {
      analyticsStore.setInActive('MainSidebar')
    }
  },
  { immediate: true },
)
</script>

<script lang="ts">
export default {
  name: 'MainSidebar',
}
</script>
