import {
  ConsoleInstrumentation,
  ConsoleTransport,
  ErrorsInstrumentation,
  EventAttributes,
  Faro,
  FetchTransport,
  initializeFaro,
  LogLevel,
  PushErrorOptions,
  SessionInstrumentation,
  WebVitalsInstrumentation,
} from '@grafana/faro-web-sdk'

import { FaroLogFunction } from './utils/types'

let faro: Faro | undefined

export const initFaro = (url: string, apiKey: string, name: string) => {
  if (faro || ['local', 'testing'].includes(name)) return
  faro = initializeFaro({
    instrumentations: [
      new ErrorsInstrumentation(),
      new WebVitalsInstrumentation(),
      new ConsoleInstrumentation({
        disabledLevels: [LogLevel.DEBUG, LogLevel.ERROR, LogLevel.TRACE],
      }),
      new SessionInstrumentation(),
    ],
    transports: [
      new FetchTransport({
        url,
        apiKey,
      }),
      new ConsoleTransport(),
    ],
    app: {
      name,
      version: __APP_VERSION__,
    },
  })
}

export default {
  initUser: (id: string) => {
    faro?.api.setUser({ id })
  },

  measure: (
    type: string,
    values: Record<string, number>,
    params?: Record<string, any>,
  ) => {
    faro?.api.pushMeasurement(
      {
        type,
        values,
      },
      params && {
        context: Object.fromEntries(
          Object.keys(params).map(key => [key, params[key].toString()]),
        ),
      },
    )
  },

  event: (name: string, attrs: EventAttributes) => {
    faro?.api.pushEvent(name, attrs)
  },

  resetUser: () => faro?.api.resetUser(),
  error: (value: Error, options?: PushErrorOptions) =>
    faro?.api.pushError(value, options),

  log: {
    default: (args, context) => faro?.api.pushLog(args, { context }),
    error: (args, context) =>
      faro?.api.pushLog(args, { context, level: LogLevel.ERROR }),
    info: (args, context) =>
      faro?.api.pushLog(args, { context, level: LogLevel.INFO }),
    trace: (args, context) =>
      faro?.api.pushLog(args, { context, level: LogLevel.TRACE }),
    warn: (args, context) =>
      faro?.api.pushLog(args, { context, level: LogLevel.WARN }),
  } as Record<string, FaroLogFunction>,
}
