<template>
  <UIButton
    v-bind="{ size }"
    :icon="ArrowUpTrayIcon"
    label="Export"
    variant="light-gray"
    hide-label-on-mobile
  />
</template>

<script setup lang="ts">
import { inject } from 'vue'

import { ButtonSizes } from '@types'

import { ArrowUpTrayIcon } from '@heroicons/vue/24/outline'

import { UIButton } from '@ui'

const size = inject<ButtonSizes>('size')
</script>

<script lang="ts">
export default {
  name: 'PanelExport',
}
</script>

<style scoped lang="postcss"></style>
