import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import { Transaction } from '@types'

import { prepareResponseError } from '../utils/helpers'

import api, { getPaginatedRequest } from '@/store/api'
import useAnalyticsStore from '@/store/analytics'
import { useRepositoriesStore } from '@/store/repositories'

export const useTransactionsDeletedStore = defineStore(
  'transactions-deleted',
  () => {
    // INIT
    const repositoriesStore = useRepositoriesStore()
    const analyticsStore = useAnalyticsStore()

    const list = ref<Transaction[]>([])

    const initFlag = ref(false)

    const loading = ref(true)

    let abortController = new AbortController()

    const repositoryId = computed(() => repositoriesStore.currentRepositoryId)

    // GETTERS

    const getList = computed(() => list.value)

    // SETTERS

    const setList = (data: Transaction[]) => {
      list.value = data
    }

    const fetch = async (): Promise<void> => {
      loading.value = true
      try {
        const { data } = await getPaginatedRequest<Transaction>(
          'transactions',
          {
            repository_id: repositoryId.value,
            show_deleted: true,
          },
        )
        setList(data)
        initFlag.value = true
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loading.value = false
      }
    }

    const restore = async (id: string): Promise<void> => {
      try {
        await api.post(`transactions/${id}/restore`, {})
        analyticsStore.markAsDeprecated()
      } catch (e) {
        throw Error(prepareResponseError(e))
      }
    }

    const bulkRestore = async (ids: string[]): Promise<void> => {
      try {
        await api.post(
          `transactions/restore?repository_id=${repositoryId.value}`,
          ids,
        )
      } catch (e) {
        throw Error(prepareResponseError(e))
      }
    }

    const permanentDelete = async (id: string): Promise<void> => {
      try {
        await api.delete(`transactions/${id}?is_hard=1`)
      } catch (e) {
        throw Error(prepareResponseError(e))
      }
    }

    const bulkPermanentDelete = async (ids: string[]): Promise<void> => {
      try {
        await api.delete(`transactions?is_hard=1`, { data: ids })
        setList(getList.value.filter(item => !ids.includes(item.id)))
      } catch (e) {
        throw Error(prepareResponseError(e))
      }
    }

    const cancel = () => {
      abortController.abort()
      abortController = new AbortController()
    }

    const clear = () => {
      initFlag.value = false
      setList([])
    }

    return {
      initFlag,
      loading,

      getList,

      fetch,

      restore,
      bulkRestore,
      permanentDelete,
      bulkPermanentDelete,

      cancel,
      clear,
    }
  },
)
