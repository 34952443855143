import { EntityFields } from '@/entities/utils/types'
import { Tag } from './types'

export { TAG_ID_PREFIX } from '@/const/common'

export const TAG_FIELD: EntityFields<Tag> = {
  ID: 'id',
  TAG_NAME: 'tag_name',
  TAG_VALUE: 'tag_value',
  REPOSITORY_ID: 'repository_id',
  ASSETS: 'assets',
  CREATED_AT: 'created_at',
  CREATED_BY: 'created_by',
  UPDATED_AT: 'updated_at',
  UPDATED_BY: 'updated_by',
}

export const TAG_DEFAULT_DATA = {
  id: '',
  tag_name: '',
  tag_value: '',
  repository_id: '',
  assets: [],
}

export const TAG_FIELD_LABEL = {
  [TAG_FIELD.TAG_NAME]: 'Name',
  [TAG_FIELD.TAG_VALUE]: 'Value',
  [TAG_FIELD.CREATED_AT]: 'Created at',
  [TAG_FIELD.UPDATED_AT]: 'Updated at',
}
