<template>
  <div :data-refid="refId" class="asset-tab" :class="mainClasses">
    {{ label }}
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

type Props = {
  label: string
  active?: boolean
}

const props = defineProps<Props>()

const mainClasses = computed(() => ({
  'asset-tab--active': props.active,
}))

const refId = computed(() => `assetFormTab${props.label}`)
</script>

<script lang="ts">
export default {
  name: 'AssetTabsItem',
}
</script>

<style scoped>
@import url('./styles/asset.tabs.css');
</style>
