<template>
  <div class="draggable__wrapper">
    <div class="draggable__label">{{ label }}</div>
    <slot />
  </div>
</template>

<script setup lang="ts">
type Props = {
  label: string
}

defineProps<Props>()
</script>

<script lang="ts">
export default {
  name: 'UIDraggableWrapper',
}
</script>

<style scoped>
.draggable__wrapper {
  @apply px-4 pt-2 pb-3 inline-block space-y-2;
  @apply border rounded;
  @apply border-gray-200 dark:border-gray-600;
}
.draggable__label {
  @apply text-xs text-gray-500 dark:text-gray-400;
}
</style>
