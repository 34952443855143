<template>
  <div v-if="isNegative" class="field__sign">
    <MinusIcon aria-hidden="true" />
  </div>
  <input
    v-bind="$attrs"
    ref="inputRef"
    v-model="formattedValue"
    v-number="numberMask"
    v-tooltip="{
      content: tooltipMessage,
      shown: tooltipVisible,
      triggers: ['manual'],
    }"
    :placeholder="inputPlaceholder"
    :class="inputClass"
    @keydown="handleKeyDown"
  />
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useTimeoutFn } from '@vueuse/core'

import { directive as vNumber } from '@coders-tm/vue-number-format'

import { NumberFieldValue } from './utils/types'

import {
  MUST_BE_NEGATIVE_MESSAGE,
  MUST_BE_POSITIVE_MESSAGE,
} from '@/helpers/validate'

import { MinusIcon } from '@heroicons/vue/24/outline'

type Props = {
  isAlwaysNegative?: boolean
  isAlwaysPositive?: boolean
  placeholder?: string
  isPercentage?: boolean
}
const { isAlwaysNegative, isAlwaysPositive, ...props } = defineProps<Props>()

const modelValue = defineModel<NumberFieldValue>({ required: true })

const inputRef = ref<HTMLInputElement | null>(null)

defineExpose({
  blur() {
    inputRef.value?.blur()
  },
  focus() {
    inputRef.value?.focus()
  },
  select() {
    inputRef.value?.select()
  },
})

const tooltipVisible = ref(false)
const tooltipMessage = computed(() =>
  isAlwaysNegative ? MUST_BE_NEGATIVE_MESSAGE : MUST_BE_POSITIVE_MESSAGE,
)

const numberMask = computed(() => ({
  precision: 10,
  ...(props.isPercentage ? { suffix: '%' } : {}),
}))

const { start, stop } = useTimeoutFn(() => {
  tooltipVisible.value = false
}, 1500)

const showTooltip = () => {
  stop()
  tooltipVisible.value = true
  start()
}

const isNegative = ref(false)

const inputPlaceholder = computed(
  () => props.placeholder ?? `0.00${props.isPercentage ? '%' : ''}`,
)

const inputClass = computed(() => ({
  'field__input--with-sign': isNegative.value,
}))

const formattedValue = ref('')

const parseNumber = (value: string) => {
  return value.replace(/[^0-9.]/g, '')
}

const handleKeyDown = (event: KeyboardEvent) => {
  if (!['-', '+'].includes(event.key)) return

  event.preventDefault()

  if (
    (isAlwaysNegative && isNegative.value) ||
    (isAlwaysPositive && !isNegative.value)
  ) {
    showTooltip()
    return
  }

  isNegative.value = !isNegative.value
  if (modelValue.value) {
    modelValue.value *= -1
  }
}

const updateInput = (value: NumberFieldValue) => {
  if (value === null) {
    formattedValue.value = ''
    return
  }
  value = Number(value)

  if (value && value < 0) {
    isNegative.value = true
  }

  formattedValue.value = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 10,
    style: props.isPercentage ? 'percent' : 'decimal',
  }).format(Math.abs(value))
}

const updateModel = () => {
  const value = parseNumber(formattedValue.value)
  if (!value) {
    modelValue.value = null
    return
  }

  const newValue = Number(value) * (isNegative.value ? -1 : 1)
  modelValue.value = props.isPercentage ? newValue / 100 : newValue
}

watch(
  () => [isAlwaysNegative, isAlwaysPositive],
  ([isAlwaysNegative, isAlwaysPositive]) => {
    isNegative.value = isAlwaysNegative || !isAlwaysPositive
    updateModel()
  },
)

watch(modelValue, updateInput, { immediate: true })
watch(formattedValue, value => {
  if (value.startsWith('-')) {
    if (isAlwaysPositive) {
      showTooltip()
    } else {
      isNegative.value = true
    }
    formattedValue.value = value.slice(1)
  }
  updateModel()
})

onMounted(() => {
  if (!inputRef.value) return
  isNegative.value = modelValue.value ? modelValue.value < 0 : isAlwaysNegative
})
</script>

<script lang="ts">
export default {
  name: 'AppNumberField',
  inheritAttrs: false,
}
</script>

<style scoped>
@import './styles/data-field.input.css';
@import './styles/data-field.number.css';
</style>
