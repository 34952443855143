<template>
  <UILabeledField v-model="actionValue" v-bind="{ size }" />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { TransactionRule } from '@types'

import { CONTROL_SIZE as size } from '../utils/const'

import { UILabeledField } from '@ui'

const modelValue = defineModel<TransactionRule['data']['actions'][number]>({
  required: true,
})

const actionValue = computed({
  get() {
    return modelValue.value.value
  },
  set(value) {
    modelValue.value.value = value || ''
  },
})
</script>

<script lang="ts">
export default {
  name: 'LinkedDataRulesActionCounterparty',
}
</script>
