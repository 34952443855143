<template>
  <div
    class="ui-input-dropdown__item group"
    :class="mainClasses"
    @click="handleClick"
  >
    <slot name="prefix" />
    <slot>
      <div data-refid="inputDropdownItem" class="ui-input-dropdown__item-text">
        {{ displayValue }}
      </div>
    </slot>
    <slot name="postfix" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { InputDropdownItem } from '../utils/types'
import { InputData } from '../../utils/types'

type Props = {
  item: InputDropdownItem
  idKey: string
  valueKey: string
  currentValue?: InputData
  selected?: boolean
}

type Emits = {
  'click:item': [data: InputDropdownItem]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const mainClasses = computed(() => ({
  'ui-input-dropdown__item--active':
    props.currentValue === props.item[props.idKey],
  'ui-input-dropdown__item--selected': props.selected,
}))

const displayValue = computed(() => props.item[props.valueKey])

const handleClick = () => {
  emit('click:item', props.item)
}
</script>

<script lang="ts">
export default {
  name: 'InputDropdownItem',
}
</script>

<style lang="postcss">
.ui-input-dropdown {
  &__item {
    @apply flex items-center justify-between;
    @apply px-4 py-2;
    @apply gap-2;
    @apply text-sm;
    @apply hover:bg-gray-100 dark:hover:bg-gray-850;
    @apply text-gray-700 dark:text-gray-300;
    @apply hover:text-gray-900 dark:hover:text-gray-100;
    @apply cursor-pointer;

    &--active {
      @apply bg-gray-50 text-indigo-600;
      @apply dark:bg-gray-850 dark:text-indigo-400;
    }

    &--selected {
      @apply bg-indigo-500 text-white;
      @apply dark:bg-indigo-400 dark:text-gray-900;
    }

    &-text {
      @apply text-ellipsis overflow-hidden;
    }
  }
}
</style>
