<template>
  <div class="alert" :class="`alert--${alert.type}`">
    <div class="text-sm font-medium">
      {{ message }}
    </div>
    <button
      v-if="alert.closeable"
      class="alert__close-button"
      @click="remove(alert.instance)"
    >
      <span class="sr-only">Close</span>
      <XMarkIcon class="h-5 w-5" aria-hidden="true" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { MainAlert } from './types'

import { useAlerts } from './'

import { XMarkIcon } from '@heroicons/vue/20/solid'

type Props = {
  alert: MainAlert
}

const props = defineProps<Props>()

const { remove } = useAlerts()

const message = computed(() => props.alert.message)
</script>

<script lang="ts">
export default {
  name: 'AlertItem',
}
</script>

<style scoped lang="postcss">
.alert {
  @apply flex items-center justify-between p-4 rounded-md;

  &--progress {
    @apply bg-indigo-50 text-indigo-800;
    @apply dark:bg-indigo-900 dark:text-indigo-300;
  }
  &--success {
    @apply bg-green-50 text-green-800;
    @apply dark:bg-green-900 dark:text-green-300;
  }
  &--error {
    @apply bg-red-50 text-red-800;
    @apply dark:bg-red-900 dark:text-red-300;
  }
  &--warn {
    @apply bg-yellow-50 text-yellow-800;
    @apply dark:bg-yellow-900 dark:text-yellow-300;
  }

  &__close-button {
    @apply bg-transparent rounded-md inline-flex;
    @apply text-gray-400 hover:text-gray-500;
    @apply dark:text-gray-200 dark:hover:text-gray-300;
    @apply focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
  }
}
</style>
