<template>
  <UITagsList
    :model-value="tags"
    v-bind="{ list: tagsList, readonly }"
    @mousedown.stop
    @mouseup.stop
    @update:model-value="handleUpdateTransactionTags"
    @item:click="handleTagClick"
    @item:rename="handleTagRename"
    @plus:click="handleTagPlusClick"
  />
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'

import { CommonTag, LinkedDataTransaction, ReadonlyMode } from '@types'

import { NOTIFICATION_DELAY, READONLY_MODE } from '@/const/common'

import { handleCatchedError } from '@/helpers/common'

import { useNotifications } from '@/plugins/notification'

import { useLinkedDataTransactionsStore } from '@/store/linkedData/transactions'
import { useLinkedDataTransactionsTagsStore } from '@/store/linkedData/transactionsTags'

import { UITagsList } from '@ui'

type Props = {
  id: string
  tags: CommonTag[]
  isStoreUsed?: boolean
}

type Emits = {
  'click:tag': [data: string]
  'click:assign': [data: string]
  update: [data: LinkedDataTransaction[]]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineExpose({
  async exclude(transaction: LinkedDataTransaction) {
    const tags = [...transaction.tags]
    if (excludeTag.value) tags.push(excludeTag.value)

    handleUpdateTransactionTags(tags)
  },
})

const { progress, remove, update } = useNotifications()

const linkedDataTransactionsStore = useLinkedDataTransactionsStore()
const linkedDataTransactionsTagsStore = useLinkedDataTransactionsTagsStore()

const readonly = inject<ReadonlyMode>(READONLY_MODE)

const tagsList = computed(() => linkedDataTransactionsTagsStore.getList)
const excludeTag = computed(() =>
  tagsList.value.find(tag => tag.name === 'exclude'),
)

const handleTagClick = (value: string) => {
  emit('click:tag', value)
}

const handleTagRename = (id: string, name: string) => {
  linkedDataTransactionsTagsStore.update({ id, name })
}

const handleTagPlusClick = () => {
  emit('click:assign', props.id)
}

const handleUpdateTransactionTags = async (data: CommonTag[]) => {
  const dataArray = {
    id: props.id,
    tags: data.map(tag => `${tag.id}`),
  }
  const nid = await progress({
    message: 'Update transaction tags',
  })
  try {
    const result = await linkedDataTransactionsStore.bulkUpdate([dataArray])
    if (nid) {
      await update(
        nid,
        {
          type: 'success',
          message: 'Tags were updated',
        },
        NOTIFICATION_DELAY,
      )
    }
    if (props.isStoreUsed) return
    emit('update', result)
  } catch (e) {
    if (nid) await remove(nid)
    handleCatchedError(e as string)
  }
}
</script>

<script lang="ts">
export default {
  name: 'TransactionsListTags',
}
</script>

<style lang="postcss"></style>
