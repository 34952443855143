import { Ref, computed, onMounted, ref } from 'vue'
import { orderBy } from 'lodash'

import { Sort } from '@types'
import { GridColumn, GridItem } from '@types'
import { watchDebounced } from '@vueuse/core'

const useGridSort = <T extends GridItem>(
  sort: Ref<Sort[] | undefined>,
  columns: Ref<GridColumn[]>,
  items: Ref<T[]>,
  exclude?: string[],
  defaultIteratee?: Ref<string | undefined>,
  defaultOrder?: Ref<'asc' | 'desc' | undefined>,
) => {
  const debouncedSort = ref<Sort[]>()

  const iteratees = computed(() =>
    debouncedSort.value?.length
      ? debouncedSort.value.map(s => {
          const { field } = s
          const column = columns.value.find(col => col.name === field)
          return column?.formatter && !exclude?.includes(field)
            ? (item: T) => column.formatter?.(item[field as keyof T], item)
            : field
        })
      : defaultIteratee?.value,
  )

  const orders = computed(() =>
    debouncedSort.value?.length
      ? debouncedSort.value.map(s => s.sortDirection)
      : defaultOrder?.value,
  )

  const sortedItems = computed(() => {
    if (iteratees.value?.length || defaultIteratee?.value) {
      return orderBy(items.value, iteratees.value, orders.value)
    }
    return items.value
  })

  watchDebounced(
    sort,
    value => {
      debouncedSort.value = value
    },
    { debounce: 1000 },
  )

  onMounted(() => {
    debouncedSort.value = sort.value
  })

  return sortedItems
}

export default useGridSort
