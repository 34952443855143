<template>
  <nav aria-label="Sidebar" :class="sidebarClasses">
    <template v-if="isOpen">
      <slot />
    </template>
    <div v-if="isOpen" class="sidebar__bottom">
      <slot name="bottom" />
      <div v-if="!toTablet" class="sidebar__version" :title="appVersion">
        {{ appVersion }}
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useResponsive } from '@/plugins/responsiveUI'

type Props = {
  isOpen: boolean
}

const props = defineProps<Props>()

const { toTablet } = useResponsive()

const sidebarClasses = computed(() => ({
  sidebar: true,
  'md:w-80 lg:w-96 shrink-0': props.isOpen,
}))

// eslint-disable-next-line no-undef
const appVersion = `${__APP_VERSION__ || ''}`
</script>
<script lang="ts">
export default {
  name: 'AppSidebar',
}
</script>

<style>
.sidebar {
  @apply flex flex-col shrink-0 max-h-full;
  @apply bg-gray-50 md:bg-gray-100;
  @apply dark:bg-gray-750;
}
.sidebar__header {
  @apply px-4 py-2;
  @apply flex justify-between z-30;
}
.sidebar__bottom {
  @apply flex flex-col py-2 px-4 overflow-visible;
}
.sidebar__version {
  @apply mt-3 text-center text-xs truncate;
  @apply text-gray-400 dark:text-gray-500;
}
</style>
