<template>
  <div class="skeleton-tree__item" :class="mainClasses">
    <div class="skeleton-tree__item__text">
      <div class="skeleton-tree__item__text__content" :class="contentClasses" />
    </div>
    <div class="skeleton-tree__item__balances">
      <div class="skeleton-tree__item__balances__value" :class="valueClasses" />
      <div
        class="skeleton-tree__item__balances__amount"
        :class="amountClasses"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

type Props = {
  depth?: 1 | 2
  first?: boolean
}

const props = defineProps<Props>()

const mainClasses = computed(() => ({
  [`skeleton-tree__item--depth${props.depth || 0}`]: true,
  'skeleton-tree__item--first': props.first,
}))

const contentClasses = computed(() => [
  `skeleton-tree__item__text__content--depth${props.depth || 0}`,
])

const valueClasses = computed(() => [
  `skeleton-tree__item__balances__value--depth${props.depth || 0}`,
])

const amountClasses = computed(() => [
  `skeleton-tree__item__balances__amount--depth${props.depth || 0}`,
])
</script>

<script lang="ts">
export default {
  name: 'SkeletonTreeItem',
}
</script>

<style scoped lang="postcss">
.skeleton-tree {
  &__item {
    @apply flex;
    @apply relative;
    @apply pr-5;
    @apply gap-x-2;

    @apply before:content-[''];
    @apply before:absolute before:bottom-1/2 before:right-full;
    @apply before:w-3;
    @apply before:border before:border-dotted;
    @apply before:border-gray-300 dark:border-gray-700;
    @apply before:border-t-0 before:border-r-0;

    @apply after:content-[''];
    @apply after:absolute after:bottom-1/2 after:left-0;
    @apply before:h-12;
    @apply after:border-l after:border-dotted;
    @apply after:border-l-gray-300 dark:border-l-gray-700;

    &--first {
      @apply before:h-[2.4rem];
    }
    &--first.skeleton-tree__item--depth1 {
      @apply before:h-[1.85rem];
    }

    &--depth0 {
      @apply before:hidden;
      @apply after:hidden;
      @apply mb-[0.75rem];
    }

    &--depth1 {
      @apply after:hidden;
      @apply ml-[1.2rem];
      @apply mb-[1.3rem];
    }

    &--depth2 {
      @apply ml-[2.4rem];
      @apply mb-[1.3rem];
      @apply after:h-12 after:-ml-[1.95rem];
    }

    &__text__content,
    &__balances__value,
    &__balances__amount {
      @apply animate-pulse;

      &--depth0 {
        @apply bg-gray-300 dark:bg-gray-700;
      }

      &--depth1 {
        @apply bg-gray-200 dark:bg-gray-600;
      }

      &--depth2 {
        @apply bg-gray-200 dark:bg-gray-600;
      }
    }

    &__text {
      @apply flex-auto;

      &__content {
        @apply w-full h-6;
        @apply rounded;

        &--sm {
          @apply w-1/3;
        }

        &--md {
          @apply w-1/2;
        }

        &--lg {
          @apply w-2/3;
        }
      }
    }

    &__balances {
      @apply flex flex-col items-end;
      @apply gap-y-1;

      &__value,
      &__amount {
        @apply h-3;
        @apply rounded-sm;
      }

      &__value {
        @apply w-20;
      }
      &__amount {
        @apply w-8;
      }
    }
  }
}
</style>
