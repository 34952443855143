<template>
  <input
    ref="inputRef"
    :value="modelValue"
    autocomplete="email"
    type="email"
    @input="handleInput"
    @keydown.enter.prevent
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'

const modelValue = defineModel<string>({ required: true })

const inputRef = ref<HTMLInputElement | null>(null)

defineExpose({
  blur() {
    inputRef.value?.blur()
  },
  focus() {
    inputRef.value?.focus()
  },
  select() {
    inputRef.value?.select()
  },
  begin() {
    if (!modelValue.value) return
    inputRef.value?.setSelectionRange(
      modelValue.value.length,
      modelValue.value.length,
    )
  },
})

const handleInput = (e: Event) => {
  modelValue.value = (e.target as HTMLInputElement).value
}
</script>

<script lang="ts">
export default {
  name: 'AppEmailField',
}
</script>
