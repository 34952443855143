<template>
  <TransactionFormAmount
    ref="fieldRef"
    v-bind="{ ...$attrs, instance }"
    :entry-index="2"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { TransactionClass } from '..'

import TransactionFormAmount from './TransactionFormAmount.vue'

type Props = {
  instance: TransactionClass
}

defineProps<Props>()

defineExpose({
  focus() {
    fieldRef.value?.focus()
  },
})

const fieldRef = ref<typeof TransactionFormAmount>()
</script>

<script lang="ts">
export default {
  name: 'TransactionFormAmount3',
}
</script>
