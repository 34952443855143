<template>
  <component
    :is="instance.entity.getModal()"
    v-bind="instance.props"
    :modal="instance"
  />
</template>

<script setup lang="ts">
import { ModalFactoryInstance } from './utils/types'

type Props = {
  instance: ModalFactoryInstance
}

defineProps<Props>()
</script>

<script lang="ts">
export default {
  name: 'ModalFactoryItem',
}
</script>
