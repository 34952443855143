import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { Capacitor } from '@capacitor/core'

import { UserPlan } from '@types'
import { READONLY_MODE, READWRITE_MODE, ROUTE_NAME } from '@/const'

import { useNotifications } from '@/plugins/notification'

import { prepareResponseError } from '@/store/utils/helpers'

import { useUserStore } from '@/store/user'

export async function requireAuth(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
): Promise<void> {
  const { error } = useNotifications()
  const userStore = useUserStore()
  try {
    await userStore.auth()
    if (!userStore.isAuthenticated) {
      throw 'You need to be authorized'
    }
    next()
  } catch (e) {
    const message = prepareResponseError(e)
    await error({ message })
    next({ name: ROUTE_NAME.SIGNIN })
  }
}

export async function isAlreadyAuth(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
): Promise<void> {
  const userStore = useUserStore()
  try {
    await userStore.auth()
    if (userStore.isAuthenticated) {
      next({ name: ROUTE_NAME.DASHBOARDS })
    } else {
      throw undefined
    }
  } catch {
    next()
  }
}

export async function redirectToSignin(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
): Promise<void> {
  if (
    Capacitor.isNativePlatform() ||
    window.location.origin.includes('//app.')
  ) {
    next({ name: ROUTE_NAME.SIGNIN })
  } else {
    next()
  }
}

export async function deniedIfReadonly(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
): Promise<void> {
  // @ts-ignore
  if (window[READONLY_MODE]) {
    next({ name: ROUTE_NAME.PERMISSION_DENIED })
  } else {
    next()
  }
}

export async function deniedIfReadWrite(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
): Promise<void> {
  // @ts-ignore
  if (window[READWRITE_MODE]) {
    next({ name: ROUTE_NAME.PERMISSION_DENIED })
  } else {
    next()
  }
}

export async function checkAccess(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
): Promise<void> {
  const userStore = useUserStore()
  try {
    if (
      to.meta.access &&
      !(to.meta.access as UserPlan[]).includes(userStore.getUserPlan)
    ) {
      throw undefined
    } else {
      next()
    }
  } catch {
    next({ name: ROUTE_NAME.UPGRADE_PLAN })
  }
}
