<template>
  <Block v-bind="{ title }" v-model="toggleValue">
    <component :is="component" v-if="toggleValue" v-model="modelValue" />
  </Block>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from 'vue'

import { TransactionRule, TransactionRuleType } from '@types'

import Block from './components/Block.vue'
import CriteriaAccount from './components/CriteriaAccount.vue'
import CriteriaAmount from './components/CriteriaAmount.vue'
import CriteriaCategory from './components/CriteriaCategory.vue'
import CriteriaStatus from './components/CriteriaStatus.vue'
import CriteriaText from './components/CriteriaText.vue'
import CriteriaTransactionType from './components/CriteriaTransactionType.vue'

type Props = {
  name: TransactionRuleType
}

const props = defineProps<Props>()

const modelValue = defineModel<TransactionRule['data']['criteria'][number]>({
  default: {
    type: undefined,
    comparison: undefined,
    value: undefined,
  },
})

const toggleValue = ref(false)

const title = computed(
  () => `${props.name.charAt(0).toUpperCase()}${props.name.slice(1)}`,
)

const component = computed(() => {
  switch (props.name) {
    case TransactionRuleType.ACCOUNT:
      return CriteriaAccount
    case TransactionRuleType.AMOUNT:
      return CriteriaAmount
    case TransactionRuleType.CATEGORY:
      return CriteriaCategory
    case TransactionRuleType.STATUS:
      return CriteriaStatus
    case TransactionRuleType.TRANSACTION_TYPE:
      return CriteriaTransactionType
    default:
      return CriteriaText
  }
})

watch(toggleValue, value => {
  if (value) return
  modelValue.value.comparison = undefined
  modelValue.value.value = undefined
})

onBeforeMount(() => {
  toggleValue.value = modelValue.value.comparison !== undefined
})
</script>

<script lang="ts">
export default {
  name: 'LinkedDataRulesSettingsCriteria',
}
</script>
