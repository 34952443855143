<template>
  <input
    v-model="modelValue"
    type="checkbox"
    class="checkbox"
    @change="handleChange"
  />
</template>

<script setup lang="ts">
type Emits = {
  change: [data: boolean]
}

const emit = defineEmits<Emits>()

const modelValue = defineModel<(string | number)[] | boolean>()

const handleChange = (event: Event) => {
  const flag = (event?.target as HTMLInputElement).checked
  emit('change', flag)
}
</script>

<script lang="ts">
export default {
  name: 'GridCheckbox',
}
</script>
