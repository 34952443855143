import { ref } from 'vue'

import { appendScript } from '@/helpers/common'

const {
  VITE_APP_GOOGLE_DRIVE_PICKER_CLIENT_ID,
  VITE_APP_GOOGLE_DRIVE_PICKER_API_KEY,
} = import.meta.env

const SCOPE = 'https://www.googleapis.com/auth/drive.file'

export const useGooglePicker = () => {
  const callbackFunction = ref<(data: google.picker.DocumentObject) => void>()
  const accessToken = ref<string>()
  const tokenClient = ref()
  const pickerInited = ref<boolean>(false)
  const gisInited = ref<boolean>(false)

  const onApiLoad = () => {
    gapi.load('picker', onPickerApiLoad)
  }

  const onPickerApiLoad = () => {
    pickerInited.value = true
  }

  const gisLoaded = () => {
    tokenClient.value = google.accounts.oauth2.initTokenClient({
      client_id: VITE_APP_GOOGLE_DRIVE_PICKER_CLIENT_ID,
      scope: SCOPE,
      callback: async response => {
        if (response.error !== undefined) {
          throw response
        }
        accessToken.value = response.access_token
        showPicker()
      },
    })
    gisInited.value = true
  }

  const showPicker = () => {
    if (!accessToken.value) return
    const picker = new google.picker.PickerBuilder()
      .addView(google.picker.ViewId.DOCS)
      .setOAuthToken(accessToken.value)
      .setDeveloperKey(VITE_APP_GOOGLE_DRIVE_PICKER_API_KEY)
      .setCallback(pickerCallback)
      .build()
    picker.setVisible(true)
  }

  appendScript(
    'https://apis.google.com/js/api.js',
    'apis-google-script-com',
  ).then(onApiLoad)
  appendScript(
    'https://accounts.google.com/gsi/client',
    `accounts-google-script-com`,
  ).then(gisLoaded)

  const pickerCallback = (data: google.picker.ResponseObject) => {
    if (
      callbackFunction.value &&
      data[google.picker.Response.ACTION] == google.picker.Action.PICKED
    ) {
      callbackFunction.value(data[google.picker.Response.DOCUMENTS][0])
    }
  }

  return {
    open: async (callback: (data: google.picker.DocumentObject) => void) => {
      callbackFunction.value = callback
      if (accessToken.value === undefined) {
        tokenClient.value.requestAccessToken({ prompt: 'consent' })
      } else {
        tokenClient.value.requestAccessToken({ prompt: '' })
      }
    },
  }
}
