import { computed } from 'vue'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

export const useResponsive = () => {
  const breakpoints = useBreakpoints(breakpointsTailwind)
  const greaterSm = breakpoints.greater('sm')
  const smallerMd = breakpoints.smaller('md')
  const greaterMd = breakpoints.greater('md')
  const smallerLg = breakpoints.smaller('lg')
  const greaterLg = breakpoints.greater('lg')
  const smallerXl = breakpoints.smaller('xl')

  const isMobile = breakpoints.smaller('sm')
  const isTablet = computed(() => greaterSm.value && smallerMd.value)
  const toTablet = computed(() => smallerMd.value)
  const isDesktop = computed(() => greaterMd.value && smallerLg.value)
  const toDesktop = computed(() => smallerLg.value)
  const isLargeDesktop = computed(() => greaterLg.value && smallerXl.value)
  const toLargeDesktop = computed(() => smallerXl.value)
  const isLargestDesktop = breakpoints.greater('xl')

  return {
    isMobile,
    isTablet,
    toTablet,
    isDesktop,
    toDesktop,
    isLargeDesktop,
    toLargeDesktop,
    isLargestDesktop,
  }
}
