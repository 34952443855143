<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UISlidePanel
      v-bind="{ zIndex, isMinimized, title }"
      @minimize="handleMinimize"
      @hide="handleClose"
    >
      <CurrencyForm v-bind="{ instance: modal.entity }" @close="handleClose" />
      <CurrencyTabs v-if="isSavedAsset" v-bind="{ instance: modal.entity }" />
    </UISlidePanel>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { TransitionRoot } from '@headlessui/vue'

import { AssetClass } from '..'
import { ModalClass, ModalState } from '@types'

import { CURRENCY_MODAL_TITLE_NEW, CURRENCY_MODAL_TITLE } from './utils/const'
import { ASSET_ID_PREFIX } from '../utils/const'

import { useModalsStore } from '@/store/modals'

import { UISlidePanel } from '@ui'
import CurrencyForm from './CurrencyForm.vue'
import CurrencyTabs from './CurrencyTabs.vue'

type Props = {
  modal: ModalClass<AssetClass>
}

const props = defineProps<Props>()

const modalsStore = useModalsStore()

const title = computed(() =>
  props.modal.entity.isNew ? CURRENCY_MODAL_TITLE_NEW : CURRENCY_MODAL_TITLE,
)

const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const isMinimized = computed(() => props.modal.state === ModalState.HIDDEN)

const isSavedAsset = computed(() =>
  props.modal.entity.id.startsWith(ASSET_ID_PREFIX),
)

const zIndex = computed(() => props.modal.zIndex)

const handleMinimize = (flag: boolean) => {
  if (flag) {
    props.modal.hide()
  } else {
    props.modal.show(modalsStore.getZIndex())
  }
}

const handleClose = () => {
  props.modal.conditionalClose()
}
</script>

<script lang="ts">
export default {
  name: 'CurrencySlideover',
}
</script>

<style scoped>
@import url('./styles/asset.css');
</style>
