<template>
  <UIInputDropdown
    v-model="modelValue"
    :data-refid="dataRefid"
    v-bind="{
      data,
      disabled,
      error,
      focusOnLoad,
      label,
      silentError,
      size,
    }"
    id-key="key"
    placeholder="Find an option"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'
import { orderBy } from 'lodash'

import { DocumentClass } from '..'
import { DataFieldSizes, ListItem } from '@types'

import { ASSET_FIELD } from '@/entities/assets/utils/const'
import { DOCUMENT_FIELD } from '../utils/const'

import { useAssetsBunchStore } from '@/store/assets/bunch'

import { UIInputDropdown } from '@ui'

type Props = {
  instance: DocumentClass
  disabled?: boolean
  label?: string
  focusOnLoad?: boolean
  dataRefid?: string
  size?: DataFieldSizes
}

const props = defineProps<Props>()

const assetsBunchStore = useAssetsBunchStore()

const modelValue = props.instance.field<string | undefined>(
  DOCUMENT_FIELD.ASSET_ID,
)

const isSavedDocument = inject('isSavedDocument', ref(false))

const error = computed(() => props.instance.errors.name)
const silentError = computed(() => !isSavedDocument.value)

const data = computed(() => {
  const list = Array.from(assetsBunchStore.getList.entries())
  const mappedList = list.reduce((acc, [key, instance]) => {
    if (instance.isNew) return acc
    acc.push({
      key,
      value: instance.field<string>(ASSET_FIELD.NAME).value,
    })
    return acc
  }, [] as ListItem[])
  const orderedList = orderBy(mappedList, [item => item.value?.toLowerCase()])
  return orderedList
})
</script>

<script lang="ts">
export default {
  name: 'DocumentFormAsset',
}
</script>
