<template>
  <UIInputEditableDropdown
    v-bind="{ data }"
    v-model="modelValue"
    v-model:search="searchModel"
    placeholder="Find or create new"
    id-key="id"
    locked-key="is_system"
    value-key="name"
    @select="handleSelectTag"
    @create="handleCreateTag"
    @update="handleUpdateTag"
    @delete="handleDeleteTag"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { orderBy } from 'lodash'

import { useNotifications } from '@/plugins/notification'
import { useLinkedDataTransactionsTagsStore } from '@/store/linkedData/transactionsTags'

import { NOTIFICATION_DELAY } from '@/const/common'
import { handleCatchedError } from '@/helpers/common'

import { UIInputEditableDropdown } from '@ui'
import { CommonTag } from '@/@types'

type props = {
  selected?: CommonTag[]
}

const { selected } = defineProps<props>()

type Emits = {
  select: [data: CommonTag]
}
const emit = defineEmits<Emits>()

const modelValue = defineModel<string>()
const searchModel = defineModel<string>('search', { default: '' })

const { progress, remove, update } = useNotifications()
const linkedDataTransactionsTagsStore = useLinkedDataTransactionsTagsStore()

const data = computed(() => {
  const tags = linkedDataTransactionsTagsStore.getList.filter(
    tag => !selected?.find(selectedTag => selectedTag.id === tag.id),
  )
  return orderBy(tags, 'name')
})

const handleSelectTag = (value: CommonTag) => {
  emit('select', value)
}

const handleCreateTag = async (tag: string) => {
  const nid = await progress({
    message: 'Creating tag',
  })
  try {
    const result = await linkedDataTransactionsTagsStore.store(tag)
    if (!result?.id) throw Error('Unknown tag')
    modelValue.value = result.id
    await update(
      nid,
      {
        type: 'success',
        message: `Tag "${result.name}" created`,
      },
      NOTIFICATION_DELAY,
    )
    handleSelectTag(result)
  } catch (e) {
    if (nid) await remove(nid)
    handleCatchedError(e as string, { tag })
  }
}

const handleUpdateTag = async (id: string, name: string) => {
  const nid = await progress({
    message: 'Updating tag',
  })
  try {
    const result = await linkedDataTransactionsTagsStore.update({
      id,
      name,
    })
    if (nid) {
      await update(
        nid,
        {
          type: 'success',
          message: `Tag ${result?.name} updated`,
        },
        NOTIFICATION_DELAY,
      )
    }
  } catch (e) {
    if (nid) await remove(nid)
    handleCatchedError(e as string, { id, name })
  }
}

const handleDeleteTag = async (id: string) => {
  const nid = await progress({
    message: 'Deleting tag',
  })
  try {
    await linkedDataTransactionsTagsStore.destroy(id)
    if (nid) {
      await update(
        nid,
        {
          type: 'success',
          message: 'Tag deleted',
        },
        NOTIFICATION_DELAY,
      )
    }
  } catch (e) {
    if (nid) await remove(nid)
    handleCatchedError(e as string, { id })
  }
}
</script>

<script lang="ts">
export default {
  name: 'TagsListDropdown',
}
</script>
