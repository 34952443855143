import { AssetClass } from '@/entities/assets'
import { AnalyticsIncomeSources } from './enums'

export const parseSource = (source: AnalyticsIncomeSources[]) => {
  const displayMarketGainAndLoss = source.includes(
    AnalyticsIncomeSources.MARKET_GAIN_AND_LOSS,
  )
  const displayIncomeAndExpense = source.includes(
    AnalyticsIncomeSources.INCOME_AND_EXPENSE,
  )
  const displayTransfers = source.includes(
    AnalyticsIncomeSources.EXCLUDE_TRANSFER,
  )
  return { displayMarketGainAndLoss, displayIncomeAndExpense, displayTransfers }
}

export const filterBySource = <T extends { category_account_id: string }>(
  item: T,
  source: {
    displayMarketGainAndLoss: boolean
    displayIncomeAndExpense: boolean
    displayTransfers: boolean
  },
  assetsBunch: Map<string, AssetClass>,
) => {
  const categoryAccount = assetsBunch.get(item.category_account_id)
  return (
    ((source.displayMarketGainAndLoss &&
      categoryAccount?.isInvestmentAccount) ||
      (source.displayIncomeAndExpense &&
        !categoryAccount?.isInvestmentAccount)) &&
    ((source.displayTransfers && !categoryAccount?.isTransferAccount) ||
      !source.displayTransfers)
  )
}
