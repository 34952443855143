<template>
  <RepositoriesFormInvite
    v-bind="{ disabled, repositoryId }"
    @submit="handleSubmitForm"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import {
  RepositoryCollaborator,
  RepositoryInvite,
  RepositoryInvitePost,
  RepositoryStatus,
} from '@types'

import RepositoriesFormInvite from './components/FormInvite.vue'

import { useNotifications } from '@/plugins/notification'

import { useRepositoriesStore } from '@/store/repositories'
import { handleCatchedError } from '@/helpers/common'

type Props = {
  repositoryId: string
  list: (RepositoryInvite | RepositoryCollaborator)[]
}

const props = defineProps<Props>()

const repositoriesStore = useRepositoriesStore()
const { error } = useNotifications()

const disabled = computed(() => repositoriesStore.loadingCollaboratorsAction)

const handleSubmitForm = async (value: RepositoryInvitePost) => {
  const collaborator = props.list?.find(
    item =>
      item.email === value.email && item.status !== RepositoryStatus.DECLINED,
  )
  if (collaborator) {
    await error({
      message: `User ${value.email} ${
        collaborator.status === RepositoryStatus.PENDING
          ? 'was already invited'
          : 'is already a collaborator'
      }`,
    })
    return
  }
  try {
    await repositoriesStore.addRepositoryInvite(value)
  } catch (e) {
    handleCatchedError(e as string, value)
  }
}
</script>

<script lang="ts">
export default {
  name: 'RepositoryCollaboratorsInvite',
}
</script>
