<template>
  <div class="skeleton-data-field" />
</template>

<script lang="ts">
export default {
  name: 'AppDataField',
}
</script>

<style scoped lang="postcss">
.skeleton-data-field {
  @apply h-[1.875rem];
  @apply bg-gray-200 dark:bg-gray-700;
  @apply animate-pulse;
  @apply rounded;
}
</style>
