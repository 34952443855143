<template>
  <div class="asset-summary-title">
    <div class="asset-summary-title__container">
      <div class="asset-summary-title__area">
        <span class="asset-summary-title__name">{{ name }}</span>
        <span v-if="isTickerVisible" class="asset-summary-title__ticker">
          {{ ticker }}
        </span>
      </div>
      <div v-if="type" class="asset-summary-title__type">
        {{ type }}
      </div>
    </div>
    <div v-if="showDate" class="asset-summary-title__date">
      As of: {{ asOfDate }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { stringToLocalDateString } from '@/helpers/dates'

import { useRepositoriesStore } from '@/store/repositories'

type Props = {
  name: string
  ticker?: string
  type?: string
  showDate?: boolean
}

const props = defineProps<Props>()

const repositoriesStore = useRepositoriesStore()

const isTickerVisible = computed(
  () =>
    !!props.ticker &&
    props.ticker.toLocaleLowerCase() !== props.name.toLocaleLowerCase(),
)
const asOfDate = computed(() =>
  stringToLocalDateString(repositoriesStore.getTimelineEnd),
)
</script>

<script lang="ts">
export default {
  name: 'AssetPanelTitle',
}
</script>

<style lang="postcss">
.asset-summary-title {
  @apply flex items-start justify-between;
  @apply flex-col sm:flex-row;
  @apply gap-2 sm:mr-6;

  &__container {
    @apply flex flex-col;
    @apply gap-y-1;
    @apply leading-none;
  }

  &__area {
    @apply flex items-start sm:items-center;
    @apply flex-col sm:flex-row;
    @apply gap-2;
  }

  &__name {
    @apply text-xl;
    @apply leading-none;
    @apply text-gray-500;
  }

  &__ticker {
    @apply py-0.5 px-2;
    @apply rounded;
    @apply bg-indigo-600;
    @apply text-white;
    @apply text-xs;
  }

  &__type {
    @apply text-xs leading-none;
    @apply text-gray-400;
  }

  &__date {
    @apply flex items-center sm:mt-1;
    @apply leading-none;
    @apply whitespace-nowrap;
    @apply text-gray-400;
  }
}
</style>
