<template>
  <UILabeledField
    v-model="modelValue"
    :data-refid="dataRefid"
    v-bind="{
      disabled,
      error,
      label,
      silentError,
      size,
    }"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'

import { TransactionClass } from '..'
import { DataFieldSizes } from '@types'

import { TRANSACTION_FIELD } from '../utils/const'

import { UILabeledField } from '@ui'

type Props = {
  dataRefid?: string
  disabled?: boolean
  instance: TransactionClass
  label?: string
  size?: DataFieldSizes
}

const props = defineProps<Props>()

const modelValue = props.instance.field<string>(TRANSACTION_FIELD.DESCRIPTION)

const isSavedTransaction = inject('isSavedTransaction', ref(false))

const error = computed(() => props.instance.errors.description)
const silentError = computed(() => !isSavedTransaction.value)
</script>

<script lang="ts">
export default {
  name: 'TransactionFormDescription',
}
</script>
