<template>
  <UIInputDropdown v-model="criteriaComparison" v-bind="{ size, data }" />
  <div v-if="isFieldVisible" class="mapping-rules-criteria-amount">
    <UILabeledField
      v-model="criteriaValue"
      v-bind="{ size, type }"
      placeholder=""
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from 'vue'

import { FilterComparison, FilterField, TransactionRule } from '@types'

import { CONTROL_SIZE as size } from '../utils/const'
import { getComparisonData } from '@/components/UI/Filter/components/utils/helpers'

import { UIInputDropdown, UILabeledField } from '@ui'

const modelValue = defineModel<TransactionRule['data']['criteria'][number]>({
  required: true,
})

const isPercent = ref(false)

const criteriaComparison = computed({
  get() {
    return modelValue.value.comparison
  },
  set(value) {
    modelValue.value.comparison = value
  },
})

const criteriaValue = computed({
  get() {
    return modelValue.value.value
  },
  set(value) {
    modelValue.value.value = value
  },
})

const data = computed(() =>
  getComparisonData({ isAmount: true } as FilterField).map(key => ({
    key,
    value: key,
  })),
)

const type = computed(() => (isPercent.value ? 'percent' : 'number'))

const isFieldVisible = computed(
  () =>
    criteriaComparison.value &&
    ![FilterComparison.EMPTY, FilterComparison.NOTEMPTY].includes(
      criteriaComparison.value,
    ),
)

watch(isPercent, () => {
  criteriaValue.value = null
})

watch(isFieldVisible, value => {
  criteriaValue.value = value ? null : undefined
})

onBeforeMount(() => {
  if (criteriaComparison.value) return
  criteriaComparison.value = FilterComparison.GREATER
})
</script>

<script lang="ts">
export default {
  name: 'LinkedDataRulesCriteriaAmount',
}
</script>

<style lang="postcss">
.mapping-rules-criteria-amount {
  @apply flex items-center;
  @apply gap-4;

  .field__container {
    @apply flex-auto;
  }
}
</style>
