import { RendererElement, RendererNode, VNode, h } from 'vue'

import { Transaction, TransactionSettings } from '@types'

import { TRANSACTION_ID_PREFIX } from '@/const/common'

import { numberFormat } from '@/helpers/numbers'

export const prepareTransactionAmount = (
  rule: string | undefined,
  amount: number | null,
  unformated?: boolean,
) => {
  const isPercentField = rule === 'percent'
  const amountValue = isPercentField ? (amount || 0) * 100 : amount || 0
  return `${unformated ? amountValue : numberFormat(amountValue)}${
    isPercentField ? '%' : ''
  }`
}

export function getTransactionString(
  transaction: Transaction,
  settingsList: TransactionSettings[],
  getAssetName: (id: string) => string | undefined,
  trim = false,
):
  | VNode<RendererNode, RendererElement, Record<string, unknown>>
  | string
  | undefined {
  const { type, entries: transactionEntries } = transaction
  const entries = transactionEntries
  if (!settingsList) return
  const settings = settingsList?.find(item => item.name === type)
  if (!transaction.id?.startsWith(TRANSACTION_ID_PREFIX)) {
    for (let i = 0; i < (settings?.entries?.length || 0); i++) {
      if (!entries[i]) {
        entries[i] = {
          account_id: '',
          amount: null,
          asset_id: '',
        }
      }
    }
  }
  if (!settings?.printString) return
  const result: (
    | string
    | VNode<RendererNode, RendererElement, Record<string, unknown>>
  )[] = []
  entries.forEach((item, index) => {
    const string = settings.printString[index]
    if (!string) return
    const account = getAssetName(item.account_id) || '...'
    const asset = getAssetName(item.asset_id) || '...'
    const amount = prepareTransactionAmount(
      settings?.entries[index]?.amount?.rule,
      item.amount,
    )
    const childs = string.map(elem => {
      const str =
        elem === 'account'
          ? account
          : elem === 'asset'
            ? asset
            : elem === 'amount'
              ? amount
              : elem
      let spanClass = undefined
      if (elem === 'amount') {
        spanClass = `blurable-number transaction-compact__amount--${
          (item.amount || 0) < 0 ? 'negative' : 'positive'
        }`
      }
      return !str
        ? h('span', { class: 'transaction-compact__loading' }, 'Loading...')
        : trim
          ? str
          : h('span', { class: spanClass }, str)
    })
    if (trim) {
      result.push(childs.join(' '))
    } else {
      if (index !== 0) {
        result.push(h('span', { class: 'transaction-compact__delimeter' }))
      }
      result.push(...childs)
    }
  })
  return trim
    ? result.join(' ')
    : h('div', { class: 'transaction-compact__description' }, result)
}
