import { set } from 'lodash'
import { AnalyticsTree, AnalyticsPreparedTree } from './types'

export const prepareTree = (data?: AnalyticsTree[]) => {
  if (!data?.length) return
  const result: AnalyticsPreparedTree = {}
  for (let i = 0; i < data.length; i++) {
    const { path } = data[i]
    set(result, path, data[i])
  }
  return result
}
