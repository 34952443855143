import { EntityFields } from '@/entities/utils/types'
import { Transaction, TransactionEntry } from './types'

export { TRANSACTION_ID_PREFIX } from '@/const/common'

export const TRANSACTION_FIELD: EntityFields<Transaction> = {
  ID: 'id',
  DATE: 'date',
  TYPE: 'type',
  DESCRIPTION: 'description',
  ENTRIES: 'entries',
  REPOSITORY_ID: 'repository_id',
  SOURCE: 'source',
  DELETED_AT: 'deleted_at',
  CREATED_AT: 'created_at',
  CREATED_BY: 'created_by',
  UPDATED_AT: 'updated_at',
  UPDATED_BY: 'updated_by',
  LINKED_ID: 'linked_id',
}

export const TRANSACTION_ENTRY_FIELD: EntityFields<TransactionEntry> = {
  ACCOUNT_ID: 'account_id',
  AMOUNT: 'amount',
  ASSET_ID: 'asset_id',
  POS_ID: 'pos_id',
}

export const TRANSACTION_DEFAULT_DATA = {
  id: '',
  date: '',
  type: '',
  description: '',
  entries: [],
  repository_id: '',
}

export const TRANSACTION_FIELD_LABEL = {
  [TRANSACTION_FIELD.DATE]: 'Date',
  [TRANSACTION_FIELD.TYPE]: 'Type',
  [TRANSACTION_FIELD.DESCRIPTION]: 'Description',
  [TRANSACTION_FIELD.CREATED_AT]: 'Created at',
  [TRANSACTION_FIELD.UPDATED_AT]: 'Updated at',
}

export const TRANSACTION_ENTRY_FIELD_LABEL = {
  [TRANSACTION_ENTRY_FIELD.ACCOUNT_ID]: 'Account',
  [TRANSACTION_ENTRY_FIELD.AMOUNT]: 'Amount',
  [TRANSACTION_ENTRY_FIELD.ASSET_ID]: 'Asset',
}
