import { createWebHistory, createRouter } from 'vue-router'
import { cloneDeep } from 'lodash'

import { ROUTE_NAME } from '@/const'

import routes from '@/router/routes'

const { VITE_APP_MODE } = import.meta.env

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0, behavior: 'smooth' }
  },
})

router.beforeEach((to, from, next) => {
  if ((to.meta?.disallowedModes as any)?.includes(VITE_APP_MODE)) {
    next({ name: ROUTE_NAME.PERMISSION_DENIED })
  } else if (
    to.meta?.allowedModes &&
    !(to.meta?.allowedModes as any)?.includes(VITE_APP_MODE)
  ) {
    next({ name: ROUTE_NAME.PERMISSION_DENIED })
  } else if (to.path === '/linked-accounts') {
    next({ path: '/connectors', query: to.query })
  } else if (!from.name && to.name === ROUTE_NAME.DASHBOARDS && to.query.code) {
    const route = cloneDeep(to)
    delete route.query.code
    delete route.query.state
    next(route)
  } else {
    next()
  }
})
export default router
