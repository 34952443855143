<template>
  <div class="transaction-compact">
    <div class="transaction-compact__type">
      {{ displayType }} ({{ displayDate }})
    </div>
    <component :is="displayDescription" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { Transaction } from '@types'

import { stringToLocalDateString } from '@/helpers/dates'

import { getTransactionString } from './utils/helpers'

import { useTransactionsSettingsStore } from '@/store/transactions/settings'
import { useAssetsBunchStore } from '@/store/assets/bunch'

type Props = {
  item: Transaction
}

const props = defineProps<Props>()

const transactionsSettingsStore = useTransactionsSettingsStore()
const assetsBunchStore = useAssetsBunchStore()

const displayType = computed(() => props.item.type)
const displayDate = computed(() => stringToLocalDateString(props.item.date))

const displayDescription = computed(() =>
  getTransactionString(
    props.item,
    transactionsSettingsStore.getList,
    assetsBunchStore.getNameById,
  ),
)
</script>

<script lang="ts">
export default {
  name: 'TransactionCompact',
}
</script>

<style scoped lang="postcss">
.transaction-compact {
  &__type {
    @apply text-gray-900 dark:text-gray-100;
  }

  &__description {
    @apply space-x-1;
    @apply sm:truncate sm:whitespace-nowrap;
  }

  &__delimeter {
    @apply w-1.5 h-1.5;
    @apply inline-block align-middle;
    @apply rounded-full;
    @apply bg-indigo-200;
  }

  &__amount {
    &--positive {
      @apply text-gray-950 dark:text-gray-50;
    }
    &--negative {
      @apply text-red-600 dark:text-red-400;
    }
  }

  &__loading {
    @apply text-gray-400 dark:text-gray-600;
  }
}
</style>
