<template>
  <UIDropdown
    v-bind="{ items, placement }"
    v-model="modelValue"
    id-key="label"
    value-key="label"
    :class="mainClasses"
  >
    <UIButton
      v-bind="{ disabled, icon, full, label, size, variant }"
      :hide-label-on-mobile="!showLabelOnMobile"
      type="button"
      class="h-full"
      @click="modelValue = ''"
    />
  </UIDropdown>
</template>

<script setup lang="ts">
import { computed, h } from 'vue'
import { Placement } from 'floating-vue'

import { ButtonSizes, ButtonVariant } from '@types'

import { LinkIcon, PlusIcon } from '@heroicons/vue/20/solid'
import { UIButton, UIDropdown, UIServiceIcon } from '@ui'

type Props = {
  label?: string
  disabled?: boolean
  placement?: Placement
  variant?: ButtonVariant
  size?: ButtonSizes
  full?: boolean
  hideIcon?: boolean
  showLabelOnMobile?: boolean
}

const {
  label = 'New',
  placement = 'bottom-end',
  variant = 'primary',
  size = 'default',
  ...props
} = defineProps<Props>()

const modelValue = defineModel<string>({ default: '' })

const providers = ['Google Drive', 'Dropbox', 'OneDrive']

const items = computed(() => [
  ...providers.map(label => ({
    label,
    icon: h(UIServiceIcon, { path: label, isNotLink: true }),
  })),
  { label: 'Other Link', icon: LinkIcon },
])

const mainClasses = computed(() => `${props.full ? 'w-full ' : ''}flex`)

const icon = computed(() => (props.hideIcon ? undefined : PlusIcon))
</script>

<script lang="ts">
export default {
  name: 'UIServicesDropdown',
}
</script>
