import * as yup from 'yup'

export const NEGATIVE_RULES = ['negative', 'maxzero']
export const POSITIVE_RULES = ['positive', 'minzero']
export const MAX_STRING_LENGTH = 255

const REQUIRED_MESSAGE = 'Field is required'
const LONG_STRING_MESSAGE = 'Too long string'
const MUST_BE_NUMERIC_MESSAGE = 'Must be numeric'
const MUST_BE_EQUAL_ONE_MESSAGE = 'Must be equal one'
export const MUST_BE_NEGATIVE_MESSAGE = 'Must be negative'
export const MUST_BE_POSITIVE_MESSAGE = 'Must be positive'

export type Rule = yup.AnySchema | yup.AnyObjectSchema

export const rules: Record<string, Rule> = {
  date: yup.date(),
  required: yup
    .string()
    .max(MAX_STRING_LENGTH, LONG_STRING_MESSAGE)
    .trim()
    .required(REQUIRED_MESSAGE),
  limited: yup.string().max(MAX_STRING_LENGTH, LONG_STRING_MESSAGE),
  number: yup.number().typeError(MUST_BE_NUMERIC_MESSAGE),
  positive: yup
    .number()
    .typeError(MUST_BE_NUMERIC_MESSAGE)
    .positive(MUST_BE_POSITIVE_MESSAGE),
  negative: yup
    .number()
    .typeError(MUST_BE_NUMERIC_MESSAGE)
    .negative(MUST_BE_NEGATIVE_MESSAGE),
  minzero: yup
    .number()
    .typeError(MUST_BE_NUMERIC_MESSAGE)
    .min(0, MUST_BE_POSITIVE_MESSAGE),
  maxzero: yup
    .number()
    .typeError(MUST_BE_NUMERIC_MESSAGE)
    .max(0, MUST_BE_NEGATIVE_MESSAGE),
  equalone: yup
    .number()
    .min(1, MUST_BE_EQUAL_ONE_MESSAGE)
    .max(1, MUST_BE_EQUAL_ONE_MESSAGE)
    .typeError(MUST_BE_EQUAL_ONE_MESSAGE),
  percent: yup
    .number()
    .min(-1, 'Must be greater than or equal -100%')
    .notOneOf([0], 'Cannot be zero')
    .max(1, 'Must be less than or equal 100%'),
  accountTags: yup
    .array()
    .of(yup.string())
    .test('Tags', 'Category and Subcategory are required', (item: any) => {
      return item?.filter((item: any) => !!item).length === 2
    }),
}

export const makeRequired = (rule: Rule) =>
  rule.required(REQUIRED_MESSAGE).nullable()

export const oneof = (
  values: string[],
  types: string[],
  optional = false,
  itemType = 'type',
) => {
  let chain
  if (!optional) {
    chain = yup.string().required(REQUIRED_MESSAGE)
  } else {
    chain = yup.string().nullable()
  }
  return chain
    .max(MAX_STRING_LENGTH, LONG_STRING_MESSAGE)
    .trim()
    .test(
      'is-valid-value',
      `The ${itemType} must be${
        types.length > 1 ? ' one of' : ''
      }: ${types.join(', ')}`,
      value => (value ? values.includes(value) : optional || false),
    )
}
