import { AssetClass } from '@/entities/assets'

import { ASSET_FIELD } from '@/entities/assets/utils/const'
import { EMPTY_VALUE_PLACEHOLDER } from '@/const/common'

import { numberFormat } from '@/helpers/numbers'

export const displayAsset = (
  assetId: string | undefined,
  assetsBunch: Map<string, AssetClass>,
) => {
  if (!assetId) return EMPTY_VALUE_PLACEHOLDER
  const asset = assetsBunch.get(assetId)
  return asset?.field<string>(ASSET_FIELD.NAME).value
}

export const displayAmount = (
  amount: number | null | undefined,
  isPercent?: boolean,
  params = { fractionDigits: 2 },
) => {
  if (amount === null || amount === undefined) return EMPTY_VALUE_PLACEHOLDER
  const result = isPercent ? amount * 100 : amount
  return `${numberFormat(result, params)}${isPercent ? '%' : ''}`
}
