export enum TaskStatus {
  WAITING = 'waiting',
  RUNNING = 'running',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
  TIME_OUT = 'time out',
  ABORTED = 'aborted',
  DONE = 'done',
}

export enum TaskType {
  ACCOUNTS_LINK = 'link account',
  DEFAULT = 'default',
  EOD_SEARCH = 'eod search',
  REPOSITORY_IMPORT = 'repository import',
  SYNC_HOLDING_ACCOUNT = 'sync linked account',
  MARKET_DATA_SYNC = 'market data sync',
  ANALYTICS_COMPUTE = 'analytics compute',
}
