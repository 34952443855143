export enum ModalState {
  OPENED = 'opened',
  CLOSED = 'closed',
  HIDDEN = 'hidden',
}

export enum ModalEvent {
  OPEN = 'open',
  CLOSE = 'close',
  HIDE = 'hide',
  SHOW = 'show',
}
