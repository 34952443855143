import { UserPlan } from './enums'

export const CUSTOM_ALLPOSIT_UUID_KEY = 'custom:allposit_uuid'
export const CUSTOM_PASSWORD_PROVIDED = 'custom:password_provided'

export const USER_PLAN_NAME: Record<UserPlan, string> = {
  [UserPlan.PREMIUM]: 'Premium',
  [UserPlan.PREMIUM_PLUS]: 'Premium Plus',
  [UserPlan.ULTRA]: 'Ultra',
}
