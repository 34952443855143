import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { useRepoLocalStorage } from '@/hooks/repoLocalStorage'
import { useResponsive } from '@/plugins/responsiveUI'

import { useRepositoriesStore } from './repositories'

export const useSidebarStore = defineStore('sidebar', () => {
  const repositoriesStore = useRepositoriesStore()
  const { isMobile, isTablet } = useResponsive()

  const storageFlag = useRepoLocalStorage<boolean>('SIDEBAR_OPEN', true)

  const internalFlag = ref(false)

  const open = computed({
    get() {
      if (!repositoriesStore.list?.length) {
        return false
      }
      return isMobile.value || isTablet.value
        ? internalFlag.value
        : storageFlag.value
    },
    set(value) {
      isMobile.value || isTablet.value
        ? (internalFlag.value = value)
        : (storageFlag.value = value)
    },
  })

  return { open }
})
