import { AssetClass } from '@/entities/assets'

import { ASSET_FIELD } from '@/entities/assets/utils/const'

export const prepareAsset = <T extends { asset_id: string }>(
  item: T,
  assetsBunch: Map<string, AssetClass>,
) => {
  const asset_type =
    assetsBunch.get(item.asset_id)?.field<string>(ASSET_FIELD.TYPE).value || ''
  const tags = assetsBunch.get(item.asset_id)?.tags
  return {
    ...item,
    asset_type,
    ...tags,
  }
}
