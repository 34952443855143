import { computed, ref, watch } from 'vue'
import { defineStore } from 'pinia'
import { AxiosResponse } from 'axios'
import { orderBy } from 'lodash'

import { Tag } from '@/entities/tags/utils/types'
import { ApiListResponse } from '../utils/types'

import {
  addStoreListItem,
  prepareResponseError,
  removeStoreListItem,
  updateStoreListItem,
} from '../utils/helpers'

import api from '@/store/api'
import { useAssetsBunchStore } from '../assets/bunch'
import { useRepositoriesStore } from '@/store/repositories'

export const useTagsStore = defineStore('tags', () => {
  // INIT
  const assetsBunchStore = useAssetsBunchStore()
  const repositoriesStore = useRepositoriesStore()

  const list = ref<Tag[]>([])

  const initFlag = ref(false)

  const loading = ref(true)
  const loadingAction = ref(false)

  let abortController = new AbortController()

  const repositoryId = computed(() => repositoriesStore.currentRepositoryId)

  // GETTERS

  const getList = computed<Tag[]>(() =>
    orderBy(
      list.value,
      ['tag_name', 'tag_value'].map(
        field => (item: Tag) =>
          item[field as keyof Tag]?.toString().toLowerCase(),
      ),
      ['asc'],
    ),
  )

  // SETTERS

  const setList = (data: Tag[]) => {
    list.value = data
  }

  const fetch = async (): Promise<Tag[]> => {
    loading.value = true
    try {
      const result = await api.get<ApiListResponse<Tag[]>>('asset_tags', {
        params: {
          repository_id: repositoryId.value,
        },
        signal: abortController.signal,
      })
      const data = result.data.data
      setList(data)
      initFlag.value = true
      return data
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loading.value = false
    }
  }

  const store = async (tag: Tag): Promise<Tag> => {
    loadingAction.value = true
    try {
      const result = await api.post<Tag, AxiosResponse<Tag>, Tag>(
        'asset_tags',
        { ...tag, repository_id: repositoryId.value },
      )
      const data = result.data
      addStoreListItem<Tag>(list.value, data)
      return data
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loadingAction.value = false
    }
  }

  const update = async (tag: Tag): Promise<Tag | undefined> => {
    loadingAction.value = true
    try {
      const { id } = tag
      const result = await api.put<Tag, AxiosResponse<Tag>, Tag>(
        `asset_tags/${id}`,
        tag,
      )
      const data = result.data
      updateStoreListItem<Tag>(list.value, data)
      return data
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loadingAction.value = false
    }
  }

  const destroy = async (id: string): Promise<void> => {
    loadingAction.value = true
    try {
      await api.delete(`asset_tags/${id}`)
      removeStoreListItem<Tag>(list.value, id)
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loadingAction.value = false
    }
  }

  const cancel = () => {
    abortController.abort()
    abortController = new AbortController()
  }

  const clear = () => {
    initFlag.value = false
    list.value = []
  }

  watch(list, () => {
    for (const instance of assetsBunchStore.getIncomeAccountsList.values()) {
      instance.resetTags()
    }
    for (const instance of assetsBunchStore.getExpenseAccountsList.values()) {
      instance.resetTags()
    }
  })

  return {
    initFlag,
    loading,
    loadingAction,

    getList,

    fetch,

    store,
    update,
    destroy,

    cancel,
    clear,
  }
})
