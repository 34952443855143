<template>
  <nav aria-label="Tabs" class="asset-tabs">
    <template v-for="(label, key) in ASSET_TABS" :key="key">
      <AssetTabsItem
        v-bind="{ label, active: currentTab === key }"
        @click="handleTabChange(key)"
      />
      <div
        v-if="key !== Object.keys(ASSET_TABS).pop()"
        class="asset-tabs__separator"
      />
    </template>
  </nav>
  <div class="asset-tabs__body">
    <AssetTabSecurityMaster
      v-if="currentTab === AssetTab.SECURITY_MASTER"
      :key="securityMasterComponentKey"
      v-bind="{ instance }"
    />
    <AssetTabPrices
      v-if="currentTab === AssetTab.PRICES"
      v-bind="{ instance }"
    />
    <AssetTabTags v-if="currentTab === AssetTab.TAGS" v-bind="{ instance }" />
    <AssetTabDocuments
      v-if="currentTab === AssetTab.DOCUMENTS"
      v-bind="{ instance }"
    />
    <AssetTabContacts
      v-if="currentTab === AssetTab.CONTACTS"
      v-bind="{ instance }"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { AssetClass } from '..'
import { AssetTab } from './utils/enums'

import { ASSET_TABS } from './utils/const'
import { ASSET_FIELD } from '../utils/const'
import { ROUTE_NAME } from '@/const'

import AssetTabsItem from './AssetTabsItem.vue'
import AssetTabSecurityMaster from './AssetTabSecurityMaster.vue'
import AssetTabPrices from './AssetTabPrices.vue'
import AssetTabTags from './AssetTabTags.vue'
import AssetTabDocuments from './AssetTabDocuments.vue'
import AssetTabContacts from './AssetTabContacts.vue'

type Props = {
  instance: AssetClass
}

const props = defineProps<Props>()

const route = useRoute()
const router = useRouter()

const internalRoute = ref<string>()

const assetId = computed(() => props.instance.id)

const securityMasterComponentKey = computed(
  () =>
    props.instance.field<string>(ASSET_FIELD.LINKED_SECURITY_ID).value ||
    undefined,
)

const routeName = computed(() => internalRoute.value || route.name)

const currentTab = computed<AssetTab | undefined>(() => {
  switch (routeName.value) {
    case ROUTE_NAME.ASSETS_ITEM_SECURITY_MASTER:
      return AssetTab.SECURITY_MASTER
    case ROUTE_NAME.ASSETS_ITEM_PRICES:
    case ROUTE_NAME.ASSETS_ITEM_PRICES_CHART:
      return AssetTab.PRICES
    case ROUTE_NAME.ASSETS_ITEM_TAGS:
      return AssetTab.TAGS
    case ROUTE_NAME.ASSETS_ITEM_DOCUMENTS:
      return AssetTab.DOCUMENTS
    case ROUTE_NAME.ASSETS_ITEM_CONTACTS:
      return AssetTab.CONTACTS
    default:
      return undefined
  }
})

const handleTabChange = (tab: AssetTab) => {
  let name
  switch (tab) {
    case AssetTab.SECURITY_MASTER:
      name = ROUTE_NAME.ASSETS_ITEM_SECURITY_MASTER
      break
    case AssetTab.PRICES:
      name = ROUTE_NAME.ASSETS_ITEM_PRICES
      break
    case AssetTab.TAGS:
      name = ROUTE_NAME.ASSETS_ITEM_TAGS
      break
    case AssetTab.DOCUMENTS:
      name = ROUTE_NAME.ASSETS_ITEM_DOCUMENTS
      break
    case AssetTab.CONTACTS:
      name = ROUTE_NAME.ASSETS_ITEM_CONTACTS
      break
  }
  if (route.name?.toString().startsWith(ROUTE_NAME.ASSETS)) {
    router.push({ name, params: { id: assetId.value } })
  }
  internalRoute.value = name
}
</script>

<script lang="ts">
export default {
  name: 'AssetTabs',
}
</script>

<style scoped>
@import url('./styles/asset.tabs.css');
</style>
