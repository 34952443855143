<template>
  <div v-if="isTypeFilled" class="transaction__link">
    <div class="transaction__link__label">
      Map <strong v-text="amount" /> to:
    </div>
    <div class="transaction__link__list">
      <template v-for="(entry, index) in settings?.entries" :key="entry.name">
        <label v-if="entry.amount" class="transaction__link__item">
          <input
            type="radio"
            class="transaction__link__radio"
            :value="index"
            :checked="currentEntryIndex === index"
            @change="currentEntryIndex = index"
          />
          <span>{{ entry.title }}</span>
        </label>
      </template>
    </div>
  </div>
  <div v-else class="transaction__link__label transaction__link__label--amount">
    Amount: <strong v-text="amount" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch, watchEffect } from 'vue'
import { set } from 'lodash'

import { TransactionClass } from '..'

import { TRANSACTION_FIELD } from '../utils/const'

import { numberFormat } from '@/helpers/numbers'
import { NEGATIVE_RULES, POSITIVE_RULES } from '@/helpers/validate'

type Props = {
  instance: TransactionClass
}

const props = defineProps<Props>()

const currentEntryIndex = ref(0)

const linkAccount = ref('')
const linkAmount = ref(0)

const isTypeFilled = computed(
  () => props.instance.field(TRANSACTION_FIELD.TYPE).value,
)

const settings = computed(() => props.instance.settings)

const amount = computed(() => {
  if (!isTypeFilled.value) {
    const amount = props.instance.entries[currentEntryIndex.value].amount || 0
    return numberFormat(amount)
  }
  const key = `displayAmount${
    currentEntryIndex.value + 1
  }` as keyof TransactionClass
  return props.instance[key]
})

watch(settings, value => {
  props.instance.field(TRANSACTION_FIELD.ENTRIES).value = Array.from(
    { length: value?.entries.length || 1 },
    () => ({
      account_id: '',
      amount: null,
      asset_id: '',
    }),
  )
})

watchEffect(() => {
  const amountRule =
    settings.value?.entries[currentEntryIndex.value]?.amount?.rule
  if (!amountRule) return
  if (
    (POSITIVE_RULES.includes(amountRule) && linkAmount.value < 0) ||
    (NEGATIVE_RULES.includes(amountRule) && linkAmount.value > 0)
  ) {
    linkAmount.value *= -1
  }
  settings.value?.entries.forEach((_, index) => {
    set(
      props.instance.data,
      ['entries', index, 'account_id'],
      index === currentEntryIndex.value ? linkAccount.value : '',
    )
    set(
      props.instance.data,
      ['entries', index, 'amount'],
      index === currentEntryIndex.value ? linkAmount.value : null,
    )
  })
})

onMounted(() => {
  linkAccount.value = props.instance.entries[0].account_id || ''
  linkAmount.value = props.instance.entries[0].amount || 0
})
</script>

<script lang="ts">
export default {
  name: 'TransactionFormLinkPanel',
}
</script>

<style scoped>
@import url('./styles/transaction.css');
</style>
