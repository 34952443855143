export enum FilterComparison {
  EQ = 'EQ',
  NOTEQ = 'NOTEQ',
  CONTAINS = 'CONTAINS',
  NOTCONTAINS = 'NOTCONTAINS',

  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
  LAST = 'LAST',
  RANGE = 'RANGE',

  GREATER = 'GREATER',
  LESS = 'LESS',

  EMPTY = 'EMPTY',
  NOTEMPTY = 'NOTEMPTY',
}

export enum FilterLogic {
  AND = 'AND',
  OR = 'OR',
}
