<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UISlidePanel
      v-bind="{ zIndex, isMinimized, title }"
      @minimize="handleMinimize"
      @hide="handleClose"
    >
      <TagForm v-bind="{ instance: modal.entity }" @close="handleClose" />
    </UISlidePanel>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { TransitionRoot } from '@headlessui/vue'

import { TagClass } from '..'
import { ModalClass, ModalState } from '@types'

import { TAG_MODAL_TITLE, TAG_MODAL_TITLE_NEW } from './utils/const'

import { useModalsStore } from '@/store/modals'

import { UISlidePanel } from '@ui'
import TagForm from './TagForm.vue'

type Props = {
  modal: ModalClass<TagClass>
}

const props = defineProps<Props>()

const modalsStore = useModalsStore()

const title = computed(() =>
  props.modal.entity.isNew ? TAG_MODAL_TITLE_NEW : TAG_MODAL_TITLE,
)

const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const isMinimized = computed(() => props.modal.state === ModalState.HIDDEN)

const zIndex = computed(() => props.modal.zIndex)

const handleMinimize = (flag: boolean) => {
  if (flag) {
    props.modal.hide()
  } else {
    props.modal.show(modalsStore.getZIndex())
  }
}

const handleClose = () => {
  props.modal.conditionalClose()
}
</script>

<script lang="ts">
export default {
  name: 'TagSlideover',
}
</script>
