import { computed, ref, watch } from 'vue'
import { defineStore } from 'pinia'

import { TaskType } from '@types'

import { useAlerts } from '@/plugins/alerts'
import { AlertInstance } from '@/plugins/alerts/types'

import { handleCatchedError } from '@/helpers/common'

import useAnalyticsStore from '@/store/analytics'
import { useAssetsStore } from '@/store/assets'
import { useLinkedDataStore } from '@/store/linkedData'
import { useLinkedDataConnector } from '@/views/LinkedData/hooks/useLinkedDataConnector'
import { useLinkedDataConnectorsStore } from '@/store/linkedData/connectors'
import { useLinkedDataTransactionsStore } from '@/store/linkedData/transactions'
import { useLinkedDataTransactionsTagsStore } from '@/store/linkedData/transactionsTags'
import { useRepositoriesStore } from '@/store/repositories'
import { useTasksStore } from '@/store/tasks'
import { useTransactionsStore } from '@/store/transactions'
import { useTagsStore } from '@/store/tags'

export const useTasksService = defineStore('tasksService', () => {
  // INIT

  const analyticsStore = useAnalyticsStore()
  const assetsStore = useAssetsStore()
  const linkedDataStore = useLinkedDataStore()
  const linkedDataConnector = useLinkedDataConnector()
  const linkedDataConnectorsStore = useLinkedDataConnectorsStore()
  const linkedDataTransactionsStore = useLinkedDataTransactionsStore()
  const linkedDataTransactionsTagsStore = useLinkedDataTransactionsTagsStore()
  const repositoriesStore = useRepositoriesStore()
  const tagsStore = useTagsStore()
  const tasksStore = useTasksStore()
  const transactionsStore = useTransactionsStore()

  const { progress, remove } = useAlerts()

  const alerts = ref<Record<string, AlertInstance>>({})

  // GETTERS

  const getSyncingAccountsTypes = computed(() => {
    return tasksStore.getProcessing
      .filter(task => task.type === TaskType.SYNC_HOLDING_ACCOUNT)
      .map(task => task.linked_type?.toLowerCase())
  })

  const isLinkedDataSyncing = computed(
    () => !!getSyncingAccountsTypes.value.length,
  )

  const isRepositoryImporting = computed(
    () =>
      !!tasksStore.getProcessing.find(task =>
        task.type.startsWith(TaskType.REPOSITORY_IMPORT),
      ),
  )

  // ACTIONS
  watch(isLinkedDataSyncing, async value => {
    const key = 'HOLDING_ACCOUNTS'
    linkedDataConnector.syncing.value = value
    if (value) {
      const alert = await progress({
        message:
          'Your accounts are now being refreshed. This may take several minutes.',
      })
      alerts.value[key] = alert
    } else if (alerts.value?.[key]) {
      await remove(alerts.value[key])
      delete alerts.value[key]
      try {
        Promise.all([
          assetsStore.fetchAssets(),
          transactionsStore.fetch(),
          tagsStore.fetch(),
          linkedDataStore.fetch(),
          linkedDataTransactionsStore.fetch(),
          linkedDataTransactionsTagsStore.fetch(),
          linkedDataConnectorsStore.fetch(),
        ])
        analyticsStore.markAsDeprecated()
      } catch (e) {
        handleCatchedError(e as string)
      }
    }
  })

  watch(isRepositoryImporting, async values => {
    const key = 'IMPORT_REPOSITORY'
    if (values) {
      const alert = await progress({
        message:
          'Your repository is now being imported. This may take several minutes.',
      })
      alerts.value[key] = alert
    } else if (alerts.value?.[key]) {
      await remove(alerts.value[key])
      delete alerts.value[key]
      try {
        repositoriesStore.fetchRepositories()
      } catch (e) {
        handleCatchedError(e as string)
      }
    }
  })

  return {
    getSyncingAccountsTypes,
    isLinkedDataSyncing,
  }
})
