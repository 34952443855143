import { ref } from 'vue'
import { DateTime } from 'luxon'

import {
  DATE_FORMATS,
  ISO_DATE_SHORT_FORMAT,
  ISO_DATE_CUT_FORMAT,
  ISO_DATE_FORMAT,
  LEAP_YEAR_EXAMPLE,
  TIME_FORMAT,
} from './utils/const'
import { DateTimeObject, DateTimeOptions } from './utils/types'

export const dateFormat = ref(DATE_FORMATS[0])

export function isLongDate(value: string) {
  return ISO_DATE_FORMAT.test(value) || ISO_DATE_CUT_FORMAT.test(value)
}

export function isShortDate(value: string) {
  return ISO_DATE_SHORT_FORMAT.test(value)
}

export function isDate(value?: string) {
  return value && (isLongDate(value) || isShortDate(value))
}

const mapDateFormat = (data: string) =>
  data.replace('yyyy', 'yy').replace('MM', 'M').replace('dd', 'd')

export const stringToDateTime = (data?: string | null, formatted = false) => {
  if (!data) return
  if (formatted) {
    return DateTime.fromFormat(data, mapDateFormat(dateFormat.value), {
      zone: 'utc',
    })
  }
  if (isShortDate(data)) {
    return DateTime.fromISO(data, {
      zone: 'utc',
    })
  }
  if (isLongDate(data)) {
    return DateTime.fromISO(data)
  }
}

export const dateTimeToString = (
  date?: DateTime | null,
  options?: DateTimeOptions,
) => {
  if (!(date instanceof DateTime)) {
    return ''
  }
  if (options?.iso) {
    return options.withTime ? date.toISO() : date.toISODate()
  } else if (options?.relative) {
    const today = DateTime.now()
    let stringDate
    if (today.hasSame(date, 'day')) {
      stringDate = 'today'
    }
    const yesterday = today.plus({ days: -1 })
    if (yesterday.hasSame(date, 'day')) {
      stringDate = 'yesterday'
    }
    if (!stringDate) {
      const relativeDateFormat =
        'dd MMM' + (DateTime.now().hasSame(date, 'year') ? '' : ' yyyy')
      stringDate = date.toFormat(relativeDateFormat)
    }
    if (options?.withTime) {
      stringDate += date.toFormat(` | ${TIME_FORMAT}`)
    }
    return stringDate
  } else if (options?.customFormat) {
    return date.toFormat(options?.customFormat)
  } else {
    return options?.withTime
      ? date.toFormat(`${dateFormat.value} ${TIME_FORMAT}`)
      : date.toFormat(dateFormat.value)
  }
}

export const stringToLocalDateString = (
  data?: string | null,
  options?: DateTimeOptions,
) => {
  return dateTimeToString(stringToDateTime(data), options)
}

export const objectDateTimeToString = (
  data?: DateTimeObject,
  options?: DateTimeOptions,
) => {
  return dateTimeToString(
    data && DateTime.fromObject(data, { zone: 'utc' }),
    options,
  )
}

export function getCurrentDate(): DateTime
export function getCurrentDate(options: DateTimeOptions): string
export function getCurrentDate(options?: DateTimeOptions) {
  const date = DateTime.now()
  if (options) {
    return dateTimeToString(date, { iso: true, ...options })
  } else {
    return DateTime.utc(date.year, date.month, date.day)
  }
}

export const isTheSameDates = (dateOne?: string, dateTwo?: string) => {
  const dateOneObject = stringToDateTime(dateOne)
  const dateTwoObject = stringToDateTime(dateTwo)
  return dateTwoObject && dateOneObject?.hasSame(dateTwoObject, 'day')
}

export const getDaysInMonthCount = (month: number, year?: number | null) => {
  if (!year) year = LEAP_YEAR_EXAMPLE // any leap year works as default
  return new Date(year, month, 0).getDate()
}

export const getLastWeek = (date: DateTime) => {
  return date.startOf('week').plus({ days: -1 })
}

export const getLastMonth = (date: DateTime) => {
  return date.startOf('month').plus({ days: -1 })
}

export const getLastYear = (date: DateTime) => {
  return date.startOf('year').plus({ days: -1 })
}

export const monthDateToName = (value?: string | null) => {
  const date = stringToDateTime(value)
  return `${date?.monthShort}`
}

export const convertDateToISO = (date: string) => {
  return stringToDateTime(date, true)?.toISODate()
}
