<template>
  <template v-if="transactionsErrors?.length || tasksNotifies?.length">
    <template v-if="isMobile">
      <HeaderNotificationsIcon @click="isOpen = true" />
      <teleport to="body">
        <UIModal
          :is-open="isOpen"
          :type="ModalType.SLIDEPANEL"
          title="Notifications"
          wrapper-class="flex-auto"
          hide-close-button
          hide-minimise-button
          @hide="isOpen = false"
        >
          <HeaderNotificationsList
            :notifies="tasksNotifies"
            :errors="transactionsErrors"
            @click:item="isOpen = false"
          />
        </UIModal>
      </teleport>
    </template>
    <Dropdown v-else placement="bottom" auto-boundary-max-size>
      <template #default="{ toggle }">
        <HeaderNotificationsIcon @click="toggle" />
      </template>
      <template #popper="{ hide }">
        <HeaderNotificationsList
          :notifies="tasksNotifies"
          :errors="transactionsErrors"
          wrapped
          @click:item="hide"
        />
      </template>
    </Dropdown>
  </template>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { Dropdown } from 'floating-vue'

import { ModalType, TransactionError, TransactionErrorNotify } from '@types'

import { TRANSACTION_FIELD } from '@/entities/transactions/utils/const'
import { EMPTY_VALUE_PLACEHOLDER } from '@/const/common'

import { isDate, stringToLocalDateString } from '@/helpers/dates'

import { useResponsive } from '@/plugins/responsiveUI'

import { useTasksStore } from '@/store/tasks'
import { useTransactionsStore } from '@/store/transactions'
import { useTransactionsBunchStore } from '@/store/transactions/bunch'

import { filterNotify, prepareNotify } from './utils/helpers'

import { UIModal } from '@ui'
import HeaderNotificationsList from './HeaderNotificationsList.vue'
import HeaderNotificationsIcon from './HeaderNotificationsIcon.vue'

const transactionsStore = useTransactionsStore()
const transactionsBunchStore = useTransactionsBunchStore()
const tasksStore = useTasksStore()

const { isMobile } = useResponsive()

const isOpen = ref(false)

const transactionsErrors = computed(() => {
  if (!transactionsStore.initFlag) return
  return transactionsStore.getErrors?.reduce(
    (calc: TransactionErrorNotify[], item: TransactionError) => {
      const entries = item.errors?.entries
      if (entries) {
        const instance = transactionsBunchStore.getList.get(item.id)
        Object.values(entries).forEach(entry => {
          Object.values(entry).forEach(message => {
            calc.push({
              id: item.id,
              date:
                stringToLocalDateString(
                  instance.field(TRANSACTION_FIELD.DATE),
                ) || EMPTY_VALUE_PLACEHOLDER,
              type: instance.field(TRANSACTION_FIELD.TYPE),
              message: isDate(message)
                ? stringToLocalDateString(message)
                : message,
            })
          })
        })
      }
      return calc
    },
    [],
  )
})

const tasksNotifies = computed(() => {
  return tasksStore.getFinished.filter(filterNotify).map(prepareNotify)
})
</script>

<script lang="ts">
export default {
  name: 'HeaderNotifications',
}
</script>
