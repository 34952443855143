import { Ref, computed, onMounted, ref } from 'vue'
import { get } from 'lodash'
import { watchDebounced } from '@vueuse/core'

import { GridColumn, GridItem } from '@types'
import { FilterGroup } from '../UI/Filter/utils/types'
import { FilterLogic } from '../UI/Filter/utils/enums'
import { filterItemByExpressions } from '../UI/Filter/utils/business-logic'

const useGridFilter = <T extends GridItem>(
  columns: Ref<GridColumn[]>,
  items: Ref<T[]>,
  search: Ref<string | undefined>,
  filter?: Ref<FilterGroup | undefined>,
  exclude?: string[],
) => {
  const debouncedSearch = ref<string>()
  const debouncedFilter = ref<FilterGroup>()

  const filterLogic = computed(
    () => debouncedFilter?.value?.logic || FilterLogic.AND,
  )

  const filteredItems = computed(() => {
    const params = debouncedFilter?.value?.params
    if (debouncedSearch.value === '' && !params?.length) return items.value
    return items.value.filter(item => {
      let isFiltered = true
      const preparedItem: GridItem = {}
      columns.value.forEach(column => {
        const field = column.name
        let value = get(item, field)
        if (column?.formatter && !exclude?.includes(field)) {
          value = column.formatter?.(value, item)
        }
        preparedItem[field] = value
      })
      if (params?.length) {
        isFiltered = !!filterItemByExpressions(
          preparedItem,
          params,
          filterLogic.value,
        )
      }
      const lookup = Object.values(preparedItem).join(' ').toLowerCase()
      const isSearched =
        !debouncedSearch.value ||
        (lookup && lookup.includes(debouncedSearch.value))
      return isSearched && isFiltered
    })
  })

  const prepareSearchQuery = (value: string) => value.trim().toLowerCase()

  watchDebounced(
    search,
    value => {
      debouncedSearch.value = value && prepareSearchQuery(value)
    },
    { debounce: 500 },
  )

  watchDebounced(
    () => filter?.value,
    value => {
      debouncedFilter.value = value
    },
    { debounce: 1000 },
  )

  onMounted(() => {
    debouncedSearch.value = search.value && prepareSearchQuery(search.value)
    debouncedFilter.value = filter?.value
  })

  return filteredItems
}

export default useGridFilter
